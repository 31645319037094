import { AxiosResponse } from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  _chartEmptyPerformance,
  _emptyConnectedCredentials,
  _emptyConnectedEntity,
  _portfolioWallet,
} from 'src/_mock/_wallets';
import {
  ChartPerformance,
  ChartPerformanceMap,
  DeleteMovementPayload,
  DividendMonthYear,
  DividendsByPeriod,
  GainsPerPeriodMap,
  ImportError,
  NetWorthType,
  NetworthPeriodPayload,
  PerformanceStatisticsMap,
  PerformanceType,
  Portfolio,
  PortfolioMap,
  PortfolioSettingsMap,
  ReferenceIndice,
  RiskMetricsMap,
  TimeSeriesMap,
  Transaction,
  UpdateAllPayload,
  UpdatePortfolioPerformancePayload,
  UpdatePortfolioRiskPayload,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState } from 'src/redux/store';
import {
  AddPortfolioResponseNetType,
  GetAllInfoResponseNetType,
  GetImportErrorsResponseNetType,
  GetMissingSecuritiesResponseNetType,
  GetMovementsResponseNetType,
  GetNetworthPeriodNetType,
  GetPorfolioPositionsResponseNetType,
  GetPorfolioResponseNetType,
  GetPorfoliosResponseNetType,
  GetPortfolioDividendsResponseNetType,
  GetPortfolioSettingsResponseNetType,
  GetPortfolioSummaryResponseNetType,
  GetRiskResponseNetType,
  GetSummaryDashResponseNetType,
  UnknownSecurities,
} from './NetTypes';

export function transformAddPortfolio(
  name: string,
  flanksEntity: string,
  entityName: string,
  liquidityConsidered: boolean,
  baseCurrency: string
): (response: AxiosResponse<AddPortfolioResponseNetType>, state: RootState) => Portfolio {
  return (response: AxiosResponse<AddPortfolioResponseNetType>, state: RootState) => {
    if (flanksEntity !== '') {
      return _portfolioWallet(
        response.data.message,
        name,
        '',
        baseCurrency,
        'PENDING',
        liquidityConsidered,
        'OWNER',
        [],
        _emptyConnectedEntity(flanksEntity, entityName),
        _emptyConnectedCredentials()
      );
    }
    return _portfolioWallet(
      response.data.message,
      name,
      '',
      baseCurrency,
      'READY',
      liquidityConsidered
    );
  };
}

export function transformAddManualPortfolio(
  name: string,
  baseCurrency: string,
  liquidityConsidered: boolean,
  entity?: string
): (response: AxiosResponse<AddPortfolioResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<AddPortfolioResponseNetType>, _state: RootState) => {
    const entityData = entity ? { id: entity, name: entity } : undefined;

    return _portfolioWallet(
      data.message,
      name,
      '',
      baseCurrency,
      'READY',
      liquidityConsidered,
      'OWNER',
      [],
      entityData,
      undefined,
      false,
      'STOCKS'
    );
  };
}

export function transformGetPorfolios(): (
  response: AxiosResponse<GetPorfoliosResponseNetType>,
  state: RootState
) => PortfolioMap {
  return ({ data }: AxiosResponse<GetPorfoliosResponseNetType>, state: RootState) => {
    const portfolios: PortfolioMap = {};
    const oldPortfolios = state.portfolio.portfolios;
    data.portfolios.forEach(
      ({
        id,
        name,
        status,
        owners,
        connectionDetails,
        entity,
        role,
        liquidityConsidered,
        firstTransactionDate,
        type,
      }) => {
        let activesNotFoundShowed =
          oldPortfolios[id] && oldPortfolios[id].settings.activesNotFoundShowed;
        if (id)
          if (!!oldPortfolios[id] && oldPortfolios[id].firstTransactionDate !== '')
            portfolios[id] = {
              ...oldPortfolios[id],
              stateDataTab: {
                ...oldPortfolios[id].stateDataTab,
                isLoaded: false,
                tabRefresh: {
                  refreshPositions: true,
                  refreshDividends: true,
                  refreshMovements: true,
                  refreshPerformance: true,
                },
              },
              entity,
              liquidityConsidered,
              type,
            };
          else
            portfolios[id] = _portfolioWallet(
              id,
              name,
              firstTransactionDate,
              'EUR',
              status,
              liquidityConsidered ?? false,
              role,
              owners,
              entity,
              connectionDetails,
              activesNotFoundShowed,
              type
            );
      }
    );
    return portfolios;
  };
}

export function transformGetSummaryPorfolio(
  id: string
): (response: AxiosResponse<GetSummaryDashResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetSummaryDashResponseNetType>, state: RootState) => {
    let portfolio = { ...state.portfolio.portfolios[id] };

    if (Object.keys(data).length > 0) {
      portfolio.importError = data.importError;
      portfolio.incomplete = data.incomplete;
      portfolio.empty = data.empty;

      if (data.firstTransactionDate) portfolio.firstTransactionDate = data.firstTransactionDate;
      if (data.riskFreeAssetBenchmark)
        portfolio.riskFreeAssetBenchmark = data.riskFreeAssetBenchmark;
      if (data.latestOperation) portfolio.latestOperation = data.latestOperation;

      portfolio.performanceType = data.method;

      const newReferenceIndices: ReferenceIndice[] = [];

      if (data.referenceIndices) {
        data.referenceIndices.forEach((i) => {
          const index = portfolio.referenceIndices.findIndex((k) => k.meta.id === i.id);
          newReferenceIndices.push({
            annualPerformances: { performance: {}, value: {} },
            accumulatedPerformances: {
              accumulatedPerformanceByPeriod: {},
              accumulatedValueByPeriod: {},
            },
            performanceTimeSeries: {},
            absoluteValueTimeSeries: {},
            annualizedPerformances: {},
            components: [],
            riskMetrics: {},
            meta: {
              ...i,
            },
            ...(index !== -1 ? portfolio.referenceIndices[index] : {}),
          });
        });
      }

      portfolio.referenceIndices = newReferenceIndices;

      if (data.gainsPerPeriod) {
        let newGainsPerPeriod: GainsPerPeriodMap = {};

        Object.keys(data.gainsPerPeriod).forEach((i) => {
          const periodObject = data.gainsPerPeriod[i];
          newGainsPerPeriod[i] = {
            gains: periodObject.map((item) => item.gains),
            invested: periodObject.map((item) => item.invested),
            totalValue: periodObject.map((item) => item.totalValue),
            axis: periodObject.map((item) => {
              if (i === 'lastYear') return moment(item.lastDayOfPeriod).format('MMM YY');
              if (i === 'lastThreeYears') {
                const quarter = moment(item.lastDayOfPeriod).quarter();
                return 'Q' + quarter + ' ' + moment(item.lastDayOfPeriod).format('YY') + "'";
              }
              if (i === 'lastSixYears') {
                const quarter = moment(item.lastDayOfPeriod).quarter();
                const semester = quarter === 1 || quarter === 2 ? 1 : 2;
                return 'S' + semester + ' ' + moment(item.lastDayOfPeriod).format('YY') + "'";
              }
              if (i === 'all') return moment(item.lastDayOfPeriod).format('YYYY');
              return '';
            }),
          };
        });
        portfolio.gainsPerPeriod = newGainsPerPeriod;
      }

      if (data.meta) portfolio.performanceMeta = data.meta;
      if (data.todaysGainers) portfolio.todaysGainers = data.todaysGainers;
      if (data.todaysLosers) portfolio.todaysLosers = data.todaysLosers;
      if (data.currencyPairPerformances)
        portfolio.currencyPairPerformances = data.currencyPairPerformances;
      if (data.upcomingEvents) portfolio.upcomingEvents = data.upcomingEvents;
    }
    let newStateDataTab = { ...portfolio.stateDataTab };
    portfolio.stateDataTab = {
      ...newStateDataTab,
      isLoaded: true,
      performance: { ...newStateDataTab.performance },
    };
    return portfolio;
  };
}

export function transformPerformancePorfolio(
  id: string,
  fullLoad: boolean,
  period: string
): (
  response: AxiosResponse<GetPorfolioResponseNetType>,
  state: RootState
) => UpdatePortfolioPerformancePayload {
  return ({ data }: AxiosResponse<GetPorfolioResponseNetType>, state: RootState) => {
    let portfolio = { ...state.portfolio.portfolios[id] };

    let performanceMap: ChartPerformanceMap = { ...portfolio.performance };
    let absoluteValueMap: ChartPerformanceMap = { ...portfolio.absoluteValue };
    let performanceStatistics: PerformanceStatisticsMap = { ...portfolio.performanceStatistics };
    let referenceIndices: ReferenceIndice[] = [...portfolio.referenceIndices];
    let yearlyPerformance = { ...portfolio.yearlyPerformance };
    let accumulatedPerformances = { ...portfolio.accumulatedPerformances };
    let annualizedPerformances = { ...portfolio.annualizedPerformances };
    let performanceMeta = { ...portfolio.performanceMeta };

    let newPerformance: ChartPerformance = _chartEmptyPerformance();
    let newAbsoluteValue: ChartPerformance = _chartEmptyPerformance();

    if (period === '' || data.method !== portfolio.performanceType || fullLoad) {
      performanceMap = {};
      absoluteValueMap = {};
      performanceStatistics = {};
      referenceIndices = [];
    }

    if (data.performanceStatistics) {
      performanceStatistics[period === '' ? '7D' : period] = data.performanceStatistics;
    }

    if (data.meta) {
      performanceMeta = { ...data.meta };
    }

    if (data.yearlyPerformance) {
      yearlyPerformance = { ...data.yearlyPerformance };
    }

    if (data.performance?.portfolio.accumulatedPerformances) {
      accumulatedPerformances = { ...data.performance.portfolio.accumulatedPerformances };
    }

    if (data.performance?.portfolio.annualizedPerformances) {
      annualizedPerformances = { ...data.performance?.portfolio.annualizedPerformances };
    }

    if (data.performance) {
      newPerformance = {
        period: period === '' ? '7D' : period,
        amount: data.performance?.portfolio.valueChange,
        percent: data.performance?.portfolio.performance * 100,
        annualizedPerformance: (data.performance?.portfolio?.annualizedPerformance ?? 0) * 100,
        timeSeries: data.performance?.portfolio.performanceTimeSeries.map((item) => ({
          value: item.value * 100,
          date: item.date,
        })),
        riskMetrics: data.performance?.portfolio?.riskMetrics,
        investedAmount: [],
        positionsPerformance: data.positionsPerformance,
      };
      referenceIndices =
        data.performance?.referenceIndices.map((r) => {
          const index = portfolio.referenceIndices.findIndex((i) => i.meta.id === r.meta.id);
          const rTimeSeries: TimeSeriesMap = {
            ...(index !== -1 ? portfolio.referenceIndices[index].performanceTimeSeries : {}),
            [period === '' ? '7D' : period]: r.performanceTimeSeries.map((item) => ({
              value: item.value * 100,
              date: item.date,
            })),
          };
          return {
            ...portfolio.referenceIndices[index],
            ...r,
            absoluteValueTimeSeries:
              portfolio?.referenceIndices[index]?.absoluteValueTimeSeries ?? {},
            performanceTimeSeries: rTimeSeries,
          };
        }) ?? [];
    }

    if (data.absoluteValue) {
      newAbsoluteValue = {
        period: period === '' ? '7D' : period,
        amount: data.absoluteValue?.portfolio.valueChange ?? 0,
        percent: data.absoluteValue?.portfolio.performance * 100 ?? 0,
        timeSeries:
          data.absoluteValue?.portfolio.absoluteValueTimeSeries.map((item) => ({
            value: item.value,
            date: item.date,
          })) ?? [],
        investedAmount: data.absoluteValue.investedAmountPerPeriod,
        positionsPerformance: [],
      };

      referenceIndices =
        data.absoluteValue?.referenceIndices.map((r) => {
          const index = referenceIndices.findIndex((i) => i.meta.id === r.meta.id);
          const rTimeSeries: TimeSeriesMap = {
            ...(index !== -1 ? referenceIndices[index].absoluteValueTimeSeries : {}),
            [period === '' ? '7D' : period]: r.absoluteValueTimeSeries.map((item) => ({
              value: item.value,
              date: item.date,
            })),
          };
          return {
            ...referenceIndices[index],
            absoluteValueTimeSeries: rTimeSeries,
          };
        }) ?? [];
    }

    performanceMap[period === '' ? '7D' : period] = {
      ...newPerformance,
    };

    absoluteValueMap[period === '' ? '7D' : period] = {
      ...newAbsoluteValue,
    };

    return {
      id,
      performance: performanceMap,
      absoluteValue: absoluteValueMap,
      performanceStatistics: performanceStatistics,
      performanceType: data.method,
      monthlyPerformance: data.monthlyPerformance,
      performanceMeta,
      referenceIndices,
      yearlyPerformance,
      accumulatedPerformances,
      annualizedPerformances,
    };
  };
}

export function transformRiskPortfolio(
  id: string,
  period: string = ''
): (
  response: AxiosResponse<GetRiskResponseNetType>,
  state: RootState
) => UpdatePortfolioRiskPayload {
  return ({ data }: AxiosResponse<GetRiskResponseNetType>, state: RootState) => {
    const portfolio: Portfolio = state.portfolio.portfolios[id];
    let newRiskMetrics: RiskMetricsMap = { ...portfolio.riskMetrics };

    if (period === '') {
      newRiskMetrics = {};
    }

    newRiskMetrics[period] = {
      ...newRiskMetrics[period],
      ...data.portfolio.riskMetrics,
    };

    const newReferemceIndices = portfolio.referenceIndices.map((i) => {
      const index = data.referenceIndices.findIndex((k) => k.meta.id === i.meta.id);
      if (index !== -1) {
        let newIndRiskMetrics: RiskMetricsMap = { ...i.riskMetrics };
        if (period === '') {
          newIndRiskMetrics = {};
        }
        newIndRiskMetrics[period] = {
          ...i.riskMetrics[period],
          ...data.referenceIndices[index].riskMetrics,
        };
        return { ...i, riskMetrics: newIndRiskMetrics };
      }
      return i;
    });

    return {
      id,
      riskMetrics: newRiskMetrics,
      referenceIndices: newReferemceIndices,
    };
  };
}

export function transformGetMovements(
  id: string,
  isFirstTime: boolean,
  isCustom: boolean
): (response: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => Portfolio {
  return (response: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => {
    const portfolio: Portfolio = state.portfolio.portfolios[id];
    return {
      // aqui
      ...portfolio,
      transactions: _.uniqBy([...response.data.transactions], 'id'),
      transactionsCount: {
        ...portfolio.transactionsCount,
        total: isFirstTime ? response.data.totalItems : portfolio.transactionsCount.total,
        custom: isCustom ? response.data.totalItems : 0,
      },
    };
  };
}

export function transformGetAllMovements(): (
  response: AxiosResponse<GetMovementsResponseNetType>,
  state: RootState
) => GetMovementsResponseNetType {
  return ({ data }: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => ({
    ...data,
  });
}

export function transformGetSkippedMovements(): (
  response: AxiosResponse<GetMovementsResponseNetType>,
  state: RootState
) => { transactions: Transaction[]; totalItems: number } {
  return (response: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => ({
    transactions: _.uniqBy([...response.data.transactions], 'id'),
    totalItems: response.data.totalItems,
  });
}

export function transformDeleteMovements(
  portfolioId: string,
  transactionId: string
): (
  response: AxiosResponse<GetMovementsResponseNetType>,
  state: RootState
) => DeleteMovementPayload {
  return (_response: AxiosResponse<GetMovementsResponseNetType>, _state: RootState) => ({
    portfolioId,
    transactionId,
  });
}

export function transformGetPortfolioDividends(
  portfolioId: string,
  period: string
): (response: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => {
    let portfolio = { ...state.portfolio.portfolios[portfolioId] };
    let newDividendsMap = { ...portfolio.dividends.incomeMap };
    let newDividendsByMonthAndYear = [...portfolio.dividends.incomeByMonthAndYear];

    if (period === '') {
      newDividendsMap = {};
      newDividendsByMonthAndYear = [];
    }

    if (data.incomeByMonthAndYear !== null)
      newDividendsByMonthAndYear = _.orderBy(data.incomeByMonthAndYear, ['year', 'month']);

    newDividendsMap[period === '' ? '1Y' : period] = [...data.incomeTimeSeries];

    return {
      ...portfolio,
      dividends: {
        incomeMap: newDividendsMap,
        incomeByMonthAndYear: newDividendsByMonthAndYear,
        total: data.totalIncome,
        ytd: data.ytdIncome,
      },
    };
  };
}

export function transformGetPortfolioBonds(
  portfolioId: string,
  period: string
): (response: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => {
    let portfolio = { ...state.portfolio.portfolios[portfolioId] };
    let newBondsMap = { ...portfolio.bonds.incomeMap };
    let newBondsByMonthAndYear = [...portfolio.bonds.incomeByMonthAndYear];

    if (period === '') {
      newBondsMap = {};
      newBondsByMonthAndYear = [];
    }

    if (data.incomeByMonthAndYear !== null)
      newBondsByMonthAndYear = _.orderBy(data.incomeByMonthAndYear, ['year', 'month']);

    newBondsMap[period === '' ? '1Y' : period] = [...data.incomeTimeSeries];

    return {
      ...portfolio,
      bonds: {
        incomeMap: newBondsMap,
        incomeByMonthAndYear: newBondsByMonthAndYear,
        total: data.totalIncome,
        ytd: data.ytdIncome,
      },
    };
  };
}

export function transformGetPorfolioPositions(
  id: string
): (response: AxiosResponse<GetPorfolioPositionsResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPorfolioPositionsResponseNetType>, state: RootState) => {
    let portfolio = state.portfolio.portfolios[id]
      ? { ...state.portfolio.portfolios[id] }
      : ({ id } as Portfolio);
    if (data.positions) portfolio.positions = [...data.positions];
    if (data.historicalPositions) portfolio.historicalPositions = [...data.historicalPositions];
    return portfolio;
  };
}

export function transformGetMissingSecurities(): (
  response: AxiosResponse<GetMissingSecuritiesResponseNetType>,
  state: RootState
) => UnknownSecurities[] {
  return ({ data }: AxiosResponse<GetMissingSecuritiesResponseNetType>, _state: RootState) =>
    data.unknownSecurities;
}

export function transformUpdatePortfolioVisibility(
  portfolioSettings: PortfolioSettingsMap
): (_response: AxiosResponse<any>, state: RootState) => PortfolioMap {
  return (_response: AxiosResponse<any>, state: RootState) => {
    let newPortfolios = { ...state.portfolio.portfolios };
    Object.keys(portfolioSettings).forEach((i) => {
      if (!!newPortfolios[i] && !!newPortfolios[i].id) {
        let newPortfolio = { ...newPortfolios[i] };
        newPortfolio.settings = portfolioSettings[i];
        newPortfolios[i] = newPortfolio;
      }
    });
    return newPortfolios;
  };
}

export function transformUpdatePortfolioSettings(
  id: string,
  performanceType: PerformanceType
): (_response: AxiosResponse<any>, state: RootState) => PortfolioMap {
  return (_response: AxiosResponse<any>, state: RootState) => {
    let newPortfolios = { ...state.portfolio.portfolios };
    let newPortfolio = { ...newPortfolios[id] };
    newPortfolio.performanceType = performanceType;
    newPortfolios[id] = newPortfolio;
    return newPortfolios;
  };
}

export function transformGetPortfolioSettings(): (
  _response: AxiosResponse<GetPortfolioSettingsResponseNetType>,
  state: RootState
) => PortfolioMap {
  return ({ data }: AxiosResponse<GetPortfolioSettingsResponseNetType>, state: RootState) => {
    let newPortfolios = { ...state.portfolio.portfolios };
    data.portfolioSettings.forEach((i) => {
      if (!!newPortfolios[i.portfolioId] && !!newPortfolios[i.portfolioId].id) {
        let newPortfolio = { ...newPortfolios[i.portfolioId] };
        newPortfolio.settings = {
          ...newPortfolio.settings,
          share: i.visibility,
          showDividends: i.showDividends,
          showHistoricalPositions: i.showHistoricalPositions,
          showHoldings: i.showHoldings,
          showMoney: i.showMoney,
          showReturn: i.showReturn,
          showTransactions: i.showTransactions,
          password: i.password,
          shareLink: i.shareLink,
        };
        newPortfolios[i.portfolioId] = newPortfolio;
      }
    });
    return newPortfolios;
  };
}

export function transformGetPortfolioByCredentialSettings(): (
  _response: AxiosResponse<GetPortfolioSettingsResponseNetType>,
  state: RootState
) => PortfolioSettingsMap {
  return ({ data }: AxiosResponse<GetPortfolioSettingsResponseNetType>, _state: RootState) => {
    let newSettings: PortfolioSettingsMap = {};
    data.portfolioSettings.forEach((i) => {
      newSettings[i.portfolioId] = {
        share: i.visibility,
        showDividends: i.showDividends,
        showHistoricalPositions: i.showHistoricalPositions,
        showHoldings: i.showHoldings,
        showMoney: i.showMoney,
        showReturn: i.showReturn,
        showTransactions: i.showTransactions,
        password: i.password,
        shareLink: i.shareLink,
      };
    });
    return newSettings;
  };
}

export function transformGetPortfolioSummary(
  id: string,
  period: string
): (_response: AxiosResponse<GetPortfolioSummaryResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPortfolioSummaryResponseNetType>, state: RootState) => {
    let portfolio = { ...state.portfolio.portfolios[id] };
    let performanceStatistics: PerformanceStatisticsMap = { ...portfolio.performanceStatistics };
    performanceStatistics[period] = data.performanceStatistics;
    portfolio.performanceStatistics = performanceStatistics;
    return portfolio;
  };
}

export function transformGetImportErrors(): (
  _response: AxiosResponse<GetImportErrorsResponseNetType>,
  state: RootState
) => ImportError[] {
  return ({ data }: AxiosResponse<GetImportErrorsResponseNetType>, state: RootState) =>
    data.importErrors;
}

export function transformGetNetWorth(): (
  _response: AxiosResponse<NetWorthType>,
  state: RootState
) => NetWorthType {
  return ({ data }: AxiosResponse<NetWorthType>, state: RootState) => ({
    ...state.portfolio.netWorth,
    ...data,
  });
}

export function transformGetNetWorthPeriod(
  fullLoad: boolean,
  period: string = ''
): (response: AxiosResponse<GetNetworthPeriodNetType>, state: RootState) => NetworthPeriodPayload {
  return ({ data }: AxiosResponse<GetNetworthPeriodNetType>, state: RootState) => {
    let newNetworthPeriod: NetworthPeriodPayload = {
      performance: fullLoad ? { '7D': [] } : { ...state.portfolio.netWorth.performance },
      dailyGain: state.portfolio.netWorth.dailyGain,
    };

    if (data.netWorthSnapshotsList) {
      newNetworthPeriod.performance[period === '' ? '7D' : period] = [
        ...data.netWorthSnapshotsList.map((item) => ({ value: item.value, date: item.date })),
      ];
    }

    if (data.dailyGain !== undefined && data.dailyGain !== null)
      newNetworthPeriod.dailyGain = data.dailyGain;

    return newNetworthPeriod;
  };
}

export function transformGetAllInfo(
  fullLoad: boolean = false,
  period: string = ''
): (response: AxiosResponse<GetAllInfoResponseNetType>, state: RootState) => UpdateAllPayload {
  return ({ data }: AxiosResponse<GetAllInfoResponseNetType>, state: RootState) => {
    let portfolios: PortfolioMap = { ...state.portfolio.portfolios };
    let { agregatedSummary } = state.portfolio;

    Object.keys(data.portfoliosSummary).forEach((key) => {
      let portfolio = { ...portfolios[key] };

      if (portfolio) {
        let performanceMap: ChartPerformanceMap = { ...portfolio.performance };
        let absoluteValueMap: ChartPerformanceMap = { ...portfolio.absoluteValue };

        let newPerformance: ChartPerformance = _chartEmptyPerformance();
        let newAbsoluteValue: ChartPerformance = _chartEmptyPerformance();

        if (period === '' || fullLoad) {
          performanceMap = {};
          absoluteValueMap = {};
        }

        const { performance, absoluteValue, positionsPerformance, meta } =
          data.portfoliosSummary[key];

        if (performance) {
          newPerformance = {
            period: period === '' ? '7D' : period,
            amount: performance?.portfolio.valueChange ?? 0,
            percent: performance?.portfolio.performance * 100 ?? 0,
            annualizedPerformance: (performance?.portfolio?.annualizedPerformance ?? 0) * 100,
            timeSeries:
              performance?.portfolio.performanceTimeSeries.map((item) => ({
                value: item.value * 100,
                date: item.date,
              })) ?? [],
            riskMetrics: performance?.portfolio?.riskMetrics,
            investedAmount: [],
            positionsPerformance,
          };
        }

        if (absoluteValue) {
          newAbsoluteValue = {
            period: period === '' ? '7D' : period,
            amount: absoluteValue.portfolio.valueChange,
            percent: absoluteValue.portfolio.performance * 100,
            timeSeries: absoluteValue.portfolio.absoluteValueTimeSeries.map((item) => ({
              value: item.value,
              date: item.date,
            })),
            investedAmount: absoluteValue.investedAmountPerPeriod,
            positionsPerformance: [],
          };
        }

        performanceMap[period === '' ? '7D' : period] = {
          ...newPerformance,
        };

        absoluteValueMap[period === '' ? '7D' : period] = {
          ...newAbsoluteValue,
        };

        if (meta) portfolio.performanceMeta = meta;

        portfolios[key] = {
          ...portfolio,
          performance: performanceMap,
          absoluteValue: absoluteValueMap,
        };
      }
    });

    if (data.aggregatedSummary) agregatedSummary = data.aggregatedSummary;

    return { portfolios, agregatedSummary };
  };
}
export function transformGetAllDividends(): (
  response: AxiosResponse<GetPortfolioDividendsResponseNetType>,
  state: RootState
) => DividendsByPeriod {
  return ({ data }: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => {
    let newDividendsByMonthAndYear: DividendMonthYear[] = [];

    if (data.incomeByMonthAndYear !== null)
      newDividendsByMonthAndYear = _.orderBy(data.incomeByMonthAndYear, ['year', 'month']);

    return {
      dividendPeriod: data.incomeTimeSeries,
      dividendsByMonthAndYear: newDividendsByMonthAndYear,
    };
  };
}
