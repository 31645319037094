// @mui
import { alpha, Theme, useTheme, styled } from '@mui/material/styles';
import { BoxProps } from '@mui/material';
// theme
import { ASSETS_COLORS } from '../theme/palette';

// ----------------------------------------------------------------------

export type LabelColor =
  | 'springGreen'
  | 'turquoise'
  | 'redFire'
  | 'bluePurple'
  | 'greyDark'
  | 'warning'
  | 'primary'
  | 'success'
  | 'error'
  | 'black'
  | 'info'
  | 'blue'
  | 'sea'
  | 'pink'
  | 'default';

export type LabelColorVariant = 'main' | 'light' | 'dark' | 'darker';

type LabelVariant = 'filled' | 'outlined' | 'ghost';

const RootStyle = styled('span')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      color: LabelColor;
      colorVariant: LabelColorVariant;
      variant: LabelVariant;
    };
  }) => {
    const { color, variant, colorVariant } = ownerState;

    const styleFilled = (color: LabelColor, colorVariant: LabelColorVariant) => ({
      color: ASSETS_COLORS[color].contrastText,
      backgroundColor: ASSETS_COLORS[color][colorVariant],
    });

    const styleOutlined = (color: LabelColor, colorVariant: LabelColorVariant) => ({
      color: ASSETS_COLORS[color][colorVariant],
      backgroundColor: 'transparent',
      border: `1px solid ${ASSETS_COLORS[color][colorVariant]}`,
    });

    const styleGhost = (color: LabelColor, colorVariant: LabelColorVariant) => ({
      color: theme.palette.mode === 'dark' ? ASSETS_COLORS[color].light : ASSETS_COLORS[color].main,
      backgroundColor: alpha(ASSETS_COLORS[color][colorVariant], 0.16),
    });

    return {
      height: 22,
      minWidth: 22,
      lineHeight: 0,
      borderRadius: 6,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,

      ...(color !== 'default'
        ? {
            ...(variant === 'filled' && { ...styleFilled(color, colorVariant) }),
            ...(variant === 'outlined' && { ...styleOutlined(color, colorVariant) }),
            ...(variant === 'ghost' && { ...styleGhost(color, colorVariant) }),
          }
        : {
            ...(variant === 'outlined' && {
              backgroundColor: 'transparent',
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.grey[500_32]}`,
            }),
            ...(variant === 'ghost' && {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.grey[500_16],
            }),
          }),
    };
  }
);

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  color?: LabelColor;
  colorVariant?: LabelColorVariant;
  variant?: LabelVariant;
}

export default function SimpleLabel({
  color = 'default',
  colorVariant = 'main',
  variant = 'ghost',
  children,
  sx,
  onClick,
  ...props
}: Props) {
  const theme = useTheme();

  return (
    <RootStyle
      onClick={onClick}
      ownerState={{ color, variant, colorVariant, ...props }}
      sx={sx}
      theme={theme}
    >
      {children}
    </RootStyle>
  );
}
