import {
  getUserSettings,
  updateMFA,
  updateNeedsTour,
  updateUserSettings,
} from 'src/redux/slices/AuthSlice/authSlice';
import { ThemeMode, ThemeStretch } from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import { RootState } from 'src/redux/store';
import { generalCommandsUrl, generalQueriesUrl } from '../EnpointConstants';
import { getAxiosEmptyRequest, getAxiosEmptyRequestAction } from '../http/axios';
import { GetUserSettingsMorePayload } from './NetTypes';
import {
  CHANGE_PASSWORD,
  CREATE_USER_SETTINGS,
  DELETE_USER,
  GET_EMAIL_WAITING_LIST,
  GET_MFA_QR_CODE,
  GET_USER_LOGIN_HISTORIC,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_FAILURE,
  GET_USER_SETTINGS_SUCCESS,
  SAVE_BETA_EMAIL,
  SAVE_BETA_EMAIL_FILE,
  SETUP_MFA,
  SETUP_MFA_FAILURE,
  SETUP_MFA_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_LANGUAGE,
  UPDATE_USER_LANGUAGE_FAILURE,
  UPDATE_USER_LANGUAGE_SUCCESS,
  UPDATE_USER_NEEDSTOUR,
  UPDATE_USER_NEEDSTOUR_FAILURE,
  UPDATE_USER_NEEDSTOUR_SUCCESS,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_FAILURE,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_WEB_SETTINGS,
} from './RequestIds';
import {
  setUpdateUserLanguageBody,
  setUpdateUserNeedsTourBody,
  setUpdateUserSettingsBody,
  setUpdateUserWebSettingsBody,
} from './RequestUtils';
import {
  transformGetUserSettings,
  transformGetUserSettingsRequest,
  transformSetupMfaCode,
  transformUpdateUser,
  transformUpdateUserLanguage,
  transformUpdateUserSettings,
  transformUserNeedsTourBody,
} from './ResponseUtils';

const getUserSettingsUrl = () => generalQueriesUrl + `/user/settings`;
const updateUserSettingsUrl = () => generalCommandsUrl + `/user/settings`;
const updateUserNeedsTourUrl = () => generalCommandsUrl + `/user/needsTour`;
const updateUserWebSettingsUrl = () => generalCommandsUrl + `/user/web-settings`;
const createUserSettingsUrl = () => generalCommandsUrl + `/user/settings`;
const updateUserUrl = (
  name: string,
  firstSurname: string,
  secondSurname: string,
  userId: string,
  country: string = '0'
) =>
  generalCommandsUrl +
  `/user?name=${name}&firstSurname=${firstSurname}&secondSurname=${secondSurname}&country=${country}&userId=${userId}`;
const changePasswordUrl = '/api/v1/change_password';
const reNewPasswordUrl = '/api/v1/renew_password';
const saveBetaEmailUrl = '/api/v1/commands/user/beta/marketing';
const saveBetaEmailFileUrl = '/api/v1/commands/user/beta/file/marketing';
const deleteUserUrl = '/api/v1/commands/user/delete';
const getMfaQrCodeUrl = '/api/v1/mfa/setup';
const setupMfaCodeUrl = '/api/v1/mfa/setup';
const getEmailBetaListUrl = (size: number, page: number) =>
  generalQueriesUrl + `/user/waiting-list/all?size=${size}&page=${page}`;
const getUserLoginHistoryUrl = (size: number, page: number) =>
  generalQueriesUrl + `/user/login_history?size=${size}&page=${page}`;

export function getUserSettingsRequestAction(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, void, any>();
  apiCall.setEndpointFromState = () => getUserSettingsUrl();
  apiCall.method = 'GET';
  apiCall.reqId = GET_USER_SETTINGS;
  apiCall.startedReqType = GET_USER_SETTINGS_SUCCESS;
  apiCall.successReqType = getUserSettings.type;
  apiCall.failureReqType = GET_USER_SETTINGS_FAILURE;
  apiCall.transformResponseDataWithState = transformGetUserSettings();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getUserSettingsRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetUserSettingsMorePayload, any>();
  apiCall.setEndpointFromState = () => getUserSettingsUrl();
  apiCall.method = 'GET';
  apiCall.reqId = GET_USER_SETTINGS;
  apiCall.transformResponseDataWithState = transformGetUserSettingsRequest();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateUserSettingsRequestAction(
  baseCurrency: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, void, any>();
  apiCall.setEndpointFromState = () => updateUserSettingsUrl();
  apiCall.method = 'PUT';
  apiCall.reqId = UPDATE_USER_SETTINGS;
  apiCall.startedReqType = UPDATE_USER_SETTINGS_SUCCESS;
  apiCall.successReqType = updateUserSettings.type;
  apiCall.failureReqType = UPDATE_USER_SETTINGS_FAILURE;
  apiCall.setBodyFromState = setUpdateUserSettingsBody(baseCurrency);
  apiCall.transformResponseDataWithState = transformUpdateUserSettings(baseCurrency);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateUserLanguajeRequestAction(
  language: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, void, any>();
  apiCall.setEndpointFromState = () => updateUserSettingsUrl();
  apiCall.method = 'PUT';
  apiCall.reqId = UPDATE_USER_LANGUAGE;
  apiCall.startedReqType = UPDATE_USER_LANGUAGE_SUCCESS;
  apiCall.successReqType = updateUserSettings.type;
  apiCall.failureReqType = UPDATE_USER_LANGUAGE_FAILURE;
  apiCall.setBodyFromState = setUpdateUserLanguageBody(language);
  apiCall.transformResponseDataWithState = transformUpdateUserLanguage(language);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateUserRequestAction(
  name: string,
  country: string,
  firstSurname: string,
  secondSurname: string,
  userId: string,
  formData: FormData,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, void, any>();
  apiCall.setEndpointFromState = () =>
    updateUserUrl(name, firstSurname, secondSurname, userId, country);
  apiCall.method = 'PUT';
  apiCall.reqId = UPDATE_USER;
  apiCall.setBodyFromState = (_) => formData;
  apiCall.transformResponseDataWithState = transformUpdateUser();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function changePasswordRequest(
  oldPassword: string,
  newPassword: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = CHANGE_PASSWORD;
  apiCall.setBodyFromState = () => ({ oldPassword, newPassword });
  apiCall.setEndpointFromState = () => changePasswordUrl;
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function reNewPasswordRequest(
  newPassword: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = CHANGE_PASSWORD;
  apiCall.setBodyFromState = () => ({ newPassword });
  apiCall.setEndpointFromState = () => reNewPasswordUrl;
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function saveBetaEmailRequest(
  email: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = SAVE_BETA_EMAIL;
  apiCall.setBodyFromState = () => ({ email });
  apiCall.setEndpointFromState = () => saveBetaEmailUrl;
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function saveBetaEmailFileRequest(
  file: FormData,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = SAVE_BETA_EMAIL_FILE;
  apiCall.setBodyFromState = () => file;
  apiCall.setEndpointFromState = () => saveBetaEmailFileUrl;
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function deleteUserRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, void, any>();
  apiCall.setEndpointFromState = () => deleteUserUrl;
  apiCall.method = 'DELETE';
  apiCall.reqId = DELETE_USER;
  apiCall.setBodyFromState = () => {};
  apiCall.transformResponseDataWithState = () => {};
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getMfaQrCodeRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = GET_MFA_QR_CODE;
  apiCall.setEndpointFromState = () => getMfaQrCodeUrl;
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function setupMfaCodeAction(
  code: string,
  secret: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = SETUP_MFA;
  apiCall.startedReqType = SETUP_MFA_SUCCESS;
  apiCall.successReqType = updateMFA.type;
  apiCall.failureReqType = SETUP_MFA_FAILURE;
  apiCall.setBodyFromState = () => ({ code, secret });
  apiCall.setEndpointFromState = () => setupMfaCodeUrl;
  apiCall.transformResponseDataWithState = transformSetupMfaCode(true);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getUserLoginHistoryRequest(
  page: number = 0,
  size: number = 10,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = GET_USER_LOGIN_HISTORIC;
  apiCall.setEndpointFromState = () => getUserLoginHistoryUrl(size, page);
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function deleteMFAUserAction(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = SETUP_MFA;
  apiCall.startedReqType = SETUP_MFA_SUCCESS;
  apiCall.successReqType = updateMFA.type;
  apiCall.failureReqType = SETUP_MFA_FAILURE;
  apiCall.setEndpointFromState = () => setupMfaCodeUrl;
  apiCall.transformResponseDataWithState = transformSetupMfaCode(false);
  apiCall.method = 'DELETE';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateUserNeedsTourRequestAction(
  needsTour: boolean,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, void, any>();
  apiCall.setEndpointFromState = () => updateUserNeedsTourUrl();
  apiCall.method = 'PUT';
  apiCall.reqId = UPDATE_USER_NEEDSTOUR;
  apiCall.startedReqType = UPDATE_USER_NEEDSTOUR_SUCCESS;
  apiCall.successReqType = updateNeedsTour.type;
  apiCall.failureReqType = UPDATE_USER_NEEDSTOUR_FAILURE;
  apiCall.setBodyFromState = setUpdateUserNeedsTourBody(needsTour);
  apiCall.transformResponseDataWithState = transformUserNeedsTourBody(needsTour);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateUserWebSettingsRequest(
  themeMode: ThemeMode,
  themeStretch: ThemeStretch,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, void, any>();
  apiCall.setEndpointFromState = () => updateUserWebSettingsUrl();
  apiCall.method = 'PUT';
  apiCall.reqId = UPDATE_USER_WEB_SETTINGS;
  apiCall.setBodyFromState = setUpdateUserWebSettingsBody(themeMode, themeStretch);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getEmailBetaListRequest(
  page: number = 0,
  size: number = 10,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = GET_EMAIL_WAITING_LIST;
  apiCall.setEndpointFromState = () => getEmailBetaListUrl(size, page);
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
export function createUserSettingsRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = CREATE_USER_SETTINGS;
  apiCall.setEndpointFromState = () => createUserSettingsUrl();
  apiCall.transformResponseDataWithState = transformSetupMfaCode(true);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
