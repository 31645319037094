import {
  PerformanceType,
  PortfolioSettingsMap,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState } from 'src/redux/store';
import {
  AddPortfolioBodyNetType,
  UpdatePortfoliosSettingsBodyNetType,
  PortfolioSettingsNetType,
} from './NetTypes';
import { MissingSecurities } from 'src/pages/dashboard/NewConnection/RevisionStep/services/useGetMovementsToAdjust';

export function setAddPortfolioBody(
  name: string,
  credentialsToken?: string,
  delayed?: boolean,
  flanksEntity?: string,
  client?: string,
  liquidityConsidered?: boolean
): (state: RootState) => AddPortfolioBodyNetType {
  return () => ({
    name,
    type: 'STOCKS',
    delayed,
    credentialsToken,
    flanksEntity,
    client,
    liquidityConsidered,
  });
}

export function setAddManualPortfolioBody(
  name: string,
  client?: string,
  liquidityConsidered?: boolean,
  riskFreeAsset?: string,
  referenceIndices?: string[],
  entity?: string
): (state: RootState) => AddPortfolioBodyNetType {
  const newEntity = entity && entity !== '' ? { entity } : {};
  return () => ({
    name,
    type: 'STOCKS',
    client,
    liquidityConsidered,
    riskFreeAsset,
    referenceIndices,
    ...newEntity,
  });
}

export function setSendMissingSecuritiesBody(
  securities: MissingSecurities[]
): (state: RootState) => { securityId: string; securitySuggestionId: string }[] {
  return (_state: RootState) =>
    securities.map((item) => ({
      securityId: item.unkownSecurity.id,
      securitySuggestionId: item.notFind ? '' : item.unkownSecurity.id,
    }));
}

export function setUpdatePortfolioVisibilitySettingsBody(
  portfoliosSettings: PortfolioSettingsMap
): (state: RootState) => PortfolioSettingsNetType[] {
  return (state: RootState) => {
    const newPortfolioSettings: PortfolioSettingsNetType[] = [];

    Object.values(state.portfolio.portfolios).forEach((i) => {
      if (portfoliosSettings[i.id] && i.settings !== portfoliosSettings[i.id]) {
        newPortfolioSettings.push({
          portfolioId: i.id,
          showHoldings: portfoliosSettings[i.id].showHoldings,
          showMoney: portfoliosSettings[i.id].showMoney,
          showTransactions: portfoliosSettings[i.id].showTransactions,
          showDividends: portfoliosSettings[i.id].showDividends,
          showReturn: portfoliosSettings[i.id].showReturn,
          showHistoricalPositions: portfoliosSettings[i.id].showHistoricalPositions,
          visibility: portfoliosSettings[i.id].share,
          password: portfoliosSettings[i.id].password,
          shareLink: portfoliosSettings[i.id].shareLink,
        });
      }
    });

    return newPortfolioSettings;
  };
}

export function setUpdatePortfolioSettingsBody(
  performanceType: PerformanceType,
  referenceIndices: string[],
  riskFreeAsset: string
): (state: RootState) => UpdatePortfoliosSettingsBodyNetType {
  return () => ({ preferredPerformance: performanceType, referenceIndices, riskFreeAsset });
}
