import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, ListItemText, Link, Stack } from '@mui/material';
// locales
import useLocales from 'src/hooks/useLocales';
//
import { NavItemProps } from '../types';
import { StyledItem, StyledIcon } from './styles';
import Iconify from 'src/components/Iconify';
import BasicTooltip from 'src/components/BasicTooltip';

// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ item, depth, open, active, isExternalLink, hasChild, ...other }, ref) => {
    const { translate } = useLocales();
    const { title, path, icon, info, children, disabled, caption, isAdvisor, isConnected } = item;

    const subItem = depth !== 1;

    const renderContent = (
      <StyledItem
        active={active}
        depth={depth}
        disabled={disabled}
        open={open}
        ref={ref}
        {...other}
      >
        {icon && <StyledIcon marginRight={title === '' ? 0 : 8}>{icon}</StyledIcon>}

        <Stack alignItems="center" flexDirection="row">
          <ListItemText
            primary={translate(title)}
            primaryTypographyProps={{
              noWrap: true,
              component: 'span',
              maxWidth: 160,
              variant: active ? 'subtitle2' : 'body2',
            }}
          />

          {isConnected && (
            <Iconify
              color={'info.main'}
              height={15}
              icon={'octicon:verified-16'}
              sx={{ ml: 2 }}
              width={15}
            />
          )}

          {isAdvisor && (
            <BasicTooltip title="Cartera gestionada por asesor">
              <Iconify
                color={active ? 'primary.main' : 'text.secondary'}
                height={14}
                icon={'mdi:user'}
                sx={{ ml: 1.4, mr: 0.2, mb: 0.3 }}
                width={14}
              />
            </BasicTooltip>
          )}
        </Stack>

        {info && (
          <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {caption && (
          <Tooltip arrow title={translate(caption) as string}>
            <Box component="span" sx={{ ml: 0.5, lineHeight: 0 }}>
              <Iconify icon="eva:info-outline" width={16} />
            </Box>
          </Tooltip>
        )}

        {!!children && title !== '' && (
          <Iconify
            icon={subItem ? 'eva:chevron-right-fill' : 'eva:chevron-down-fill'}
            sx={{ ml: isConnected || isAdvisor ? 1 : 0.5 }}
            width={16}
          />
        )}
      </StyledItem>
    );

    const renderItem = () => {
      // ExternalLink
      if (isExternalLink)
        return (
          <Link href={path} rel="noopener" target="_blank" underline="none">
            {renderContent}
          </Link>
        );

      // Default

      if (hasChild) {
        return renderContent;
      }

      return (
        <Link component={RouterLink} to={path} underline="none">
          {renderContent}
        </Link>
      );
    };

    return renderItem();
  }
);

export default NavItem;
