import { useContext } from 'react';
// mui
import { Drawer, useTheme } from '@mui/material';
// context
import Context, { ContextType } from '../context';
// config
import { NAVBAR } from 'src/config';
// utils
import cssStyles from 'src/utils/cssStyles';

type Props = {
  collapseHover: boolean;
  children: React.ReactNode;
};

export default function NavbarDrawer({ collapseHover, children }: Props) {
  const { isCollapse }: ContextType = useContext<ContextType>(Context);

  const theme = useTheme();

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: NAVBAR.DASHBOARD_WIDTH,
          borderRightStyle: 'dashed',
          bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'background.paper',
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.standard,
            }),
          ...(isCollapse && {
            width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
          }),
          ...(collapseHover && {
            ...cssStyles(theme).bgBlur(),
            boxShadow: (theme) => theme.customShadows.z24,
          }),
        },
      }}
      open
      variant="persistent"
    >
      {children}
    </Drawer>
  );
}
