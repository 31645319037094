import { useEffect } from 'react';
// utils
import { getlogoUrl } from 'src/utils/formatUrl';
import useGetInfoCompany from './useGetInfoCompany';
import { APP_ENV } from 'src/config';

// ----------------------------------------------------------------------

function ensureHttpsUrl(url: string): string {
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }
  return url;
}

export default function useChangeFavIcon() {
  const { logoUrl } = useGetInfoCompany();
  const isBusiness = APP_ENV === 'business' || APP_ENV === 'businesstest';

  useEffect(() => {
    const link = document.createElement('link');
    const oldLink = document.getElementById('favicon');
    link.id = 'favicon';
    link.rel = 'favicon icon';
    link.href = ensureHttpsUrl(
      isBusiness && logoUrl && logoUrl !== '' ? logoUrl : getlogoUrl('vumi_favicon.ico')
    );

    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }, [logoUrl, isBusiness]);
}
