import { useContext } from 'react';
// mui
import { Stack } from '@mui/material';
// components
import Scrollbar from 'src/components/Scrollbar';
import { NavSectionVertical } from 'src/components/nav-section';
// hooks
import useGetNavConfig from '../NavConfiguration/useGetNavConfig';
// context
import Context, { ContextType } from '../context';

export default function NavbarContent() {
  const { isCollapse }: ContextType = useContext<ContextType>(Context);
  const { navConfig } = useGetNavConfig();

  return (
    <Scrollbar
      sx={{
        pt: 5,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      />

      <NavSectionVertical isCollapse={isCollapse} navConfig={navConfig} />
    </Scrollbar>
  );
}
