import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CardType,
  SubInfoPayload,
  SubPrice,
  Subscription,
  subscriptionDefault,
} from 'src/redux/slices/SubscriptionSlice/subscriptionSlice.d';

type SubscriptionState = {
  paymentCards: CardType[];
  subPricesList: SubPrice[];
  subscription: Subscription;
};

export const initialState: SubscriptionState = {
  paymentCards: [],
  subPricesList: [],
  subscription: subscriptionDefault,
};

const subSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    getSubInfo: (state, action: PayloadAction<SubInfoPayload>) =>
      (state = {
        ...state,
        paymentCards: action.payload.paymentCards,
        subPricesList: action.payload.subPricesList,
        subscription: action.payload.subscription,
      }),
    deleteCard: (state, action: PayloadAction<{ id: string }>) =>
      (state = {
        ...state,
        paymentCards: state.paymentCards.filter((i) => i.id !== action.payload.id),
      }),
  },
});

const persistSubConfig = {
  key: 'subscription',
  storage,
  whitelist: ['cards'],
};

const persistSubReducer = persistReducer<SubscriptionState>(persistSubConfig, subSlice.reducer);

export const { getSubInfo, deleteCard } = subSlice.actions;

export default persistSubReducer;
