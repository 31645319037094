import { Paper, PaperProps, Typography } from '@mui/material';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({ searchQuery = '', ...other }: Props) {
  const { translate } = useLocales();

  return searchQuery ? (
    <Paper {...other}>
      <Typography align="center" gutterBottom variant="subtitle1">
        {translate('securities_search.not_found_title')}
      </Typography>
      <Typography align="center" variant="body2">
        {translate('securities_search.not_found_subtitle', { searchQuery })}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2">{translate('securities_search.keywords')}</Typography>
  );
}
