import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from 'src/redux/store';
import {
  ModalManagerEventProps,
  ModalManagerInputProps,
  ModalManagerOwnProps,
} from './ModalManager.Types';
import View from './ModalManager.View';
import { hideModal } from 'src/redux/slices/ModalSlice/modalSlice';

const mapStateToProps = (
  state: RootState,
  _ownProps: ModalManagerOwnProps
): ModalManagerInputProps => ({
  visible: state.modal.visible,
  title: state.modal.content?.title,
  children: state.modal.content?.view,
  size: state.modal.content?.size,
});

const mapDispatchToProps: MapDispatchToPropsFunction<
  ModalManagerEventProps,
  ModalManagerOwnProps
> = (dispatch: ThunkDispatch<any, any, AnyAction>, _ownProps: ModalManagerOwnProps) => ({
  hideModalAction: () => {
    dispatch(hideModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
