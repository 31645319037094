import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './GuestGuard.View';
import { GuestGuardInputProps, GuestGuardOwnProps, GuestGuardEventProps } from './GuestGuard.Types';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState, ownProps: GuestGuardOwnProps): GuestGuardInputProps => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps: MapDispatchToPropsFunction<GuestGuardEventProps, GuestGuardOwnProps> = (
  _dispatch: ThunkDispatch<any, any, AnyAction>,
  _ownProps: GuestGuardOwnProps
) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(View);
