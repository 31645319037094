import { memo } from 'react';
// @mui
import { AppBar, Box, BoxProps, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// config
import { HEADER, NAVBAR } from '../../config';
// utils
import { bgBlur } from '../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../components/nav-section';
import Scrollbar from 'src/components/Scrollbar';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useGetClientNavConfig from './NavConfiguration/useGetClientNavConfig';

// ----------------------------------------------------------------------

type Props = {
  isCollapse?: boolean;
};

function NavbarClient({ isCollapse }: Props) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const isSm = useResponsive('up', 'sm');

  const { navConfig } = useGetClientNavConfig();

  return (
    <AppBar
      color={'inherit'}
      component="nav"
      sx={{
        boxShadow: 0,
        top: isDesktop
          ? HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT + 6
          : HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: !isDesktop
          ? '100%'
          : `calc(100% - ${
              isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH
            }px)`,
        mt: isSm ? 0 : 7.3,
      }}
    >
      <Scrollbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Toolbar
          sx={{
            ...bgBlur({
              color: theme.palette.background.default,
            }),
          }}
        >
          <NavSectionHorizontal data={navConfig} />
        </Toolbar>
      </Scrollbar>
      <Shadow />
    </AppBar>
  );
}

export default memo(NavbarClient);

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
