import { AxiosResponse } from 'axios';

import { CountryType } from 'src/redux/slices/DataSlice/dataSlice.d';
import { GetCountriesNetType } from './NetTypes';

export function transformGetCountries(): (
  response: AxiosResponse<GetCountriesNetType>
) => CountryType[] {
  return (response: AxiosResponse<GetCountriesNetType>) => response.data.countries;
}
