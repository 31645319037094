import SvgIconStyle from 'src/components/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  wallets: getIcon('ic_wallet'),
  crypto: getIcon('ic_crypto'),
  info: getIcon('ic_info'),
  help: getIcon('ic_help'),
  business: getIcon('ic_business'),
  people: getIcon('ic_people'),
  home: getIcon('ic_home'),
  connection: getIcon('ic_connection'),
  reporting: getIcon('ic_reporting'),
  notification: getIcon('ic_notification'),
  altInvest: getIcon('ic_alt_invest'),
  more: getIcon('ic_more'),
};
