import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  User,
  InitialPayload,
  LoginPayload,
  RegisterPayload,
  defaultUser,
  RefreshTokenPayload,
  GetUserSettingsPayload,
} from 'src/redux/slices/AuthSlice/authSlice.d';

type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isReady: boolean;
  user: User;
  token: string | null;
  refreshToken: string | null;
};

export const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isReady: false,
  user: defaultUser,
  token: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initSolo: (state) =>
      (state = {
        refreshToken: state.refreshToken,
        isAuthenticated: false,
        isInitialized: true,
        isReady: false,
        user: state.user,
        token: state.token,
      }),
    getInit: (state, action: PayloadAction<InitialPayload>) =>
      (state = {
        refreshToken: state.refreshToken,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isReady: true,
        user: action.payload.user,
        token: action.payload.token,
      }),
    login: (state, action: PayloadAction<LoginPayload>) =>
      (state = {
        isInitialized: state.isInitialized,
        isAuthenticated: action.payload.mfaToken === '',
        user: action.payload.user,
        isReady: false,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      }),
    logout: (state) => (state = { ...initialState, isInitialized: true }),
    register: (state, action: PayloadAction<RegisterPayload>) =>
      (state = {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: null,
      }),
    refreshToken: (state, action: PayloadAction<RefreshTokenPayload>) =>
      (state = {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      }),
    updateUser: (state, action: PayloadAction<RegisterPayload>) =>
      (state = {
        ...state,
        user: { ...state.user, ...action.payload.user },
      }),
    getUserSettings: (state, action: PayloadAction<GetUserSettingsPayload>) =>
      (state = {
        ...state,
        user: { ...state.user, ...action.payload },
      }),
    updateUserSettings: (state, action: PayloadAction<GetUserSettingsPayload>) =>
      (state = {
        ...state,
        user: { ...state.user, ...action.payload },
      }),
    updateMFA: (state, action: PayloadAction<{ mfaEnabled: boolean }>) =>
      (state = {
        ...state,
        user: { ...state.user, mfaEnabled: action.payload.mfaEnabled },
      }),
    updateCompanyOnBoading: (state, action: PayloadAction<{ isCompleted: boolean }>) =>
      (state = {
        ...state,
        user: { ...state.user, onboardingFinished: action.payload.isCompleted },
      }),
    updateNeedsTour: (state, action: PayloadAction<{ needsTour: boolean }>) =>
      (state = {
        ...state,
        user: { ...state.user, needsTour: action.payload.needsTour },
      }),
    updateSingleReportsGen: (state) =>
      (state = {
        ...state,
        user: { ...state.user, reportsGenerated: state.user.reportsGenerated + 1 },
      }),
    updateHideAccount: (state, action: PayloadAction<{ hideAccount: boolean }>) =>
      (state = {
        ...state,
        user: { ...state.user, hideAccount: action.payload.hideAccount },
      }),
  },
});

const persistAuthConfig = {
  key: 'auth',
  storage: sessionStorage,
  blackList: ['isAuthenticated', 'isInitialized', 'isReady'],
  whitelist: ['user', 'token', 'refreshToken'],
};

const persistAuthReducer = persistReducer<AuthState>(persistAuthConfig, authSlice.reducer);

export const {
  initSolo,
  getInit,
  login,
  logout,
  register,
  refreshToken,
  updateUser,
  getUserSettings,
  updateUserSettings,
  updateMFA,
  updateCompanyOnBoading,
  updateNeedsTour,
  updateSingleReportsGen,
  updateHideAccount,
} = authSlice.actions;

export default persistAuthReducer;
