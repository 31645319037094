import { RootState } from 'src/redux/store';
import { CreateIndiceBodyNetType, CustomStockIndexComponentDto } from './NetTypes';

export function setCreateIndiceBody(
  customIndices: CustomStockIndexComponentDto[],
  name: string
): (state: RootState) => CreateIndiceBodyNetType {
  return (_state: RootState) => ({
    name,
    components: customIndices.map((i) => ({
      indexId: i.referenceIndex.id,
      weight: i.weight,
    })),
  });
}
