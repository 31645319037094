import * as MiddlewareFuncs from 'src/services/http/redux/NetworkMiddleware';
import * as ReduxTypes from 'src/services/http/redux/Types.d';
import ReduxUtils from 'src/services/http/redux/Redux.Utils';

export const { getServiceClientMiddleware } = MiddlewareFuncs;
export const { loggerMiddleware } = MiddlewareFuncs;
export const adaptRequestFromReduxAction = ReduxUtils;
export type OutActionStarted = ReduxTypes.OutActionStarted;
export type OutActionSuccess<ResponseType> = ReduxTypes.OutActionSuccess<ResponseType>;
export type OutActionFailure<ErrorType> = ReduxTypes.OutActionFailure<ErrorType>;
