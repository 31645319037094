import { orderBy, values } from 'lodash';
// hooks
import useGetPortfolios from 'src/hooks/useGetPortfolios';
// utils
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICONS } from 'src/utils/iconUtils';
import { getConnectionDetails, getWalletsByType, getWalletsChildren } from './utils/navConfigUtils';

export default function useGetActives() {
  const { portfolios } = useGetPortfolios();

  const connections = getConnectionDetails(
    getWalletsByType(portfolios ? orderBy(values(portfolios), 'name') : [], 'STOCKS')
  );
  const altInvestWallets = getConnectionDetails(
    getWalletsByType(
      portfolios ? orderBy(values(portfolios), 'name') : [],
      'ALTERNATIVE_INVESTMENTS'
    )
  );

  return [
    {
      title: 'navbar.financial_investments',
      path: PATH_DASHBOARD.general.wallets.root,
      icon: ICONS.wallets,
      isTranslate: true,
      children: [...getWalletsChildren(connections)],
    },
    {
      title: 'navbar.alt_investments',
      path: PATH_DASHBOARD.addAssets.alternative.wallet,
      icon: ICONS.altInvest,
      isTranslate: true,
      children: [...getWalletsChildren(altInvestWallets)],
    },
    {
      title: 'navbar.current_accounts',
      path: PATH_DASHBOARD.general.current_accounts,
      icon: ICONS.banking,
      isTranslate: true,
      highlightAdd: true,
    },
  ];
}
