import { LoadingButton } from '@mui/lab';
import { Checkbox, Grid, IconButton, Stack, styled, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import useLocales from 'src/hooks/useLocales';

import { FormProvider } from 'src/components/hook-form';
import { UnknownSecurities } from 'src/services/portfolio/NetTypes';
import SecuritiesSearch, {
  SecurityType,
} from 'src/sections/@dashboard/general/wallets/transactions/SecuritiesSearch';
import { useForm } from 'react-hook-form';
import { defaultSecurity } from 'src/pages/dashboard/WalletsAddMovement/WalletsAddMovement.Types';

// ----------------------------------------------------------------------

export type MissingSecurities = {
  unkownSecuritie: UnknownSecurities;
  securitieFind: SecurityType;
  notFind: boolean;
};

type Props = {
  securities: MissingSecurities[];
  hasError: boolean;
  withColumns?: boolean;
  handleSecurityChange: (newSec: MissingSecurities[]) => void;
  onSubmitSuggestion: (item: MissingSecurities) => void;
};

const OptionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 2.5),
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

export default function SharesNotFoundList(props: Props) {
  const { translate } = useLocales();

  const methods = useForm<{ security: SecurityType }>({
    defaultValues: {
      security: defaultSecurity,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async () => {};

  const onSubmitSingle = (item: MissingSecurities) => {
    props.onSubmitSuggestion(item);
  };

  const handleNotFindSelect = (id: string) => {
    const index = props.securities.findIndex((i) => i.unkownSecuritie.id === id);
    if (index !== -1) {
      let newSec = props.securities;
      newSec[index].notFind = !newSec[index].notFind;
      newSec[index].securitieFind = defaultSecurity;
      props.handleSecurityChange([...newSec]);
    }
  };

  const handleSecuritySelect = (id: string, security: SecurityType) => {
    const index = props.securities.findIndex((i) => i.unkownSecuritie.id === id);
    if (index !== -1) {
      let newSec = props.securities;
      newSec[index].securitieFind = security;
      props.handleSecurityChange([...newSec]);
    }
  };

  const renderISINItem = (item: MissingSecurities) => (
    <Grid
      item
      key={item.unkownSecuritie.id}
      lg={props.withColumns ? 6 : 12}
      md={props.withColumns ? 6 : 12}
      xs={12}
    >
      <OptionStyle key={item.unkownSecuritie.id} sx={{}}>
        <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
          <Stack alignItems="center" flexDirection="row">
            <Typography color="primary.main" textAlign={'left'} variant="subtitle2">
              {item.unkownSecuritie.isin}
            </Typography>
            <Tooltip sx={{ ml: 0.4 }} title={'tooltip'}>
              <IconButton onClick={() => navigator.clipboard.writeText(item.unkownSecuritie.isin)}>
                <Iconify
                  color={'text.secondary'}
                  height={17}
                  icon={'fluent:copy-16-filled'}
                  width={17}
                />
              </IconButton>
            </Tooltip>
            <Typography
              color="text.primary"
              fontSize={12}
              sx={{ ml: 2 }}
              textAlign={'left'}
              variant="subtitle2"
            >
              {item.unkownSecuritie.name}
            </Typography>
            <Typography
              color="primary.main"
              fontSize={12}
              sx={{ ml: 3 }}
              textAlign={'left'}
              variant="subtitle2"
            >
              {item.unkownSecuritie.currencyCode}
            </Typography>
          </Stack>
          <Stack alignItems="center" flexDirection="row">
            <Checkbox
              checked={item.notFind}
              onClick={() => handleNotFindSelect(item.unkownSecuritie.id)}
              size={'small'}
            />

            <Typography color="text.primary" sx={{ mt: 0.2 }} textAlign={'left'} variant="body2">
              {translate('shares_not_found.not_found_it')}
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ flexDirection: 'row', mt: 1.5, alignItems: 'center' }}>
          <SecuritiesSearch
            name={'security'}
            onSelectSecurity={(security) => handleSecuritySelect(item.unkownSecuritie.id, security)}
            security={item.securitieFind}
          />
          <Stack sx={{ ml: 2 }}>
            <LoadingButton
              loading={false}
              onClick={() => onSubmitSingle(item)}
              size="medium"
              variant="contained"
            >
              {translate('shares_not_found.send')}
            </LoadingButton>
          </Stack>
        </Stack>
      </OptionStyle>
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {props.hasError && (
        <Typography
          color="text.primary"
          fontSize={12}
          sx={{ color: 'error.main' }}
          textAlign={'left'}
          variant="body1"
        >
          {translate('shares_not_found.match_error')}
        </Typography>
      )}
      <Scrollbar sx={{ maxHeight: 500 }}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {props.securities.map((i) => renderISINItem(i))}
        </Grid>
      </Scrollbar>
    </FormProvider>
  );
}
