import { useState } from 'react';
// @mui
import { IconButton, Tooltip, Stack } from '@mui/material';
//
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';

// ----------------------------------------------------------------------

type Props = {
  actions: React.ReactNode;
  isConfirmed?: boolean;
  isSplit?: boolean;
  isOwner?: boolean;
  isConnected?: boolean;
  note?: string;
  onClickEdit?: () => void;
};

export default function TableMoreMenu({
  actions,
  isConfirmed,
  onClickEdit,
  note,
  isSplit,
  isConnected,
  isOwner,
}: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Stack flexDirection={'row'} sx={{ justifyContent: 'flex-end' }}>
        {!isConfirmed && !isSplit && isOwner && (
          <Tooltip title="Movimiento automático sin confirmar">
            <IconButton onClick={onClickEdit}>
              <Iconify
                color={'primary.main'}
                height={20}
                icon={'ant-design:thunderbolt-filled'}
                width={20}
              />
            </IconButton>
          </Tooltip>
        )}

        {note && note !== '' && (
          <Tooltip title={note}>
            <IconButton sx={{ p: 0.7 }}>
              <Iconify color={'info.light'} height={16} icon={'fluent:note-16-filled'} width={16} />
            </IconButton>
          </Tooltip>
        )}

        {isConfirmed && !isConnected && isOwner && !isSplit && (
          <IconButton onClick={handleOpen}>
            <Iconify height={20} icon={'eva:more-vertical-fill'} width={20} />
          </IconButton>
        )}
      </Stack>

      <MenuPopover
        anchorEl={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        arrow="right-top"
        onClose={handleClose}
        open={Boolean(open)}
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
