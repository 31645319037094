import { useEffect, useState } from 'react';
import MomentAdapter from '@date-io/moment';
import moment from 'moment';
// form
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Button, DialogActions, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';

// components
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { Transaction } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import {
  FullSchema,
  DepositSchema,
} from 'src/pages/dashboard/WalletsAddMovement/WalletAddMovements.Utils';
import useLocales from 'src/hooks/useLocales';
import { fDecimalNumber } from 'src/utils/formatNumber';
import { TransactionsTypesDict } from './WalletMovementsUtils';
import { IBuyForm } from 'src/pages/dashboard/WalletsAddMovement/WalletsAddMovement.Types';

// ----------------------------------------------------------------------

type FormValuesProps = {
  wallet: string;
  localCurrency: string;
  security: string;
  quantity: number;
  price: number;
  exchangeRate: number;
  currencyPair: string;
  feeCurrency: string;
  fee: number;
  notes: string;
  date: Date;
  type: string;
};

export type Props = {
  onCancel: VoidFunction;
  transaction?: Transaction;
  walletName: string;
  baseCurrency: string;
  editMovement: (id: string, data: IBuyForm, transaction: Transaction) => void;
};

export default function WalletEditMov({
  onCancel,
  transaction,
  walletName,
  baseCurrency,
  editMovement,
}: Props) {
  const [isDiffCurrency, setIsDiffCurrency] = useState<boolean>(false);
  const [selectableCurrencies, setSelectableCurrencies] = useState([baseCurrency]);

  const { translate } = useLocales();

  const isDepoOrRe =
    !!transaction?.type &&
    (TransactionsTypesDict[transaction?.type ?? ''].type === 'deposits' ||
      TransactionsTypesDict[transaction?.type ?? ''].type === 'removals');

  const isCoupon = transaction?.type === 'COUPON';

  const isSplit =
    !!transaction?.type && TransactionsTypesDict[transaction?.type ?? ''].type === 'splits';
  const isDividend =
    !!transaction?.type && TransactionsTypesDict[transaction?.type ?? ''].type === 'dividends';

  const currencyResults = baseCurrency === 'EUR' ? ' €' : ' $';

  const isDebit =
    !!transaction?.type &&
    (TransactionsTypesDict[transaction?.type ?? ''].type === 'buys' ||
      TransactionsTypesDict[transaction?.type ?? ''].type === 'removals');

  const buildCurrencyPairs = () => [
    `${baseCurrency}/${transaction?.localCurrencyCode}`,
    `${transaction?.localCurrencyCode}/${baseCurrency}`,
  ];

  const amount = transaction?.amount ?? 0;
  const shares = transaction?.shares ?? 0;
  const fee = transaction?.fee ?? 0;
  const amountValue = isDebit ? amount + fee : amount;

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(
      isDepoOrRe || isCoupon ? DepositSchema : FullSchema(isDiffCurrency, isSplit)
    ),
    defaultValues: {
      type: transaction?.type,
      wallet: walletName,
      date: new Date(transaction?.transactionDate ?? ''),
      quantity: shares,
      price: +Math.abs(
        transaction?.exchangeRate
          ? (amountValue * transaction?.exchangeRate) / (isCoupon ? 1 : shares)
          : amountValue / (isDepoOrRe || isCoupon ? 1 : shares)
      ).toFixed(4),
      fee,
      exchangeRate: transaction?.exchangeRate!!,
      localCurrency: transaction?.localCurrencyCode,
      security: transaction?.security,
      feeCurrency: baseCurrency,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    setValue,
  } = methods;

  const watchValues = watch();

  useEffect(() => {
    if (isDiffCurrency && buildCurrencyPairs()[0] && !isDepoOrRe)
      setValue('currencyPair', buildCurrencyPairs()[0]);
  }, [isDiffCurrency]);

  useEffect(() => {
    if (transaction?.localCurrencyCode && transaction?.localCurrencyCode !== baseCurrency) {
      setIsDiffCurrency(true);
      isDividend
        ? setSelectableCurrencies([transaction?.localCurrencyCode ?? '', baseCurrency])
        : setSelectableCurrencies([baseCurrency, transaction?.localCurrencyCode ?? '']);
    } else {
      setIsDiffCurrency(false);
      setSelectableCurrencies([baseCurrency]);
    }
  }, []);

  const onSubmit = async (data: IBuyForm) => {
    try {
      if (transaction)
        editMovement(
          transaction.id ?? '',
          { ...data, exchangeRate: getExchangeRate() },
          transaction
        );
    } catch (error) {
      console.error(error);
    }
  };

  const renderResultRow = (title: string, value: string | number) => (
    <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 1 }}>
      <Typography
        sx={{
          color: 'text.secondary',
        }}
        variant="body2"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: 'text.main',
        }}
        variant="body2"
      >
        {value ?? 0}
      </Typography>
    </Stack>
  );

  const getExchangeRate = () => {
    if (watchValues.currencyPair) {
      if (watchValues.currencyPair.split('/')[0] !== watchValues.localCurrency)
        return watchValues.exchangeRate;
      else return 1.0 / watchValues.exchangeRate;
    }
    return 1.0;
  };

  const getSubtotal = () => {
    let quantity = watchValues.quantity ?? 0;
    let price = watchValues.price ?? 0;
    let exchangeRate: any = getExchangeRate() ?? 1;

    if (
      TransactionsTypesDict[transaction?.type ?? ''].type === 'deposits' ||
      TransactionsTypesDict[transaction?.type ?? ''].type === 'removals' ||
      transaction?.type === 'COUPON'
    )
      quantity = 1;

    if (exchangeRate === 0 || exchangeRate === '0' || !exchangeRate) exchangeRate = 1;

    return ((quantity * price) / exchangeRate) * (isDebit ? -1 : 1);
  };

  const getFee = () =>
    watchValues.fee /
      (watchValues.feeCurrency !== baseCurrency
        ? getExchangeRate() <= 0
          ? 1.0
          : getExchangeRate()
        : 1.0) || 0.0;

  const renderResults = () => (
    <Grid item md={6} xs={12}>
      <Typography
        sx={{
          color: 'text.main',
        }}
        variant="subtitle2"
      >
        {translate('movements.mov_result')}
      </Typography>
      {renderResultRow(translate('movements.subtotal'), getSubtotal().toFixed(2) + currencyResults)}
      {renderResultRow(translate('movements.fee'), `(-) ${getFee().toFixed(2)}` + currencyResults)}
      <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 1 }}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="body2"
        >
          {translate('movements.total')}
        </Typography>
        <Typography
          sx={{
            color: 'text.main',
          }}
          variant="h4"
        >
          {fDecimalNumber(getSubtotal() - getFee()) + currencyResults}
        </Typography>
      </Stack>
    </Grid>
  );

  const renderSplitResult = () => (
    <Grid item md={6} xs={12}>
      <Typography
        sx={{
          color: 'primary.main',
        }}
        variant="subtitle2"
      >
        {'Movimiento automático'}
      </Typography>
      <Typography
        sx={{
          color: 'text.secondary',
          mt: 1,
        }}
        variant="body2"
      >
        {isDividend
          ? 'Este movimiento se ha generado de forma automática debido a un evento registrado para dicha acción. Por favor, rellene los datos que faltan si fuese necesario y confirme que el movimiento es correcto.'
          : 'Este movimiento se ha generado de forma automática debido a un evento registrado para dicha acción.'}
      </Typography>
      <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 1, mr: 1 }}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="body2"
        >
          {translate('movements.total')}
        </Typography>
        <Typography
          sx={{
            color: 'text.main',
          }}
          variant="h6"
        >
          {fDecimalNumber(getSubtotal() - getFee()) + currencyResults}
        </Typography>
      </Stack>
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ pt: 3, pl: 3, mb: 1 }} variant="h5">
        {'Editar movimiento'}
      </Typography>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={6}>
          <RHFTextField
            disabled
            label={translate('movements.wallet')}
            name="wallet"
            value={walletName}
          />
        </Grid>
        <Grid item xs={6}>
          <RHFTextField
            disabled
            label={translate('movements.type')}
            name="type"
            value={translate(TransactionsTypesDict[transaction?.type ?? ''].label)}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue={watchValues.date}
            name="date"
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={MomentAdapter}>
                <DatePicker
                  PaperProps={{
                    sx: {
                      '& .PrivatePickersYear-yearButton': {
                        textAlign: 'center',
                      },
                    },
                  }}
                  allowSameDateSelection
                  disabled={isSplit || isDividend}
                  label="Fecha"
                  maxDate={moment()}
                  onChange={(newValue: any) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} error={!!error} fullWidth helperText={error?.message} />
                  )}
                  value={field.value}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        {!isDepoOrRe && !isCoupon && (
          <Grid item md={6} xs={12}>
            <RHFTextField
              disabled={isSplit || isDividend}
              label={translate('movements.quantity')}
              name="quantity"
              type="number"
              value={watchValues.quantity}
            />
          </Grid>
        )}
        {!isSplit && (
          <Grid item md={6} xs={12}>
            <RHFTextField
              disabled={isDividend}
              label={isCoupon ? translate('Importe') : isDepoOrRe ? 'Cantidad' : 'Precio'}
              name="price"
              type="number"
              value={watchValues.price}
            />
          </Grid>
        )}
        {!isSplit && (
          <Grid item md={isDepoOrRe ? 12 : 6} xs={12}>
            <Stack flexDirection={'row'}>
              <RHFTextField
                label={isDividend ? translate('movements.fee_div') : translate('movements.fee')}
                name="fee"
                sx={{ mr: 3 }}
                type="number"
                value={watchValues.fee ?? ''}
              />
              <RHFSelect disabled={!isDividend} label="Divisa de la comisión" name="feeCurrency">
                {selectableCurrencies.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
          </Grid>
        )}
        {isDiffCurrency && !isDepoOrRe && !isSplit && (
          <Grid item md={12} xs={12}>
            <Stack alignItems={'center'} flexDirection={'row'} gap={3}>
              <RHFTextField
                disabled={isDividend}
                label={translate('movements.exchangeRate')}
                name="exchangeRate"
                type="number"
                value={watchValues.exchangeRate}
              />

              <RHFSelect
                disabled={isDividend}
                label="Divisa del tipo de cambio"
                name="currencyPair"
              >
                {buildCurrencyPairs().map((currencyPair) => (
                  <option key={currencyPair} value={currencyPair}>
                    {currencyPair}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <RHFTextField label={translate('movements.notes')} multiline name="notes" rows={5} />
        </Grid>
        {isSplit || (isDividend && !transaction?.confirmed) ? renderSplitResult() : renderResults()}
      </Grid>

      <DialogActions>
        <Button color="inherit" onClick={onCancel} variant="outlined">
          {translate('movements.cancel')}
        </Button>

        {isDividend && !transaction?.confirmed ? (
          <LoadingButton
            loading={isSubmitting}
            onClick={() => onSubmit({ ...watchValues })}
            type="submit"
            variant="contained"
          >
            {translate('movements.save_confirm')}
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={isSubmitting}
            onClick={() => onSubmit({ ...watchValues })}
            type="submit"
            variant="contained"
          >
            {translate('movements.save')}
          </LoadingButton>
        )}
      </DialogActions>
    </FormProvider>
  );
}
