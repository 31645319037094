// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verifyMFA: path(ROOTS_AUTH, '/verifyMFA'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  companyOnBoarding: path(ROOTS_AUTH, '/company-onboarding'),
};

export const PATH_PAGE = {
  share: {
    wallets: {
      root: path('/share', '/wallets'),
      view: (name: string) => path('/share', `/wallets/${name}`),
    },
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    markets: path(ROOTS_DASHBOARD, '/markets'),
    my_investments: path(ROOTS_DASHBOARD, '/my-investments'),
    my_wealth: {
      root: path(ROOTS_DASHBOARD, '/my-wealth'),
      general: path(ROOTS_DASHBOARD, '/my-wealth/general'),
      alternative_investments: path(ROOTS_DASHBOARD, '/my-wealth/altInvest'),
      financial_investments: path(ROOTS_DASHBOARD, '/my-wealth/finInvest'),
    },
    summary: path(ROOTS_DASHBOARD, '/summary'),
    wallets: {
      root: path(ROOTS_DASHBOARD, '/wallets'),
      all: path(ROOTS_DASHBOARD, '/wallets/all'),
      view: (name: string) => path(ROOTS_DASHBOARD, `/wallets/${name}`),
      connection: path(ROOTS_DASHBOARD, '/wallets/connection'),
      updateConnection: path(ROOTS_DASHBOARD, '/wallets/update-connection'),
      updateCredentialsConnection: path(ROOTS_DASHBOARD, '/wallets/update-credentials-connection'),
      addMovement: path(ROOTS_DASHBOARD, '/wallets/add-movement'),
      newConnection: path(ROOTS_DASHBOARD, '/wallets/new-connection'),
      newWallet: path(ROOTS_DASHBOARD, '/wallets/new-wallet'),
      importDocument: path(ROOTS_DASHBOARD, '/wallets/import-document'),
    },
    exchanges: path(ROOTS_DASHBOARD, '/exchanges'),
    current_accounts: path(ROOTS_DASHBOARD, '/current-accounts'),
    reporting: {
      root: path(ROOTS_DASHBOARD, '/reporting/all'),
      all: path(ROOTS_DASHBOARD, '/reporting/all'),
      new: path(ROOTS_DASHBOARD, '/reporting/new'),
      new_template: path(ROOTS_DASHBOARD, '/reporting/new_template'),
    },
    wallets_managment: path(ROOTS_DASHBOARD, '/wallet_managment'),
  },
  security: {
    info: {
      view: (name: string) => path(ROOTS_DASHBOARD, `/security/info/${name}`),
    },
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  myConnections: path(ROOTS_DASHBOARD, '/my-connections'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    subs: path(ROOTS_DASHBOARD, '/user/subs'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  feedback: path(ROOTS_DASHBOARD, '/feedback'),
  admin: path(ROOTS_DASHBOARD, '/admin'),
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    info: path(ROOTS_DASHBOARD, '/company/info'),
    new_employee: path(ROOTS_DASHBOARD, '/company/new-employee'),
    edit_employee: path(ROOTS_DASHBOARD, '/company/edit-employee'),
    new_office: path(ROOTS_DASHBOARD, '/company/new-office'),
    new_client: path(ROOTS_DASHBOARD, '/company/new-client'),
    edit_office: (name: string) => path(ROOTS_DASHBOARD, `/company/office/${name}/edit`),
    clients: path(ROOTS_DASHBOARD, '/company/clients'),
    notifications: path(ROOTS_DASHBOARD, '/company/clients-notifications'),
  },
  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    all: path(ROOTS_DASHBOARD, '/client/wallets/all'),
    my_investments: path(ROOTS_DASHBOARD, '/client/my-investments'),
    walletsRoot: path(ROOTS_DASHBOARD, '/client/wallets'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/client/wallets/${name}`),
    calendar: path(ROOTS_DASHBOARD, '/client/calendar'),
    info: path(ROOTS_DASHBOARD, '/client/info'),
    summary: path(ROOTS_DASHBOARD, '/client/summary'),
    current_accounts: path(ROOTS_DASHBOARD, '/client/current-accounts'),
    reporting: path(ROOTS_DASHBOARD, '/client/reporting'),
    connections: path(ROOTS_DASHBOARD, '/client/connections'),
    altInvest: {
      all: path(ROOTS_DASHBOARD, '/client/alt-invest/all'),
      view: (id: string) => path(ROOTS_DASHBOARD, `/client/alt-invest/${id}`),
    },
    my_wealth: {
      root: path(ROOTS_DASHBOARD, '/client/wealth'),
      general: path(ROOTS_DASHBOARD, '/client/wealth/general'),
      alternative_investments: path(ROOTS_DASHBOARD, '/client/wealth/altInvest'),
      financial_investments: path(ROOTS_DASHBOARD, '/client/wealth/finInvest'),
    },
  },
  addAssets: {
    root: path(ROOTS_DASHBOARD, '/add-assets'),
    connection: path(ROOTS_DASHBOARD, `/add-assets/connection`),
    financial: {
      root: path(ROOTS_DASHBOARD, '/add-assets/financial'),
      wallet: path(ROOTS_DASHBOARD, '/add-assets/financial/wallet'),
      movement: path(ROOTS_DASHBOARD, '/add-assets/financial/movement'),
    },
    alternative: {
      root: path(ROOTS_DASHBOARD, '/add-assets/alternative'),
      type: path(ROOTS_DASHBOARD, '/add-assets/alternative/type/all'),
      wallet: path(ROOTS_DASHBOARD, '/add-assets/alternative/wallet'),
      movement: (id: string) => path(ROOTS_DASHBOARD, `/add-assets/alternative/type/${id}`),
    },
  },
  altInvest: {
    all: path(ROOTS_DASHBOARD, '/alt-invest/all'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/alt-invest/${id}`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
