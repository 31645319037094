import { Box, Typography } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  unReadyConnections: number;
};

export default function MenuTitle({ unReadyConnections }: Props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1">Conexiones importando</Typography>
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          Tienes {unReadyConnections} {`${unReadyConnections === 1 ? 'conexión' : 'conexiones'}`}{' '}
          importando
        </Typography>
      </Box>
    </Box>
  );
}
