import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Notification,
  NotificationsPayload,
} from 'src/redux/slices/NotificationsSlice/notificationsSlice.d';

type NotificationsState = {
  unReadNotifications: Notification[];
  readNotifications: Notification[];
};

export const initialState: NotificationsState = {
  unReadNotifications: [],
  readNotifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications: (state, action: PayloadAction<NotificationsPayload>) =>
      (state = {
        ...state,
        unReadNotifications: action.payload.unReadNotifications,
        readNotifications: action.payload.readNotifications,
      }),
    addNewNotification: (state, action: PayloadAction<Notification>) =>
      (state = {
        ...state,
        unReadNotifications: [action.payload, ...state.unReadNotifications],
      }),
    readNotifications: (state, action: PayloadAction<NotificationsPayload>) =>
      (state = {
        ...state,
        unReadNotifications: action.payload.unReadNotifications,
        readNotifications: action.payload.readNotifications,
      }),
  },
});

const persistNotificationsConfig = {
  key: 'notifications',
  storage,
  whitelist: ['cards'],
};

const persistNotificationsReducer = persistReducer<NotificationsState>(
  persistNotificationsConfig,
  notificationsSlice.reducer
);

export const { getNotifications, addNewNotification, readNotifications } =
  notificationsSlice.actions;

export default persistNotificationsReducer;
