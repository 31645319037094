import React, { createContext } from 'react';

type FeatureToggleProps = {
  toggles: { [key: string]: boolean };
};

type FeatureToggleProviderProps = React.PropsWithChildren<FeatureToggleProps>;

export const FeatureToggleContext = createContext<{ [key: string]: boolean }>({});

const FeatureToggleProvider: React.FunctionComponent<FeatureToggleProviderProps> = ({
  toggles,
  children,
}: FeatureToggleProviderProps) => (
  <FeatureToggleContext.Provider value={toggles}>{children}</FeatureToggleContext.Provider>
);

export default FeatureToggleProvider;
