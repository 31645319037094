import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';
import useGetInfoUser from 'src/layouts/dashboard/services/useGetUserInfo';
import useGetInfoCompany from 'src/hooks/useGetInfoCompany';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => {
  const { isBusiness } = useGetInfoUser();
  const { name } = useGetInfoCompany();

  return (
    <>
      <Helmet>
        <title>{`${title} | ${isBusiness ? name : 'Vumi'}`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

export default Page;
