// @mui
import { TableRow, TableBody, TableCell } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  height?: number;
};

export default function TableSearchNotFound({ title, height = 180 }: Props) {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={12} sx={{ height, typography: 'h6' }}>
          {title}
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
