import { useContext } from 'react';
// mui
import { alpha, Box, Stack, styled } from '@mui/material';
// components
import HeaderLogo from './HeaderLogo';
import NavbarDrawerButton from './NavbarDrawerButton';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useGetInfoUser from '../services/useGetUserInfo';
// context
import Context, { ContextType } from '../context';
// config
import { NAVBAR } from 'src/config';
// utils
import cssStyles from 'src/utils/cssStyles';

const HeaderStyle = styled(Stack)<{ isDesktop?: boolean }>(({ theme, isDesktop }) => {
  const { palette } = theme;
  const colorGradienst = isDesktop
    ? palette.mode === 'dark'
      ? palette.background.default
      : palette.background.paper
    : palette.background.paper;
  return {
    ...cssStyles(theme).bgGradient({
      direction: 'bottom',
      startColor: `${alpha(colorGradienst, 1)}`,
      endColor: `${alpha(colorGradienst, 0.4)}`,
    }),
    position: 'fixed',
    zIndex: 100,
    width: NAVBAR.DASHBOARD_WIDTH - 1,
    ...cssStyles(theme).bgBlur({
      opacity: 0.4,
      blur: 0.8,
      color: colorGradienst,
    }),
    boxShadow: 'none',
  };
});

type Props = {
  collapseClick: boolean;
  onToggleCollapse: VoidFunction;
};

export default function NavbarHeader({ collapseClick, onToggleCollapse }: Props) {
  const { isCollapse }: ContextType = useContext<ContextType>(Context);
  const { isBusiness, isClient } = useGetInfoUser();
  const isDesktop = useResponsive('up', 'lg');

  return (
    <HeaderStyle
      alignItems="center"
      direction={isCollapse ? 'column' : 'row'}
      isDesktop={isDesktop}
      justifyContent={'space-between'}
      spacing={3}
      sx={{
        pt: isBusiness || isClient ? 2 : 3,
        px: 2.5,
        pb: 3,
        ...(isCollapse && { alignItems: 'center', width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH - 1 }),
      }}
    >
      <HeaderLogo />

      {isDesktop && (
        <NavbarDrawerButton collapseClick={collapseClick} onToggleCollapse={onToggleCollapse} />
      )}
      <Box />
    </HeaderStyle>
  );
}
