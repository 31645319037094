import numeral from 'numeral';

numeral.register('locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});

numeral.locale('es');

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0 $' : '0,0.00 $');
}

export function fProfitWithCurrency(number: string | number) {
  return (number >= 0 ? '+' : '') + numeral(number).format('0,0.00 $');
}

export function fPercent(number: number) {
  const resultNumber =
    typeof number === 'number' && number.toString().indexOf('e+') !== -1 ? 0 : number;
  return numeral(resultNumber / 100).format('0.00%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fBigNumber(number: string | number) {
  return numeral(number).format('0,0a');
}

export function fDNumber(number: number) {
  return Math.round(number * 1000) / 1000;
}

export function fDecimalNumber(number: string | number, moreDecimals?: boolean) {
  const resultNumber =
    typeof number === 'number' && number.toString().indexOf('e+') !== -1 ? 0 : number;

  const result = numeral(resultNumber).format(
    Number.isInteger(resultNumber) ? '0,0' : moreDecimals ? '0,0.0000' : '0,0.00'
  );

  if (!moreDecimals && result === '0,00') return '0';

  return result;
}

export function fProfitWithDecimals(number: string | number) {
  return (number >= 0 ? '+' : '') + numeral(number).format('0,0.00');
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function nTotalFormatter(num: number) {
  if (num >= 1000000) return nDivider(num) + nGetFormatter(num);
  return fDecimalNumber(num);
}

export function nFormatter(num: number) {
  if (num >= 1000000) return (num / 1000000).toString().replace(/\.0$/, '') + 'M';
  return num;
}

export function nDivider(num: number) {
  if (num >= 1000000) return fDecimalNumber(num / 1000000);
  return fNumber(num);
}

export function nGetFormatter(num: number) {
  if (num >= 1000000) return ' M';
  return '';
}

export function countTrailingZeros(num: number) {
  let numStr = num.toString();

  // Comprueba si el número tiene decimales
  let decimalIndex = numStr.indexOf('.');
  if (decimalIndex === -1 && numStr.substring(0, decimalIndex) === '0') {
    return 0;
  }

  // Obtén la parte decimal de la cadena
  let decimalPart = numStr.substring(decimalIndex + 1);

  // Cuenta los ceros al inicio de la parte decimal
  let leadingZerosCount = 0;
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] === '0') {
      leadingZerosCount++;
    } else {
      break;
    }
  }

  return leadingZerosCount;
}
