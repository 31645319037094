import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICONS } from 'src/utils/iconUtils';
import { ConnectionPortfolioMap, getWalletsChildren } from './navConfigUtils';

export const summaryClientPathConfig = {
  title: 'navbar.summary',
  path: PATH_DASHBOARD.client.summary,
  icon: ICONS.home,
  isTranslate: true,
};

export const myWealthClientPathConfig = {
  title: 'navbar.my_wealth',
  path: PATH_DASHBOARD.client.my_wealth.general,
  icon: ICONS.analytics,
  isTranslate: true,
  children: [
    {
      title: 'navbar.global_wallet',
      path: PATH_DASHBOARD.client.my_wealth.general,
      isConnected: false,
      isTranslate: true,
    },
    {
      title: 'navbar.financial_investments',
      path: PATH_DASHBOARD.client.my_wealth.financial_investments,
      isConnected: false,
      isTranslate: true,
    },
    {
      title: 'navbar.alt_investments',
      path: PATH_DASHBOARD.client.my_wealth.alternative_investments,
      isConnected: false,
      isTranslate: true,
    },
  ],
};

export const financialClientPathConfig = (connections: ConnectionPortfolioMap) => ({
  title: 'navbar.financial_investments',
  path: PATH_DASHBOARD.client.walletsRoot,
  icon: ICONS.wallets,
  isTranslate: true,
  children: getWalletsChildren(connections, true),
});

export const alternativeClientPathConfig = (altInvestWallets: ConnectionPortfolioMap) => ({
  title: 'navbar.alt_investments',
  path: PATH_DASHBOARD.client.altInvest.view(''),
  icon: ICONS.altInvest,
  children: getWalletsChildren(altInvestWallets, true),
});

export const currentAccountsClientPathConfig = {
  title: 'navbar.current_accounts',
  path: PATH_DASHBOARD.general.current_accounts,
  icon: ICONS.banking,
  isTranslate: true,
  highlightAdd: true,
};

export const calendarClientPathConfig = {
  title: 'navbar.calendar',
  path: PATH_DASHBOARD.client.calendar,
  icon: ICONS.calendar,
  isTranslate: true,
};

export const reportingClientPathConfig = {
  title: 'navbar.historic_reporting',
  path: PATH_DASHBOARD.client.reporting,
  icon: ICONS.reporting,
  isTranslate: true,
};

export const overflowClientPathConfig = (children: any[]) => ({
  title: '',
  path: PATH_DASHBOARD.client.walletsRoot,
  icon: ICONS.more,
  isTranslate: true,
  children,
});

export const connectionsClientPathConfig = {
  title: 'navbar.user_connections',
  path: PATH_DASHBOARD.client.connections,
  icon: ICONS.connection,
  isTranslate: true,
};

export const userInfoClientPathConfig = {
  title: 'navbar.user_info',
  path: PATH_DASHBOARD.client.info,
  icon: ICONS.user,
  isTranslate: true,
};
