import { AxiosResponse } from 'axios';

import {
  ClientCompanyForm,
  ClientsType,
  CompanyType,
  EmployeeType,
  InfoCompanyForm,
  OfficeCompanyForm,
  OfficeType,
} from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { UpdateClientPayload } from 'src/redux/slices/ClientSlice/clientSlice.d';
import { ThemeColorsApp, ThemeColorsReport } from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import { RootState } from 'src/redux/store';
import { getProfilesUrl } from 'src/utils/formatUrl';
import {
  GetClientsType,
  GetCompaniesType,
  GetCompanyDetailType,
  GetEmployeesType,
  GetOfficeNetType,
  GetOfficesType,
  GetPortfoliosManagedResponse,
  GetReportSettingsResponse,
  ImportClientsError,
  ImportClientsResponse,
} from './NetTypes';
import { defaultThemeColorReport } from 'src/utils/getColorPresets';

export function transformGetCompanies(): (response: AxiosResponse<GetCompaniesType>) => {
  companies: CompanyType[];
  totalItems: number;
} {
  return (response: AxiosResponse<GetCompaniesType>) => ({
    totalItems: response.data.totalItems,
    companies: response.data.organizations,
  });
}

export function transformGetEmployees(): (response: AxiosResponse<GetEmployeesType>) => {
  employees: EmployeeType[];
  totalItems: number;
} {
  return (response: AxiosResponse<GetEmployeesType>) => ({
    totalItems: response.data.totalItems,
    employees: response.data.employees.map((e) => ({
      ...e,
      imageUrl: !!e.imageUrl ? e.imageUrl : undefined,
    })),
  });
}

export function transformGetClients(): (response: AxiosResponse<GetClientsType>) => {
  clients: ClientsType[];
  totalItems: number;
} {
  return (response: AxiosResponse<GetClientsType>) => ({
    totalItems: response.data.totalItems,
    clients: response.data.clients.map((c) => ({
      ...c,
      imageUrl: !!c.imageUrl ? c.imageUrl : undefined,
    })),
  });
}

export function transformGetOffices(): (response: AxiosResponse<GetOfficesType>) => {
  offices: OfficeType[];
  totalItems: number;
} {
  return (response: AxiosResponse<GetOfficesType>) => ({
    totalItems: response.data.totalItems,
    offices: response.data.offices,
  });
}
export function transformGetOffice(): (response: AxiosResponse<GetOfficeNetType>) => OfficeType {
  return ({ data }: AxiosResponse<GetOfficeNetType>) => ({
    id: data.id,
    name: data.name,
    contactEmail: data.contactEmail,
    pointOfContact: data.pointOfContact,
    phoneNumber: data.phoneNumber,
    address: {
      street: data.address?.street,
      town: data.address?.town,
      city: data.address?.city,
      zipCode: data.address?.zipCode,
      country: data.address?.country?.toString() ?? '',
    },
  });
}

export function transformGetCompany(): (
  response: AxiosResponse<GetCompanyDetailType>,
  state: RootState
) => CompanyType {
  return ({ data }: AxiosResponse<GetCompanyDetailType>, state: RootState) => ({
    id: data.id,
    companyName: data.companyName,
    name: data.name,
    firstSurname: data.firstSurname,
    secondSurname: data.secondSurname,
    cif: data.cif,
    contactEmail: data.contactEmail,
    phoneNumber: data.phoneNumber,
    pointOfContact: data.pointOfContact,
    employeesAmounts: data.employeesAmounts,
    clientsAmount: data.clientsAmount,
    onboardingLanguage: 'es_ES',
    imageUrl: !!data.imageUrl ? getProfilesUrl(data.imageUrl) : undefined,
    logoUrl: !!data.logoUrl ? getProfilesUrl(data.logoUrl) : undefined,
    address: {
      street: data.address?.street,
      town: data.address?.town,
      city: data.address?.city,
      zipCode: data.address?.zipCode,
      country: data.address?.country?.toString() ?? '',
    },
    themeCompany: state.business.company.themeCompany,
  });
}

export function transformGetCompanyAction(
  newInfo: InfoCompanyForm
): (response: AxiosResponse<GetCompanyDetailType>, state: RootState) => CompanyType {
  return (_response: AxiosResponse<GetCompanyDetailType>, { business }: RootState) => ({
    ...business.company,
    contactEmail: newInfo.contact_email,
    phoneNumber: {
      telNumber: newInfo.contactNumber,
      country: newInfo.phone_code,
    },
    pointOfContact: newInfo.point_of_contact,
    address: {
      ...business.company.address,
      id: business.company.id ?? 'test',
      street: newInfo.street,
      town: newInfo.town,
      city: newInfo.city,
      zipCode: newInfo.zipCode,
      country: newInfo.country,
    },
  });
}
export function transformUpdateOfficeAction(
  newInfo: OfficeCompanyForm
): (response: AxiosResponse<any>, state: RootState) => OfficeType {
  return (_response: AxiosResponse<any>, state: RootState) => ({
    ...state.business.office,
    name: newInfo.name,
    contactEmail: newInfo.contact_email,
    phoneNumber: {
      telNumber: newInfo.contactNumber,
      country: newInfo.phone_code,
    },
    pointOfContact: newInfo.point_of_contact,
    address: {
      ...state.business.office.address,
      street: newInfo.street,
      town: newInfo.town,
      city: newInfo.city,
      zipCode: newInfo.zipCode,
      country: newInfo.country,
    },
  });
}

export function transformUpdateClient(
  client: ClientCompanyForm
): (response: AxiosResponse<any>, state: RootState) => UpdateClientPayload {
  return (_response: AxiosResponse<any>, state: RootState) => {
    const contactNumber =
      !!client.contactNumber && !!client.phone_code
        ? {
            contactNumber: {
              country: client.phone_code,
              telNumber: client.contactNumber?.toString(),
            },
          }
        : {};
    const gender = !!client.gender
      ? { gender: client.gender === 'Hombre' ? 'MALE' : 'FEMALE' }
      : {};
    const type = !!client.type
      ? { type: client.type === 'Minorista' ? 'RETAIL' : 'PROFESSIONAL' }
      : {};

    return {
      name: client.clientName,
      firstSurname: client.clientFirstSurname,
      secondSurname: client.clientSecondSurname,
      email: client.clientEmail,
      nationalID: client.nationalID,
      birthDate: client.birthDate?.toString() ?? '',
      country: client.country,
      personType: client.personType,
      administrator: client.administrator,
      residenceType: client.residenceType,
      leiCode: client.leiCode,
      clientKind: client.clientKind,
      cif: client.cif,
      ...gender,
      ...type,
      ...contactNumber,
    };
  };
}

export function transformCreateCSVClients(): (
  response: AxiosResponse<ImportClientsResponse>,
  state: RootState
) => ImportClientsError[] {
  return (response: AxiosResponse<ImportClientsResponse>, state: RootState) =>
    response.data.errorList;
}

export function transformGetPortfoliosManaged(): (
  response: AxiosResponse<GetPortfoliosManagedResponse>,
  state: RootState
) => GetPortfoliosManagedResponse {
  return (response: AxiosResponse<GetPortfoliosManagedResponse>, state: RootState) => response.data;
}

export function transformGetReportSettings(): (
  response: AxiosResponse<GetReportSettingsResponse>,
  state: RootState
) => ThemeColorsReport {
  return (response: AxiosResponse<GetReportSettingsResponse>, state: RootState) => {
    const themeColors: ThemeColorsReport = JSON.parse(response.data.reportSettings);
    return {
      ...defaultThemeColorReport,
      ...themeColors,
    };
  };
}

export function transformUpdateReportSettings(
  reportSettings: ThemeColorsReport
): (response: AxiosResponse<GetReportSettingsResponse>, state: RootState) => any {
  return (response: AxiosResponse<GetReportSettingsResponse>, state: RootState) => reportSettings;
}

export function transformUpdateCompanySettings(
  reportSettings: ThemeColorsApp
): (response: AxiosResponse<any>, state: RootState) => ThemeColorsApp {
  return (response: AxiosResponse<any>, state: RootState) => reportSettings;
}
