import { SxProps, TableCell } from '@mui/material';
import { isEqual } from 'lodash';
import { memo } from 'react';

// ----------------------------------------------------------------------

interface Props {
  title: string | number | JSX.Element;
  blur?: boolean;
  aligStart?: boolean;
  pointer?: boolean;
  sx?: SxProps;
  onClick?: () => void;
}

const TableCellOp = ({ title, blur, aligStart, sx, pointer, onClick = () => {} }: Props) => (
  <TableCell
    align={aligStart ? 'left' : 'center'}
    onClick={onClick}
    sx={{
      whiteSpace: 'nowrap',
      filter: blur ? 'blur(7px)' : 'blur(0px)',
      width: 200,
      cursor: pointer ? 'pointer' : 'default',
      ...sx,
    }}
  >
    {blur ? 'Fool' : title}
  </TableCell>
);

const arePropsEqual = (prevProps: any, nextProps: any) => isEqual(prevProps, nextProps);

export default memo(TableCellOp, arePropsEqual);
