import { AxiosResponse } from 'axios';
import moment from 'moment';

import { RegisterPayload } from 'src/redux/slices/AuthSlice/authSlice.d';
import { RootState } from 'src/redux/store';
import {
  GetSecurityInfoNetType,
  GetSecurityMovementsNetType,
  SecurityInfoNetType,
} from './NetTypes';
import {
  CountBySecurityType,
  GetMovementsResponseNetType,
  GetSecuritiosByTermCountNetType,
} from '../portfolio/NetTypes';

export function transformUpdateLatestPrices(): (
  response: AxiosResponse<any>,
  state: RootState
) => RegisterPayload {
  return (_response: AxiosResponse<any>, state: RootState) => ({
    user: { ...state.auth.user, latestPriceUpdate: moment().toString() },
  });
}
export function transformgetInfoSecurity(
  id: string,
  period: string
): (response: AxiosResponse<GetSecurityInfoNetType>, state: RootState) => SecurityInfoNetType {
  return ({ data }: AxiosResponse<GetSecurityInfoNetType>, _state: RootState) => {
    let { prices, events, cumulativePerformance } = data.graphSecurityPricesAndEvents;
    if ((period === '3Y' || period === '5Y' || period === 'ALL') && prices.length > 700) {
      if (period === 'ALL') {
        const sortedPrices = prices
          .slice()
          .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());

        const firstFriday = sortedPrices.find(({ date }) => moment(date).weekday() === 4);
        if (firstFriday) {
          const firstFridayStartOfWeek = moment(firstFriday.date).startOf('week');

          prices = prices.filter(({ date }, index) => {
            const momentDate = moment(date);
            if (events.find((event) => moment(event.date).isSame(momentDate))) return true;
            if (momentDate.weekday() !== 4) return false;
            return momentDate.startOf('week').diff(firstFridayStartOfWeek, 'weeks') % 2 === 0;
          });
        }
      } else {
        prices = prices.filter(({ date }) => {
          const momentDate = moment(date);
          if (events.find((event) => moment(event.date).isSame(momentDate))) return true;
          return momentDate.weekday() === 4;
        });
      }

      prices.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    }
    const priceListDates = prices.map((i) => i.date);

    return {
      id,
      ...data.infoPageHeader,
      prices: {
        priceList: prices,
        lastEvents: events,
        performanceList: cumulativePerformance
          .filter((k) => priceListDates.includes(k.date))
          .map((i) => ({
            ...i,
            value: i.value * 100,
          })),
      },
      ...data.infoSecurity,
      historicalPositions: data.historicalPositions,
      futureEvents: data.futureEvents,
      activePositions: data.activePositions,
    };
  };
}
export function transformGetSecurityMovements(): (
  response: AxiosResponse<GetMovementsResponseNetType>,
  state: RootState
) => GetSecurityMovementsNetType {
  return ({ data }: AxiosResponse<GetMovementsResponseNetType>, _state: RootState) => ({
    transactions: data.transactions,
    totalItems: data.totalItems,
  });
}

export function transformGetCountSecurity(): (
  response: AxiosResponse<GetSecuritiosByTermCountNetType>,
  state: RootState
) => CountBySecurityType {
  return ({ data }: AxiosResponse<GetSecuritiosByTermCountNetType>, state: RootState) =>
    data.countBySecurityType;
}
