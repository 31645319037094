import { useState } from 'react';
import { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';

// ----------------------------------------------------------------------

type Props = {
  isIcon?: boolean;
};

export default function useGetManageImageCrops({ isIcon }: Props) {
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [imageCanvas, setImageCanvas] = useState<HTMLImageElement>();
  const [crop, setCrop] = useState<Crop>();

  function centerAspectCrop(mediaWidth: number, mediaHeight: number) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: isIcon ? 30 : 50,
        },
        isIcon ? 1 : 16 / 9,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setImageCanvas(e.currentTarget);
    setCrop(centerAspectCrop(width, height));
  }

  return {
    onImageLoad,
    crop,
    completedCrop,
    imageCanvas,
    onChangeCompleteCrop: setCompletedCrop,
    onChangeCrop: setCrop,
  };
}
