import * as Auth from './auth/RequestIds';
import * as Portfolios from './portfolio/RequestIds';
import * as Markets from './indices/RequestIds';
import * as Securities from './securities/RequestIds';
import * as Transactions from './transactions/RequestIds';
import * as Subscription from './subscription/RequestIds';
import * as Toggle from './toggle/RequestIds';
import * as User from './user/RequestIds';
import * as Flanks from './flanks/RequestIds';
import * as Notifications from './notifications/RequestIds';
import * as Feedback from './feedback/RequestIds';
import * as Calendar from './calendar/RequestIds';
import * as Accounts from './accounts/RequestIds';
import * as Connections from './connections/RequestIds';
import * as Companies from './companies/RequestIds';
import * as Data from './data/RequestIds';
import * as Client from './client/RequestIds';
import * as Reports from './reports/RequestIds';
import * as Finlink from './finlink/RequestIds';
import * as Investments from './Investments/RequestIds';
import * as Wealth from './wealth/RequestIds';

export const AuthRequestIds: string[] = [
  ...Object.values(Portfolios),
  ...Object.values(Markets),
  ...Object.values(Securities),
  ...Object.values(Transactions),
  ...Object.values(Subscription),
  ...Object.values(User),
  ...Object.values(Flanks),
  ...Object.values(Notifications),
  ...Object.values(Feedback),
  ...Object.values(Calendar),
  ...Object.values(Accounts),
  ...Object.values(Connections),
  ...Object.values(Companies),
  ...Object.values(Data),
  ...Object.values(Client),
  ...Object.values(Reports),
  ...Object.values(Investments),
  ...Object.values(Finlink),
  ...Object.values(Wealth),
];
export const RequestIds: string[] = [...Object.values(Auth), ...Object.values(Toggle)];
