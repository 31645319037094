import { AxiosResponse } from 'axios';

import {
  Notification,
  NotificationsPayload,
} from 'src/redux/slices/NotificationsSlice/notificationsSlice.d';
import { RootState } from 'src/redux/store';
import { transformNotifications } from 'src/utils/notifications';
import { GetUserNotificationsResponseNetType } from './NetTypes';

export function transformGetUserNotifications(): (
  response: AxiosResponse<GetUserNotificationsResponseNetType>,
  state: RootState
) => NotificationsPayload {
  return (response: AxiosResponse<GetUserNotificationsResponseNetType>, state: RootState) => ({
    readNotifications: transformNotifications(response.data.notificationsRead, state),
    unReadNotifications: transformNotifications(response.data.notificationsUnRead, state),
  });
}

export function transformReadNotifications(
  ids: string[]
): (response: AxiosResponse<any>, state: RootState) => NotificationsPayload {
  return (_response: AxiosResponse<any>, state: RootState) => {
    const { readNotifications } = state.notifications;
    const { unReadNotifications } = state.notifications;

    const newReadNotifications: Notification[] = unReadNotifications.filter((i) => {
      const index = ids.findIndex((item) => item === i.id);
      if (index !== -1) {
        return { ...i, isRead: true };
      }
    });

    const newUnReadNotifications: Notification[] = unReadNotifications.filter((i) => {
      const index = ids.findIndex((item) => item === i.id);
      if (index === -1) return i;
    });

    return {
      readNotifications: [...readNotifications, ...newReadNotifications],
      unReadNotifications: newUnReadNotifications,
    };
  };
}
