// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  url: string;
}

export default function ConnectionIllustration({ url, ...other }: Props) {
  return (
    <Box {...other}>
      <svg fill="none" height="100%" viewBox="0 0 733 504" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
          <path
            clipRule="evenodd"
            d="M0 278.293C0 336.333 22.8228 389.222 60.3794 429.193C103.465 475.063 165.944 503.903 235.587 504C266.024 504.041 295.127 498.558 321.86 488.549C335.682 483.372 351.152 483.966 364.54 490.1C380.243 497.285 397.827 501.314 416.395 501.314C424.065 501.314 431.576 500.622 438.842 499.308C459.582 495.569 478.455 486.722 493.954 474.191C503.695 466.313 516.17 462.353 528.92 462.367H529.137C571.268 462.367 610.402 450.142 642.893 429.193C671.94 410.489 695.66 384.818 711.464 354.678C725.243 328.413 733 298.743 733 267.341C733 159.638 641.72 72.3147 529.137 72.3147C517.66 72.3147 506.415 73.2421 495.445 74.9725C466.398 29.9881 414.382 0 355.044 0C329.992 0 306.242 5.34476 284.982 14.9257C264.764 24.0077 246.788 36.9128 232.084 52.64C182.153 53.4016 135.984 69.0059 98.196 95.048C38.7138 136.017 0 202.835 0 278.293Z"
            fill="url(#paint0_linear_1778_106019)"
            fillRule="evenodd"
          />
        </g>
        <path
          d="M167.414 130.384C167.414 130.384 42.7401 181.159 38.4492 251.859C34.1582 322.557 266.901 309.809 317.795 340.297C368.69 370.783 589.274 417.115 585.023 364.009C580.771 310.902 528.366 292.581 528.366 292.581"
          opacity="0.24"
          stroke="#FF9634"
          strokeDasharray="3 3"
          strokeWidth="2"
        />
        <path
          d="M386.371 181.086L410.724 255.136C411.083 256.329 411.864 257.379 412.942 258.123C414.018 258.868 415.335 259.264 416.685 259.253L477.095 257.653C479.19 257.598 480.602 255.671 479.868 253.873L449.325 179.113C448.902 178.076 447.849 177.366 446.645 177.3L391.813 174.347C390.912 174.312 390.014 174.48 389.2 174.837C388.385 175.194 387.679 175.728 387.142 176.393C386.604 177.058 386.253 177.833 386.119 178.651C385.984 179.47 386.071 180.306 386.371 181.086Z"
          fill="#FF9634"
          opacity="0.18"
        />
        <path
          d="M534.604 236.925L509.415 171.544C509.029 170.494 508.314 169.571 507.361 168.892C506.407 168.212 505.257 167.804 504.052 167.719L397.892 161.963C396.988 161.938 396.09 162.113 395.276 162.474C394.463 162.836 393.757 163.372 393.218 164.037C392.679 164.703 392.325 165.477 392.184 166.296C392.042 167.115 392.12 167.953 392.409 168.738L416.764 242.826C417.127 244.019 417.906 245.07 418.983 245.819C420.058 246.57 421.371 246.976 422.723 246.978L529.757 244.027C530.651 243.98 531.518 243.737 532.288 243.319C533.059 242.9 533.708 242.319 534.183 241.625C534.658 240.929 534.943 240.139 535.017 239.322C535.09 238.504 534.948 237.682 534.604 236.925Z"
          fill="white"
        />
        <path
          d="M498.333 226.488C499.063 228.225 499.428 229.093 500.208 229.584C500.99 230.076 502.004 230.076 504.034 230.076H504.513C508.559 230.076 510.58 230.076 511.491 228.859C512.401 227.641 511.671 225.912 510.209 222.456L507.787 216.717C507.056 214.985 506.691 214.12 505.911 213.63C505.13 213.139 504.116 213.139 502.091 213.139H501.626C497.588 213.139 495.569 213.139 494.659 214.354C493.749 215.569 494.474 217.297 495.925 220.75L498.333 226.488Z"
          fill="#FF9634"
        />
        <path
          d="M464.84 226.49C465.569 228.226 465.935 229.094 466.715 229.586C467.497 230.077 468.511 230.077 470.54 230.077H471.021C475.065 230.077 477.088 230.077 477.997 228.859C478.907 227.641 478.177 225.912 476.717 222.454L472.586 212.674C471.855 210.942 471.49 210.077 470.71 209.587C469.929 209.097 468.915 209.097 466.89 209.097H466.428C462.389 209.097 460.37 209.097 459.46 210.312C458.55 211.527 459.276 213.255 460.729 216.71L464.84 226.49Z"
          fill="#FF9634"
        />
        <path
          d="M448.11 226.498C448.842 228.23 449.207 229.096 449.987 229.586C450.767 230.078 451.781 230.078 453.808 230.078H454.296C458.34 230.078 460.362 230.078 461.272 228.86C462.182 227.643 461.452 225.914 459.993 222.456L445.569 188.272C444.838 186.539 444.473 185.674 443.692 185.182C442.912 184.692 441.898 184.692 439.872 184.692H439.383C435.339 184.692 433.317 184.692 432.407 185.909C431.497 187.127 432.227 188.855 433.685 192.313L448.11 226.498Z"
          fill="#FF9634"
        />
        <path
          d="M481.604 226.498C482.334 228.229 482.7 229.096 483.481 229.586C484.261 230.077 485.273 230.077 487.3 230.077H487.789C491.832 230.077 493.854 230.077 494.764 228.859C495.674 227.643 494.944 225.914 493.486 222.456L483.672 199.199C482.94 197.466 482.574 196.601 481.793 196.109C481.013 195.619 480 195.619 477.974 195.619H477.485C473.442 195.619 471.42 195.619 470.51 196.837C469.599 198.054 470.329 199.783 471.788 203.239L481.604 226.498Z"
          fill="#D56701"
        />
        <path
          d="M431.345 226.495C432.076 228.229 432.441 229.096 433.221 229.586C434.002 230.077 435.016 230.077 437.042 230.077H437.534C441.576 230.077 443.596 230.077 444.506 228.861C445.417 227.644 444.688 225.915 443.231 222.459L423.479 175.598C422.747 173.865 422.382 172.998 421.602 172.508C420.821 172.017 419.807 172.017 417.781 172.017H417.289C413.247 172.017 411.227 172.017 410.317 173.233C409.406 174.45 410.135 176.179 411.592 179.635L431.345 226.495Z"
          fill="#FF9634"
        />
        <path d="M230.825 99.1318L190.101 116.397L196.974 138.397L230.825 99.1318Z" fill="white" />
        <path d="M230.825 99.1318H167.375L186.684 109.95L230.825 99.1318Z" fill="white" />
        <path
          d="M179.333 126.122L190.1 116.397L230.825 99.1318L186.684 109.95L179.333 126.122Z"
          fill="#FF9634"
        />
        <path d="M179.333 126.122L190.1 116.397L230.825 99.1318L179.333 126.122Z" fill="#FFEAD6" />
        <path
          d="M179.333 126.122L190.1 116.397L230.825 99.1318L186.684 109.95L179.333 126.122Z"
          fill="#EF7E14"
        />
        <path
          d="M140.313 278.125L164.667 352.175C165.026 353.368 165.807 354.418 166.885 355.162C167.961 355.907 169.278 356.303 170.628 356.292L231.037 354.692C233.133 354.637 234.545 352.71 233.811 350.912L203.267 276.152C202.844 275.115 201.792 274.405 200.587 274.339L145.756 271.386C144.855 271.351 143.957 271.519 143.143 271.876C142.328 272.233 141.622 272.767 141.085 273.432C140.547 274.097 140.196 274.872 140.061 275.69C139.927 276.509 140.014 277.345 140.313 278.125Z"
          fill="#FF9634"
          opacity="0.18"
        />
        <path
          d="M288.547 333.964L263.358 268.583C262.972 267.533 262.257 266.61 261.304 265.931C260.35 265.251 259.2 264.843 257.995 264.758L151.835 259.002C150.931 258.977 150.033 259.152 149.219 259.513C148.405 259.875 147.7 260.411 147.161 261.076C146.622 261.742 146.267 262.516 146.127 263.335C145.985 264.154 146.063 264.992 146.351 265.777L170.707 339.865C171.07 341.058 171.849 342.109 172.926 342.858C174.001 343.609 175.314 344.015 176.666 344.017L283.7 341.066C284.594 341.019 285.461 340.776 286.231 340.358C287.002 339.939 287.651 339.358 288.126 338.664C288.601 337.968 288.886 337.178 288.96 336.361C289.033 335.543 288.891 334.721 288.547 333.964Z"
          fill="white"
        />
        <path
          d="M334.287 56.959C337.355 53.8913 342.33 53.8913 345.398 56.959C348.465 60.0267 348.465 65.0017 345.398 68.0694C342.33 71.1371 337.355 71.1371 334.287 68.0694C331.219 65.0017 331.219 60.0267 334.287 56.959Z"
          fill="#FF9634"
        />
        <path
          d="M350.961 40.3008C354.028 37.2331 359.003 37.2331 362.071 40.3008L378.729 56.9585C381.797 60.0262 381.797 65.0012 378.729 68.0689L362.055 84.7266C358.988 87.7943 354.012 87.7943 350.945 84.7266C347.877 81.6589 347.877 76.6839 350.945 73.6162L356.492 68.0689C359.56 65.0012 359.56 60.0262 356.492 56.9585L350.961 51.3953C347.893 48.3276 347.893 43.3685 350.961 40.3008Z"
          fill="#FF9634"
        />
        <path
          d="M384.276 73.6181L362.055 95.8231C358.988 98.8908 354.012 98.8908 350.945 95.8231L328.74 73.6181C325.672 70.5505 320.697 70.5505 317.629 73.6181L313.656 77.5918C312.781 78.4661 312.781 79.8807 313.656 80.7549L350.961 118.044C354.028 121.112 359.003 121.112 362.071 118.044L399.344 80.7549C400.219 79.8807 400.219 78.4661 399.344 77.5918L395.371 73.6181C392.303 70.5505 387.344 70.5505 384.276 73.6181Z"
          fill="#FF9634"
        />
        <rect
          fill="url(#pattern0)"
          height="12.6665"
          transform="rotate(-0.622868 176 295.901)"
          width="82.9526"
          x="176"
          y="295.901"
        />
        <defs>
          <pattern height="1" id="pattern0" patternContentUnits="objectBoundingBox" width="1">
            <use
              href="#image0_1778_106019"
              transform="translate(-0.074642) scale(0.000589323 0.003861)"
            />
          </pattern>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_1778_106019"
            x1="502.12"
            x2="734.869"
            y1="593.645"
            y2="21.4544"
          >
            <stop stopColor="#FF9634" />
            <stop offset="1" stopColor="#FFEAD6" />
          </linearGradient>
          <image height="259" href={url} id="image0_1778_106019" width="1740" />
        </defs>
      </svg>
    </Box>
  );
}
