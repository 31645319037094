import { RootState } from 'src/redux/store';

export function getTokenAuthHeader(state: RootState) {
  const token = state.auth.token;
  if (!token) {
    throw new Error("there's no token credentials");
  }
  return {
    Authorization: `Bearer ${token}`,
  };
}
