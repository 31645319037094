export const CREATE_COMPANY = 'CREATE_COMPANY';

export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';

export const CREATE_OFFICE = 'CREATE_OFFICE';

export const CREATE_CLIENT = 'CREATE_CLIENT';

export const GET_OFFICE = 'CREATE_OFFICE';

export const GET_COMPANIES = 'GET_COMPANIES';

export const GET_EMPLOYEES = 'GET_EMPLOYEES';

export const GET_OFFICES = 'GET_OFFICES';

export const GET_CLIENTS = 'GET_CLIENTS';

export const GET_COMPANY_DETAIL = 'GET_COMPANY_DETAIL';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_STARTED = 'GET_COMPANY_STARTED';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_STARTED = 'UPDATE_COMPANY_STARTED';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const UPDATE_OFFICE = 'UPDATE_OFFICE';
export const UPDATE_OFFICE_STARTED = 'UPDATE_OFFICE_STARTED';
export const UPDATE_OFFICE_FAILURE = 'UPDATE_OFFICE_FAILURE';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_STARTED = 'UPDATE_CLIENT_STARTED';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';

export const DELETE_COMPANY = 'DELETE_COMPANY';

export const CREATE_CSV_CLIENTS = 'CREATE_CSV_CLIENTS';

export const DELETE_CLIENT = 'DELETE_CLIENT';

export const DELETE_CLIENTS = 'DELETE_CLIENTS';

export const GRANT_ACCESS_CLIENTS = 'GRANT_ACCESS_CLIENTS';
export const GRANT_ACCESS_CLIENTS_STARTED = 'GRANT_ACCESS_CLIENTS_STARTED';
export const GRANT_ACCESS_CLIENTS_FAILURE = 'GRANT_ACCESS_CLIENTS_FAILURE';

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const REASING_CLIENTS = 'REASING_CLIENTS';

export const GET_ALL_PORTFOLIOS_MANAGED = 'GET_ALL_PORTFOLIOS_MANAGED';

export const GET_REPORT_SETTINGS = 'GET_REPORT_SETTINGS';
export const GET_REPORT_SETTINGS_STARTED = 'GET_REPORT_SETTINGS_STARTED';
export const GET_REPORT_SETTINGS_FAILURE = 'GET_REPORT_SETTINGS_FAILURE';
