import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';

import { MotionContainer, varBounce } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function PermissionDenied() {
  return (
    <Page sx={{ height: 1 }} title="Acceso Denegado">
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography paragraph variant="h3">
                Acceso Denegado
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              No tienes permisos para acceder a esta página
            </Typography>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
