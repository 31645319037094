import { LoadingButton } from '@mui/lab';
import { DialogActions, Link, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { FormProvider } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';
import { defaultSecurity } from 'src/pages/dashboard/WalletsAddMovement/WalletsAddMovement.Types';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { SecurityType } from 'src/sections/@dashboard/general/wallets/transactions/SecuritiesSearch';
import { getNetworkClient } from 'src/services/config';
import { getMissingSecuritiesRequest, sendMissingSecuritiesRequest } from 'src/services/portfolio';
import { UnknownSecurities } from 'src/services/portfolio/NetTypes';
import SharesNotFoundList from '../shares-not-found/SharesNotFoundList';
import LogoLoader from 'src/components/LogoLoader';

// ----------------------------------------------------------------------

export type MissingSecurities = {
  unkownSecuritie: UnknownSecurities;
  securitieFind: SecurityType;
  notFind: boolean;
};

type Props = {
  id: string;
  onCancel: (isDone?: boolean) => void;
};

export default function SharesNotFoundModal({ id, onCancel }: Props) {
  const [securities, setSecurities] = useState<MissingSecurities[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { translate } = useLocales();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<{ security: SecurityType }>({
    defaultValues: {
      security: defaultSecurity,
    },
  });

  const { handleSubmit } = methods;
  const isEmpty = securities.length === 0;

  useEffect(() => {
    getNetworkClient().executeRequest(
      getMissingSecuritiesRequest(id, (data: UnknownSecurities[]) => {
        setSecurities([
          ...data.map((item) => ({
            notFind: false,
            unkownSecuritie: item,
            securitieFind: defaultSecurity,
          })),
        ]);
      })
    );
  }, []);

  const onSubmit = async () => {};

  const onSubmitSingle = (item: MissingSecurities) => {
    setShowError(false);
    setLoading(true);
    const allChecked = item.notFind || item.securitieFind.id !== '';
    if (!allChecked) {
      setLoading(false);
      setShowError(true);
    } else {
      setLoading(true);
      setShowError(false);
      getNetworkClient().executeRequest(
        sendMissingSecuritiesRequest(
          id,
          [],
          () => {
            setSecurities(
              securities.filter((i) => i.unkownSecuritie.id !== item.unkownSecuritie.id)
            );
            enqueueSnackbar(translate('shares_not_found.success'));
            setLoading(false);
          },
          () => {
            enqueueSnackbar(translate('shares_not_found.error'), { variant: 'error' });
            setLoading(false);
            onCancel();
          }
        )
      );
    }
  };

  return (
    <>
      <FormProvider autocomplete="off" methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ px: 5, py: 3 }}>
          <Typography alignSelf="center" sx={{}} textAlign={'center'} variant="h4">
            {isEmpty ? 'Gracias por ayudarnos a mejorar' : translate('shares_not_found.title')}
          </Typography>
          <Typography
            color={'text.secondary'}
            sx={{ mt: 2 }}
            textAlign="justify"
            variant="subtitle2"
          >
            {isEmpty
              ? 'Estamos trabajando en añadir los activos sugeridos. Si tienes alguna otra cuestión, ponte en contacto con el equipo de Vumi mediante el chat online situado en la esquina inferior derecha.'
              : translate('shares_not_found.description_1')}
          </Typography>

          {!isEmpty && (
            <>
              <Typography
                color={'text.secondary'}
                sx={{ mt: 1 }}
                textAlign={'left'}
                variant="subtitle2"
              >
                {translate('shares_not_found.description_2')}
              </Typography>
              <Typography
                color={'primary.main'}
                sx={{ mt: 1, fontSize: 12 }}
                textAlign={'left'}
                variant="overline"
              >
                {'Recuerda que el activo sugerido debe tener la misma moneda y cotizar en la misma bolsa'.toUpperCase()}
              </Typography>
              <Typography
                color={'text.secondary'}
                sx={{ mt: 1 }}
                textAlign={'left'}
                variant="subtitle2"
              >
                {translate('shares_not_found.description_3')}
              </Typography>
              <Stack alignItems="center" flexDirection="row" sx={{ mt: 2 }}>
                <Typography color="primary.main" textAlign={'left'} variant="subtitle2">
                  {`${securities.length} ISIN `}
                </Typography>
                <Typography
                  color="text.primary"
                  sx={{ ml: 0.5 }}
                  textAlign={'left'}
                  variant="body2"
                >
                  {translate('shares_not_found.not_found_them')}
                </Typography>
              </Stack>
              {!loading ? (
                <SharesNotFoundList
                  handleSecurityChange={setSecurities}
                  hasError={showError}
                  onSubmitSuggestion={onSubmitSingle}
                  securities={securities}
                />
              ) : (
                <LogoLoader sx={{ height: 200 }} />
              )}
            </>
          )}
        </Stack>
      </FormProvider>
      {isEmpty && (
        <DialogActions sx={{ mb: 1, mt: -2, mr: 1.6 }}>
          <LoadingButton
            loading={loading}
            onClick={() => onCancel()}
            size="medium"
            type="submit"
            variant="contained"
          >
            {'Aceptar'}
          </LoadingButton>
        </DialogActions>
      )}
    </>
  );
}
