import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './SocketWrapper.View';
import {
  SocketWrapperInputProps,
  SocketWrapperOwnProps,
  SocketWrapperEventProps,
} from './SocketWrapper.Types';
import { RootState } from 'src/redux/store';
import { isValidToken } from 'src/utils/jwt';
import SnackbarUtils from 'src/utils/SnackbarUtils';
import { refreshTokenRequestAction } from 'src/services/auth';
import { User } from 'src/redux/slices/AuthSlice/authSlice.d';
import { getInit, initSolo } from 'src/redux/slices/AuthSlice/authSlice';

const mapStateToProps = (
  state: RootState,
  _ownProps: SocketWrapperOwnProps
): SocketWrapperInputProps => ({
  token: state.auth.token ?? '',
  refreshToken: state.auth.refreshToken ?? '',
  user: state.auth.user,
});

const mapDispatchToProps: MapDispatchToPropsFunction<
  SocketWrapperEventProps,
  SocketWrapperOwnProps
> = (dispatch: ThunkDispatch<any, any, AnyAction>, _ownProps: SocketWrapperOwnProps) => ({
  refreshTokenSocket: (token: string, refreshToken: string, user: User) => {
    if (!isValidToken(token)) {
      dispatch(
        refreshTokenRequestAction(
          refreshToken ?? '',
          (response) => {
            const newToken = response.token;
            getInit({ user, token: newToken, isAuthenticated: false });
          },
          () => {
            refreshToken !== null &&
              refreshToken !== '' &&
              SnackbarUtils.warning('La sesión ha expirado. Por favor vuelva a iniciar sesión.');
            dispatch(initSolo());
          }
        )
      );
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
