import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

type FormValuesProps = {
  password: string;
  passwordConfirmation: string;
};

type OnboardingCompanyFormProps = {
  onSubmitForm: (password: string) => void;
};

export default function OnboardingChangePassForm(props: OnboardingCompanyFormProps) {
  const [showPassword, setShowPassword] = useState(false);

  const { translate } = useLocales();

  const Schema = Yup.object().shape({
    password: Yup.string()
      .required(translate('auth.password_required'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
        translate('auth.password_condition')
      ),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      translate('auth.password_must_match')
    ),
  });

  const defaultValues = {
    password: '',
    passwordConfirmation: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      props.onSubmitForm(data.password);
      reset();
    } catch (error) {
      console.error(error);
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={'Nueva contraseña'}
          name="password"
          type={showPassword ? 'text' : 'password'}
        />
        <RHFTextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={translate('auth.password_confirm')}
          name="passwordConfirmation"
          type={showPassword ? 'text' : 'password'}
        />
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          {'Siguiente'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
