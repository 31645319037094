export const LOGIN_AUTH = 'LOGIN_AUTH';
export const LOGIN_AUTH_STARTED = 'LOGIN_AUTH_STARTED';
export const LOGIN_AUTH_FAILURE = 'LOGIN_AUTH_FAILURE';

export const REGISTER_AUTH = 'REGISTER_AUTH';
export const REGISTER_AUTH_STARTED = 'REGISTER_AUTH_STARTED';
export const REGISTER_AUTH_SUCCESS = 'REGISTER_AUTH_SUCCESS';
export const REGISTER_AUTH_FAILURE = 'REGISTER_AUTH_FAILURE';

export const REFRESH_TOKEN_AUTH = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_STARTED = 'REFRESH_TOKEN_STARTED';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';

export const RENSED_CONFIRM_EMAIL = 'RENSED_CONFIRM_EMAIL';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';

export const RESET_PASSWORD = 'RESET_PASSWORD';

export const LOGIN_MFA_AUTH = 'LOGIN_MFA_AUTH';
export const LOGIN_MFA_AUTH_STARTED = 'LOGIN_MFA_AUTH_STARTED';
export const LOGIN_MFA_AUTH_FAILURE = 'LOGIN_MFA_AUTH_FAILURE';

export const GET_COMPANY_THEME_SETTINGS = 'GET_COMPANY_THEME_SETTINGS';
export const GET_COMPANY_THEME_SETTINGS_STARTED = 'GET_COMPANY_THEME_SETTINGS_STARTED';
export const GET_COMPANY_THEME_SETTINGS_FAILURE = 'GET_COMPANY_THEME_SETTINGS_FAILURE';

export const ADD_EMAIL_WAITING_LIST = 'ADD_EMAIL_WAITING_LIST';
