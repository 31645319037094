import { withSnackbar } from 'notistack';
import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { logout, updateCompanyOnBoading } from 'src/redux/slices/AuthSlice/authSlice';
import { InfoCompanyForm } from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { persistor, RootState } from 'src/redux/store';
import { OfficeFormProps } from 'src/sections/auth/onboarding-company/OnboardingOfficeForm';
import { updateCompanyAction, updateOfficeAction } from 'src/services/companies';
import { getNetworkClient } from 'src/services/config';
import { reNewPasswordRequest, updateUserSettingsRequestAction } from 'src/services/user';
import { isClient, isEmployee } from 'src/utils/privileges';
import { OnBoardingEventProps, OnBoardingInputProps, OnBoardingOwnProps } from './OnBoarding.Types';
import View from './OnBoarding.View';

const mapStateToProps = (
  state: RootState,
  _ownProps: OnBoardingOwnProps
): OnBoardingInputProps => ({
  countries: state.data.countries,
  company: state.business.company,
  hasPasswordChanged: state.auth.user.onboardingFinished ?? false,
  office: state.business.office,
  isFinalUser:
    state.auth?.user &&
    state.auth?.user?.privileges?.findIndex((i) => i === 'BUSINESS_FEATURES') === -1,
  isEmployee: isEmployee(state.auth?.user?.privileges ?? []),
  isClient: isClient(state.auth?.user?.privileges ?? []),
  needsTour: state.auth.user.needsTour,
});

const mapDispatchToProps: MapDispatchToPropsFunction<OnBoardingEventProps, OnBoardingOwnProps> = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  ownProps: OnBoardingOwnProps
) => ({
  logout: () => {
    persistor.purge().then(() => {
      dispatch(logout());
    });
  },
  changePassword: (newPassword: string, onSuccess: () => void, onFailure: (error: any) => void) => {
    getNetworkClient().executeRequest(
      reNewPasswordRequest(newPassword, onSuccess, ({ response }) => onFailure(response))
    );
  },
  updateCompanyInfo: (
    id: string,
    form: InfoCompanyForm,
    onSuccess: () => void,
    onFailure: (error: any) => void
  ) => {
    dispatch(updateCompanyAction(id, form, onSuccess, onFailure));
  },
  updateOfficeInfo: (
    companyId: string,
    officeId: string,
    form: OfficeFormProps,
    onSuccess: () => void,
    onFailure: (error: any) => void
  ) => {
    dispatch(
      updateOfficeAction(
        companyId,
        officeId,
        form,
        () => {
          dispatch(updateCompanyOnBoading({ isCompleted: true }));
          onSuccess();
        },
        onFailure
      )
    );
  },
  finishOnboarding: () => dispatch(updateCompanyOnBoading({ isCompleted: true })),
  updateBaseCurrency: (baseCurrency: string, onSuccess: () => void, onFailure: () => void) => {
    dispatch(updateUserSettingsRequestAction(baseCurrency, onSuccess, onFailure));
  },
});

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(View));
