import { ProviderContext as SnackContext } from 'notistack';
import { LoadingHOCComponentProps } from 'src/hooks/useLoading/LocalReducer.Types';
import { RoutedProps } from 'src/hooks/useRouter';

import { Portfolio, PortfolioMap } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { SecurityType } from 'src/sections/@dashboard/general/wallets/transactions/SecuritiesSearch';

export interface IBuyForm {
  wallet: string;
  localCurrency: string;
  security: string;
  quantity: number;
  price: number;
  exchangeRate: number;
  currencyPair: string;
  feeCurrency: string;
  fee: number;
  notes: string;
  date: Date | null;
  type: string;
  earliestAvailableData?: Date;
}

export interface WalletsAddMovementOwnProps
  extends SnackContext,
    RoutedProps,
    LoadingHOCComponentProps {}

export type WalletsAddMovementInputProps = {
  wallets: PortfolioMap;
  walletIdParam: string;
  loading: boolean;
  clientId: string;
};

export type WalletsAddMovementEventProps = {
  saveMovement: (
    movement: IBuyForm,
    wallet: Portfolio,
    security: SecurityType,
    clientId: string,
    onFinish: () => void,
    translate: any
  ) => void;
  getSecurityInformation: (securityId: string, onSuccess: (data: any) => void) => void;
  getSecurityEOD: (securityId: string, date: string, onSuccess: (data: any) => void) => void;
};

export type WalletsAddMovementProps = WalletsAddMovementInputProps &
  WalletsAddMovementEventProps &
  WalletsAddMovementOwnProps;

export const defaultSecurity: SecurityType = {
  name: '',
  tickerSymbol: '',
  id: '',
  currencyCode: '',
  exchange: '',
  hasSplits: false,
  tracked: false,
  type: '',
};
