import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SharedPortfolioMap, SharedPortfolio, SharedUser } from './sharePortfolioSlice.d';
import { PerformanceType, TransactionCountPayload } from '../PortfolioSlice/portfolioSlice.d';

type SharePortfoliosState = {
  portfolios: SharedPortfolioMap;
  user: SharedUser;
};

export const initialState: SharePortfoliosState = {
  portfolios: {},
  user: {
    baseCurrency: 'EUR',
    imageUrl: '',
    name: '',
  },
};

const sharedPortfoliosSlice = createSlice({
  name: 'sharePortfolios',
  initialState,
  reducers: {
    addSharePortfolio: (state, action: PayloadAction<SharedPortfolio>) => {
      state.portfolios = { ...state.portfolios, [action.payload.id]: action.payload };
    },
    getSharePortfolios: (state, action: PayloadAction<SharedPortfolioMap>) => {
      state.portfolios = { ...action.payload };
    },
    getMovementsPortfolio: (state, action: PayloadAction<SharedPortfolio>) => {
      state.portfolios = { ...state.portfolios, [action.payload.id]: action.payload };
    },
    addSharePassword: (state, action: PayloadAction<SharedPortfolio>) => {
      state.portfolios = { ...state.portfolios, [action.payload.id]: action.payload };
    },
    getShareMovementsCount: (state, action: PayloadAction<TransactionCountPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          transactionsCount: action.payload.transactionsCount,
        },
      };
    },
    updatePerformanceType: (
      state,
      action: PayloadAction<{ id: string; performanceType: PerformanceType }>
    ) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          performanceType: action.payload.performanceType,
        },
      };
    },
  },
});

const persistPortfoliosConfig = {
  key: 'sharePortfolios',
  storage,
  whitelist: [],
};

const persistPortfoliosReducer = persistReducer<SharePortfoliosState>(
  persistPortfoliosConfig,
  sharedPortfoliosSlice.reducer
);

export const {
  addSharePortfolio,
  getSharePortfolios,
  getMovementsPortfolio,
  getShareMovementsCount,
  addSharePassword,
  updatePerformanceType,
} = sharedPortfoliosSlice.actions;

export default persistPortfoliosReducer;
