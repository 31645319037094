import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  path?: string;
}

export default function Logo({
  disabledLink = false,
  sx,
  path = PATH_DASHBOARD.client.all,
}: Props) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg id="vumi.svg" fill="none" viewBox="0 0 55 53" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.4557 11.9839C15.3948 10.0448 18.5396 10.0448 20.4788 11.9839C22.4179 13.923 22.4179 17.0678 20.4788 19.007C18.5396 20.9461 15.3948 20.9461 13.4557 19.007C11.5166 17.0678 11.5166 13.923 13.4557 11.9839Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M23.9954 1.45435C25.9345 -0.484782 29.0793 -0.484782 31.0185 1.45435L41.548 11.9839C43.4872 13.923 43.4872 17.0679 41.548 19.007L31.0084 29.5366C29.0693 31.4757 25.9245 31.4757 23.9854 29.5366C22.0462 27.5974 22.0462 24.4526 23.9854 22.5135L27.4919 19.007C29.431 17.0679 29.431 13.923 27.4919 11.9839L23.9954 8.46737C22.0563 6.52824 22.0563 3.39348 23.9954 1.45435Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M45.0546 22.5136L31.0085 36.5496C29.0694 38.4888 25.9246 38.4888 23.9854 36.5496L9.94934 22.5136C8.01021 20.5744 4.86541 20.5744 2.92628 22.5136L0.414451 25.0254C-0.13815 25.578 -0.13815 26.4722 0.414451 27.0248L23.9955 50.5958C25.9346 52.5349 29.0794 52.5349 31.0185 50.5958L54.5795 27.0248C55.1321 26.4722 55.1321 25.578 54.5795 25.0254L52.0676 22.5136C50.1285 20.5744 46.9937 20.5744 45.0546 22.5136Z"
          fill={PRIMARY_MAIN}
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={path}>{logo}</RouterLink>;
}
