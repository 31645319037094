export const canConnectEntities = (privileges: string[] = []) =>
  privileges.findIndex((i) => i === 'CONNECT_ENTITIES' || i === 'ADMIN_POWER') !== -1;

export const isClient = (privileges: string[] = []) =>
  privileges.findIndex(
    (i) => i === 'MANAGE_BUSINESS' || i === 'MANAGE_CLIENTS' || i === 'MANAGE_OFFICE'
  ) === -1 && privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1;

export const isEmployee = (privileges: string[] = []) =>
  privileges.findIndex((i) => i === 'MANAGE_CLIENTS') !== -1 &&
  privileges.findIndex((i) => i === 'MANAGE_BUSINESS') === -1 &&
  privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1;

export const canCreateEmployees = (privileges: string[] = []) =>
  privileges.findIndex((i) => i === 'MANAGE_OFFICE') !== -1 ||
  privileges.findIndex((i) => i === 'MANAGE_BUSINESS') !== -1;

export const isBusinessAdmin = (privileges: string[] = []) =>
  privileges.findIndex((i) => i === 'MANAGE_BUSINESS') !== -1 &&
  privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1;

export const isOfficer = (privileges: string[] = []) =>
  privileges.findIndex((i) => i === 'MANAGE_OFFICE') !== -1 &&
  privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1;

export const isBusiness = (privileges: string[] = []) =>
  privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1;

export const isAdmin = (privileges: string[] = []) =>
  privileges.findIndex((i) => i === 'ADMIN_POWER') !== -1;

export const getUserRole = (privileges: string[] = []) => {
  if (isBusinessAdmin(privileges)) return 'ROLE_BUSINESS_ADMIN';
  if (isOfficer(privileges)) return 'ROLE_BUSINESS_OFFICE_ADMIN';
  if (isEmployee(privileges)) return 'ROLE_BUSINESS_EMPLOYEE';
  return 'USER';
};
