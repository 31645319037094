import { useContext } from 'react';
import { Stack, Toolbar } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import { IconButtonAnimate } from 'src/components/animate';
// sub-components
import ClientSearchBar from '../ClientSearchBar/ClientSearchBar';
import HideAccountButton from '../HideAccountButton';
import TopBarButtonsMenu from '../TopBarButtonsMenu';
import ReloadPricesButton from '../ReloadPricesButton';
import ConnectionsPopover from '../ConnectionsPopover/ConnectionsPopover';
import NotificationsDrawer from '../NotificationsDrawer/NotificationsDrawer';
// hooks
import useGetInfoUser from '../../services/useGetUserInfo';
import useGetPortfoliosInfo from '../../services/useGetPortfoliosInfo';
// context
import Context, { ContextType } from '../../context';

// ----------------------------------------------------------------------

export default function MobileToolbar() {
  const { isBusiness, isClient, access } = useGetInfoUser();
  const { connections } = useGetPortfoliosInfo();

  const { actions }: ContextType = useContext<ContextType>(Context);
  const { onOpenSidebar } = actions;

  const isBusinessNotClient = isBusiness && !isClient;
  const unReadyConnections = connections.filter((i) => i.pending && !i.errored);
  const isFullAccess = access === 'FULL_ACCESS';

  const topbarContent =
    !isBusinessNotClient && isFullAccess ? (
      <>
        <ReloadPricesButton />
        <HideAccountButton />
      </>
    ) : (
      <HideAccountButton />
    );

  return (
    <Toolbar
      sx={{
        minHeight: 120,
        pr: { lg: 5, xs: 2 },
        pl: { lg: 4, xs: 1 },
      }}
    >
      <Stack width={'100%'}>
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          mb={isBusinessNotClient ? 0.5 : 6.5}
          mt={isBusinessNotClient ? 3 : 0}
        >
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>

          <Stack
            alignItems="center"
            className="vertical-header"
            direction="row"
            gap={1}
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <TopBarButtonsMenu>{topbarContent}</TopBarButtonsMenu>

            {!isBusinessNotClient && unReadyConnections.length > 0 && <ConnectionsPopover />}
            <NotificationsDrawer />
          </Stack>
        </Stack>
        {isBusinessNotClient && <ClientSearchBar sx={{ ml: 1, mb: 6.5 }} />}
      </Stack>
    </Toolbar>
  );
}
