import { Avatar, AvatarProps, Stack, useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import Image from 'mui-image';
import { memo } from 'react';

import { vumiIconLightURL, vumiIconDarkURL } from 'src/utils/formatUrl';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface Props extends AvatarProps {
  url: string;
  backgroundColor: string;
  size: number;
  duration?: number;
  fit: 'contain' | 'cover';
  style?: React.CSSProperties;
}

const SecurityAvatar = ({
  url,
  backgroundColor,
  style,
  size,
  fit,
  duration = 600,
  onClick,
}: Props) => {
  const theme = useTheme();

  const errorIcon = (
    <Avatar
      src={theme.palette.mode === 'dark' ? vumiIconDarkURL : vumiIconLightURL}
      sx={{ height: size, width: size, borderRadius: 0.8 }}
    />
  );

  return (
    <Stack onClick={onClick}>
      <Image
        bgColor={undefined}
        duration={duration}
        errorIcon={errorIcon}
        fit={fit}
        height={size}
        src={url}
        style={{ borderRadius: 6, backgroundColor, ...style }}
        width={size}
      />
    </Stack>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any) => isEqual(prevProps, nextProps);

export default memo(SecurityAvatar, arePropsEqual);
