import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment, { Moment } from 'moment';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function getDiffDate(
  date: string,
  reducedWaitTime: boolean = false,
  waitHours: number = 12
) {
  const mDate = moment(date).add(
    reducedWaitTime ? 5 : waitHours,
    reducedWaitTime ? 'minutes' : 'hours'
  );
  const isAfterNow = mDate.isAfter(moment());
  const hours = mDate.diff(moment(), 'hours');
  const minutes = (reducedWaitTime ? 5 : waitHours * 60) - mDate.diff(moment(), 'minutes');
  return { isAfterNow, hours, minutes };
}

export const shortDateLabel = (startDate: Moment | null) => {
  const currentYear = new Date().getFullYear();
  const startDateYear = startDate ? startDate.get('year') : null;
  const isCurrentYear = currentYear === startDateYear;

  const today = moment();

  const isSameDays = startDate ? startDate.isSame(today, 'day') : false;

  return isCurrentYear
    ? isSameDays
      ? `Hoy a las ${startDate?.format('HH:mm')}h`
      : `${startDate?.format('DD MMM HH:mm')}h`
    : `${startDate?.format('DD MMM YY HH:mm')}h`;
};
