import { RefObject } from 'react';
import { PixelCrop } from 'react-image-crop';

// ----------------------------------------------------------------------

type Props = {
  previewCanvasRef: RefObject<HTMLCanvasElement>;
  imageCanvas?: HTMLImageElement;
  completedCrop?: PixelCrop;
  onSave: (file: any) => void;
};

export default function useGetSaveCropFile({
  previewCanvasRef,
  imageCanvas,
  completedCrop,
  onSave,
}: Props) {
  async function onSaveCropOnFile() {
    const previewCanvas = previewCanvasRef.current;

    if (!imageCanvas || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = imageCanvas.naturalWidth / imageCanvas.width;
    const scaleY = imageCanvas.naturalHeight / imageCanvas.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    onSave(
      Object.assign(blob, {
        preview: URL.createObjectURL(blob),
      })
    );
  }

  return {
    onSaveCropOnFile,
  };
}
