import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import SvgIconStyle from '../../SvgIconStyle';

export default function IndicesTitle() {
  return (
    <Stack alignItems="center" flexDirection="row">
      <Typography color="text.secondary" variant="subtitle2">
        Indices de comparación seleccionados para esta cartera
      </Typography>
      <Tooltip
        sx={{ mt: 0.2, ml: 1 }}
        title='Puedes tener hasta un máximo de 3. Pulsa otra vez en ellos para deseleccionarlos.'
      >
        <IconButton>
          <SvgIconStyle
            src={`/icons/ic_info_outline.svg`}
            sx={{ width: 16, height: 16, color: 'text.primary' }}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
