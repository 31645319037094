import { lazy } from 'react';

import Loadable from 'src/components/Loadable';
import GuestGuard from 'src/guards/GuestGuard/GuestGuard';
import CompanyOnBoarding from 'src/pages/dashboard/OnBoarding/OnBoarding';

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../../pages/auth/Login/Login')));
const Register = Loadable(lazy(() => import('../../pages/auth/Register/Register')));
const ResetPassword = Loadable(lazy(() => import('../../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../../pages/auth/VerifyMFACode')));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'auth',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    { path: 'login-unprotected', element: <Login /> },
    { path: 'register-unprotected', element: <Register /> },
    { path: 'reset-password', element: <ResetPassword /> },
    { path: 'verifyMFA', element: <VerifyCode /> },
    { path: 'company-onboarding', element: <CompanyOnBoarding /> },
  ],
};
