import { Box, Stack, Typography } from '@mui/material';

// components
import MyAvatar from 'src/components/MyAvatar';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  name: string;
  email: string;
  clientId: string;
  imageUrl?: string;
  loading?: boolean;
  onClick: () => void;
};

export default function OptionList({ name, email, imageUrl, onClick, loading, ...props }: Props) {
  return (
    <Box {...props} component="li" onClick={onClick}>
      <Stack alignItems="center" flexDirection={'row'}>
        {loading ? (
          <Iconify icon="line-md:loading-twotone-loop" sx={{ mr: 1.5 }} width={35} />
        ) : (
          <MyAvatar sx={{ mr: 1.5 }} user={{ name, imageUrl }} />
        )}
        <Stack>
          <Typography variant="subtitle2">{name}</Typography>
          <Typography color="text.secondary" fontSize={12} variant="subtitle2">
            {email}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
