export const GET_PORTFOLIO_CLIENT = 'GET_PORTFOLIO_CLIENT';
export const GET_PORTFOLIO_STARTED_CLIENT = 'GET_PORTFOLIO_STARTED_CLIENT';
export const GET_PORTFOLIO_FAILURE_CLIENT = 'GET_PORTFOLIO_FAILURE_CLIENT';

export const GET_PORTFOLIOS_CLIENT = 'GET_PORTFOLIOS_CLIENT';
export const GET_PORTFOLIOS_STARTED_CLIENT = 'GET_PORTFOLIOS_STARTED_CLIENT';
export const GET_PORTFOLIOS_FAILURE_CLIENT = 'GET_PORTFOLIOS_FAILURE_CLIENT';

export const GET_POSITIONS_CLIENT = 'GET_POSITIONS_CLIENT';
export const GET_POSITIONS_CLIENT_STARTED = 'GET_POSITIONS_CLIENT_STARTED';
export const GET_POSITIONS_CLIENT_FAILURE = 'GET_POSITIONS_CLIENT_FAILURE';

export const GET_DIVIDENDS_CLIENT = 'GET_DIVIDENDS_CLIENT';
export const GET_DIVIDENDS_CLIENT_STARTED = 'GET_DIVIDENDS_CLIENT_STARTED';
export const GET_DIVIDENDS_CLIENT_FAILURE = 'GET_DIVIDENDS_CLIENT_FAILURE';

export const GET_OPERATIONS_CLIENT = 'GET_OPERATIONS_CLIENT';
export const GET_OPERATIONS_CLIENT_STARTED = 'GET_OPERATIONS_CLIENT_STARTED';
export const GET_OPERATIONS_CLIENT_FAILURE = 'GET_OPERATIONS_CLIENT_FAILURE';

export const GET_MOVEMENTS_CLIENT = 'GET_MOVEMENTS_CLIENT';
export const GET_MOVEMENTS_CLIENT_STARTED = 'GET_MOVEMENTS_CLIENT_STARTED';
export const GET_MOVEMENTS_CLIENT_FAILURE = 'GET_MOVEMENTS_CLIENT_FAILURE';

export const GET_CURRENT_ACCOUNTS_CLIENT = 'GET_CURRENT_ACCOUNTS_CLIENT';

export const GET_INFO_CLIENT = 'GET_CURRENT_ACCOUNTS_CLIENT';

export const UPDATE_CLIENT_PORTFOLIO_SETTINGS = 'UPDATE__CLIENT_PORTFOLIO_SETTINGS';

export const GET_SKIPPED_CLIENT_MOVEMENTS = 'GET_SKIPPED_CLIENT_MOVEMENTS';

export const GET_PORTFOLIO_CLIENT_IMPORT_ERRORS = 'GET_PORTFOLIO_CLIENT_IMPORT_ERRORS';

export const GET_CLIENT_NET_WORTH = 'GET_CLIENT_NET_WORTH';
export const GET_CLIENT_NET_WORTH_STARTED = 'GET_CLIENT_NET_WORTH_STARTED';
export const GET_CLIENT_NET_WORTH_FAILED = 'GET_CLIENT_NET_WORTH_FAILED';

export const GET_CLIENT_ALL_INFO = 'GET_CLIENT_ALL_INFO';
export const GET_CLIENT_ALL_INFO_STARTED = 'GET_CLIENT_ALL_INFO_STARTED';
export const GET_CLIENT_ALL_INFO_FAILED = 'GET_CLIENT_ALL_INFO_FAILED';

export const GET_CLIENT_NET_WORTH_PERIOD = 'GET_CLIENT_NET_WORTH_PERIOD';
export const GET_CLIENT_NET_WORTH_PERIOD_STARTED = 'GET_CLIENT_NET_WORTH_PERIOD_STARTED';
export const GET_CLIENT_NET_WORTH_PERIOD_FAILED = 'GET_CLIENT_NET_WORTH_PERIOD_FAILED';

export const UPDATE_CLIENT_PORTFOLIO = 'UPDATE_CLIENT_PORTFOLIO';
export const UPDATE_CLIENT_PORTFOLIO_STARTED = 'UPDATE_CLIENT_PORTFOLIO_STARTED';
export const UPDATE_CLIENT_PORTFOLIO_FAILED = 'UPDATE_CLIENT_PORTFOLIO_FAILED';

export const GET_SECURITY_INFO = 'GET_SECURITY_INFO';

export const GET_SECURITY_MOVEMENTS = 'GET_SECURITY_MOVEMENTS';

export const GET_CLIENT_NOTIFICATIONS = 'GET_CLIENT_NOTIFICATIONS';

export const GET_CLIENT_CONNECTIONS = 'GET_CLIENT_CONNECTIONS';
export const GET_CLIENT_CONNECTIONS_STARTED = 'GET_CLIENT_CONNECTIONS_STARTED';
export const GET_CLIENT_CONNECTIONS_FAILURE = 'GET_CLIENT_CONNECTIONS_FAILURE';

export const GET_CLIENT_CREDENTIALS_NETWORTH = 'GET_CLIENT_CREDENTIALS_NETWORTH';
export const GET_CLIENT_CREDENTIALS_NETWORTH_STARTED = 'GET_CLIENT_CREDENTIALS_NETWORTH_STARTED';
export const GET_CLIENT_CREDENTIALS_NETWORTH_FAILURE = 'GET_CLIENT_CREDENTIALS_NETWORTH_FAILURE';

export const GET_CLIENT_CONNECTION_DETAIL = 'GET_CLIENT_CONNECTION_DETAIL';
export const GET_CLIENT_CONNECTION_DETAIL_STARTED = 'GET_CLIENT_CONNECTION_DETAIL_STARTED';
export const GET_CLIENT_CONNECTION_DETAIL_FAILURE = 'GET_CLIENT_CONNECTION_DETAIL_FAILURE';

export const MARK_READ_CLIENT_NOTIFICATIONS = 'MARK_READ_CLIENT_NOTIFICATIONS';

export const GET_CLIENT_ALL_DIVIDENDS = 'GET_CLIENT_ALL_DIVIDENDS';
