import { DebugForcedResponseType, RequestInterface } from 'src/services/http/base/Request.d';

export default class Request<
  StateType,
  ResponseType,
  ErrorType,
  DomainResponseType,
  DomainErrorType
> implements
    RequestInterface<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
{
  public reqId: string;

  public setEndpointFromState?: (state: StateType) => string;

  public method: string;

  public setBodyFromState?: (state: StateType) => any;

  public getHeadersFromState: (state: StateType) => { [key: string]: string };

  public onStart: () => void;

  public onSuccess: (response: DomainResponseType) => void;

  public onFailure: (error: DomainErrorType) => void;

  public onFinish: () => void;

  public transformResponseDataWithState?: (
    response: ResponseType,
    state: StateType
  ) => DomainResponseType;

  public transformErrorDataWithState?: (error: ErrorType, state: StateType) => DomainErrorType;

  public debugForcedResponse: DebugForcedResponseType<Partial<ResponseType>, Partial<ErrorType>>;

  constructor() {
    this.reqId = '';
    this.method = 'GET';
    this.getHeadersFromState = () => ({});
    this.onStart = () => undefined;
    this.onSuccess = () => undefined;
    this.onFailure = () => undefined;
    this.onFinish = () => undefined;
    this.debugForcedResponse = { debugForced: 'disabled' };
  }
}
