import { Stack, SxProps, Theme, debounce } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
// components
import RejectionFiles from '../RejectionFiles';
import canvasPreview from './CanvasPreview';
import DropZoneSelector from './DropZoneSelector';
import HelperText from './HelperText';
import HorizontalSlider from './HorizontalSlider';
import PreviewCanvasSelected from './PreviewCanvasSelected';
import ReactCropZone from './ReactCropZone';
import SelectorFooter from './SelectorFooter';
// hooks
import useGetDropzone from './hooks/useGetDropzone';
import useGetManageImageCrops from './hooks/useGetManageImageCrops';
import useGetSaveCropFile from './hooks/useGetSaveCropFile';

// ----------------------------------------------------------------------

export interface Props extends DropzoneOptions {
  error?: boolean;
  file: any | null;
  onSave: (filePreview: any) => void;
  hideRejectFiles?: boolean;
  isIcon?: boolean;
  sx?: SxProps<Theme>;
}

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: 250,
  margin: 'auto',
  borderRadius: 10,
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

// ----------------------------------------------------------------------

export default function UploadCompanyLogo({
  error,
  file,
  sx,
  disabled,
  accept,
  maxSize,
  onSave,
  hideRejectFiles,
  isIcon,
}: Props) {
  const { dropZoneState, filePreview, onChangeFile } = useGetDropzone({
    accept,
    maxSize,
    disabled,
  });
  const { isDragReject, fileRejections } = dropZoneState;

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [isSelected, setIsSelected] = useState<boolean>();
  const [scale, setScale] = useState<number>(1);

  const { crop, completedCrop, imageCanvas, onImageLoad, onChangeCompleteCrop, onChangeCrop } =
    useGetManageImageCrops({ isIcon });
  const { onSaveCropOnFile } = useGetSaveCropFile({
    imageCanvas,
    completedCrop,
    previewCanvasRef,
    onSave,
  });

  const debouncedCrop = useMemo(
    () =>
      debounce(() => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imageCanvas &&
          previewCanvasRef.current
        )
          canvasPreview(imageCanvas, previewCanvasRef.current, completedCrop, scale);
      }, 100),
    [isSelected, completedCrop, imgRef, previewCanvasRef, isSelected, scale]
  );

  useEffect(() => {
    debouncedCrop();
  }, [completedCrop, scale]);

  const handleOnCancel = () => {
    if (isSelected) {
      setIsSelected(false);
    } else {
      onChangeFile(undefined);
      onChangeCrop(undefined);
      setIsSelected(false);
    }
  };

  const handleOnSelect = () => {
    if (isSelected) {
      onSaveCropOnFile();
      onChangeFile(undefined);
      onChangeCrop(undefined);
      setIsSelected(false);
      onChangeFile(undefined);
    } else {
      setIsSelected(true);
    }
  };

  const handleOnChangeScale = (value: number) => {
    setScale(value);
  };

  return (
    <>
      <Stack alignItems="center" flexDirection={'row'}>
        <RootStyle
          sx={{
            ...((isDragReject || error) && {
              borderColor: 'error.light',
            }),
            ...sx,
          }}
        >
          {filePreview && !isSelected && (
            <ReactCropZone
              crop={crop}
              filePreview={filePreview}
              imgRef={imgRef}
              isIcon={isIcon}
              onChangeCompleteCrop={onChangeCompleteCrop}
              onChangeCrop={onChangeCrop}
              onImageLoad={onImageLoad}
              scale={scale}
            />
          )}

          {!filePreview && !isSelected && (
            <DropZoneSelector
              disabled={disabled}
              dropZoneState={dropZoneState}
              error={error}
              file={file}
              hasFile={filePreview}
            />
          )}
          {completedCrop && (
            <PreviewCanvasSelected
              completedCrop={completedCrop}
              isSelected={isSelected}
              previewCanvasRef={previewCanvasRef}
            />
          )}
        </RootStyle>
      </Stack>
      {filePreview && !isSelected && <HorizontalSlider onChange={handleOnChangeScale} />}
      {filePreview && (
        <SelectorFooter
          isSelected={isSelected}
          onCancel={handleOnCancel}
          onSelect={handleOnSelect}
        />
      )}
      {!hideRejectFiles && !disabled && !filePreview && <HelperText />}
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
}
