import { Typography } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fData } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------

export default function HelperText() {
  const { translate } = useLocales();

  return (
    <Typography
      sx={{
        mt: 2,
        mx: 'auto',
        display: 'block',
        textAlign: 'center',
        color: 'text.secondary',
      }}
      variant="caption"
    >
      {translate('account.image_types')} {translate('account.max_size')} {fData(1000000)}
    </Typography>
  );
}
