import { alpha } from '@mui/material/styles';
import { ThemeColorsApp } from 'src/redux/slices/SettingsSlice/settingsSlice.d';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

export interface ColorPalette {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
  contrastText?: string;
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: string[];
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: string[];
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};
const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
};
const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: '#fff',
};
const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const customPalette = ({ primary, text, background, chartColors }: ThemeColorsApp) => {
  const primaryColors = {
    ...primary,
  };

  const GRADIENTS = {
    primary: createGradient(primaryColors.light, primaryColors.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
  };
  const COMMON = {
    common: { black: '#000000', white: '#ffffff' },
    primary: { ...primaryColors, contrastText: '#ffffff' },
    secondary: { ...SECONDARY, contrastText: '#ffffff' },
    info: { ...INFO, contrastText: '#ffffff' },
    success: { ...SUCCESS, contrastText: GREY[800] },
    warning: { ...WARNING, contrastText: GREY[800] },
    error: { ...ERROR, contrastText: '#ffffff' },
    grey: GREY,
    gradients: GRADIENTS,
    chart: [...chartColors],
    divider: GREY[500_24],
    action: {
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
    },
  };
  return {
    light: {
      ...COMMON,
      mode: 'light',
      text: {
        primary: text.primary,
        secondary: text.secondary,
        disabled: text.disabled,
      },
      background: {
        paper: background.paper,
        default: background.default,
        neutral: background.neutral,
      },
      action: { active: GREY[600], ...COMMON.action },
    },
    dark: {
      ...COMMON,
      mode: 'dark',
      text: { primary: '#ffffff', secondary: GREY[500], disabled: GREY[600] },
      background: { paper: GREY[800], default: GREY[900], neutral: GREY[50016] },
      action: { active: GREY[500], ...COMMON.action },
    },
  } as const;
};

export default customPalette;
