// @mui
import { Box, IconButton, SxProps, useTheme } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
  color?: string;
  sx?: SxProps;
};

export default function NavbarDrawerButton({ onToggleCollapse, collapseClick, color, sx }: Props) {
  const theme = useTheme();
  return (
    <IconButton
      onClick={onToggleCollapse}
      sx={{
        padding: 0.4,
        position: 'absolute',
        cursor: 'pointer',
        right: collapseClick ? -14 : -14,
        top: collapseClick ? 6 : 29,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 1,
      }}
    >
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        <Iconify icon="iconamoon:arrow-left-2-duotone" />
      </Box>
    </IconButton>
  );
}

// ----------------------------------------------------------------------
