import { Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import LoadingIcons from 'react-loading-icons';
// hooks
import useGetPortfoliosInfo from '../../services/useGetPortfoliosInfo';
// components
import ConnectionsButton from './ConnectionsButton';
import ConnectionsMenu from './ConnectionsMenu/ConnectionsMenu';

// ----------------------------------------------------------------------

export default function ConnectionsPopover() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { connections } = useGetPortfoliosInfo();

  const theme = useTheme();

  const unReadyConnections = connections.filter((i) => i.pending && !i.errored);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Stack className="connection-button" sx={{ alignItems: 'center', justifyContent: 'center' }}>
        {unReadyConnections.length > 0 && (
          <LoadingIcons.Puff
            height={'3.2em'}
            stroke={theme.palette.primary.main}
            style={{ position: 'absolute', zIndex: -100 }}
            width={'200'}
          />
        )}
        <ConnectionsButton handleOpen={handleOpen} />
      </Stack>
      <ConnectionsMenu anchorEl={anchorEl} onClose={handleClose} open={open} />
    </>
  );
}
