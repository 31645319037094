import { ElementType, Suspense, useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LoadingScreen from './LoadingScreen';

export const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { store } = useContext(ReactReduxContext);
  const isDashboard = pathname.includes('/dashboard') && store.getState().auth.isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
