import { useEffect, useState } from 'react';

import createAvatar from '../utils/createAvatar';
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ user, ...other }: AvatarProps) {
  const [color, setColor] = useState(!!user?.imageUrl ? 'default' : createAvatar(user?.name).color);

  useEffect(() => setColor(!!user?.imageUrl ? 'default' : createAvatar(user?.name).color), [user]);

  return (
    <Avatar
      alt={user?.name}
      color={color}
      onError={() => setColor(createAvatar(user?.name).color)}
      src={user?.imageUrl}
      sx={{
        width: { xs: 32, sm: 40 },
        height: { xs: 32, sm: 40 },
      }}
      user={user}
      {...other}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  );
}
