import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import { ButtonProps, Typography } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
// hooks
import useLocales from 'src/hooks/useLocales';
import useGetInfoUser from 'src/hooks/useGetInfoUser';
// constants
import { PATH_DASHBOARD } from 'src/routes/paths';

export default function AddAssetsBtn(props: ButtonProps) {
  const { translate } = useLocales();
  const { access } = useGetInfoUser();
  const navigate = useNavigate();

  const isFullAccess = access === 'FULL_ACCESS';

  return (
    <Button
      {...props}
      color="primary"
      onClick={() =>
        navigate(
          isFullAccess
            ? PATH_DASHBOARD.addAssets.root
            : PATH_DASHBOARD.general.wallets.newConnection
        )
      }
      size="small"
      variant="contained"
    >
      <Iconify height={16} icon="akar-icons:plus" mr={1} width={16} />
      <Typography variant="subtitle2">
        {translate(isFullAccess ? 'Añadir activos' : 'Añadir conexión')}
      </Typography>
    </Button>
  );
}
