import _ from 'lodash';
import { Portfolio } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { replaceAll } from 'src/utils/formatText';

export type ConnectionPortfolioMap = { [connectionId: string]: Portfolio[] };

export const getWalletsChildren = (connections: ConnectionPortfolioMap, isClient?: boolean) => {
  const children: any[] = [];
  const manualPortfolios: any[] = [];
  const route = (name: string) =>
    isClient
      ? PATH_DASHBOARD.client.view(replaceAll(name ?? '', ' ', '-'))
      : PATH_DASHBOARD.general.wallets.view(replaceAll(name ?? '', ' ', '-'));
  const AltInvestRoute = (id: string) =>
    isClient ? PATH_DASHBOARD.client.altInvest.view(id) : PATH_DASHBOARD.altInvest.view(id);

  Object.keys(connections).forEach((connectionId) => {
    const portfoliosInConnection = connections[connectionId];
    const connectionName =
      connectionId !== 'Sin entidad' ? portfoliosInConnection[0]?.entity?.name : connectionId;
    if (portfoliosInConnection.length === 1 && connectionName !== 'Sin entidad') {
      const portfolio = portfoliosInConnection[0];

      children.push({
        id: portfolio.id,
        title: portfolio.name,
        path:
          portfolio.type === 'ALTERNATIVE_INVESTMENTS'
            ? AltInvestRoute(portfolio.id)
            : route(portfolio.name),
        isTranslate: false,
        isConnected: !!portfolio.connectionDetails,
        isAdvisor: !isClient ? portfolio.role === 'CLIENT' : portfolio.role === 'OWNER',
      });
    } else {
      const item = {
        title: connectionName,
        path: `/dashboard/wallets/${replaceAll(connectionName ?? '', ' ', '')}/root`,
        isConnected: false,
        children: portfoliosInConnection.map((portfolio) => ({
          id: portfolio.id,
          title: portfolio.name,
          path:
            portfolio.type === 'ALTERNATIVE_INVESTMENTS'
              ? AltInvestRoute(portfolio.id)
              : route(portfolio.name),
          isTranslate: false,
          isConnected:
            portfolio.connectionDetails !== undefined && portfolio.connectionDetails !== null,
          isAdvisor: !isClient ? portfolio.role === 'CLIENT' : portfolio.role === 'OWNER',
        })),
      };
      connectionName !== 'Sin entidad' ? children.push(item) : manualPortfolios.push(item);
    }
  });

  const result = [..._.orderBy(children, 'title'), ...manualPortfolios];

  return result;
};

export const getConnectionDetails = (
  portfolios: Portfolio[]
): { [connectionId: string]: Portfolio[] } => {
  const connections: { [connectionId: string]: Portfolio[] } = {};

  portfolios.forEach((portfolio) => {
    const connectionId = portfolio.entity?.id || 'Sin entidad';
    connections[connectionId] = connections[connectionId] || [];
    connections[connectionId].push(portfolio);
  });

  return connections;
};

export const getWalletsByType = (portfolios: Portfolio[], type: string) =>
  portfolios.filter((portfolio) => portfolio.type === type);
