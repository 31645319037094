import { Box, Card, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'mui-image';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from 'src/components/Logo';
import LogoIso from 'src/components/LogoIso';
import Page from 'src/components/Page';
import StepperFlow from 'src/components/StepperFlow';
import useGetInfoCompany from 'src/hooks/useGetInfoCompany';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import LanguagePopover from 'src/layouts/dashboard/DashboardHeader/LanguagePopover/LanguagePopover';
import { InfoCompanyForm } from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { dispatch } from 'src/redux/store';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import { OnboardingChangePassForm, OnboardingInfoForm } from 'src/sections/auth/onboarding-company';
import OnboardingOfficeForm, {
  OfficeFormProps,
} from 'src/sections/auth/onboarding-company/OnboardingOfficeForm';
import OnboardingPersonalization from 'src/sections/auth/onboarding-company/OnboardingPersonalization/OnboardingPersonalization';
import OnboardingCurrency from 'src/sections/auth/onboarding/OnboardingCurrency';
import { getCompanyDetailAction } from 'src/services/companies';
import { OnBoardingProps } from './OnBoarding.Types';
import useGetInfoUser from 'src/hooks/useGetInfoUser';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function OnBoarding(props: OnBoardingProps) {
  const [activeStep, setActiveStep] = useState<number>(0);

  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  const { translate } = useLocales();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { access } = useGetInfoUser();

  const company = useGetInfoCompany();

  const STEPS = [
    translate('company_onboarding.new_password'),
    'Moneda base',
    translate('company_onboarding.add_info'),
    'Personalización',
    translate('company_onboarding.create_office'),
  ];

  const CLIENT_STEPS = [translate('company_onboarding.new_password'), 'Moneda base'];

  useEffect(() => {
    if (props.hasPasswordChanged) setActiveStep(1);
  }, [props.hasPasswordChanged]);

  const handleOnSubmitPassword = (password: string) => {
    props.changePassword(
      password,
      () => {
        if (props.isClient) {
          props.finishOnboarding();
          navigate(
            access === 'FULL_ACCESS'
              ? PATH_DASHBOARD.general.wallets.newWallet
              : PATH_DASHBOARD.general.summary
          );
        } else {
          setActiveStep(1);
        }
      },
      () =>
        enqueueSnackbarError(
          translate('company_onboarding.service_error', {
            action: translate('company_onboarding.change_pass'),
          })
        )
    );
  };

  const handleOnSubmitInfo = (data: InfoCompanyForm) => {
    props.updateCompanyInfo(
      props.company.id,
      data,
      () => setActiveStep(3),
      () =>
        enqueueSnackbarError(
          translate('company_onboarding.service_error', {
            action: translate('company_onboarding.modify_data'),
          })
        )
    );
  };

  const handleOnSubmitOffice = (data: OfficeFormProps) => {
    props.updateOfficeInfo(
      props.company.id,
      props.office.id,
      data,
      () => {
        const onFinish = () => navigate(PATH_DASHBOARD.company.clients);
        dispatch(getCompanyDetailAction(props.company.id, onFinish, onFinish));
      },
      () =>
        enqueueSnackbarError(
          translate('company_onboarding.service_error', {
            action: translate('company_onboarding.modify_office'),
          })
        )
    );
  };

  const handleOnSubmitCurrency = (baseCurrency: string) => {
    const navigateBasedOnRole = () => {
      if (props.isFinalUser || props.isClient) navigate(PATH_DASHBOARD.general.wallets.newWallet);
      if (props.isEmployee || props.isClient) props.finishOnboarding();
      if (!props.isFinalUser && !props.isEmployee && !props.isClient) setActiveStep(2);
    };

    const showError = () =>
      enqueueSnackbarError(
        'Se ha producido un error al intentar añadir la moneda base, por favor vuelta a intentarlo.'
      );

    props.updateBaseCurrency(baseCurrency, navigateBasedOnRole, showError);
  };

  const handleLogout = () => {
    props.logout();
    navigate(PATH_AUTH.login, { replace: true });
  };

  const enqueueSnackbarError = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  const renderFooter = () => (
    <Stack sx={{ mb: 5, mx: 5, position: 'absolute', bottom: 0 }}>
      <Stack alignItems={'center'} flexDirection={'row'}>
        <Typography color="text.secondary" fontSize={13} sx={{ mr: 1.3 }} variant="body1">
          {'Powered by'}
        </Typography>
        <LogoIso height={30} sx={{ mt: 0.6 }} width={60} />
      </Stack>
    </Stack>
  );

  return (
    <Page title="OnBoarding">
      <RootStyle>
        <HeaderStyle>
          {company.imageUrl ? (
            <Stack>
              <Image
                bgColor={undefined}
                duration={200}
                errorIcon={<Logo />}
                fit={'contain'}
                height={50}
                src={company.imageUrl ?? ''}
                width={'100%'}
              />
            </Stack>
          ) : (
            <Logo />
          )}
          <Stack alignItems="center" direction="row" sx={{ mt: { md: -2 } }}>
            <LanguagePopover />
            {smUp && (
              <Link
                color="text.primary"
                onClick={handleLogout}
                sx={{ ml: 2, cursor: 'pointer' }}
                variant="subtitle2"
              >
                {'Cerrar sesión'}
              </Link>
            )}
          </Stack>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography sx={{ px: 5, mt: 10, mb: 1 }} variant="h3">
              {translate('company_onboarding.welcome_title', {
                title: props.isClient ? props.company.name : 'Vumi',
              })}
            </Typography>
            {props.isClient ? (
              <Typography
                color="text.secondary"
                display="inline"
                flexDirection="row"
                sx={{ px: 5, mb: 5 }}
                variant="body1"
              >
                {
                  'Te hemos invitado a nuestra plataforma para que disfrutes de todo lo que ofrecemos.'
                }
              </Typography>
            ) : (
              <Typography color="text.secondary" sx={{ px: 5, mb: 5 }}>
                {translate('company_onboarding.thx_title')}
              </Typography>
            )}
            {(props.isClient || props.isEmployee) && renderFooter()}
          </SectionStyle>
        )}

        <Container>
          <ContentStyle sx={{ maxWidth: activeStep === 0 ? 600 : 700 }}>
            {!props.isFinalUser && !props.isClient && (
              <StepperFlow activeStep={activeStep} steps={props.isClient ? CLIENT_STEPS : STEPS} />
            )}
            <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h4">
                  {activeStep !== 3
                    ? translate('company_onboarding.before_start')
                    : 'Personaliza a tu gusto'}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {props.isFinalUser
                    ? 'Necesitamos que nos indiques con qué moneda base trabajaremos'
                    : activeStep !== 3
                    ? translate('company_onboarding.before_start_des')
                    : 'Añade los logos y los colores de tu empresa.'}
                </Typography>
              </Box>
            </Box>
            {activeStep === 0 && !props.isFinalUser && (
              <OnboardingChangePassForm onSubmitForm={handleOnSubmitPassword} />
            )}
            {(activeStep === 1 || props.isFinalUser) && (
              <OnboardingCurrency
                isStep={!props.isFinalUser}
                onSubmitForm={handleOnSubmitCurrency}
              />
            )}
            {activeStep === 2 && !props.isFinalUser && (
              <OnboardingInfoForm
                company={props.company}
                countries={props.countries}
                onSubmitForm={handleOnSubmitInfo}
              />
            )}
            {activeStep === 3 && !props.isFinalUser && (
              <OnboardingPersonalization
                companyId={props.company.id}
                onPressNext={() => setActiveStep(4)}
              />
            )}
            {activeStep === 4 && (
              <OnboardingOfficeForm
                company={props.company}
                countries={props.countries}
                office={props.office}
                onSubmitForm={handleOnSubmitOffice}
              />
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
