import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from 'src/redux/store';
import { updateUserSettingsRequestAction } from 'src/services/user';
import { onInitAppFlow } from 'src/thunks/onInitAppFlow';
import { RoutesEventProps, RoutesInputProps, RoutesOwnProps } from './Routes.Types';
import View from './Routes.View';

const mapStateToProps = (state: RootState, _ownProps: RoutesOwnProps): RoutesInputProps => ({
  user: state.auth.user,
});

const mapDispatchToProps: MapDispatchToPropsFunction<RoutesEventProps, RoutesOwnProps> = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  _ownProps: RoutesOwnProps
) => ({
  onInitApp: () => {
    dispatch(onInitAppFlow);
  },
  updateUserSettings: (baseCurrency: string, setLoading: (loading: boolean) => void) => {
    setLoading(true);
    dispatch(
      updateUserSettingsRequestAction(
        baseCurrency,
        () => setLoading(false),
        () => setLoading(false)
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
