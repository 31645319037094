import { Box, Stack, Toolbar, useMediaQuery } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import { IconButtonAnimate } from 'src/components/animate';
// sub-components
import AddAssetsBtn from '../AddAssetsBtn';
import LastUpdateLabel from '../LastUpdateLabel';
import ClientSearchBar from '../ClientSearchBar/ClientSearchBar';
import HideAccountButton from '../HideAccountButton';
import ConnectionsPopover from '../ConnectionsPopover/ConnectionsPopover';
import ReloadPricesButton from '../ReloadPricesButton';
import NotificationsDrawer from '../NotificationsDrawer/NotificationsDrawer';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useGetInfoUser from '../../services/useGetUserInfo';
import useGetPortfoliosInfo from '../../services/useGetPortfoliosInfo';
// context
import { useContext } from 'react';
import Context, { ContextType } from '../../context';

// ----------------------------------------------------------------------

export default function MainToolbar() {
  const { isBusiness, isClient, access } = useGetInfoUser();
  const { connections } = useGetPortfoliosInfo();

  const { actions }: ContextType = useContext<ContextType>(Context);
  const { onOpenSidebar } = actions;

  const isFullAccess = access === 'FULL_ACCESS';
  const isBusinessNotClient = isBusiness && !isClient;
  const isSm = useResponsive('up', 'sm');
  const isDownLg = useResponsive('down', 'lg');
  const isUp700 = useMediaQuery('(min-width:700px)');

  const unReadyConnections = connections.filter((i) => (i.pending || !i.displayed) && !i.errored);

  return (
    <Toolbar
      sx={{
        minHeight: '100% !important',
        pr: { lg: 5 },
        pl: { lg: 4 },
      }}
    >
      {isDownLg && (
        <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButtonAnimate>
      )}

      {isBusinessNotClient && isSm && <ClientSearchBar />}

      <Box sx={{ flexGrow: 1 }} />

      <Stack alignItems="center" className="vertical-header" direction="row" gap={0.25}>
        {!isBusinessNotClient && isUp700 && isFullAccess && <LastUpdateLabel />}
        {!isBusiness && <ReloadPricesButton />}
        <HideAccountButton />
        {!isBusinessNotClient && unReadyConnections.length > 0 && <ConnectionsPopover />}
        <NotificationsDrawer />
        <AddAssetsBtn sx={{ ml: 2 }} />
      </Stack>
    </Toolbar>
  );
}
