import { useNavigate } from 'react-router-dom';
import { Divider, MenuItem } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
import useGetPortfoliosInfo from '../../services/useGetPortfoliosInfo';
import useGetInfoUser from 'src/hooks/useGetInfoUser';
// components
import { PATH_DASHBOARD } from 'src/routes/paths';

type Props = {
  onClick: () => void;
};

export default function MoreButtonItems({ onClick }: Props) {
  const { portfolios } = useGetPortfoliosInfo();

  const navigate = useNavigate();
  const { translate } = useLocales();
  const { access } = useGetInfoUser();

  const isFullAccess = access === 'FULL_ACCESS';

  const hasManualPortfolios = () => {
    let itHas = false;
    const hasPortfolios = Object.keys(portfolios).length > 0;
    if (hasPortfolios) {
      Object.values(portfolios).forEach((i) => {
        if (!!!i.connectionDetails && i.role === 'OWNER') itHas = true;
      });
    }
    return itHas;
  };

  const propsItem = {
    addWallet: {
      onClick: () => {
        onClick();
        navigate(
          isFullAccess
            ? PATH_DASHBOARD.general.wallets.newWallet
            : PATH_DASHBOARD.general.wallets.newConnection
        );
      },
      children: translate(isFullAccess ? 'wallets.add_wallet' : 'new_connection.add'),
    },
    addMovement: {
      onClick: () => {
        onClick();
        navigate(PATH_DASHBOARD.general.wallets.addMovement, {
          state: {
            walletId: Object.values(portfolios).filter((i) => !!!i.connectionDetails)[0].id,
          },
        });
      },
      children: translate('wallets.add_movement'),
    },
  };

  return (
    <>
      <MenuItem {...propsItem.addWallet} />
      {hasManualPortfolios() && (
        <>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <MenuItem {...propsItem.addMovement} />
        </>
      )}
    </>
  );
}
