import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useState } from 'react';

import { FormProvider, RHFSelect } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------
type InfoForm = {
  baseCurrency: string;
};

type OnboardingCompanyFormProps = {
  onSubmitForm: (baseCurrency: string) => void;
  isStep?: boolean;
};

export default function OnboardingCurrency(props: OnboardingCompanyFormProps) {
  const [loading, setLoading] = useState(false);

  const { translate } = useLocales();

  const Schema = Yup.object().shape({
    baseCurrency: Yup.string().required('Por favor introduzca la moneda'),
  });

  const defaultValues = {
    baseCurrency: 'EUR',
  };

  const methods = useForm<InfoForm>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: InfoForm) => {
    try {
      setLoading(true);
      props.onSubmitForm(data.baseCurrency);
      reset();
    } catch (error) {
      setLoading(false);
      console.error(error);
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={props.isStep ? 12 : 9} xs={12}>
          <RHFSelect label="Moneda base" name="baseCurrency">
            {['EUR', 'USD'].map((val, index) => (
              <option key={index} value={val}>
                {val}
              </option>
            ))}
          </RHFSelect>
          <LoadingButton
            fullWidth
            loading={loading}
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
          >
            {props.isStep ? 'Siguiente' : 'Guardar'}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
