import moment from 'moment';
// mui
import { Typography } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
import useGetInfoUser from '../services/useGetUserInfo';

export default function LastUpdateLabel() {
  const { latestAutomaticPriceUpdate, latestPriceUpdate } = useGetInfoUser();

  const { translate } = useLocales();

  const latestPriceUpdateMoment = moment(latestPriceUpdate);
  const latestAutomaticPriceUpdateMoment = moment(latestAutomaticPriceUpdate);

  let date = latestPriceUpdateMoment.isAfter(latestAutomaticPriceUpdate)
    ? latestPriceUpdateMoment.calendar()
    : latestAutomaticPriceUpdateMoment.calendar();

  return (
    <Typography color="text.secondary" sx={{ mr: 1, fontSize: 12 }} variant="subtitle2">
      {latestPriceUpdate === '' && latestAutomaticPriceUpdate
        ? translate('navbar.need_to_update')
        : translate('navbar.last_update', {
            date,
            latestPriceUpdate: latestPriceUpdateMoment.isSame(moment(), 'day') ? 'h' : '',
          })}
    </Typography>
  );
}
