import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// config
import { VERSION } from 'src/config';
// actions
import { logout } from 'src/redux/slices/AuthSlice/authSlice';
// redux store
import { persistor } from 'src/redux/store';

export default function useCheckAppVersion() {
  const dispatch = useDispatch();

  useEffect(() => {
    checkVersion();
  }, []);

  function checkVersion() {
    const appVersion = localStorage.getItem('appVersion');
    if (appVersion !== VERSION) {
      persistor.pause();
      persistor.flush().then(() => {
        persistor.purge().then(() => {
          dispatch(logout());
          localStorage.setItem('appVersion', VERSION);
          window.location.reload();
        });
      });
    }
  }
}
