import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ThemeMode,
  ThemeColorPresets,
  ThemeStretch,
  UpdateSettingsPayload,
  ColorVariants,
  ThemeColorsApp,
  ThemeColorBackgrounds,
  ThemeColorTexts,
  ThemeColorsReport,
} from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import {
  defaultPreset,
  defaultThemeColorApp,
  defaultThemeColorReport,
} from 'src/utils/getColorPresets';
import { ColorPalette } from 'src/theme/palette';

type SettingsState = {
  themeMode: ThemeMode;
  themeColorPresets: ThemeColorPresets;
  themeColorApp: ThemeColorsApp;
  themeColorReport: ThemeColorsReport;
  themeStretch: ThemeStretch;
  setColor: ColorVariants;
  colorOption: {
    name: string;
    value: string;
  }[];
};

export const initialState: SettingsState = {
  themeMode: 'light',
  themeColorPresets: 'default',
  themeColorApp: defaultThemeColorApp,
  themeColorReport: defaultThemeColorReport,
  themeStretch: true,
  setColor: defaultPreset,
  colorOption: [],
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<UpdateSettingsPayload>) =>
      (state = {
        ...state,
        ...action.payload,
      }),
    updateTheme: (state, action: PayloadAction<{ themeMode: ThemeMode }>) =>
      (state = {
        ...state,
        themeMode: action.payload.themeMode,
      }),
    updateThemeStretch: (state, action: PayloadAction<{ themeStretch: ThemeStretch }>) =>
      (state = {
        ...state,
        themeStretch: action.payload.themeStretch,
      }),
    updateColorPresets: (state, action: PayloadAction<{ themeColorPresets: ThemeColorPresets }>) =>
      (state = {
        ...state,
        themeColorPresets: action.payload.themeColorPresets,
      }),
    updatePrimaryColors: (state, action: PayloadAction<{ primaryColors: ColorPalette }>) =>
      (state = {
        ...state,
        themeColorApp: { ...state.themeColorApp, primary: action.payload.primaryColors },
        themeMode: 'light',
      }),
    updateBackgroundColors: (
      state,
      action: PayloadAction<{ backgroundColors: ThemeColorBackgrounds }>
    ) =>
      (state = {
        ...state,
        themeColorApp: { ...state.themeColorApp, background: action.payload.backgroundColors },
        themeMode: 'light',
      }),
    updateTextColors: (state, action: PayloadAction<{ textColors: ThemeColorTexts }>) =>
      (state = {
        ...state,
        themeColorApp: { ...state.themeColorApp, text: action.payload.textColors },
        themeMode: 'light',
      }),
    updateChartColors: (state, action: PayloadAction<{ chartColors: string[] }>) =>
      (state = {
        ...state,
        themeColorApp: { ...state.themeColorApp, chartColors: action.payload.chartColors },
        themeMode: 'light',
      }),
    updateThemeColorApp: (state, action: PayloadAction<ThemeColorsApp>) =>
      (state = {
        ...state,
        themeColorApp: { ...state.themeColorApp, ...action.payload },
        themeMode: 'light',
      }),
    getReportSettings: (state, action: PayloadAction<ThemeColorsReport>) =>
      (state = {
        ...state,
        themeColorReport: action.payload,
      }),
    updateReportSettings: (state, action: PayloadAction<ThemeColorsReport>) =>
      (state = {
        ...state,
        themeColorReport: action.payload,
      }),
  },
});

const persistSettingsConfig = {
  key: 'settings',
  storage,
  whitelist: ['themeMode', 'themeStretch', 'themeColorApp'],
};

const persistSettingsReducer = persistReducer<SettingsState>(
  persistSettingsConfig,
  settingsSlice.reducer
);

export const {
  updateSettings,
  updateThemeStretch,
  updateColorPresets,
  updateTheme,
  updatePrimaryColors,
  updateBackgroundColors,
  updateTextColors,
  updateChartColors,
  getReportSettings,
  updateReportSettings,
  updateThemeColorApp,
} = settingsSlice.actions;

export default persistSettingsReducer;
