import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultContent, ModalContent } from './modalSlice.d';

type ModalState = {
  visible: boolean;
  content: ModalContent;
};

export const initialState: ModalState = {
  visible: false,
  content: defaultContent,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalContent>) =>
      (state = {
        ...state,
        visible: true,
        content: action.payload,
      }),
    hideModal: (state) =>
      (state = {
        ...state,
        visible: false,
        content: defaultContent,
      }),
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
