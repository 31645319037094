import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import PermissionDenied from 'src/pages/PermissionDenied';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { BusinessGuardProps } from './BusinessGuard.Types';

// ----------------------------------------------------------------------

export default function BusinessGuard({ isBusiness, hasClientSelected }: BusinessGuardProps) {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  if (!isBusiness) return <PermissionDenied />;

  if (!hasClientSelected && pathname.includes('/dashboard/client')) {
    navigate(PATH_DASHBOARD.root);
  }

  return (
    <>
      <Outlet />
    </>
  );
}
