import { RootState } from 'src/redux/store';
import {
  CreateConnectionBodyNetType,
  CreateManualConnectionBodyNetType,
  UpdateAvConnectionBody,
  UpdateAvConnectionBodyNetType,
} from './NetTypes';

export function setCreateConnectionBody(
  name: string,
  token: string,
  entity: string
): (state: RootState) => CreateConnectionBodyNetType {
  return (_state: RootState) => ({
    name,
    token,
    entity,
  });
}

export function setCreateManualConnectionBody(
  name: string,
  token: string,
  entity: string,
  provider: string
): (state: RootState) => CreateManualConnectionBodyNetType {
  return (_state: RootState) => ({
    name,
    delayed: false,
    token,
    entity: entity,
    isAdminFlow: true,
    provider,
  });
}

export function setUpdateConnectionNameBody(
  name: string,
  id: string
): (state: RootState) => { name: string; displayed: boolean } {
  return (state: RootState) => ({
    name,
    displayed:
      state.portfolio.connections[state.portfolio.connections.findIndex((i) => i.id === id)]
        .displayed,
  });
}

export function setUpdateConnectionDisplayedBody(
  id: string
): (state: RootState) => { name: string; displayed: boolean } {
  return (state: RootState) => ({
    name: state.portfolio.connections[state.portfolio.connections.findIndex((i) => i.id === id)]
      .name,
    displayed: true,
  });
}

export function setUpdateAvConnectionBody(
  components: UpdateAvConnectionBody[]
): (state: RootState) => UpdateAvConnectionBodyNetType {
  return (_state: RootState) => ({
    components,
  });
}
