export const SETUP_INTENT = 'SETUP_INTENT';

export const GET_CUSTOMER_INFO = 'GET_CUSTOMER_INFO';
export const GET_CUSTOMER_INFO_STARTED = 'GET_CUSTOMER_INFO_STARTED';
export const GET_CUSTOMER_INFO_FAILURE = 'GET_CUSTOMER_INFO_FAILURE';

export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_STARTED = 'DELETE_CARD_STARTED';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';

export const CREATE_SUBCRIPTION = 'CREATE_SUBCRIPTION';
