import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';

import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import SimpleLabel from 'src/components/SimpleLabel';
import { IconButtonAnimate } from 'src/components/animate';
import useTabs from 'src/hooks/useTabs';
import { Notification } from 'src/redux/slices/NotificationsSlice/notificationsSlice.d';
import { store } from 'src/redux/store';
import { transformActionNotifications, transformIconNotifications } from 'src/utils/notifications';
import { NotificationsDrawerProps } from './NotificationsDrawer.Types';
import EmptyContent from 'src/components/empty-content';

// ----------------------------------------------------------------------

export default function NotificationsDrawer(props: NotificationsDrawerProps) {
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);

  const [unReadNotifications, setUnReadNotifications] = useState(
    transformActionNotifications(props.unReadNotifications ?? [], navigate, store.getState())
  );
  const [readNotifications, setReadNotifications] = useState(
    transformActionNotifications(props.readNotifications ?? [], navigate, store.getState())
  );

  const totalUnRead = unReadNotifications.length;

  const { currentTab, onChangeTab, setCurrentTab } = useTabs(
    unReadNotifications.length !== 0 ? 'Sin leer' : 'Leídas'
  );

  useEffect(() => {
    setReadNotifications(
      transformActionNotifications(props.readNotifications, navigate, store.getState())
    );
    setUnReadNotifications(
      transformActionNotifications(props.unReadNotifications, navigate, store.getState())
    );
  }, [props.unReadNotifications, props.readNotifications]);

  useEffect(() => {
    setCurrentTab(unReadNotifications.length !== 0 ? 'Sin leer' : 'Leídas');
  }, [unReadNotifications]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    if (unReadNotifications.length > 0) {
      props.readNotificationsRequest(unReadNotifications.map((i) => i.id));
    }
    setOpen(false);
  };

  const NOTIFICATION_TABS = [
    {
      value: 'Leídas',
      component: (
        <List disablePadding>
          {_.orderBy(readNotifications, 'sentDate', 'desc').map((notification: Notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onClose={() => handleClose()}
            />
          ))}
          {readNotifications.length === 0 && (
            <EmptyContent
              sx={{
                '& span.MuiBox-root': { height: 160 },
              }}
              title="No tienes nuevas notificaciones"
            />
          )}
        </List>
      ),
      icon: (
        <SimpleLabel color={'default'} sx={{ ml: 1 }}>
          {readNotifications.length}
        </SimpleLabel>
      ),
    },
    {
      value: 'Sin leer',
      component: (
        <List disablePadding>
          {_.orderBy(unReadNotifications, 'sentDate', 'desc').map((notification: Notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onClose={() => handleClose()}
            />
          ))}
          {unReadNotifications.length === 0 && (
            <EmptyContent
              sx={{
                '& span.MuiBox-root': { height: 160 },
              }}
              title="No tienes nuevas notificaciones"
            />
          )}
        </List>
      ),
      icon: (
        <SimpleLabel
          color={unReadNotifications.length !== 0 ? 'primary' : 'default'}
          sx={{ ml: 1 }}
        >
          {unReadNotifications.length}
        </SimpleLabel>
      ),
    },
  ];

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>
      <Drawer
        BackdropProps={{
          invisible: false,
        }}
        PaperProps={{
          sx: { width: { sm: 420, xs: 350 } },
        }}
        anchor="right"
        onClose={handleClose}
        open={open}
      >
        <Scrollbar sx={{ height: 1 }}>
          <Stack sx={{ p: 2.5 }}>
            <Typography variant="h6">{'Notificaciones'}</Typography>
            <Typography sx={{ color: 'text.secondary' }} variant="body2">
              Tienes {totalUnRead} notificaciones sin leer
            </Typography>
          </Stack>

          <Divider />

          <Tabs
            allowScrollButtonsMobile
            indicatorColor="primary"
            onChange={onChangeTab}
            scrollButtons="auto"
            sx={{ px: 3 }}
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {NOTIFICATION_TABS.map((tab) => (
              <Tab
                color="red"
                disableRipple
                icon={tab.icon}
                iconPosition="end"
                key={tab.value}
                label={tab.value}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Divider />

          {NOTIFICATION_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Scrollbar>
      </Drawer>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({
  notification,
  onClose,
}: {
  notification: Notification;
  onClose: () => void;
}) {
  const { avatar, title } = renderContent(notification);

  return (
    <>
      <ListItemButton
        onClick={() => {
          notification.onPress && notification.onPress();
          onClose();
        }}
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.isRead && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <ListItemAvatar sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
              }}
              variant="caption"
            >
              <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />

              {moment(notification.sentDate).fromNow()}
            </Typography>
          }
        />
      </ListItemButton>
      <Divider sx={{ borderStyle: 'dashed' }} />
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: Notification) {
  const notIcon = transformIconNotifications(notification);

  const avatar =
    notIcon.iconify !== '' ? (
      <Iconify color={notIcon.color} height={20} icon={notIcon.iconify} width={20} />
    ) : (
      <img alt={notification.code} src={notIcon.url} />
    );

  return {
    avatar,
    title: (
      <Box>
        <Typography variant="subtitle2">
          <Box
            sx={{
              display: {
                xs: 'inline-block',
                md: 'none',
                marginRight: '.5em',
                verticalAlign: 'middle',
                lineHeight: 1,
              },
            }}
          >
            {avatar}
          </Box>
          <span>{notification.topic}</span>
        </Typography>
        <Typography component="span" sx={{ color: 'text.secondary' }} variant="body2">
          {notification.message}
        </Typography>
      </Box>
    ),
  };
}
