import { useNavigate } from 'react-router';
import Image from 'mui-image';
// components
import Logo from 'src/components/Logo';
import MyAvatar from 'src/components/MyAvatar';
// hooks
import useGetInfoUser from '../services/useGetUserInfo';
// config
import { PATH_DASHBOARD } from 'src/routes/paths';

export default function HeaderLogo() {
  const { isBusiness, isClient, companyName, companyLogo } = useGetInfoUser();

  const navigate = useNavigate();

  return isBusiness || isClient ? (
    <Image
      bgColor={undefined}
      duration={200}
      errorIcon={
        <MyAvatar
          onClick={() => (isClient ? {} : navigate(PATH_DASHBOARD.company.clients))}
          sx={{ width: 40, height: 40, cursor: isClient ? 'auto' : 'pointer' }}
          user={{ name: companyName, imageUrl: companyLogo }}
        />
      }
      fit={'contain'}
      height={50}
      src={companyLogo ?? ''}
      width={'100%'}
    />
  ) : (
    <Logo path={PATH_DASHBOARD.general.summary} />
  );
}
