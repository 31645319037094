import {
  ClientCompanyForm,
  EditEmployeeCompanyForm,
  EmployeeCompanyForm,
  InfoCompanyForm,
  OfficeCompanyForm,
} from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { RootState } from 'src/redux/store';
import { CompanyFormProps } from 'src/sections/@dashboard/admin/AdminCompanyDrawer';
import {
  CreateClientBodyNetType,
  CreateCompanyBodyNetType,
  CreateEmployeeBodyNetType,
  CreateOfficeBodyNetType,
  EditEmployeeBodyNetType,
  UpdateCompanyBodyNetType,
  UpdateOfficeBodyNetType,
} from './NetTypes';
import { ThemeColorsApp, ThemeColorsReport } from 'src/redux/slices/SettingsSlice/settingsSlice.d';

export function setCreateCompanyBody(
  companyName: string,
  name: string,
  firstSurname: string,
  secondSurname: string,
  cif: string,
  adminEmail: string,
  onboardingLanguage: string
): (state: RootState) => CreateCompanyBodyNetType {
  return (_state: RootState) => ({
    companyName,
    name,
    firstSurname,
    secondSurname,
    cif,
    adminEmail,
    onboardingLanguage,
  });
}

export function setUpdateCompanyBody(
  id: string,
  company: CompanyFormProps
): (state: RootState) => UpdateCompanyBodyNetType {
  return (_state: RootState) => {
    let body: UpdateCompanyBodyNetType = {
      id,
    };

    if (company.name !== '') body.name = company.name;
    if (company.cif !== '') body.cif = company.cif;
    if (company.email !== '') body.billingEmail = company.email;
    if (!!company.contactNumber && company.contactNumber !== '' && company.country !== '')
      body.contactNumber = {
        country: company.country,
        telNumber: parseInt(company.contactNumber),
      };
    if (company.street !== '')
      body.billingAddress = { ...body.billingAddress, street: company.street };
    if (company.town !== '') body.billingAddress = { ...body.billingAddress, town: company.town };
    if (company.city !== '') body.billingAddress = { ...body.billingAddress, city: company.city };
    if (company.country !== '')
      body.billingAddress = { ...body.billingAddress, country: company.country };
    if (company.zipCode !== undefined)
      body.billingAddress = { ...body.billingAddress, zipCode: company.zipCode };

    return body;
  };
}

export function setUpdateCompanyActionBody(
  id: string,
  newInfo: InfoCompanyForm
): (state: RootState) => UpdateCompanyBodyNetType {
  return (state: RootState) => ({
    id,
    name: state.business.company.name,
    cif: newInfo.cif ?? state.business.company.cif,
    contactEmail: newInfo.contact_email,
    contactNumber: {
      country: newInfo.phone_code,
      telNumber: parseInt(newInfo.contactNumber),
    },
    billingAddress: {
      street: newInfo.street,
      town: newInfo.town,
      city: newInfo.city,
      zipCode: newInfo.zipCode,
      country: newInfo.country,
    },
    pointOfContact: newInfo.point_of_contact,
  });
}
export function setUpdateOfficeActionBody(
  orgId: string,
  officeId: string,
  newInfo: OfficeCompanyForm
): (state: RootState) => UpdateOfficeBodyNetType {
  return (_state: RootState) => ({
    orgId,
    officeId,
    name: newInfo.name,
    contactEmail: newInfo.contact_email,
    contactNumber: {
      country: newInfo.phone_code,
      telNumber: parseInt(newInfo.contactNumber),
    },
    billingAddress: {
      street: newInfo.street,
      town: newInfo.town,
      city: newInfo.city,
      zipCode: newInfo.zipCode,
      country: newInfo.country,
    },
    pointOfContact: newInfo.point_of_contact,
  });
}

export function setCreateEmployeeBody(
  employee: EmployeeCompanyForm
): (state: RootState) => CreateEmployeeBodyNetType {
  return (_state: RootState) => ({
    name: employee.name,
    email: employee.email,
    firstSurname: employee.firstSurname,
    secondSurname: employee.secondSurname,
    onboardingLanguage: employee.onboardingLanguage,
    officeAdmin: employee.officeAdmin,
  });
}

export function setEditEmployeeBody(
  employee: EditEmployeeCompanyForm
): (state: RootState) => EditEmployeeBodyNetType {
  return (_state: RootState) => ({
    name: employee.name,
    email: employee.email,
    firstSurname: employee.firstSurname,
    secondSurname: employee.secondSurname,
    role: employee.officeAdmin ? 'ROLE_BUSINESS_OFFICE_ADMIN' : 'ROLE_BUSINESS_EMPLOYEE',
  });
}

export function setCreateClientBody(
  employeeId: string,
  client: ClientCompanyForm
): (state: RootState) => CreateClientBodyNetType {
  return (_state: RootState) => {
    const contactNumber =
      !!client.contactNumber && !!client.phone_code
        ? {
            contactNumber: {
              country: client.phone_code,
              telNumber: client.contactNumber,
            },
          }
        : {};

    return {
      employeeId,
      clientName: client.clientName,
      clientFirstSurname: client.clientFirstSurname,
      clientSecondSurname: client.clientSecondSurname,
      clientEmail: client.clientEmail,
      country: client.country,
      name: 'test',
      nationalID: client.nationalID,
      birthDate: client.birthDate,
      signDate: client.signDate,
      clientBaseCurrency: client.baseCurrency,
      clientKind: client.clientKind,
      cif: client.cif,
      leiCode: client.leiCode,
      residenceType: client.residenceType,
      administrator: client.administrator,
      personType: client.personType,
      access: client.access,
      type: client.type,
      gender: client.gender,
      ...contactNumber,
    };
  };
}

export function setCreateOfficeBody(
  office: OfficeCompanyForm
): (state: RootState) => CreateOfficeBodyNetType {
  return (_state: RootState) => ({
    name: office.name,
    contactEmail: office.contact_email,
    contactNumber: {
      country: office.phone_code,
      telNumber: parseInt(office.contactNumber),
    },
    billingAddress: {
      street: office.street,
      town: office.town,
      city: office.city,
      zipCode: office.zipCode,
      country: office.country,
    },
    pointOfContact: office.point_of_contact,
  });
}

export function setUpdateReportSettingsBody(
  reportSettings: ThemeColorsReport
): (state: RootState) => any {
  return (_state: RootState) => reportSettings;
}

export function setUpdateCompanySettingsBody(
  webSettings: ThemeColorsApp
): (state: RootState) => any {
  return (_state: RootState) => webSettings;
}
