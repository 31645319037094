// hooks
import { useSelector } from 'react-redux';
// utils
import get from 'lodash/get';
// redux types
import { RootState } from 'src/redux/store';
import { defaultUser } from 'src/redux/slices/AuthSlice/authSlice.d';

export default function useGetInfoUser() {
  const userInfo = useSelector((state: RootState) => get(state, ['auth', 'user'], defaultUser));

  return {
    ...userInfo,
  };
}
