import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { defaultUser, User } from 'src/redux/slices/AuthSlice/authSlice.d';
import { login } from 'src/redux/slices/AuthSlice/authSlice';
import { dispatch } from 'src/redux/store';
import { getUserFromToken, getRefreshToken } from 'src/utils/jwt';
import { PATH_DASHBOARD, PATH_AUTH } from '../paths';

export const OAuth2RedirectHandler = () => {
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');

  useEffect(() => {
    if (token) {
      const user: User = getUserFromToken(token);
      const refreshToken: string = getRefreshToken(token);
      if (user.mfaEnabled) {
        dispatch(login({ user: defaultUser, token: '', refreshToken: '', mfaToken: token }));
        navigate(PATH_AUTH.verifyMFA, { state: { token } });
      } else dispatch(login({ user, token, refreshToken, mfaToken: '' }));
    }
  }, [token]);

  return token ? (
    <Navigate to={PATH_DASHBOARD.general.summary} />
  ) : (
    <Navigate to={PATH_AUTH.login} />
  );
};

export default OAuth2RedirectHandler;
