import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
// components
import LogoSelector from './LogoSelector';
// hooks
import useGetUpdateLogos from './useGetUpdateLogos';

// ----------------------------------------------------------------------

type Props = {
  onPressNext: () => void;
  companyId: string;
};

export default function OnboardingPersonalization({ onPressNext, companyId }: Props) {
  const { logoIcon, logo, onUpdateLogo, onUpdateLogoIcon, loading, onUpdateLogos } =
    useGetUpdateLogos({
      companyId,
      onFinish: onPressNext,
    });

  return (
    <Stack>
      <Grid container spacing={3}>
        <LogoSelector
          description="Influirá en algunos lugares de la web y en la generación de informes. Si es posible, adjunte un logo horizontal o ajustado a esas dimensiones."
          file={logo}
          onSave={onUpdateLogo}
          title="Logo web y reportes"
        />
        <LogoSelector
          description="Este logo se verá reflejado en la pestaña del explorador."
          file={logoIcon}
          isIcon
          onSave={onUpdateLogoIcon}
          title="Logo icono"
        />
        <Grid item xs={12}>
          <Typography color="text.secondary" fontSize={12} textAlign={'center'} variant="body1">
            {'Permitido *.jpeg, *.jpg, *.png, *.svg. Tamaño máximo de 1,0 MB'}
          </Typography>
          <Typography
            color="text.secondary"
            fontSize={12}
            sx={{ mt: 1 }}
            textAlign={'center'}
            variant="body1"
          >
            {'* Se recomienda que ambos sean con fondos transparentes.'}
          </Typography>
        </Grid>
      </Grid>
      <LoadingButton
        fullWidth
        loading={loading}
        onClick={onUpdateLogos}
        size="large"
        sx={{ mt: 3 }}
        variant="contained"
      >
        {'Siguiente'}
      </LoadingButton>
    </Stack>
  );
}
