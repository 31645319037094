import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RefObject } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { PixelCrop } from 'react-image-crop';

// ----------------------------------------------------------------------

export interface Props extends DropzoneOptions {
  isSelected?: boolean;
  completedCrop: PixelCrop;
  previewCanvasRef: RefObject<HTMLCanvasElement>;
}

const Container = styled(Stack)(({ theme }) => ({
  width: 480,
  height: 250,
  margin: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function PreviewCanvasSelected({
  isSelected,
  previewCanvasRef,
  completedCrop,
}: Props) {
  return (
    <Container
      sx={{
        opacity: isSelected ? 1 : 0,
        width: '100%',
        height: 250,
        margin: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <canvas
        ref={previewCanvasRef}
        style={{
          objectFit: 'contain',
          width: completedCrop.width,
          height: completedCrop.height,
        }}
      />
    </Container>
  );
}
