import { ThemeMode } from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import {
  updateColorPresets,
  updateThemeStretch,
  updateTheme,
  updateSettings,
  initialState,
} from 'src/redux/slices/SettingsSlice/settingsSlice';
import { dispatch, RootState, useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const useSettings = () =>
  useSelector((state: RootState) => ({
    ...state.settings,
    updateTheme: (themeMode: ThemeMode) => dispatch(updateTheme({ themeMode })),
    updateThemeStretch: (themeStretch: boolean) => dispatch(updateThemeStretch({ themeStretch })),
    reset: () => dispatch(updateSettings(initialState)),
    updateColorPresets,
  }));

export default useSettings;
