import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { PROFILES_URL, S3_URL } from 'src/config';

import { getInit, initSolo } from 'src/redux/slices/AuthSlice/authSlice';
import { User, defaultUser } from 'src/redux/slices/AuthSlice/authSlice.d';
import { getCompany, getOffice } from 'src/redux/slices/BusinessSlice/businessSlice';
import { updateSettings } from 'src/redux/slices/SettingsSlice/settingsSlice';
import {
  ThemeColorPresets,
  UpdateSettingsPayload,
} from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import { RootState } from 'src/redux/store';
import { refreshTokenRequestAction } from 'src/services/auth';
import { getCompanyDetailAction, getReportSettingsAction } from 'src/services/companies';
import { getNetworkClient } from 'src/services/config';
import { getConnectionsAction } from 'src/services/connections';
import { getCountriesRequestAction } from 'src/services/data';
import { getUserNotificationsRequestAction } from 'src/services/notifications';
import {
  getNetWorthPeriodRequestAction,
  getNetWorthRequestAction,
  getPortfoliosRequestAction,
} from 'src/services/portfolio';
import { createUserSettingsRequest, getUserSettingsRequest } from 'src/services/user';
import { GetUserSettingsMoreNetType, GetUserSettingsMorePayload } from 'src/services/user/NetTypes';
import SnackbarUtils from 'src/utils/SnackbarUtils';
import { getUserFromToken, isValidToken } from 'src/utils/jwt';

export function onInitAppFlow(
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => RootState
) {
  try {
    refreshTokenLogic(dispatch, getState);
  } catch (err) {
    console.error(err);
    dispatch(getInit({ user: defaultUser, token: null, isAuthenticated: false }));
  }
}

const onSuccessInit = (
  token: string,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => RootState
) => {
  let user: User = {
    ...getUserFromToken(token),
    hideAccount: getState().auth.user.hideAccount,
    onboardingFinished: getState().auth.user.onboardingFinished,
  };
  dispatch(getNetWorthRequestAction());
  dispatch(getNetWorthPeriodRequestAction(true, ''));
  dispatch(getConnectionsAction());
  dispatch(getPortfoliosRequestAction());
  getSettingsFlow(dispatch, user, (settings: GetUserSettingsMoreNetType) => {
    updateAppSettings(settings.webSettings, getState().settings.themeColorPresets, dispatch);
    dispatch(getInit({ user: { ...user, ...settings }, token, isAuthenticated: true }));
  });

  const { countries } = getState().data;
  if (!!!countries || countries.length === 0) dispatch(getCountriesRequestAction());
  dispatch(getUserNotificationsRequestAction());
};

export const refreshTokenLogic = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => RootState
) => {
  const { token, refreshToken } = getState().auth;

  if (token && isValidToken(token)) {
    onSuccessInit(token, dispatch, getState);
  } else {
    dispatch(
      refreshTokenRequestAction(
        refreshToken ?? '',
        () => {
          const newToken = getState().auth.token;
          onSuccessInit(newToken ?? '', dispatch, getState);
        },
        () => {
          if (refreshToken !== null)
            SnackbarUtils.warning('La sesión ha expirado. Por favor vuelva a iniciar sesión.');
          dispatch(initSolo());
        }
      )
    );
  }
};

const updateAppSettings = (
  webSettings: string,
  themeColorPresets: ThemeColorPresets,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => {
  if (webSettings) {
    const json: UpdateSettingsPayload = JSON.parse(webSettings);
    if (!!json && !!json.themeMode && json.themeStretch !== null)
      dispatch(
        updateSettings({
          themeMode: json.themeMode,
          themeStretch: json.themeStretch,
          themeColorPresets,
        })
      );
  }
};

const getSettingsFlow = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  user: User,
  onFinish: (settings: GetUserSettingsMoreNetType) => void
) => {
  const isB2B = user.privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1;
  const isEmployee =
    user.privileges.findIndex(
      (i) => i === 'MANAGE_BUSINESS' || i === 'MANAGE_CLIENTS' || i === 'MANAGE_OFFICE'
    ) !== -1;

  const onFailure = () => (error: any) => {
    console.error('SETTINGS ERROR', error);
    const empty: any = {};
    onFinish(empty);
  };

  getUserSettingsReq(dispatch, isB2B, isEmployee, onFinish, () => {
    getNetworkClient().executeRequest(
      createUserSettingsRequest(
        () => getUserSettingsReq(dispatch, isB2B, isEmployee, onFinish, onFailure),
        onFailure
      )
    );
  });
};

const getUserSettingsReq = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  isB2B: boolean,
  isEmployee: boolean,
  onFinish: (settings: GetUserSettingsMoreNetType) => void,
  onFailure: () => void
) => {
  getNetworkClient().executeRequest(
    getUserSettingsRequest((response: GetUserSettingsMorePayload) => {
      if (isB2B && !!response.company && !!response.office) {
        if (isEmployee) {
          dispatch(getCompanyDetailAction(response.company.id));
        } else
          dispatch(
            getCompany({
              ...response.company,
              imageUrl: !!response.company.imageUrl
                ? `${S3_URL}/${PROFILES_URL}/${response.company.imageUrl}?r=${Math.random() * 1000}`
                : undefined,
            })
          );
        dispatch(getOffice(response.office));
        dispatch(getReportSettingsAction(response.company.id));
      }
      onFinish(response.settings);
    }, onFailure)
  );
};
