import { RootState } from 'src/redux/store';

import { login, refreshToken as refreshTokenType } from 'src/redux/slices/AuthSlice/authSlice';
import {
  LoginPayload,
  RefreshTokenPayload,
  RegisterPayload,
} from 'src/redux/slices/AuthSlice/authSlice.d';
import { setLoginBody, setRefreshBody, setRegisterBody } from 'src/services/auth/RequestUtils';
import {
  transformGetCompanyThemeResponse,
  transformLoginMFAResponse,
  transformLoginResponse,
  transformRefreshResponse,
  transformRegisterResponse,
} from 'src/services/auth/ResponseUtils';
import { getAxiosEmptyRequest, getAxiosEmptyRequestAction } from 'src/services/http/axios';
import { generalQueriesUrl } from '../EnpointConstants';
import { GetCompanySettingsResponse } from './NetTypes';
import {
  ADD_EMAIL_WAITING_LIST,
  CONFIRM_ACCOUNT,
  GET_COMPANY_THEME_SETTINGS,
  LOGIN_AUTH,
  LOGIN_AUTH_FAILURE,
  LOGIN_AUTH_STARTED,
  LOGIN_MFA_AUTH,
  LOGIN_MFA_AUTH_FAILURE,
  LOGIN_MFA_AUTH_STARTED,
  RECOVER_PASSWORD,
  REFRESH_TOKEN_AUTH,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_STARTED,
  REGISTER_AUTH,
  REGISTER_AUTH_FAILURE,
  REGISTER_AUTH_STARTED,
  REGISTER_AUTH_SUCCESS,
  RENSED_CONFIRM_EMAIL,
  RESET_PASSWORD,
} from './RequestIds';

const loginUrl = '/api/v1/login';
const loginMFAUrl = '/api/v1/mfa/login';
const registeUrl = '/api/v1/commands/user';
const refreshTokenUrl = '/api/v1/refresh_token';
const confirmAccountUrl = '/api/v1/confirm_token?token=:token';
const resendEmailConfirmationUrl = '/api/v1/resend_confirmation?email=:email';
const recoverPasswordUrl = '/api/v1/commands/user/recover-pass';
const addEmailWaitingListUrl = () => `/api/v1/commands/user/waiting-list`;
const getCompanyThemeSettingsUrl = (id: string) =>
  `${generalQueriesUrl}/organizations/${id}/settings/web `;

export function loginRequestAction(
  email: string,
  password: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, LoginPayload, any>();
  apiCall.reqId = LOGIN_AUTH;
  apiCall.startedReqType = LOGIN_AUTH_STARTED;
  apiCall.successReqType = login.type;
  apiCall.failureReqType = LOGIN_AUTH_FAILURE;
  apiCall.setEndpointFromState = () => loginUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setLoginBody(email, password);
  apiCall.transformResponseDataWithState = transformLoginResponse();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function registerRequest(
  name: string,
  firstSurname: string,
  email: string,
  password: string,
  preferLanguage: string = 'es',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, RegisterPayload, any>();
  apiCall.reqId = REGISTER_AUTH;
  apiCall.startedReqType = REGISTER_AUTH_STARTED;
  apiCall.successReqType = REGISTER_AUTH_SUCCESS;
  apiCall.failureReqType = REGISTER_AUTH_FAILURE;
  apiCall.setEndpointFromState = () => registeUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setRegisterBody(name, firstSurname, email, password, preferLanguage);
  apiCall.transformResponseDataWithState = transformRegisterResponse(name, firstSurname, email);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function refreshTokenRequestAction(
  refreshToken: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, RefreshTokenPayload, any>();
  apiCall.reqId = REFRESH_TOKEN_AUTH;
  apiCall.startedReqType = REFRESH_TOKEN_STARTED;
  apiCall.successReqType = refreshTokenType.type;
  apiCall.failureReqType = REFRESH_TOKEN_FAILURE;
  apiCall.getHeadersFromState = () => ({ 'Content-Type': 'application/json' });
  apiCall.setEndpointFromState = () => refreshTokenUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setRefreshBody(refreshToken);
  apiCall.transformResponseDataWithState = transformRefreshResponse();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function confirmAccountRequest(
  token: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = CONFIRM_ACCOUNT;
  apiCall.setEndpointFromState = () => confirmAccountUrl.replace(':token', token);
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function resendEmailConfirmationRequest(
  email: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = RENSED_CONFIRM_EMAIL;
  apiCall.setEndpointFromState = () => resendEmailConfirmationUrl.replace(':email', email);
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function recoverPasswordRequest(
  email: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = RECOVER_PASSWORD;
  apiCall.setBodyFromState = () => ({ email });
  apiCall.setEndpointFromState = () => recoverPasswordUrl;
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function resetPasswordRequest(
  password: string,
  token: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = RESET_PASSWORD;
  apiCall.setBodyFromState = () => ({ password, token });
  apiCall.setEndpointFromState = () => recoverPasswordUrl;
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function loginMFARequestAction(
  token: string,
  code: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, LoginPayload, any>();
  apiCall.reqId = LOGIN_MFA_AUTH;
  apiCall.startedReqType = LOGIN_MFA_AUTH_STARTED;
  apiCall.successReqType = login.type;
  apiCall.failureReqType = LOGIN_MFA_AUTH_FAILURE;
  apiCall.setEndpointFromState = () => loginMFAUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = () => ({ code, token });
  apiCall.transformResponseDataWithState = transformLoginMFAResponse();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function addEmailToWaitingListRequest(
  email: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = ADD_EMAIL_WAITING_LIST;
  apiCall.setBodyFromState = () => ({ email });
  apiCall.setEndpointFromState = () => addEmailWaitingListUrl();
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getCompanyThemeSettingsAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetCompanySettingsResponse, any>();
  apiCall.reqId = GET_COMPANY_THEME_SETTINGS;
  apiCall.setEndpointFromState = () => getCompanyThemeSettingsUrl(id);
  apiCall.transformResponseDataWithState = transformGetCompanyThemeResponse();
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
