import { AdminSecurity, SectorSecurity } from 'src/pages/dashboard/Admin/Admin.View';
import { updateUser } from 'src/redux/slices/AuthSlice/authSlice';
import { RegisterPayload } from 'src/redux/slices/AuthSlice/authSlice.d';
import { Security, TransactionsPayload } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState } from 'src/redux/store';
import { NewSecurityType, SplitType } from 'src/sections/@dashboard/admin/AddNewSecurityPanel';
import { generalCommandsUrl, generalQueriesUrl } from '../EnpointConstants';
import { getAxiosEmptyRequest, getAxiosEmptyRequestAction } from '../http/axios';
import { GetSecurityMovementsNetType, SecurityInfoNetType } from './NetTypes';
import {
  ACCEPT_SUGGESTED_SECURITY,
  DECLINE_SUGGESTED_SECURITY,
  GET_EARLIEST_DATE,
  GET_SECURITIES,
  GET_SECURITY_EOD,
  GET_SECURITY_INFO,
  GET_SECURITY_MOVEMENTS,
  UPDATE_LATEST_PRICES,
  UPDATE_LATEST_PRICES_FAILED,
  UPDATE_LATEST_PRICES_STARTED,
  GET_SECURITIES_COUNT,
  ADD_SECURITIES_MORGING_STARS,
  MODIFY_UNKNOWN_SECURITY,
} from './RequestIds';
import {
  setAcceptSuggestedSectorBody,
  setAcceptSuggestedSecurityBody,
  setModifyUnknownSecurityBody,
} from './RequestUtils';
import {
  transformGetCountSecurity,
  transformGetSecurityMovements,
  transformUpdateLatestPrices,
  transformgetInfoSecurity,
} from './ResponseUtils';
import { CountBySecurityType } from '../portfolio/NetTypes';

const generalSecuritiesQueriesUrl = generalQueriesUrl + '/securities';
const generalSecuritiesCommandUrl = generalCommandsUrl + '/securities';

const getAllSecuritiesByTermUrl = (term: string, type: string = '', page: number = 0) =>
  generalSecuritiesQueriesUrl +
  `/finder?term=${term.replace(' ', '+')}&type=${type}&page=${page}&size=${20}`;
const getAllSecuritiesByTermCountUrl = (term: string) =>
  generalSecuritiesQueriesUrl + `/finder/count?term=${term.replace(' ', '+')}`;
const updateLatestPricesUrl = () => generalSecuritiesCommandUrl + `/user/update`;
const acceptSuggestedSecurityUrl = () => generalSecuritiesCommandUrl + `/suggestions`;
const archiveSuggestedSecurityUrl = (id: string) => generalSecuritiesCommandUrl + `/archive/${id}`;
const modifyUnknownSecurityUrl = (id: string) => generalSecuritiesCommandUrl + `/${id}/data`;
const acceptSuggestedSectorUrl = (id: string) => generalSecuritiesCommandUrl + `/${id}`;
const addSecuritiesMorningstarsUrl = () => generalSecuritiesCommandUrl + `/morningstar`;
const createNewSecurityUrl = () => generalSecuritiesCommandUrl + `/manual`;

const getSecurityInformationUrl = (securityId: string) =>
  generalSecuritiesQueriesUrl + `/${securityId}/security-information`;
const getSecurityEODUrl = (securityId: string, date: string) =>
  generalSecuritiesQueriesUrl + `/${securityId}/eod?date=${date}`;
const getSuggestedSecuritiesUrl = (page: number, size: number, isin: string) =>
  generalSecuritiesQueriesUrl + `/suggestions/filled?page=${page}&size=${size}&isin=${isin}`;
const getUnknownSecuritiesUrl = (page: number, size: number, isin: string) =>
  generalSecuritiesQueriesUrl + `/suggestions/unknown?page=${page}&size=${size}&isin=${isin}`;
const getUnknownSectorSecuritiesUrl = (page: number, size: number, isin: string) =>
  generalSecuritiesQueriesUrl + `/sectors/unknown?page=${page}&size=${size}&isin=${isin}`;
const getExchangeByTermUrl = (term: string) =>
  generalSecuritiesQueriesUrl + `/exchanges/finder?term=${term}`;
const getInfoSecurityUrl = (
  id: string,
  period: string,
  startDate: string = '',
  endDate: string = ''
) => generalSecuritiesQueriesUrl + `/${id}?period=${period}&from=${startDate}&to=${endDate}`;
const getPortfolioMovementsUrl = (
  size: number,
  page: number,
  securityId: string,
  type: string,
  portfolioId: string = '',
  clientId: string = '',
  startDate?: string,
  endDate?: string,
  min?: string,
  max?: string
) =>
  generalQueriesUrl +
  `${
    clientId !== '' ? '/clients/' + clientId : ''
  }/securities/${securityId}/transactions?size=${size}&page=${page}
  &portfolio=${portfolioId}&types=${type}&startDate=${startDate}&endDate=${endDate}&minAmount=${min}&maxAmount=${max}`;

export function getSecuritiesByTermRequest(
  term: string,
  type: string,
  page: number,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Security, any>();
  apiCall.setEndpointFromState = () => getAllSecuritiesByTermUrl(term, type, page);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITIES;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getAllSecuritiesByTermCountRequest(
  term: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, CountBySecurityType, any>();
  apiCall.setEndpointFromState = () => getAllSecuritiesByTermCountUrl(term);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITIES_COUNT;
  apiCall.transformResponseDataWithState = transformGetCountSecurity();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getSecurityInformationRequest(
  securityId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.setEndpointFromState = () => getSecurityInformationUrl(securityId);
  apiCall.method = 'GET';
  apiCall.reqId = GET_EARLIEST_DATE;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getSecurityEODRequest(
  securityId: string,
  date: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.setEndpointFromState = () => getSecurityEODUrl(securityId, date);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITY_EOD;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateLatestPricesRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, RegisterPayload, any>();
  apiCall.reqId = UPDATE_LATEST_PRICES;
  apiCall.startedReqType = UPDATE_LATEST_PRICES_STARTED;
  apiCall.successReqType = updateUser.type;
  apiCall.failureReqType = UPDATE_LATEST_PRICES_FAILED;
  apiCall.setEndpointFromState = () => updateLatestPricesUrl();
  apiCall.method = 'POST';
  apiCall.transformResponseDataWithState = transformUpdateLatestPrices();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getSuggestedSecuritiesRequest(
  page: number = 0,
  size: number = 10,
  isin: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Security, any>();
  apiCall.setEndpointFromState = () => getSuggestedSecuritiesUrl(page, size, isin);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITIES;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getUnknownSecuritiesRequest(
  page: number = 0,
  size: number = 20,
  isin: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Security, any>();
  apiCall.setEndpointFromState = () => getUnknownSecuritiesUrl(page, size, isin);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITIES;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getUnknownSectorSecuritiesRequest(
  page: number = 0,
  size: number = 20,
  isin: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Security, any>();
  apiCall.setEndpointFromState = () => getUnknownSectorSecuritiesUrl(page, size, isin);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITIES;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function acceptSuggestedSecurityRequest(
  securityId: string,
  securitySuggestionId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = ACCEPT_SUGGESTED_SECURITY;
  apiCall.setEndpointFromState = () => acceptSuggestedSecurityUrl();
  apiCall.setBodyFromState = setAcceptSuggestedSecurityBody(securityId, securitySuggestionId);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function archiveSuggestedSecurityRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = ACCEPT_SUGGESTED_SECURITY;
  apiCall.setEndpointFromState = () => archiveSuggestedSecurityUrl(id);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function acceptSuggestedSectorRequest(
  security: SectorSecurity,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = ACCEPT_SUGGESTED_SECURITY;
  apiCall.setEndpointFromState = () => acceptSuggestedSectorUrl(security.id);
  apiCall.setBodyFromState = setAcceptSuggestedSectorBody(security);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function createNewSecurityRequest(
  security: NewSecurityType,
  splits: SplitType[],
  prices: FormData,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const ob = { ...security, splits };
  prices.append('newSecurityString', JSON.stringify(ob));
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = ACCEPT_SUGGESTED_SECURITY;
  apiCall.setEndpointFromState = () => createNewSecurityUrl();
  apiCall.setBodyFromState = () => prices;
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function declineSuggestedSecurityRequest(
  securityId: string,
  securitySuggestionId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = DECLINE_SUGGESTED_SECURITY;
  apiCall.setEndpointFromState = () => acceptSuggestedSecurityUrl();
  apiCall.setBodyFromState = setAcceptSuggestedSecurityBody(securityId, securitySuggestionId);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getExchangeByTermRequest(
  term: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Security, any>();
  apiCall.setEndpointFromState = () => getExchangeByTermUrl(term);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITIES;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getInfoSecurityRequest(
  id: string,
  period: string,
  startDate: string = '',
  endDate: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, SecurityInfoNetType, any>();
  apiCall.setEndpointFromState = () => getInfoSecurityUrl(id, period, startDate, endDate);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITY_INFO;
  apiCall.transformResponseDataWithState = transformgetInfoSecurity(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

// add here function for get security price
export function getSecurityPriceRequest(
  securityId: string,
  period: string,
  startDate: string = '',
  endDate: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.setEndpointFromState = () =>
    generalSecuritiesQueriesUrl +
    `/${securityId}/prices?period=${period}&from=${startDate}&to=${endDate}`;
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITY_INFO;
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getSecurityMovementsRequest(
  payload: TransactionsPayload,
  securityId: string,
  portfolioId: string = '',
  clientId: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetSecurityMovementsNetType, any>();
  apiCall.reqId = GET_SECURITY_MOVEMENTS;
  apiCall.setEndpointFromState = () =>
    getPortfolioMovementsUrl(
      payload.size,
      payload.page,
      securityId,
      payload.type ?? 'ALL',
      portfolioId,
      clientId ?? '',
      payload.startDate ?? '',
      payload.endDate ?? '',
      payload.min ?? '',
      payload.max ?? ''
    );

  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetSecurityMovements();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function modifyUnknownSecurityRequest(
  security: AdminSecurity,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = MODIFY_UNKNOWN_SECURITY;
  apiCall.setEndpointFromState = () => modifyUnknownSecurityUrl(security.id);
  apiCall.setBodyFromState = setModifyUnknownSecurityBody(security);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function addSecuritiesMorningstarsRequest(
  securities: FormData,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = ADD_SECURITIES_MORGING_STARS;
  apiCall.setEndpointFromState = () => addSecuritiesMorningstarsUrl();
  apiCall.setBodyFromState = () => securities;
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
