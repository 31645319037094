// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

export default function BlockContent() {
  return (
    <Stack
      alignItems="center"
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="center"
      spacing={2}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          Arrastra o selecciona el archivo
        </Typography>

        <Typography sx={{ color: 'text.secondary', maxWidth: 330 }} variant="body2">
          Suelta tus archivos o haz click&nbsp;
          <Typography
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
            variant="body2"
          >
            aquí
          </Typography>
          &nbsp;para encontrarlos en tu dispositivo
        </Typography>
      </Box>
    </Stack>
  );
}
