import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './NotificationsDrawer.View';
import {
  NotificationsDrawerInputProps,
  NotificationsDrawerOwnProps,
  NotificationsDrawerEventProps,
} from './NotificationsDrawer.Types';
import { RootState } from 'src/redux/store';
import { readNotificationsRequestAction } from 'src/services/notifications';

const mapStateToProps = (
  state: RootState,
  _ownProps: NotificationsDrawerOwnProps
): NotificationsDrawerInputProps => ({
  unReadNotifications: state.notifications.unReadNotifications ?? [],
  readNotifications: state.notifications.readNotifications ?? [],
});

const mapDispatchToProps: MapDispatchToPropsFunction<
  NotificationsDrawerEventProps,
  NotificationsDrawerOwnProps
> = (dispatch: ThunkDispatch<any, any, AnyAction>, _ownProps: NotificationsDrawerOwnProps) => ({
  readNotificationsRequest: (ids: string[]) => {
    dispatch(readNotificationsRequestAction(ids));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
