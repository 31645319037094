import { AutocompleteRenderInputParams, InputAdornment, InputBase } from '@mui/material';

// components
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  params: AutocompleteRenderInputParams;
  handleKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function AutoInputBase({ params, handleKeyUp }: Props) {
  return (
    <InputBase
      {...params.InputProps}
      autoFocus
      color="primary"
      fullWidth
      inputProps={params.inputProps}
      onKeyUp={handleKeyUp}
      placeholder="Busca y selecciona a tu cliente"
      startAdornment={
        <InputAdornment position="start">
          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', height: 20, width: 20 }} />
        </InputAdornment>
      }
      sx={{ height: 1, typography: 'subtitle2' }}
    />
  );
}
