import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConnectionType,
  defaultNetWorth,
  DeleteMovementPayload,
  GetAccountsPayload,
  NetWorthType,
  OperationsPayload,
  PerformanceType,
  Portfolio,
  PortfolioMap,
  PortfolioSummary,
  TransactionCountPayload,
  TransactionPayload,
  defaultAgregated,
  UpdateAllPayload,
  NetworthPeriodPayload,
  UpdatePerformanceTabPayload,
  UpdateTabRefreshPayload,
  UpdatePortfolioPerformancePayload,
  defaultPerformance,
  UpdatePortfolioRiskPayload,
  UpdatePortfolioEntity,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';

type PortfoliosState = {
  portfolios: PortfolioMap;
  connections: ConnectionType[];
  netWorth: NetWorthType;
  agregatedSummary: PortfolioSummary;
  currentPortfolio: string;
};

export const initialState: PortfoliosState = {
  portfolios: {},
  connections: [],
  netWorth: defaultNetWorth,
  agregatedSummary: defaultAgregated,
  currentPortfolio: '',
};

const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState,
  reducers: {
    addPortfolio: (state, action: PayloadAction<Portfolio>) => {
      state.portfolios = { ...state.portfolios, [action.payload.id]: action.payload };
    },
    getPortfolios: (state, action: PayloadAction<PortfolioMap>) => {
      state.portfolios = { ...action.payload };
    },
    getMovementsPortfolio: (state, action: PayloadAction<Portfolio>) => {
      state.portfolios = { ...state.portfolios, [action.payload.id]: action.payload };
    },
    addMovement: (state, action: PayloadAction<TransactionPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...handleUpdatePorfolio(state.portfolios[action.payload.id]),
          transactions: action.payload.transactions,
        },
      };
    },
    getMovementsCount: (state, action: PayloadAction<TransactionCountPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          transactionsCount: action.payload.transactionsCount,
        },
      };
    },
    getOperations: (state, action: PayloadAction<OperationsPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          operations: action.payload.operations,
        },
      };
    },
    editPortfolio: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: { ...state.portfolios[action.payload.id], name: action.payload.name },
      };
    },
    deletePortfolio: (state, action: PayloadAction<{ id: string }>) => {
      delete state.portfolios[action.payload.id];
    },
    deleteMovement: (state, action: PayloadAction<DeleteMovementPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.portfolioId]: {
          ...handleUpdatePorfolio(state.portfolios[action.payload.portfolioId]),
          transactions: state.portfolios[action.payload.portfolioId].transactions.filter(
            (i) => i.id !== action.payload.transactionId
          ),
        },
      };
    },
    updatePortfolioSettings: (state, action: PayloadAction<PortfolioMap>) => {
      state.portfolios = {
        ...state.portfolios,
        ...action.payload,
      };
    },
    updatePerformanceType: (
      state,
      action: PayloadAction<{ id: string; performanceType: PerformanceType }>
    ) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          performanceType: action.payload.performanceType,
        },
      };
    },
    addConnection: (state, action: PayloadAction<ConnectionType>) => {
      state.connections = [...state.connections, action.payload];
    },
    updateConnection: (state, action: PayloadAction<ConnectionType>) => {
      const index = state.connections.findIndex((i) => i.id === action.payload.id);
      if (index !== -1) {
        let newConnections = [...state.connections];
        newConnections[index] = action.payload;
        state.connections = [...newConnections];
      }
    },
    getConnections: (state, action: PayloadAction<ConnectionType[]>) => {
      state.connections = [...action.payload];
    },
    getAccounts: (state, action: PayloadAction<GetAccountsPayload>) => {
      const index = state.connections.findIndex((i) => i.id === action.payload.id);
      if (index !== -1) {
        let newConnections = [...state.connections];
        newConnections[index] = { ...newConnections[index], accounts: action.payload.accounts };
        state.connections = [...newConnections];
      }
    },
    deleteConnection: (state, action: PayloadAction<{ id: string }>) => {
      state.connections = [...state.connections.filter((i) => i.id !== action.payload.id)];
    },
    updateNetWorth: (state, action: PayloadAction<NetWorthType>) => {
      state.netWorth = action.payload;
    },
    updateNetWorthPeriod: (state, action: PayloadAction<NetworthPeriodPayload>) => {
      state.netWorth = { ...state.netWorth, ...action.payload };
    },
    updateAllInfo: (state, action: PayloadAction<UpdateAllPayload>) => {
      state.portfolios = action.payload.portfolios;
      state.agregatedSummary = action.payload.agregatedSummary;
    },
    updatePerformanceTabData: (state, action: PayloadAction<UpdatePerformanceTabPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          stateDataTab: {
            isLoaded: true,
            performance: {
              ...action.payload,
            },
            tabRefresh: { ...state.portfolios[action.payload.id].stateDataTab.tabRefresh },
          },
        },
      };
    },
    updateTabRefresh: (state, action: PayloadAction<UpdateTabRefreshPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          stateDataTab: {
            isLoaded: true,
            performance: {
              ...state.portfolios[action.payload.id].stateDataTab.performance,
            },
            tabRefresh: { ...action.payload.tabRefresh },
          },
        },
      };
    },
    updatePortfolioPerformance: (
      state,
      { payload }: PayloadAction<UpdatePortfolioPerformancePayload>
    ) => {
      const { id, performanceMeta, monthlyPerformance } = payload;
      state.portfolios = {
        ...state.portfolios,
        [id]: {
          ...state.portfolios[id],
          ...payload,
          performanceMeta: !!performanceMeta
            ? performanceMeta
            : state.portfolios[payload.id].performanceMeta,
          monthlyPerformance: !!monthlyPerformance
            ? monthlyPerformance
            : state.portfolios[payload.id].monthlyPerformance,
        },
      };
    },
    updatePortfolioRisk: (state, { payload }: PayloadAction<UpdatePortfolioRiskPayload>) => {
      const { id } = payload;
      state.portfolios = {
        ...state.portfolios,
        [id]: {
          ...state.portfolios[id],
          ...payload,
        },
      };
    },
    changeCurrentPortfolio: (state, { payload }: PayloadAction<string>) => {
      state.currentPortfolio = payload;
      state.portfolios = {
        ...state.portfolios,
        [payload]: {
          ...state.portfolios[payload],
          stateDataTab: {
            ...state.portfolios[payload].stateDataTab,
            performance: defaultPerformance,
          },
        },
      };
    },
    updatePortfolioEntity: (state, { payload }: PayloadAction<UpdatePortfolioEntity>) => {
      const { id, entity } = payload;
      state.portfolios = {
        ...state.portfolios,
        [id]: {
          ...state.portfolios[id],
          entity,
        },
      };
    },
  },
});

const persistPortfoliosConfig = {
  key: 'portfolios',
  storage,
  whitelist: ['portfolios', 'connections'],
  blacklist: ['currentPortfolio'],
};

const persistPortfoliosReducer = persistReducer<PortfoliosState>(
  persistPortfoliosConfig,
  portfoliosSlice.reducer
);

export const {
  addPortfolio,
  editPortfolio,
  deletePortfolio,
  getPortfolios,
  getMovementsPortfolio,
  deleteMovement,
  getMovementsCount,
  addMovement,
  getOperations,
  updatePortfolioSettings,
  updatePerformanceType,
  addConnection,
  updateConnection,
  getConnections,
  getAccounts,
  deleteConnection,
  updateNetWorth,
  updateNetWorthPeriod,
  updateAllInfo,
  updatePerformanceTabData,
  updateTabRefresh,
  updatePortfolioPerformance,
  changeCurrentPortfolio,
  updatePortfolioRisk,
  updatePortfolioEntity,
} = portfoliosSlice.actions;

const handleUpdatePorfolio = (portfolio: any): Portfolio => ({
  ...portfolio,
  performance: { '7D': portfolio.performance['7D'] },
  operations: {
    vol: {},
    count: {},
  },
});

export default persistPortfoliosReducer;
