import moment from 'moment';
// hooks
import { useSelector } from 'react-redux';
// utils
import get from 'lodash/get';
import { isBusiness, isClient, isAdmin, isBusinessAdmin } from 'src/utils/privileges';
// types
import { RootState } from 'src/redux/store';
import { defaultUser } from 'src/redux/slices/AuthSlice/authSlice.d';
import { defaultCompany } from 'src/redux/slices/BusinessSlice/businessSlice.d';

export default function useGetInfoUser() {
  const {
    privileges,
    hideAccount,
    latestPriceUpdate,
    latestAutomaticPriceUpdate,
    baseCurrency,
    access,
  } = useSelector((state: RootState) => get(state, ['auth', 'user'], defaultUser));

  const { name, imageUrl } = useSelector((state: RootState) =>
    get(state, ['business', 'company'], defaultCompany)
  );

  const getCanUpdatePrices = () => {
    if (!latestPriceUpdate || latestPriceUpdate === '') return true;
    return moment(latestPriceUpdate)
      .add(privileges?.length === 0 ? 30 : 5, 'minutes')
      .isBefore(moment());
  };

  const timeLeft = Math.round(
    moment(latestPriceUpdate)
      .add(privileges?.length === 0 ? 30 : 5, 'minutes')
      .diff(moment()) /
      1000 /
      60
  ).toFixed(0);

  return {
    isBusiness: isBusiness(privileges),
    isBusinessAdmin: isBusinessAdmin(privileges),
    isClient: isClient(privileges),
    isAdmin: isAdmin(privileges),
    hideAccount,
    timeLeft,
    canUpdatePrices: getCanUpdatePrices(),
    companyName: name,
    companyLogo: imageUrl,
    latestPriceUpdate,
    latestAutomaticPriceUpdate,
    baseCurrency,
    access,
  };
}
