import { useEffect, useState } from 'react';
import { getNetworkClient } from 'src/services/config';
import { RiskFreeAssetDto } from 'src/services/indices/NetTypes';
import { getRiskValueRequest } from 'src/services/indices';

interface State {
  data: RiskFreeAssetDto[] | null;
  error: boolean;
  loading: boolean;
}

export default function useGetRiskValues() {
  const [state, setState] = useState<State>({
    data: null,
    error: false,
    loading: true,
  });

  useEffect(() => {
    getNetworkClient().executeRequest(
      getRiskValueRequest(
        (response) => {
          setState({
            data: [...response],
            error: false,
            loading: false,
          });
        },
        () => {
          setState({
            data: null,
            error: true,
            loading: false,
          });
        }
      )
    );
  }, []);

  return {
    ...state,
  };
}
