import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './BusinessGuard.View';
import {
  BusinessGuardInputProps,
  BusinessGuardOwnProps,
  BusinessGuardEventProps,
} from './BusinessGuard.Types';
import { RootState } from 'src/redux/store';

const mapStateToProps = (
  state: RootState,
  _ownProps: BusinessGuardOwnProps
): BusinessGuardInputProps => ({
  isBusiness:
    state.auth.user.privileges.findIndex(
      (i) => i === 'MANAGE_BUSINESS' || i === 'MANAGE_CLIENTS' || i === 'MANAGE_OFFICE'
    ) !== -1,
  hasClientSelected: state.client.id !== '' && state.client.name !== '',
});

const mapDispatchToProps: MapDispatchToPropsFunction<
  BusinessGuardEventProps,
  BusinessGuardOwnProps
> = (_dispatch: ThunkDispatch<any, any, AnyAction>, _ownProps: BusinessGuardOwnProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(View);
