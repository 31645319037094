import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import InfoActionModal from 'src/components/modal/InfoActionModal';

import FirstDepositModal from 'src/components/modal/FirstDepositModal';
import SendBetaEmailModal from 'src/components/modal/SendBetaEmailModal';
import SharesNotFoundModal from 'src/components/modal/SharesNotFoundModal';
import UpdateAvailabilityAccountsModal from 'src/components/modal/UpdateAvailabilityAccountsModal';
import WalletSettingsModal from 'src/components/modal/WalletSettings/WalletSettingsModal';
import { IBuyForm } from 'src/pages/dashboard/WalletsAddMovement/WalletsAddMovement.Types';
import { hideModal } from 'src/redux/slices/ModalSlice/modalSlice';
import {
  ConnectionAccount,
  ConnectionType,
  PerformanceType,
  Portfolio,
  ReferenceIndice,
  Transaction,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import NewWalletModalSelect from 'src/sections/@dashboard/general/wallets/new-wallet/modals/NewWalletModalSelect';
import WalletEditMov from 'src/sections/@dashboard/general/wallets/positions-movements/WalletEditMov';

export const renderNewWalletModalSelect = (
  canConnectWallet: boolean,
  onPressManual: () => void,
  onPressBroker: () => void,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => (
  <NewWalletModalSelect
    canConnect={canConnectWallet}
    onCancel={() => dispatch(hideModal())}
    onPressBroker={onPressBroker}
    onPressManual={onPressManual}
  />
);

export const renderUpdateAvailabilityAccountsModal = (
  connection: ConnectionType,
  isLoading: boolean,
  onSave: (newAccounts: ConnectionAccount[], onSuccess: () => void) => void,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => (
  <UpdateAvailabilityAccountsModal
    connection={connection}
    isLoading={isLoading}
    onCancel={() => dispatch(hideModal())}
    onSave={onSave}
  />
);
export const renderSendBetaEmailModal = (
  isLoading: boolean,
  onSave: (email: string, onSuccess: () => void) => void,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => (
  <SendBetaEmailModal
    isLoading={isLoading}
    onCancel={() => dispatch(hideModal())}
    onSave={(email: string) => onSave(email, () => dispatch(hideModal()))}
  />
);

export const renderInfoModal = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  title?: string,
  description?: string,
  cancelTitle?: string,
  acceptTitle?: string,
  loading?: boolean,
  isError?: boolean,
  onAccept?: () => void
) => (
  <InfoActionModal
    acceptTitle={acceptTitle}
    cancelTitle={cancelTitle}
    description={description}
    isError={isError}
    loading={loading}
    onAccept={onAccept}
    onCancel={() => dispatch(hideModal())}
    title={title}
  />
);

export const renderFirstDepositModal = (
  portfolio: Portfolio,
  baseCurrency: string,
  onSubmitModal: (data: any, onFinish: () => void) => void,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => (
  <FirstDepositModal
    baseCurrency={baseCurrency}
    onCancel={() => dispatch(hideModal())}
    onSubmitModal={onSubmitModal}
    walletName={portfolio.name}
  />
);

export const renderSharesNotFoundModal = (
  id: string,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => <SharesNotFoundModal id={id} onCancel={() => dispatch(hideModal())} />;

export const renderEditMovModal = (
  portfolio: Portfolio,
  transaction: Transaction | undefined,
  editMovement: (id: string, data: IBuyForm, transaction: Transaction) => void,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => (
  <WalletEditMov
    baseCurrency={portfolio.baseCurrency}
    editMovement={editMovement}
    onCancel={() => dispatch(hideModal())}
    transaction={transaction}
    walletName={portfolio.name}
  />
);

export const renderWalletSettingsModal = (
  performanceType: PerformanceType,
  referenceIndices: ReferenceIndice[],
  riskId: string,
  onSave: (
    newPerformanceType: PerformanceType,
    riskFreeAsset: string,
    indicesIds: string[],
    onSuccess: () => void,
    onFailure: () => void
  ) => void,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => (
  <WalletSettingsModal
    onCancel={() => dispatch(hideModal())}
    onSave={onSave}
    performanceType={performanceType}
    referenceIndices={referenceIndices}
    riskFreeAssetBenchmark={riskId}
  />
);
