import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import SvgIconStyle from '../../SvgIconStyle';

export default function RiskValueFreeLabel() {
  return (
    <Stack alignItems="center" flexDirection="row">
      <Typography color="text.secondary" variant="subtitle2">
        {'Activo libre de riesgo'}
      </Typography>
      <Tooltip
        sx={{ mt: 0.2, ml: 1 }}
        title={
          'Activo libre de riesgo: Elige el activo libre de riesgo para el cálculo de métricas de gestión de cartera dependiendo de la cartera de inversión.'
        }
      >
        <IconButton>
          <SvgIconStyle
            src={`/icons/ic_info_outline.svg`}
            sx={{ width: 16, height: 16, color: 'text.primary' }}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
