import { AxiosResponse } from 'axios';
import _ from 'lodash';
import moment from 'moment';

import { AddClientPayload } from 'src/redux/slices/ClientSlice/clientSlice.d';
import {
  ChartPerformance,
  ChartPerformanceMap,
  ConnectionType,
  GainsPerPeriodMap,
  ImportError,
  NetworthPeriodPayload,
  OperationsPayload,
  PerformanceStatisticsMap,
  PerformanceType,
  Portfolio,
  PortfolioMap,
  ReferenceIndice,
  RiskMetricsMap,
  TimeSeriesMap,
  Transaction,
  TransactionCountPayload,
  UpdateAllPayload,
  UpdatePortfolioRiskPayload,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState } from 'src/redux/store';
import { _chartEmptyPerformance, _portfolioWallet } from 'src/_mock/_wallets';
import {
  GetAllInfoResponseNetType,
  GetImportErrorsResponseNetType,
  GetMovementsResponseNetType,
  GetNetworthPeriodNetType,
  GetPorfolioPositionsResponseNetType,
  GetPorfolioResponseNetType,
  GetPortfolioDividendsResponseNetType,
  GetRiskResponseNetType,
  GetSummaryDashResponseNetType,
} from '../portfolio/NetTypes';
import {
  GetMovementsCountResponseNetType,
  GetOperationsResponseNetType,
} from '../transactions/NetTypes';
import {
  GetClientsNotificationPayload,
  GetClientsNotificationsNetType,
  GetInfoClientResponseNetType,
  GetInitClientResponseNetType,
} from './NetTypes';
import { GetConnectionsNetworthType, UpdateAvConnectionBody } from '../connections/NetTypes';

const transformGetPortfolios = (
  newPortfolios: Portfolio[],
  oldPortfolios: PortfolioMap
): PortfolioMap => {
  const portfolios: PortfolioMap = {};
  newPortfolios.forEach(
    ({
      id,
      name,
      status,
      owners,
      connectionDetails,
      entity,
      role,
      liquidityConsidered,
      firstTransactionDate,
      type,
    }) => {
      let activesNotFoundShowed =
        oldPortfolios[id] && oldPortfolios[id].settings.activesNotFoundShowed;
      if (id)
        if (!!oldPortfolios[id] && oldPortfolios[id].firstTransactionDate !== '')
          portfolios[id] = {
            ...oldPortfolios[id],
            stateDataTab: {
              ...oldPortfolios[id].stateDataTab,
              isLoaded: false,
              tabRefresh: {
                refreshPositions: true,
                refreshDividends: true,
                refreshMovements: true,
                refreshPerformance: true,
              },
            },
            entity,
            liquidityConsidered,
          };
        else
          portfolios[id] = _portfolioWallet(
            id,
            name,
            firstTransactionDate,
            'EUR',
            status,
            liquidityConsidered ?? false,
            role,
            owners,
            entity,
            connectionDetails,
            activesNotFoundShowed,
            type
          );
    }
  );
  return portfolios;
};

export function transformGetPorfoliosCompleteAction(
  id: string,
  client: GetInfoClientResponseNetType
): (response: AxiosResponse<GetInitClientResponseNetType>, state: RootState) => AddClientPayload {
  return ({ data }: AxiosResponse<GetInitClientResponseNetType>, state: RootState) => {
    const oldPortfolios = state.portfolio.portfolios;
    return {
      id,
      portfolios: transformGetPortfolios(data.portfolios, oldPortfolios),
      ...client,
      imageUrl: client.imageUrl ?? '',
    };
  };
}

export function transformGetPorfoliosAction(): (
  response: AxiosResponse<GetInitClientResponseNetType>,
  state: RootState
) => PortfolioMap {
  return ({ data }: AxiosResponse<GetInitClientResponseNetType>, state: RootState) => {
    const oldPortfolios = state.portfolio.portfolios;
    return transformGetPortfolios(data.portfolios, oldPortfolios);
  };
}

export function transformGetPorfolios(): (
  response: AxiosResponse<GetInitClientResponseNetType>,
  state: RootState
) => Portfolio[] {
  return (response: AxiosResponse<GetInitClientResponseNetType>, _state: RootState) =>
    response.data.portfolios;
}

export function transformGetSummaryPorfolio(
  id: string
): (response: AxiosResponse<GetSummaryDashResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetSummaryDashResponseNetType>, state: RootState) => {
    let portfolio = { ...state.client.portfolios[id] };
    if (Object.keys(data).length > 0) {
      portfolio.importError = data.importError;
      portfolio.incomplete = data.incomplete;
      portfolio.empty = data.empty;

      if (data.firstTransactionDate) portfolio.firstTransactionDate = data.firstTransactionDate;
      if (data.riskFreeAssetBenchmark)
        portfolio.riskFreeAssetBenchmark = data.riskFreeAssetBenchmark;
      if (data.latestOperation) portfolio.latestOperation = data.latestOperation;

      portfolio.performanceType = data.method;

      const newReferenceIndices: ReferenceIndice[] = [];

      if (data.referenceIndices) {
        data.referenceIndices.forEach((i) => {
          const index = portfolio.referenceIndices.findIndex((k) => k.meta.id === i.id);
          newReferenceIndices.push({
            annualPerformances: { performance: {}, value: {} },
            accumulatedPerformances: {
              accumulatedPerformanceByPeriod: {},
              accumulatedValueByPeriod: {},
            },
            performanceTimeSeries: {},
            absoluteValueTimeSeries: {},
            annualizedPerformances: {},
            components: [],
            riskMetrics: {},
            meta: {
              ...i,
            },
            ...(index !== -1 ? portfolio.referenceIndices[index] : {}),
          });
        });
      }

      portfolio.referenceIndices = newReferenceIndices;

      if (data.gainsPerPeriod) {
        let newGainsPerPeriod: GainsPerPeriodMap = {};

        Object.keys(data.gainsPerPeriod).forEach((i) => {
          const periodObject = data.gainsPerPeriod[i];
          newGainsPerPeriod[i] = {
            gains: periodObject.map((item) => item.gains),
            invested: periodObject.map((item) => item.invested),
            totalValue: periodObject.map((item) => item.totalValue),
            axis: periodObject.map((item) => {
              if (i === 'lastYear') return moment(item.lastDayOfPeriod).format('MMM YY');
              if (i === 'lastThreeYears') {
                const quarter = moment(item.lastDayOfPeriod).quarter();
                return 'Q' + quarter + ' ' + moment(item.lastDayOfPeriod).format('YY') + "'";
              }
              if (i === 'lastSixYears') {
                const quarter = moment(item.lastDayOfPeriod).quarter();
                const semester = quarter === 1 || quarter === 2 ? 1 : 2;
                return 'S' + semester + ' ' + moment(item.lastDayOfPeriod).format('YY') + "'";
              }
              if (i === 'all') return moment(item.lastDayOfPeriod).format('YYYY');
              return '';
            }),
          };
        });
        portfolio.gainsPerPeriod = newGainsPerPeriod;
      }

      if (data.meta) portfolio.performanceMeta = data.meta;
      if (data.todaysGainers) portfolio.todaysGainers = data.todaysGainers;
      if (data.todaysLosers) portfolio.todaysLosers = data.todaysLosers;
      if (data.currencyPairPerformances)
        portfolio.currencyPairPerformances = data.currencyPairPerformances;
      if (data.upcomingEvents) portfolio.upcomingEvents = data.upcomingEvents;
    }
    let newStateDataTab = { ...portfolio.stateDataTab };

    portfolio.stateDataTab = {
      ...newStateDataTab,
      tabRefresh: {
        ...newStateDataTab.tabRefresh,
        refreshPerformance: true,
      },
      isLoaded: true,
      performance: { ...newStateDataTab.performance },
    };
    return portfolio;
  };
}

export function transformGetPorfolio(
  id: string,
  fullLoad: boolean,
  period: string
): (response: AxiosResponse<GetPorfolioResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPorfolioResponseNetType>, state: RootState) => {
    let portfolio = { ...state.client.portfolios[id] };
    if (Object.keys(data).length > 0) {
      let performanceMap: ChartPerformanceMap = { ...portfolio.performance };
      let absoluteValueMap: ChartPerformanceMap = { ...portfolio.absoluteValue };
      let performanceStatistics: PerformanceStatisticsMap = { ...portfolio.performanceStatistics };
      let yearlyPerformance = { ...portfolio.yearlyPerformance };
      let accumulatedPerformances = { ...portfolio.accumulatedPerformances };
      let annualizedPerformances = { ...portfolio.annualizedPerformances };

      let newPerformance: ChartPerformance = _chartEmptyPerformance();
      let newAbsoluteValue: ChartPerformance = _chartEmptyPerformance();

      if (period === '' || data.method !== portfolio.performanceType || fullLoad) {
        performanceMap = {};
        absoluteValueMap = {};
        performanceStatistics = {};
        portfolio.referenceIndices = [];
      }

      if (data.performanceStatistics) {
        performanceStatistics[period === '' ? '7D' : period] = data.performanceStatistics;
      }

      if (data.yearlyPerformance) {
        yearlyPerformance = { ...data.yearlyPerformance };
      }

      if (data.performance?.portfolio.accumulatedPerformances) {
        accumulatedPerformances = { ...data.performance.portfolio.accumulatedPerformances };
      }

      if (data.performance?.portfolio.annualizedPerformances) {
        annualizedPerformances = { ...data.performance?.portfolio.annualizedPerformances };
      }

      if (data.performance) {
        newPerformance = {
          period: period === '' ? '7D' : period,
          amount: data.performance?.portfolio.valueChange,
          percent: data.performance?.portfolio.performance * 100,
          annualizedPerformance: (data.performance?.portfolio?.annualizedPerformance ?? 0) * 100,
          timeSeries: data.performance?.portfolio.performanceTimeSeries.map((item) => ({
            value: item.value * 100,
            date: item.date,
          })),
          riskMetrics: data.performance?.portfolio?.riskMetrics,
          investedAmount: [],
          positionsPerformance: data.positionsPerformance,
        };
        portfolio.referenceIndices =
          data.performance?.referenceIndices.map((r) => {
            const index = portfolio.referenceIndices.findIndex((i) => i.meta.id === r.meta.id);
            const rTimeSeries: TimeSeriesMap = {
              ...(index !== -1 ? portfolio.referenceIndices[index].performanceTimeSeries : {}),
              [period === '' ? '7D' : period]: r.performanceTimeSeries.map((item) => ({
                value: item.value * 100,
                date: item.date,
              })),
            };
            return {
              ...portfolio.referenceIndices[index],
              ...r,
              absoluteValueTimeSeries:
                portfolio?.referenceIndices[index]?.absoluteValueTimeSeries ?? {},
              performanceTimeSeries: rTimeSeries,
            };
          }) ?? [];
      }

      if (data.absoluteValue) {
        newAbsoluteValue = {
          period: period === '' ? '7D' : period,
          amount: data.absoluteValue?.portfolio.valueChange,
          percent: data.absoluteValue?.portfolio.performance * 100,
          timeSeries: data.absoluteValue?.portfolio.absoluteValueTimeSeries.map((item) => ({
            value: item.value,
            date: item.date,
          })),
          investedAmount: data.absoluteValue.investedAmountPerPeriod,
          positionsPerformance: [],
        };

        portfolio.referenceIndices =
          data.absoluteValue?.referenceIndices.map((r) => {
            const index = portfolio.referenceIndices.findIndex((i) => i.meta.id === r.meta.id);
            const rTimeSeries: TimeSeriesMap = {
              ...(index !== -1 ? portfolio.referenceIndices[index].absoluteValueTimeSeries : {}),
              [period === '' ? '7D' : period]: r.absoluteValueTimeSeries.map((item) => ({
                value: item.value,
                date: item.date,
              })),
            };
            return {
              ...portfolio.referenceIndices[index],
              absoluteValueTimeSeries: rTimeSeries,
            };
          }) ?? [];
      }

      if (data.monthlyPerformance) portfolio.monthlyPerformance = data.monthlyPerformance;

      performanceMap[period === '' ? '7D' : period] = {
        ...newPerformance,
      };

      absoluteValueMap[period === '' ? '7D' : period] = {
        ...newAbsoluteValue,
      };

      portfolio.performance = performanceMap;
      portfolio.absoluteValue = absoluteValueMap;
      portfolio.performanceStatistics = performanceStatistics;
      portfolio.performanceType = data.method;
      portfolio.yearlyPerformance = yearlyPerformance;
      portfolio.accumulatedPerformances = accumulatedPerformances;
      portfolio.annualizedPerformances = annualizedPerformances;

      if (data.meta) portfolio.performanceMeta = data.meta;
    }
    return portfolio;
  };
}

export function transformRiskPortfolio(
  id: string,
  period: string = ''
): (
  response: AxiosResponse<GetRiskResponseNetType>,
  state: RootState
) => UpdatePortfolioRiskPayload {
  return ({ data }: AxiosResponse<GetRiskResponseNetType>, state: RootState) => {
    const portfolio: Portfolio = state.client.portfolios[id];
    let newRiskMetrics: RiskMetricsMap = { ...portfolio.riskMetrics };

    if (period === '') {
      newRiskMetrics = {};
    }

    newRiskMetrics[period] = {
      ...newRiskMetrics[period],
      ...data.portfolio.riskMetrics,
    };

    const newReferemceIndices = portfolio.referenceIndices.map((i) => {
      const index = data.referenceIndices.findIndex((k) => k.meta.id === i.meta.id);
      if (index !== -1) {
        let newIndRiskMetrics: RiskMetricsMap = { ...i.riskMetrics };
        if (period === '') {
          newIndRiskMetrics = {};
        }
        newIndRiskMetrics[period] = {
          ...i.riskMetrics[period],
          ...data.referenceIndices[index].riskMetrics,
        };
        return { ...i, riskMetrics: newIndRiskMetrics };
      }
      return i;
    });

    return {
      id,
      riskMetrics: newRiskMetrics,
      referenceIndices: newReferemceIndices,
    };
  };
}

export function transformGetPorfolioPositions(
  id: string
): (response: AxiosResponse<GetPorfolioPositionsResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPorfolioPositionsResponseNetType>, state: RootState) => {
    let portfolio = { ...state.client.portfolios[id] };
    if (data.positions) portfolio.positions = [...data.positions];
    if (data.historicalPositions) portfolio.historicalPositions = [...data.historicalPositions];
    return portfolio;
  };
}

export function transformGetPortfolioDividends(
  portfolioId: string,
  period: string
): (response: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => {
    let portfolio = { ...state.client.portfolios[portfolioId] };
    let newDividendsMap = { ...portfolio.dividends.incomeMap };
    let newDividendsByMonthAndYear = [...portfolio.dividends.incomeByMonthAndYear];

    if (period === '') {
      newDividendsMap = {};
      newDividendsByMonthAndYear = [];
    }

    if (data.incomeByMonthAndYear !== null)
      newDividendsByMonthAndYear = _.orderBy(data.incomeByMonthAndYear, ['year', 'month']);

    newDividendsMap[period === '' ? '1Y' : period] = [...data.incomeTimeSeries];

    return {
      ...portfolio,
      dividends: {
        incomeMap: newDividendsMap,
        incomeByMonthAndYear: newDividendsByMonthAndYear,
        total: data.totalIncome,
        ytd: data.ytdIncome,
      },
    };
  };
}

export function transformGetPortfolioBonds(
  portfolioId: string,
  period: string
): (response: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => Portfolio {
  return ({ data }: AxiosResponse<GetPortfolioDividendsResponseNetType>, state: RootState) => {
    let portfolio = { ...state.client.portfolios[portfolioId] };
    let newBondsMap = { ...portfolio.bonds.incomeMap };
    let newBondsByMonthAndYear = [...portfolio.bonds.incomeByMonthAndYear];

    if (period === '') {
      newBondsMap = {};
      newBondsByMonthAndYear = [];
    }

    if (data.incomeByMonthAndYear !== null)
      newBondsByMonthAndYear = _.orderBy(data.incomeByMonthAndYear, ['year', 'month']);

    newBondsMap[period === '' ? '1Y' : period] = [...data.incomeTimeSeries];

    return {
      ...portfolio,
      bonds: {
        incomeMap: newBondsMap,
        incomeByMonthAndYear: newBondsByMonthAndYear,
        total: data.totalIncome,
        ytd: data.ytdIncome,
      },
    };
  };
}

export function transformGetOperations(
  walletId: string,
  year: number,
  method: string
): (response: AxiosResponse<GetOperationsResponseNetType>, state: RootState) => OperationsPayload {
  return (response: AxiosResponse<GetOperationsResponseNetType>, state: RootState) => {
    const portfolio = state.client.portfolios[walletId];
    let newOperations = portfolio.operations;
    if (method === 'count') {
      newOperations = {
        ...newOperations,
        count: {
          ...newOperations.count,
          [year]: { buys: response.data.buys, sells: response.data.sells },
        },
      };
    } else {
      newOperations = {
        ...newOperations,
        vol: {
          ...newOperations.vol,
          [year]: { buys: response.data.buys, sells: response.data.sells },
        },
      };
    }
    return {
      id: walletId,
      operations: { ...newOperations },
    };
  };
}

export function transformGetMovements(
  id: string,
  isFirstTime: boolean,
  isCustom: boolean
): (response: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => Portfolio {
  return (response: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => {
    const portfolio: Portfolio = state.client.portfolios[id];
    return {
      ...portfolio,
      transactions: _.uniqBy([...response.data.transactions], 'id'),
      transactionsCount: {
        ...portfolio.transactionsCount,
        total: isFirstTime ? response.data.totalItems : portfolio.transactionsCount.total,
        custom: isCustom ? response.data.totalItems : 0,
      },
    };
  };
}

export function transformGetAllMovements(): (
  response: AxiosResponse<GetMovementsResponseNetType>,
  state: RootState
) => GetMovementsResponseNetType {
  return ({ data }: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => ({
    ...data,
  });
}

export function transformGetMovementsCount(
  id: string
): (
  response: AxiosResponse<GetMovementsCountResponseNetType>,
  state: RootState
) => TransactionCountPayload {
  return (response: AxiosResponse<GetMovementsCountResponseNetType>, state: RootState) => {
    const portfolio: Portfolio = state.client.portfolios[id];
    const { data } = response;
    return {
      id: portfolio.id,
      transactionsCount: {
        ...portfolio.transactionsCount,
        buys: data.buysCount,
        custom: 0,
        deposits: data.depositsCount,
        dividends: data.dividendsCount,
        fees: data.feesCount,
        interestCharge: data.interestChargeCount,
        notConfirmed: data.notConfirmedCount,
        removals: data.removalCount,
        sells: data.sellsCount,
        splits: data.splitsCount,
        tax_refund: data.taxRefundCount,
        taxesCount: data.taxesCount,
        transfersInCount: data.transfersInCount,
        transfersOutCount: data.transfersOutCount,
        interest: data.interestCount,
        taxes: data.taxesCount,
        taxRefund: data.taxRefundCount,
        gift: data.giftCount,
        isinChange: data.isinChangeCount,
        productChange: data.productChangeCount,
        feesRefund: data.feesRefundCount,
        rightsIssue: data.rightsIssueCount,
        rightsExclusion: data.rightsExclusionCount,
        dividendRetention: data.dividendRetentionCount,
        dividendRetentionRefund: data.dividendRetentionRefundCount,
        returnOnCapital: data.returnOnCapitalCount,
        capitalGains: data.capitalGainsCount,
        capitalGainsRetention: data.capitalGainsRetentionCount,
        capitalGainsRetentionRefund: data.capitalGainsRetentionRefundCount,
      },
    };
  };
}

export function transformGetInfoClient(): (
  response: AxiosResponse<GetInfoClientResponseNetType>,
  state: RootState
) => GetInfoClientResponseNetType {
  return (response: AxiosResponse<GetInfoClientResponseNetType>, state: RootState) => {
    const { data } = response;
    return {
      ...data,
    };
  };
}

export function transformUpdatePortfolioSettings(
  id: string,
  performanceType: PerformanceType
): (_response: AxiosResponse<any>, state: RootState) => PortfolioMap {
  return (_response: AxiosResponse<any>, state: RootState) => {
    let newPortfolios = { ...state.client.portfolios };
    let newPortfolio = { ...newPortfolios[id] };
    newPortfolio.performanceType = performanceType;
    newPortfolios[id] = newPortfolio;
    return newPortfolios;
  };
}

export function transformGetSkippedMovements(): (
  response: AxiosResponse<GetMovementsResponseNetType>,
  state: RootState
) => { transactions: Transaction[]; totalItems: number } {
  return (response: AxiosResponse<GetMovementsResponseNetType>, state: RootState) => ({
    transactions: _.uniqBy([...response.data.transactions], 'id'),
    totalItems: response.data.totalItems,
  });
}

export function transformGetImportErrors(): (
  _response: AxiosResponse<GetImportErrorsResponseNetType>,
  state: RootState
) => ImportError[] {
  return ({ data }: AxiosResponse<GetImportErrorsResponseNetType>, state: RootState) =>
    data.importErrors;
}

export function transformClientGetAllInfo(
  fullLoad: boolean = false,
  period: string = ''
): (response: AxiosResponse<GetAllInfoResponseNetType>, state: RootState) => UpdateAllPayload {
  return ({ data }: AxiosResponse<GetAllInfoResponseNetType>, state: RootState) => {
    let portfolios: PortfolioMap = { ...state.client.portfolios };
    let { agregatedSummary } = state.client;

    Object.keys(data.portfoliosSummary).forEach((key) => {
      let portfolio = { ...portfolios[key] };

      if (portfolio) {
        let performanceMap: ChartPerformanceMap = { ...portfolio.performance };
        let absoluteValueMap: ChartPerformanceMap = { ...portfolio.absoluteValue };

        let newPerformance: ChartPerformance = _chartEmptyPerformance();
        let newAbsoluteValue: ChartPerformance = _chartEmptyPerformance();

        if (period === '' || fullLoad) {
          performanceMap = {};
          absoluteValueMap = {};
        }

        const { performance, absoluteValue, positionsPerformance } = data.portfoliosSummary[key];

        if (performance) {
          newPerformance = {
            period: period === '' ? '7D' : period,
            amount: performance?.portfolio.valueChange,
            percent: performance?.portfolio.performance * 100,
            annualizedPerformance: (performance?.portfolio?.annualizedPerformance ?? 0) * 100,
            timeSeries: performance?.portfolio.performanceTimeSeries.map((item) => ({
              value: item.value * 100,
              date: item.date,
            })),
            riskMetrics: performance?.portfolio?.riskMetrics,
            investedAmount: [],
            positionsPerformance,
          };
        }

        if (absoluteValue) {
          newAbsoluteValue = {
            period: period === '' ? '7D' : period,
            amount: absoluteValue.portfolio.valueChange,
            percent: absoluteValue.portfolio.performance * 100,
            timeSeries: absoluteValue.portfolio.absoluteValueTimeSeries.map((item) => ({
              value: item.value,
              date: item.date,
            })),
            investedAmount: absoluteValue.investedAmountPerPeriod,
            positionsPerformance: [],
          };
        }

        performanceMap[period === '' ? '7D' : period] = {
          ...newPerformance,
        };

        absoluteValueMap[period === '' ? '7D' : period] = {
          ...newAbsoluteValue,
        };

        portfolio.performanceMeta = data.portfoliosSummary[key].meta;

        portfolios[key] = {
          ...portfolio,
          performance: performanceMap,
          absoluteValue: absoluteValueMap,
        };
      }
    });

    if (data.aggregatedSummary) agregatedSummary = data.aggregatedSummary;

    return { portfolios, agregatedSummary };
  };
}

export function transformGetNetWorthPeriod(
  fullLoad: boolean,
  period: string = ''
): (response: AxiosResponse<GetNetworthPeriodNetType>, state: RootState) => NetworthPeriodPayload {
  return ({ data }: AxiosResponse<GetNetworthPeriodNetType>, { client }: RootState) => {
    let newNetworthPeriod: NetworthPeriodPayload = {
      performance: fullLoad ? { '7D': [] } : { ...client.netWorth.performance },
      dailyGain: client.netWorth.dailyGain,
    };

    if (data.netWorthSnapshotsList) {
      newNetworthPeriod.performance[period === '' ? '7D' : period] = [
        ...data.netWorthSnapshotsList.map((item) => ({ value: item.value, date: item.date })),
      ];
    }

    if (data.dailyGain !== undefined && data.dailyGain !== null)
      newNetworthPeriod.dailyGain = data.dailyGain;

    return newNetworthPeriod;
  };
}

export function transformUpdateClientPortfolio(
  id: string,
  name: string
): (response: AxiosResponse<any>, state: RootState) => Portfolio {
  return (_: AxiosResponse<any>, { client }: RootState) => {
    let portfolio = client.portfolios[id];
    portfolio = { ...portfolio, name };
    return portfolio;
  };
}

export function transformGetClientsNotifications(): (
  response: AxiosResponse<GetClientsNotificationsNetType>,
  state: RootState
) => GetClientsNotificationPayload {
  return ({ data }: AxiosResponse<GetClientsNotificationsNetType>, state: RootState) => ({
    clientNotifications: data.clientNotificationsForEmployee,
    totalItems: data.totalItems,
    currentPage: data.currentPage,
  });
}

export function transformGetConnectionsNetworth(): (
  response: AxiosResponse<GetConnectionsNetworthType>,
  state: RootState
) => ConnectionType[] {
  return ({ data }: AxiosResponse<GetConnectionsNetworthType>, state: RootState) => {
    let newConnections = [...state.client.connections];
    data.netWorths.forEach((i) => {
      const index = newConnections.findIndex((k) => k.id === i.connectionDetails.id);
      if (index !== -1) {
        newConnections[index] = {
          ...newConnections[index],
          cashAccounts: i.cashAccounts,
          investments: i.investments,
          investmentsCash: i.investmentsCash,
        };
      }
    });

    return newConnections;
  };
}

export function transformUpdateAvConnection(
  id: string,
  components: UpdateAvConnectionBody[]
): (response: AxiosResponse<any>, state: RootState) => ConnectionType {
  return (_response: AxiosResponse<any>, state: RootState) => {
    const index = state.client.connections.findIndex((i) => i.id === id);
    let newAccounts = [...state.portfolio.connections[index].accounts];
    components.forEach((c) => {
      const ind = newAccounts.findIndex((i) => i.id === c.id);
      newAccounts[ind] = { ...newAccounts[ind], active: !c.inactive };
    });
    return { ...state.client.connections[index], accounts: newAccounts };
  };
}
