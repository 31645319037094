import { AxiosResponse } from 'axios';
import {
  AxiosInterceptorResponseErrorInputFunctionType,
  AxiosNetClientConfig,
} from 'src/services/http/axios';
import * as redux from 'src/services/http/redux';
import { Dispatch } from 'redux';

import { getTokenAuthHeader } from './Auth.Utils';
import { refreshTokenRequestAction } from '../auth';
import { RootState, persistor } from 'src/redux/store';
import { AuthRequestIds } from '../RequestIds';
import { logout } from 'src/redux/slices/AuthSlice/authSlice';
import SnackbarUtils from 'src/utils/SnackbarUtils';

export const retryWithRefreshToken: (
  getState: () => RootState,
  next: Dispatch
) => AxiosInterceptorResponseErrorInputFunctionType<RootState> =
  (getState: () => RootState, next: Dispatch) => (error, serviceClient) => {
    const config = error.config as AxiosNetClientConfig;
    return new Promise<AxiosResponse>(async (resolve, reject) => {
      if (error.response && error.response.status === 401) {
        if (AuthRequestIds.indexOf(config.reqId) !== -1) {
          const onRequestSuccess = async () => {
            const newConfig = {
              ...config,
              headers: {
                ...config.headers,
                ...getTokenAuthHeader(getState()),
              },
            };
            try {
              const f = await serviceClient.executeDirectCallWithConfig(newConfig);
              resolve(f);
            } catch (e) {
              reject(e);
            }
          };
          const onRequestError = () => {
            persistor.pause();
            persistor.flush().then(() => {
              persistor.purge().then(() => {
                next(logout());
              });
            });
            SnackbarUtils.warning('La sesión ha expirado. Por favor vuelva a iniciar sesión.');
            reject(error);
          };
          serviceClient.executeRequest(
            redux.adaptRequestFromReduxAction(
              getState,
              next,
              refreshTokenRequestAction(
                getState().auth.refreshToken ?? '',
                onRequestSuccess,
                onRequestError
              )
            )
          );
        } else {
          reject(error);
        }
      } else {
        reject(error);
      }
    });
  };
