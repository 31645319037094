import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'src/redux/store';
import { AxiosInterceptorResponseErrorInputFunctionType } from 'src/services/http/axios';
import { log } from 'src/utils/logger';

export const manageError: (
  _getState: () => RootState,
  next: ThunkDispatch<any, any, any>
) => AxiosInterceptorResponseErrorInputFunctionType<RootState> =
  (_: () => RootState, next: ThunkDispatch<any, any, any>) => (error, _serviceClient) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (
        error.response.status === 400 ||
        error.response.status === 404 ||
        error.response.status === 500
      ) {
        // TODO something
        log(error.response);
      } else if (error.response.status === 401 || error.response.status === 403) {
        // TODO something
        log(error.response);
      } else if (error.response.status > 500 && error.response.status < 600) {
        // TODO something
        log(error.response);
        // @ts-ignore
        // if (isBlockerRequestId(error.config.reqId)) {
        //   goToCriticalError(next)
        // }
      } else {
        log(error.response);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      if (error.message && error.message.includes('Network request failed')) {
        // showConnectivityAlert(serviceClient.next)
      }
      throw new Error('Error before response:' + JSON.stringify(error));
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error('Unmanaged error:' + JSON.stringify(error));
    }
    throw error;
  };
