import { AxiosResponse } from 'axios';

import {
  defaultUser,
  LoginPayload,
  RefreshTokenPayload,
  RegisterPayload,
  User,
} from 'src/redux/slices/AuthSlice/authSlice.d';
import { RootState } from 'src/redux/store';
import { getRefreshToken, getUserFromToken } from 'src/utils/jwt';
import { GetCompanySettingsResponse, LoginResponseNetType } from './NetTypes';

export function transformLoginResponse(): (
  response: AxiosResponse<LoginResponseNetType>,
  state: RootState
) => LoginPayload {
  return (response: AxiosResponse<LoginResponseNetType>, _state: RootState) => {
    const token = response.data.message;
    const user: User = getUserFromToken(token);
    const refreshToken: string = getRefreshToken(token);
    if (user.mfaEnabled) return { user: defaultUser, token: '', refreshToken: '', mfaToken: token };
    return { user, token, refreshToken, mfaToken: '' };
  };
}

export function transformRegisterResponse(
  name: string,
  firstSurname: string,
  email: string
): (response: AxiosResponse<LoginResponseNetType>, state: RootState) => RegisterPayload {
  return (response: AxiosResponse<LoginResponseNetType>, state: RootState) => {
    const id = response.data.message;
    const user: User = {
      name,
      firstSurname,
      email,
      id,
      imageUrl: '',
      isVerified: false,
      stripeCustomerId: '',
      baseCurrency: '',
      country: '',
      privileges: [],
      hideAccount: false,
      shareLink: '',
      latestPriceUpdate: '',
      reportsGenerated: 0,
      reportsLimit: 0,
      access: 'FULL_ACCESS',
    };
    return { user };
  };
}
export function transformRefreshResponse(): (
  response: AxiosResponse<LoginResponseNetType>,
  state: RootState
) => RefreshTokenPayload {
  return (response: AxiosResponse<LoginResponseNetType>, _state: RootState) => {
    const token = response.data.message;
    const refreshToken: string = getRefreshToken(token);
    return { token, refreshToken };
  };
}

export function transformLoginMFAResponse(): (
  response: AxiosResponse<LoginResponseNetType>,
  state: RootState
) => LoginPayload {
  return (response: AxiosResponse<LoginResponseNetType>, _state: RootState) => {
    const token = response.data.message;
    const user: User = getUserFromToken(token);
    const refreshToken: string = getRefreshToken(token);
    return { user, token, refreshToken, mfaToken: '' };
  };
}

export function transformGetCompanyThemeResponse(): (
  response: AxiosResponse<GetCompanySettingsResponse>,
  state: RootState
) => GetCompanySettingsResponse {
  return (response: AxiosResponse<GetCompanySettingsResponse>, _state: RootState) => response.data;
}
