import { useState } from 'react';
import { Stack } from '@mui/material';

// components
import LanguagePopoverItems from './LanguagePopoverItems';
import Image from 'src/components/Image';
import MenuPopover from 'src/components/MenuPopover';
import { IconButtonAnimate } from 'src/components/animate';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const isDesktop = useResponsive('up', 'lg');
  const { currentLang } = useLocales();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          ml: !isDesktop ? 0 : 2,
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <Image alt={currentLang.label} disabledEffect src={currentLang.icon} />
      </IconButtonAnimate>

      <MenuPopover
        anchorEl={open}
        onClose={handleClose}
        open={Boolean(open)}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <LanguagePopoverItems onClick={handleClose} />
        </Stack>
      </MenuPopover>
    </>
  );
}
