import { useTranslation } from 'react-i18next';
// @mui
import { enUS, esES } from '@mui/material/locale';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'spanish',
    value: 'es_ES',
    calendar: 'es',
    systemValue: esES,
    icon: 'https://flagcdn.com/w80/es.png',
  },
  {
    label: 'english',
    value: 'en',
    calendar: 'en',
    systemValue: enUS,
    icon: 'https://flagcdn.com/w80/gb.png',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  moment.locale(currentLang.value === 'es_ES' ? 'es_ES' : 'en');

  const handleChangeLanguage = (newlang: string) => {
    moment.locale(newlang === 'es_ES' ? 'es_ES' : 'en');
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
