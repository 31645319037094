import { useDispatch } from 'react-redux';
// mui
import { Box } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import useGetInfoUser from '../services/useGetUserInfo';
// components
import Iconify from 'src/components/Iconify';
import BasicTooltip from 'src/components/BasicTooltip';
import { IconButtonAnimate } from 'src/components/animate';
// redux action
import { updateHideAccount } from 'src/redux/slices/AuthSlice/authSlice';

export default function HideAccountButton() {
  const { hideAccount } = useGetInfoUser();

  const { translate } = useLocales();
  const isSm = useResponsive('up', 'sm');
  const dispatch = useDispatch();

  function onClick() {
    dispatch(updateHideAccount({ hideAccount: !hideAccount }));
  }

  return (
    <>
      <BasicTooltip title={translate('navbar.eye') as string}>
        <IconButtonAnimate onClick={onClick}>
          <Iconify icon={!hideAccount ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
        </IconButtonAnimate>
      </BasicTooltip>
      {!isSm && (
        <BasicTooltip title={translate('navbar.eye') as string}>
          <Box ml={2} onClick={onClick}>
            {translate('navbar.eye')}
          </Box>
        </BasicTooltip>
      )}
    </>
  );
}
