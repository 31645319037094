import { useEffect } from 'react';

import PermissionDenied from 'src/pages/PermissionDenied';
import { AdminGuardProps } from './AdminGuard.Types';

// ----------------------------------------------------------------------

export default function AdminGuard({ children, isAdmin }: AdminGuardProps) {
  useEffect(() => {}, []);

  if (!isAdmin) {
    return <PermissionDenied />;
  }

  return <>{children}</>;
}
