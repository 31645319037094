import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './AccountPopover.View';
import {
  AccountPopoverEventProps,
  AccountPopoverInputProps,
  AccountPopoverOwnProps,
} from './AccountPopover.Types';
import { persistor, RootState } from 'src/redux/store';
import { logout } from 'src/redux/slices/AuthSlice/authSlice';
import { getNetworkClient } from 'src/services/config';
import { updateUserWebSettingsRequest } from 'src/services/user';
import { ThemeMode, ThemeStretch } from 'src/components/settings/type';

const mapStateToProps = (
  state: RootState,
  _ownProps: AccountPopoverOwnProps
): AccountPopoverInputProps => ({
  user: state.auth.user,
});

const mapDispatchToProps: MapDispatchToPropsFunction<
  AccountPopoverEventProps,
  AccountPopoverOwnProps
> = (dispatch: ThunkDispatch<any, any, AnyAction>, _ownProps: AccountPopoverOwnProps) => ({
  logoutAction: (onSuccess: () => void) => {
    persistor.pause();
    persistor.flush().then(() => {
      persistor.purge().then(() => {
        dispatch(logout());
        onSuccess();
      });
    });
  },
  updateUserWebSettings: (themeMode: ThemeMode, themeStretch: ThemeStretch) => {
    getNetworkClient().executeRequest(updateUserWebSettingsRequest(themeMode, themeStretch));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
