import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// types
import { ClientsType } from 'src/redux/slices/BusinessSlice/businessSlice.d';
// components
import AutoCompleteBar from './AutocompleteBar/AutoCompleteBar';
import SelectedClientBar from './SelectedClientBar';
// hooks
import useGetClients from './services/useGetClients';
import useNavigateClient from './services/useNavigateClient';
import useGetClientInfo from '../../services/useGetClientInfo';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Stack, SxProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps;
};

export default function ClientSearchBar({ sx }: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedClient, setSelectClient] = useState<ClientsType>();

  const { clients, getClients, loading } = useGetClients();
  const { removeClient } = useNavigateClient();
  const client = useGetClientInfo();

  const navigate = useNavigate();

  useEffect(() => {
    setSelectClient(client && client.id !== '' ? client : undefined);
  }, [client.id]);

  const onSearch = useCallback((term: string) => {
    setSearchQuery(term);
    getClients(term && term !== '' ? term : '');
  }, []);

  const debouncedOnSearch = useMemo(
    () => debounce((term: string) => onSearch?.(term), 300),
    [onSearch]
  );

  useEffect(() => {
    debouncedOnSearch(searchQuery);
  }, [searchQuery]);

  const handleOnRemoveClient = () => {
    setSelectClient(undefined);
    removeClient();
    navigate(PATH_DASHBOARD.company.clients);
  };

  const handleOnSelectClient = (client: ClientsType | undefined) => {
    setSelectClient(client);
  };

  return (
    <Stack minWidth={260} sx={sx}>
      {selectedClient ? (
        <SelectedClientBar
          email={selectedClient.email}
          firstSurname={selectedClient.firstSurname}
          imageUrl={selectedClient.imageUrl}
          name={selectedClient.name}
          onRemoveClient={handleOnRemoveClient}
          secondSurname={selectedClient.secondSurname}
        />
      ) : (
        <AutoCompleteBar
          clients={clients}
          loading={loading}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setSelectedClient={handleOnSelectClient}
        />
      )}
    </Stack>
  );
}
