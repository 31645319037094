import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// hooks
import { getNetworkClient } from 'src/services/config';
// services
import { getClientsRequest } from 'src/services/companies';
// types
import { ClientsType } from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { RootState } from 'src/redux/store';

export default function useGetClients() {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<ClientsType[]>([]);

  const { id } = useSelector((state: RootState) => get(state, ['business', 'company']));

  useEffect(() => {
    if (id) getClients('');
  }, [id]);

  const onSuccess = (clients: ClientsType[]) => {
    setClients([...clients]);
    setLoading(false);
  };

  const onFailure = () => {
    setLoading(false);
  };

  const getClients = (name: string) => {
    setLoading(true);
    getNetworkClient().executeRequest(
      getClientsRequest(
        id,
        name,
        '',
        '',
        0,
        100,
        (response) => onSuccess(response.clients),
        onFailure
      )
    );
  };

  return {
    clients,
    getClients,
    loading,
  };
}
