import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './AdminGuard.View';
import { AdminGuardInputProps, AdminGuardOwnProps, AdminGuardEventProps } from './AdminGuard.Types';
import { RootState } from 'src/redux/store';

const mapStateToProps = (
  state: RootState,
  _ownProps: AdminGuardOwnProps
): AdminGuardInputProps => ({
  isAdmin: state.auth.user.privileges.findIndex((i) => i === 'ADMIN_POWER') !== -1,
});

const mapDispatchToProps: MapDispatchToPropsFunction<AdminGuardEventProps, AdminGuardOwnProps> = (
  _dispatch: ThunkDispatch<any, any, AnyAction>,
  _ownProps: AdminGuardOwnProps
) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(View);
