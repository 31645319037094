// @mui
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

// components

// ----------------------------------------------------------------------

type Props = {
  onCancel: VoidFunction;
  onSave: (email: string) => void;
  isLoading?: boolean;
};

export default function SendBetaEmailModal({ onCancel, onSave, isLoading }: Props) {
  const [email, setEmail] = useState('');
  const [isError, setError] = useState(false);

  const validateEmail = (email: string) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const onPressSave = () => {
    if (!validateEmail(email)) setError(true);
    else {
      setError(false);
      onSave(email);
      setEmail('');
    }
  };

  return (
    <>
      <Stack sx={{ pt: 3, px: 3 }}>
        <Typography alignSelf="center" textAlign={'center'} variant="h6">
          {'Únete a la lista de espera'}
        </Typography>
        <Typography
          alignSelf="center"
          color="text.secondary"
          sx={{ mt: 1, mb: 2 }}
          textAlign={'center'}
          variant="subtitle2"
        >
          {'Pronto recibirás un correo con la invitación a nuestra plataforma'}
        </Typography>

        <Stack sx={{ px: 3, pb: 0.5 }}>
          <TextField
            error={isError}
            helperText={isError ? 'Debe tener un formato de un correo electrónico' : ''}
            id="outlined-basic"
            label="Escribe tu correo"
            onChange={(event) => setEmail(event.target.value)}
            placeholder={'Añade tu correo'}
            type={'email'}
            value={email}
            variant="outlined"
          />
        </Stack>
      </Stack>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button color="inherit" onClick={onCancel} sx={{ width: '42%' }} variant="outlined">
          {'Cancelar'}
        </Button>

        <LoadingButton
          disabled={email === ''}
          loading={isLoading}
          onClick={onPressSave}
          sx={{ width: '42%' }}
          type="submit"
          variant="contained"
        >
          {'Enviar'}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
