import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function MaintenanceIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        height="100%"
        viewBox="0 0 355 260"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g opacity="0.2">
          <path
            clipRule="evenodd"
            d="M0 143.564C0 173.505 11.0534 200.789 29.2424 221.409C50.1093 245.072 80.3685 259.95 114.098 260C128.838 260.021 142.933 257.193 155.88 252.029C162.574 249.358 170.067 249.665 176.551 252.829C184.156 256.536 192.672 258.615 201.665 258.615C205.379 258.615 209.017 258.257 212.536 257.579C222.581 255.651 231.721 251.087 239.227 244.623C243.945 240.558 249.987 238.516 256.162 238.523H256.267C276.671 238.523 295.624 232.216 311.36 221.409C325.428 211.76 336.916 198.517 344.57 182.969C351.243 169.42 355 154.114 355 137.914C355 82.353 310.792 37.3052 256.267 37.3052C250.708 37.3052 245.262 37.7836 239.95 38.6763C225.882 15.47 200.69 0 171.952 0C159.819 0 148.316 2.75722 138.02 7.69978C128.228 12.3849 119.522 19.0423 112.401 27.1555C88.2185 27.5484 65.8584 35.5983 47.5574 49.0327C18.7495 70.1677 0 104.637 0 143.564Z"
            fill="url(#paint0_linear_1989_108298)"
            fillRule="evenodd"
          />
        </g>
        <path
          d="M243.185 71.7134L105.671 67.8926C100.974 67.7624 97.1465 70.8962 97.1465 74.8935V190.364C97.1465 194.361 100.974 197.4 105.671 197.153L243.185 189.91C247.09 189.705 250.24 186.605 250.24 182.988V78.4606C250.24 74.8408 247.09 71.8203 243.185 71.7134Z"
          fill="#FF9634"
          opacity="0.12"
        />
        <path
          d="M243.196 67.4591L109.85 63.6475C105.296 63.5177 101.584 66.6439 101.584 70.6316V185.823C101.584 189.81 105.296 192.841 109.85 192.595L243.196 185.371C246.983 185.165 250.038 182.074 250.038 178.464V74.1895C250.038 70.5791 246.983 67.5657 243.196 67.4591Z"
          fill="white"
        />
        <path
          d="M174.898 147.093C169.508 147.19 164.178 145.979 159.385 143.572C154.272 141.005 149.976 137.121 146.965 132.343C144.078 127.744 142.548 122.457 142.543 117.065C142.543 100.194 157.241 86.8418 174.898 87.2903C192.118 87.7279 205.816 101.192 205.816 117.312C205.816 133.434 192.118 146.791 174.898 147.093Z"
          fill="#DFE3E8"
          opacity="0.48"
        />
        <path
          d="M161.339 98.7152C161.359 99.0857 161.219 99.4468 160.95 99.7104L159.576 101.052L172.066 113.253C172.693 113.866 172.693 114.86 172.066 115.473C171.433 116.091 170.417 116.084 169.792 115.473L157.303 103.273L155.929 104.616C155.679 104.86 155.339 104.996 154.986 104.996C154.962 104.996 154.935 104.996 154.91 104.995C154.531 104.973 154.179 104.794 153.943 104.502L148.088 97.5582C147.669 97.0403 147.713 96.2986 148.192 95.8306L151.957 92.1523C152.436 91.6843 153.195 91.6417 153.726 92.0512L160.835 97.7691C161.134 98.0002 161.316 98.3447 161.339 98.7152Z"
          fill="#C4CDD5"
        />
        <path
          d="M178.297 119.34L182.321 123.271C182.948 123.884 182.948 124.879 182.321 125.492C181.688 126.11 180.67 126.099 180.048 125.492L176.024 121.561C175.396 120.947 175.396 119.954 176.024 119.34C176.652 118.727 177.669 118.726 178.297 119.34Z"
          fill="#C4CDD5"
        />
        <path
          d="M178.299 119.34L182.323 123.271C182.95 123.884 182.95 124.879 182.323 125.492L176.025 119.34C176.653 118.727 177.671 118.726 178.299 119.34Z"
          fill="#F4F6F8"
        />
        <path
          d="M161.34 98.7152C161.361 99.0857 161.22 99.4468 160.952 99.7104L159.578 101.052L172.067 113.253C172.695 113.866 172.694 114.86 172.067 115.473L150.076 93.9911L151.958 92.1523C152.437 91.6843 153.196 91.6417 153.727 92.0512L160.836 97.7691C161.135 98.0002 161.318 98.3447 161.34 98.7152Z"
          fill="#919EAB"
        />
        <path
          d="M199.639 140.675L197.864 142.408C195.331 144.886 191.174 144.802 188.753 142.178L176.502 128.835C175.933 128.213 175.959 127.266 176.562 126.676L183.447 119.95C184.049 119.364 185.013 119.334 185.649 119.884L199.37 131.745L199.373 131.748C202.07 134.09 202.192 138.18 199.639 140.675Z"
          fill="#FF9634"
        />
        <path
          d="M199.639 140.675L198.752 141.541L180.049 123.27L183.447 119.95C184.049 119.364 185.013 119.334 185.649 119.884L199.37 131.745L199.373 131.748C202.069 134.09 202.193 138.18 199.639 140.675Z"
          fill="#D56701"
          opacity="0.24"
        />
        <path
          d="M201.213 100.217C200.967 99.074 199.521 98.653 198.677 99.4792L193.413 104.621C192.457 105.555 190.907 105.555 189.951 104.621L188.541 103.244L187.131 101.866C186.175 100.932 186.175 99.4185 187.131 98.4861L192.395 93.3424C193.24 92.5177 192.81 91.105 191.64 90.8652C187.68 90.052 183.389 91.1483 180.313 94.1528C176.396 97.9798 175.647 103.729 178.067 108.289L148.239 132.225C146.229 133.767 146.051 136.677 147.858 138.443L150.183 140.715L152.509 142.987C154.316 144.752 157.295 144.578 158.874 142.614L183.377 113.476C188.045 115.839 193.929 115.108 197.848 111.282C200.923 108.277 202.045 104.086 201.213 100.217ZM155.848 136.752C155.404 136.752 155.003 136.575 154.711 136.292C154.421 136.007 154.24 135.615 154.24 135.181C154.24 134.313 154.961 133.611 155.848 133.611C156.293 133.611 156.693 133.787 156.985 134.07C157.275 134.355 157.455 134.747 157.455 135.181C157.455 136.048 156.736 136.752 155.848 136.752Z"
          fill="#FF9634"
        />
        <path
          d="M197.848 111.282C193.93 115.109 188.046 115.84 183.378 113.476L158.875 142.615C157.296 144.578 154.317 144.752 152.51 142.987L150.184 140.716L154.711 136.292C155.003 136.576 155.405 136.752 155.849 136.752C156.737 136.752 157.456 136.049 157.456 135.181C157.456 134.747 157.276 134.356 156.986 134.071L188.542 103.245L189.952 104.622C190.908 105.556 192.457 105.556 193.414 104.622L198.677 99.4798C199.522 98.6535 200.968 99.0746 201.213 100.217C202.045 104.086 200.923 108.278 197.848 111.282Z"
          fill="#D56701"
          opacity="0.24"
        />
        <path
          d="M220.142 169.876C220.127 171.589 218.739 172.991 216.984 173.066L137.944 176.008C135.983 176.085 134.389 174.678 134.389 172.873V160.617C134.389 158.812 135.983 157.31 137.944 157.263L216.984 155.356C217.814 155.33 218.62 155.635 219.216 156.2C219.812 156.765 220.146 157.541 220.142 158.352V169.876Z"
          fill="#DFE3E8"
          opacity="0.72"
        />
        <path
          d="M207.867 209.706V212.094C207.867 212.706 207.544 213.21 207.119 213.265L168.904 216.642C168.621 216.68 168.334 216.674 168.054 216.626C167.98 216.613 167.907 216.597 167.833 216.58L141.206 211.687C140.923 211.614 140.715 211.253 140.715 210.837V208.313L168.965 204.899L172.602 203.221L176.218 203.728L178.353 203.33L183.233 204.714L207.867 209.706Z"
          fill="url(#paint1_linear_1989_108298)"
        />
        <path
          d="M207.867 209.706V212.094C207.867 212.705 207.544 213.21 207.118 213.265L168.906 216.642C168.623 216.68 168.337 216.674 168.057 216.625V203.76L172.604 203.219L176.219 203.726L178.354 203.328L183.234 204.712L207.867 209.706Z"
          fill="url(#paint2_linear_1989_108298)"
          opacity="0.3"
        />
        <path
          d="M178.355 203.327L140.717 208.195L167.865 213.181C168.194 213.264 168.537 213.284 168.875 213.239L207.866 209.706L178.355 203.327Z"
          fill="url(#paint3_linear_1989_108298)"
        />
        <path
          d="M192.152 207.728C192.152 209.476 186.511 210.835 178.723 211.188C176.758 211.275 174.656 211.3 172.476 211.251C161.759 211.011 153.147 209.066 153.147 206.914C153.145 206.862 153.15 206.81 153.162 206.76H153.147L153.191 206.656C153.202 206.622 153.215 206.588 153.231 206.556L155.703 200.255L158.689 192.653L162.869 181.998L166.068 173.842L170.047 163.701C170.545 162.431 171.469 161.642 172.465 161.642C172.6 161.641 172.734 161.655 172.866 161.684C173.707 161.863 174.458 162.603 174.89 163.703L178.904 173.915L182.111 182.178L186.338 193.048L189.375 200.857L189.423 200.861L191.952 207.207C192.061 207.38 192.152 207.553 192.152 207.728Z"
          fill="url(#paint4_linear_1989_108298)"
        />
        <path
          d="M189.422 200.864C186.982 201.659 183.083 202.232 178.408 202.444C176.525 202.531 174.534 202.559 172.464 202.522C165.263 202.389 159.019 201.486 155.697 200.256L158.684 192.653C162.181 193.42 167.054 193.937 172.473 194.017C174.225 194.043 175.932 194.021 177.557 193.957C180.976 193.825 183.992 193.505 186.368 193.051L189.379 200.859L189.422 200.864Z"
          fill="white"
        />
        <path
          d="M182.146 182.178C180.283 182.374 178.236 182.508 176.063 182.568C174.9 182.601 173.701 182.613 172.477 182.601C169.265 182.581 166.057 182.379 162.869 181.998L166.069 173.842C168.071 173.996 170.227 174.084 172.477 174.097C173.269 174.101 174.052 174.096 174.823 174.081C176.233 174.055 177.597 174 178.905 173.915L182.146 182.178Z"
          fill="white"
        />
        <path
          d="M178.723 211.186C176.758 211.274 174.656 211.3 172.476 211.25C161.759 211.009 153.147 209.066 153.147 206.913C153.145 206.861 153.15 206.809 153.162 206.759H153.147L153.191 206.656C153.202 206.621 153.215 206.587 153.231 206.555L155.703 200.255L158.689 192.652L162.869 181.998L166.068 173.842L170.047 163.701C170.545 162.431 171.469 161.642 172.465 161.642C172.6 161.641 172.734 161.655 172.866 161.684C173.29 164.266 174.018 168.771 174.823 174.081C175.227 176.755 175.65 179.633 176.063 182.57C176.597 186.366 177.115 190.266 177.554 193.957C177.913 196.981 178.219 199.868 178.436 202.446C178.753 206.178 178.882 209.264 178.723 211.186Z"
          fill="url(#paint5_linear_1989_108298)"
          opacity="0.2"
        />
        <path
          d="M110.538 74.5203C111.56 74.5203 112.387 73.7119 112.387 72.7147C112.387 71.7176 111.56 70.9092 110.538 70.9092C109.517 70.9092 108.689 71.7176 108.689 72.7147C108.689 73.7119 109.517 74.5203 110.538 74.5203Z"
          fill="#FF4842"
        />
        <path
          d="M116.454 74.5203C117.476 74.5203 118.303 73.7119 118.303 72.7147C118.303 71.7176 117.476 70.9092 116.454 70.9092C115.433 70.9092 114.605 71.7176 114.605 72.7147C114.605 73.7119 115.433 74.5203 116.454 74.5203Z"
          fill="#FFC107"
        />
        <path
          d="M122.372 74.5203C123.394 74.5203 124.221 73.7119 124.221 72.7147C124.221 71.7176 123.394 70.9092 122.372 70.9092C121.351 70.9092 120.523 71.7176 120.523 72.7147C120.523 73.7119 121.351 74.5203 122.372 74.5203Z"
          fill="#54D62C"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_1989_108298"
            x1="243.182"
            x2="368.678"
            y1="306.245"
            y2="16.6019"
          >
            <stop stopColor="#FF9634" />
            <stop offset="1" stopColor="#FF9634" stopOpacity="0.12" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_1989_108298"
            x1="140.717"
            x2="207.869"
            y1="209.943"
            y2="209.943"
          >
            <stop stopColor="#FFC444" />
            <stop offset="1" stopColor="#F36F56" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint2_linear_1989_108298"
            x1="190.87"
            x2="190.332"
            y1="209.804"
            y2="211.32"
          >
            <stop stopColor="#FFC444" />
            <stop offset="0.59" stopColor="#F8924F" />
            <stop offset="1" stopColor="#F36F56" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint3_linear_1989_108298"
            x1="179.144"
            x2="178.928"
            y1="208.288"
            y2="208.897"
          >
            <stop stopColor="#FFC444" />
            <stop offset="0.59" stopColor="#F8924F" />
            <stop offset="1" stopColor="#F36F56" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint4_linear_1989_108298"
            x1="173.303"
            x2="170.975"
            y1="147.386"
            y2="224.915"
          >
            <stop stopColor="#FFC444" />
            <stop offset="1" stopColor="#F36F56" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint5_linear_1989_108298"
            x1="153.147"
            x2="178.791"
            y1="186.458"
            y2="186.458"
          >
            <stop stopColor="#FFC444" />
            <stop offset="1" stopColor="#F36F56" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
