import { RootState } from 'src/redux/store';
import { LoginBodyNetType, RefreshBodyNetType, RegisterBodyNetType } from './NetTypes';

export function setLoginBody(
  email: string,
  password: string
): (state: RootState) => LoginBodyNetType {
  return (_state: RootState) => ({
    email,
    password,
  });
}

export function setRegisterBody(
  name: string,
  firstSurname: string,
  email: string,
  password: string,
  preferLanguage: string
): (state: RootState) => RegisterBodyNetType {
  return (_state: RootState) => ({
    name,
    firstSurname,
    email,
    password,
    preferLanguage,
  });
}

export function setRefreshBody(refreshToken: string): (state: RootState) => RefreshBodyNetType {
  return (_state: RootState) => ({
    refreshToken,
  });
}
