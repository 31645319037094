// mui
import { Badge } from '@mui/material';
// components
import BasicTooltip from 'src/components/BasicTooltip';
import Iconify from 'src/components/Iconify';
import { IconButtonAnimate } from 'src/components/animate';
// hooks
import useGetPortfoliosInfo from '../../services/useGetPortfoliosInfo';

// ----------------------------------------------------------------------

type Props = {
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
};

export default function ConnectionsButton({ handleOpen }: Props) {
  const { connections } = useGetPortfoliosInfo();

  const unReadyConnections = connections.filter((i) => i.pending && !i.errored);
  const readyConnections = connections.filter((i) => !i.pending && !i.displayed);
  const total = unReadyConnections.length + readyConnections.length;

  return (
    <BasicTooltip
      title={
        unReadyConnections.length > 0
          ? 'Tus conexiones se están importando'
          : connections.length > 0
          ? 'Tienes conexiones listas'
          : 'Aquí podrás ver las conexiones e importaciones de carteras que realices'
      }
    >
      <IconButtonAnimate
        color={unReadyConnections.length > 0 ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={total} color="primary">
          <Iconify height={24} icon="raphael:connect" width={24} />
        </Badge>
      </IconButtonAnimate>
    </BasicTooltip>
  );
}
