import { List, ListSubheader } from '@mui/material';
// types
import { ConnectionType } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
// components
import ConnectionItem from '../ConnectionItem/ConnectionItem';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  connections: ConnectionType[];
  onClose: () => void;
};

export default function MenuList({ title, connections, onClose }: Props) {
  return (
    <List
      disablePadding
      subheader={
        <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          {title}
        </ListSubheader>
      }
    >
      {connections.map((connection: ConnectionType) => (
        <ConnectionItem connection={connection} key={connection.name} onClose={onClose} />
      ))}
    </List>
  );
}
