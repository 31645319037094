export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_FAILURE = 'GET_USER_SETTINGS_FAILURE';

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';

export const UPDATE_USER_LANGUAGE = 'UPDATE_USER_LANGUAGE';
export const UPDATE_USER_LANGUAGE_SUCCESS = 'UPDATE_USER_LANGUAGE_SUCCESS';
export const UPDATE_USER_LANGUAGE_FAILURE = 'UPDATE_USER_LANGUAGE_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const SAVE_BETA_EMAIL = 'SAVE_BETA_EMAIL';
export const SAVE_BETA_EMAIL_FILE = 'SAVE_BETA_EMAIL_FILE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_MFA_QR_CODE = 'GET_MFA_QR_CODE';

export const GET_USER_LOGIN_HISTORIC = 'GET_MFA_QR_CODE';

export const SETUP_MFA = 'SETUP_MFA';
export const SETUP_MFA_SUCCESS = 'SETUP_MFA_SUCCESS';
export const SETUP_MFA_FAILURE = 'SETUP_MFA_FAILURE';

export const UPDATE_USER_NEEDSTOUR = 'UPDATE_USER_NEEDSTOUR';
export const UPDATE_USER_NEEDSTOUR_SUCCESS = 'UPDATE_USER_NEEDSTOUR_SUCCESS';
export const UPDATE_USER_NEEDSTOUR_FAILURE = 'UPDATE_USER_NEEDSTOUR_FAILURE';

export const UPDATE_USER_WEB_SETTINGS = 'UPDATE_USER_WEB_SETTINGS';

export const GET_EMAIL_WAITING_LIST = 'GET_EMAIL_WAITING_LIST';

export const CREATE_USER_SETTINGS = 'CREATE_USER_SETTINGS';
