import { useEffect } from 'react';
// hooks
import useGetInfoCompany from 'src/hooks/useGetInfoCompany';
// redux
import { getCompanySettingsCompany } from 'src/redux/slices/BusinessSlice/businessSlice';
import { updateThemeColorApp } from 'src/redux/slices/SettingsSlice/settingsSlice';
import { ThemeColorsApp } from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import { dispatch } from 'src/redux/store';
// services
import { getCompanyThemeSettingsAction } from 'src/services/auth';
import { GetCompanySettingsResponse } from 'src/services/auth/NetTypes';
import { getNetworkClient } from 'src/services/config';
// uitils
import { getProfilesUrl } from 'src/utils/formatUrl';
import { defaultThemeColorApp } from 'src/utils/getColorPresets';

type Props = {
  company?: string;
};

export default function useGetCompanyThemeSettings({ company }: Props) {
  const { id } = useGetInfoCompany();

  useEffect(() => {
    const companyId = company && company !== '' ? company : id;
    if (companyId && companyId !== '') getCompanySettings(companyId);
  }, [company, id]);

  const getCompanySettings = (companyId: string) => {
    getNetworkClient().executeRequest(
      getCompanyThemeSettingsAction(
        companyId,
        ({ id, imageUrl, logoUrl, name, webSettings }: GetCompanySettingsResponse) => {
          const finalImageUrl = imageUrl ? getProfilesUrl(imageUrl) : undefined;
          const finalLogoUrl = logoUrl ? getProfilesUrl(logoUrl) : undefined;
          const themeColorApp: ThemeColorsApp = JSON.parse(webSettings) ?? defaultThemeColorApp;

          dispatch(
            getCompanySettingsCompany({
              imageUrl: finalImageUrl,
              id,
              name,
              logoUrl: finalLogoUrl,
              themeCompany: themeColorApp,
            })
          );
          dispatch(updateThemeColorApp(themeColorApp));
        }
      )
    );
  };
}
