import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from 'src/components/Loadable';
import AdminGuard from 'src/guards/AdminGuard/AdminGuard';
import AuthGuard from 'src/guards/AuthGuard/AuthGuard';
import BusinessGuard from 'src/guards/BusinessGuard/BusinessGuard';
import DashboardLayout from 'src/layouts/dashboard/DashboardLayout';
import SocketWrapper from '../SocketWrapper/SocketWrapper';
import { PATH_DASHBOARD } from '../paths';

// DASHBOARD

// GENERAL

const GeneralWallets = Loadable(lazy(() => import('src/pages/dashboard/Wallet/Wallet')));
const MyInvestments = Loadable(
  lazy(() => import('src/pages/dashboard/MyInvestments/MyInvestmentsView'))
);
const Summary = Loadable(lazy(() => import('src/pages/dashboard/Summary/Summary')));
const CurrentAccounts = Loadable(
  lazy(() => import('src/pages/dashboard/CurrentAccounts/CurrentAccounts'))
);

// REPORTING
const Reporting = Loadable(lazy(() => import('src/pages/dashboard/Reporting/Reporting')));
const NewReport = Loadable(lazy(() => import('src/pages/dashboard/Reporting/NewReport/NewReport')));
const NewTemplate = Loadable(
  lazy(() => import('src/pages/dashboard/Reporting/NewTemplate/NewTemplate'))
);

// WALLETS
const WalletsConnection = Loadable(
  lazy(() => import('src/pages/dashboard/WalletsConnection/WalletsConnection'))
);
const NewConnection = Loadable(
  lazy(() => import('src/pages/dashboard/NewConnection/NewConnection'))
);
const AddManualMovement = Loadable(
  lazy(() => import('src/pages/dashboard/AddManualMovement/AddManualMovement'))
);
const WalletsImportDocument = Loadable(
  lazy(() => import('src/pages/dashboard/WalletsImportFromDocument/WalletsImportFromDocument'))
);
const WalletsNewWallet = Loadable(lazy(() => import('src/pages/dashboard/WalletsNewWallet')));

const MyConnections = Loadable(
  lazy(() => import('src/pages/dashboard/MyConnections/MyConnectionsView'))
);

const UpdateConnection = Loadable(
  lazy(() => import('src/pages/dashboard/UpdateConnection/UpdateConnection'))
);

const UpdateCredentialsConnection = Loadable(
  lazy(() => import('src/pages/dashboard/UpdateCredentialsConnection/UpdateCredentialsConnection'))
);

// ALTERNATIVE INVESTMENT PAGE
const AltInvest = Loadable(lazy(() => import('src/pages/dashboard/AltInvest/AltInvest')));
const AltInvestGlobal = Loadable(
  lazy(() => import('src/pages/dashboard/AltInvestGlobal/AltInvestGlobal'))
);

// Add Alternative investment
const Alternative = Loadable(
  lazy(() => import('src/pages/dashboard/AddAssets/Alternative/Alternative'))
);
const AddCashAccount = Loadable(
  lazy(() => import('src/pages/dashboard/AddAssets/CashAccount/CashAccount'))
);
const AddAltInvestWallet = Loadable(
  lazy(() => import('src/pages/dashboard/AddAssets/Alternative/Wallet/Wallet'))
);
const AltInvestType = Loadable(
  lazy(() => import('src/pages/dashboard/AddAssets/Alternative/Type/Type'))
);
const AddAltInvestTransaction = Loadable(
  lazy(() => import('src/pages/dashboard/AddAssets/Alternative/Details/AltInvestTransaction'))
);

// financial INVESTMENT
const Financial = Loadable(lazy(() => import('src/pages/dashboard/AddAssets/Financial/Financial')));
const AddWallet = Loadable(lazy(() => import('src/pages/dashboard/AddAssets/Financial/Wallet')));

// USER
const UserAccount = Loadable(lazy(() => import('src/pages/dashboard/UserAccount/UserAccount')));
const Pricing = Loadable(lazy(() => import('src/pages/dashboard/Pricing/Pricing')));

// FEEDBACK
const Feedback = Loadable(lazy(() => import('src/pages/dashboard/Feedback/Feedback')));

// CALENDAR
const Calendar = Loadable(lazy(() => import('src/pages/dashboard/Calendar/Calendar')));

// ADMIN
const Admin = Loadable(lazy(() => import('src/pages/dashboard/Admin/Admin')));

// COMPANY
const Company = Loadable(lazy(() => import('src/pages/dashboard/Company/Company')));
const CompanyClients = Loadable(
  lazy(() => import('src/pages/dashboard/CompanyClients/CompanyClients'))
);
const CompanyNewEmployee = Loadable(
  lazy(() => import('src/pages/dashboard/CompanyNewEmployee/CompanyNewEmployee'))
);
const CompanyNewOffice = Loadable(
  lazy(() => import('src/pages/dashboard/CompanyNewOffice/CompanyNewOffice'))
);
const CompanyNewClient = Loadable(
  lazy(() => import('src/pages/dashboard/CompanyNewClient/CompanyNewClient'))
);
const CompanyEditOffice = Loadable(
  lazy(() => import('src/pages/dashboard/CompanyNewOffice/CompanyNewOffice'))
);
const CompanyEditEmployee = Loadable(
  lazy(() => import('src/pages/dashboard/CompanyEditEmployee/CompanyEditEmployee'))
);

// WALLETS MANAGMENT
const WalletsClientManagment = Loadable(
  lazy(() => import('src/pages/dashboard/WalletsClientManagment/WalletsClientManagmentView'))
);

// CLIENT
const ClientWallet = Loadable(lazy(() => import('src/pages/dashboard/ClientWallet/ClientWallet')));
const ClientSummary = Loadable(
  lazy(() => import('src/pages/dashboard/ClientSummary/ClientSummary'))
);
const MyInvestmentsClientView = Loadable(
  lazy(() => import('src/pages/dashboard/MyInvestments/Client/MyInvestmentsClientView'))
);
const ClientCalendar = Loadable(
  lazy(() => import('src/pages/dashboard/ClientCalendar/ClientCalendar'))
);
const CompanyInfoClient = Loadable(
  lazy(() => import('src/pages/dashboard/CompanyNewClient/CompanyNewClient'))
);
const ReportingClient = Loadable(
  lazy(() => import('src/pages/dashboard/ClientReporting2/ClientReporting'))
);
const ClientConnections = Loadable(
  lazy(() => import('src/pages/dashboard/ClientConnections/ClientConnectionsView'))
);

// SECURITY
const SecurityInfo = Loadable(
  lazy(() => import('src/pages/dashboard/SecurityInformation/SecurityInformation'))
);

// Client notifications
const ClientsNotifications = Loadable(
  lazy(() => import('src/pages/dashboard/ClientsNotifications/ClientsNotificationsView'))
);

// Add Assets
const AddAssets = Loadable(lazy(() => import('src/pages/dashboard/AddAssets/AddAssets')));

// Wealth
const MyWealth = Loadable(lazy(() => import('src/pages/dashboard/MyWealth/MyWealth')));
const ClientWealth = Loadable(lazy(() => import('src/pages/dashboard/MyWealth/ClientWealth')));

const rootRoute = {
  element: <Navigate replace to={PATH_DASHBOARD.general.summary} />,
  index: true,
};

const summaryRoute = {
  path: 'summary',
  element: <Summary />,
};

const myInvestmentsRoute = {
  path: 'my-investments',
  element: <MyInvestments />,
};

const walletsRoute = {
  path: 'wallets',
  children: [
    { element: <Navigate replace to="/dashboard/wallets/all" />, index: true },
    { path: 'all', element: <MyInvestments /> },
    { path: ':name', element: <GeneralWallets /> },
    { path: 'connection', element: <WalletsConnection /> },
    { path: 'update-connection', element: <UpdateConnection /> },
    { path: 'update-credentials-connection', element: <UpdateCredentialsConnection /> },
    { path: 'new-connection', element: <NewConnection /> },
    { path: 'new-wallet', element: <WalletsNewWallet /> },
    { path: 'add-movement', element: <AddManualMovement /> },
    { path: 'import-document', element: <WalletsImportDocument /> },
  ],
};

const altInvestRoute = (path?: string) => ({
  path: 'alt-invest',
  children: [
    { element: <Navigate replace to="/dashboard/add-assets/alternative/wallet" />, index: true },
    { path: ':id', element: <AltInvest path={path || 'portfolio'} /> },
  ],
});

const securityRoute = {
  path: 'security',
  children: [
    { element: <Navigate replace to="/dashboard/summary" />, index: true },
    { path: 'info/:name', element: <SecurityInfo /> },
  ],
};

const currentAccountsRoute = {
  path: 'current-accounts',
  element: <CurrentAccounts />,
};

const walletsClientManagmentRoute = {
  path: 'wallet_managment',
  element: <WalletsClientManagment />,
};

const reportingRoutes = {
  path: 'reporting',
  children: [
    { element: <Navigate replace to="/dashboard/reporting/all" />, index: true },
    {
      path: 'all',
      element: <Reporting />,
    },
    { path: 'new', element: <NewReport /> },
    { path: 'new_template', element: <NewTemplate /> },
  ],
};

const myConnectionsRoute = {
  path: 'my-connections',
  element: <MyConnections />,
};

const userRoute = {
  path: 'user',
  children: [
    { element: <Navigate replace to="/dashboard/user/account" />, index: true },
    { path: 'account', element: <UserAccount /> },
    { path: 'subs', element: <Pricing /> },
  ],
};

const feedbackRoute = {
  path: 'feedback',
  element: <Feedback />,
};

export const adminRoute = {
  path: 'admin',
  element: (
    <AdminGuard>
      <Admin />
    </AdminGuard>
  ),
};

export const companyRoute = {
  path: 'company',
  element: <BusinessGuard />,
  children: [
    { element: <Navigate replace to="/dashboard/company/info" />, index: true },
    { path: 'info', element: <Company /> },
    { path: 'new-employee', element: <CompanyNewEmployee /> },
    { path: 'edit-employee', element: <CompanyEditEmployee /> },
    { path: 'new-office', element: <CompanyNewOffice /> },
    { path: 'new-client', element: <CompanyNewClient /> },
    { path: 'office/:name/edit', element: <CompanyEditOffice /> },
    { path: 'clients', element: <CompanyClients /> },
    { path: 'clients-notifications', element: <ClientsNotifications /> },
  ],
};

export const clientRoute = {
  path: 'client',
  children: [
    { element: <Navigate replace to="/dashboard/client/info" />, index: true },
    {
      path: 'wallets',
      children: [
        { element: <Navigate replace to="/dashboard/client/wallets/all" />, index: true },
        { path: 'all', element: <MyInvestmentsClientView /> },
        { path: ':name', element: <ClientWallet /> },
      ],
    },
    { path: 'calendar', element: <ClientCalendar /> },
    { path: 'info', element: <CompanyInfoClient /> },
    { path: 'current-accounts', element: <CurrentAccounts /> },
    { path: 'summary', element: <ClientSummary /> },
    { path: 'my-investments', element: <MyInvestmentsClientView /> },
    { path: 'reporting', element: <ReportingClient /> },
    { path: 'connections', element: <ClientConnections /> },
    { ...altInvestRoute('client') },
    {
      path: 'wealth',
      children: [
        { element: <ClientWealth />, index: true },
        { path: 'general', element: <ClientWealth /> },
        { path: 'finInvest', element: <MyInvestmentsClientView /> },
        { path: 'altInvest', element: <AltInvestGlobal /> },
      ],
    },
  ],
};

const calendarRoute = { path: 'calendar', element: <Calendar /> };

const addAssetsRoute = {
  path: 'add-assets',
  children: [
    { element: <AddAssets />, index: true },
    { path: 'connection', element: <NewConnection /> },
    {
      path: 'financial',
      children: [
        { element: <Financial />, index: true },
        { path: 'wallet', element: <AddWallet /> },
        { path: 'movement', element: <AddManualMovement /> },
      ],
    },
    { path: 'current-account', element: <AddCashAccount /> },
    {
      path: 'alternative',
      children: [
        { element: <Alternative />, index: true },
        { path: 'wallet', element: <AddAltInvestWallet /> },
        {
          path: 'type',
          children: [
            {
              element: <Navigate replace to="/dashboard/add-assets/alternative/type/all" />,
              index: true,
            },
            { path: 'all', element: <AltInvestType /> },
            { path: ':id', element: <AddAltInvestTransaction /> },
          ],
        },
      ],
    },
  ],
};

const myWealth = {
  path: 'my-wealth',
  children: [
    { element: <MyWealth />, index: true },
    { path: 'general', element: <MyWealth /> },
    { path: 'finInvest', element: <MyInvestments /> },
    { path: 'altInvest', element: <AltInvestGlobal /> },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'dashboard',
  element: (
    <AuthGuard>
      <SocketWrapper>
        <DashboardLayout />
      </SocketWrapper>
    </AuthGuard>
  ),
  children: [
    adminRoute,
    calendarRoute,
    clientRoute,
    companyRoute,
    currentAccountsRoute,
    feedbackRoute,
    myConnectionsRoute,
    myInvestmentsRoute,
    rootRoute,
    securityRoute,
    summaryRoute,
    userRoute,
    walletsRoute,
    reportingRoutes,
    walletsClientManagmentRoute,
    addAssetsRoute,
    altInvestRoute(),
    myWealth,
  ],
};
