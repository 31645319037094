import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ConnectionType,
  defaultAgregated,
  defaultNetWorth,
  defaultPerformance,
  DeleteMovementPayload,
  GetAccountsPayload,
  NetworthPeriodPayload,
  NetWorthType,
  OperationsPayload,
  PerformanceType,
  Portfolio,
  PortfolioMap,
  PortfolioSummary,
  TransactionCountPayload,
  UpdateAllPayload,
  UpdatePerformanceTabPayload,
  UpdatePortfolioEntity,
  UpdatePortfolioRiskPayload,
  UpdateTabRefreshPayload,
} from '../PortfolioSlice/portfolioSlice.d';
import { AddClientPayload, UpdateClientPayload } from './clientSlice.d';
import { ClientAccess, CompanyPhoneNumber } from '../BusinessSlice/businessSlice.d';

export type ClientState = {
  id: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  email: string;
  imageUrl: string | undefined;
  type?: string;
  nationalID: string;
  birthDate: string;
  baseCurrency: string;
  country: string;
  gender?: string;
  signDate?: string;
  employee: string;
  netWorth: NetWorthType;
  contactNumber: CompanyPhoneNumber;
  portfolios: PortfolioMap;
  connections: ConnectionType[];
  agregatedSummary: PortfolioSummary;
  currentPortfolio: string;
  cif?: string;
  personType?: string;
  administrator?: string;
  residenceType: string;
  leiCode?: string;
  clientKind: string;
  isReady: boolean;
  access: ClientAccess;
};

export const initialState: ClientState = {
  id: '',
  name: '',
  firstSurname: '',
  secondSurname: '',
  email: '',
  imageUrl: undefined,
  type: undefined,
  nationalID: '',
  birthDate: '',
  country: '',
  gender: '',
  employee: '',
  contactNumber: {},
  portfolios: {},
  netWorth: defaultNetWorth,
  agregatedSummary: defaultAgregated,
  currentPortfolio: '',
  baseCurrency: '',
  connections: [],
  cif: '',
  personType: '',
  administrator: '',
  residenceType: '',
  leiCode: '',
  clientKind: '',
  isReady: false,
  access: 'NO_ACCESS',
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    addClient: (state, action: PayloadAction<AddClientPayload>) =>
      (state = {
        ...initialState,
        ...action.payload,
        isReady: true,
        imageUrl: action.payload.imageUrl === '' ? undefined : action.payload.imageUrl,
      }),
    updateClientInfo: (state, action: PayloadAction<UpdateClientPayload>) =>
      (state = {
        ...state,
        ...action.payload,
      }),
    updateClientLogin: (state) =>
      (state = {
        ...state,
        // loginDisabled: false,
      }),
    addClientPortfolio: (state, action: PayloadAction<Portfolio>) =>
      (state = {
        ...state,
        portfolios: { ...state.portfolios, [action.payload.id]: action.payload },
      }),
    updateClientPortfolioRisk: (state, { payload }: PayloadAction<UpdatePortfolioRiskPayload>) => {
      const { id } = payload;
      state.portfolios = {
        ...state.portfolios,
        [id]: {
          ...state.portfolios[id],
          ...payload,
        },
      };
    },
    updateConnection: (state, action: PayloadAction<ConnectionType>) => {
      const index = state.connections.findIndex((i) => i.id === action.payload.id);
      if (index !== -1) {
        let newConnections = [...state.connections];
        newConnections[index] = action.payload;
        state.connections = [...newConnections];
      }
    },
    addClientPortfolios: (state, action: PayloadAction<PortfolioMap>) =>
      (state = {
        ...state,
        portfolios: { ...action.payload },
      }),
    addClientPositions: (state, action: PayloadAction<Portfolio>) =>
      (state = {
        ...state,
        portfolios: { ...state.portfolios, [action.payload.id]: action.payload },
      }),
    addClientDividends: (state, action: PayloadAction<Portfolio>) =>
      (state = {
        ...state,
        portfolios: { ...state.portfolios, [action.payload.id]: action.payload },
      }),
    getClientOperations: (state, action: PayloadAction<OperationsPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          operations: action.payload.operations,
        },
      };
    },
    getClientMovementsPortfolio: (state, action: PayloadAction<Portfolio>) => {
      state.portfolios = { ...state.portfolios, [action.payload.id]: action.payload };
    },
    deleteClientMovement: (state, action: PayloadAction<DeleteMovementPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.portfolioId]: {
          ...handleUpdatePorfolio(state.portfolios[action.payload.portfolioId]),
          transactions: state.portfolios[action.payload.portfolioId].transactions.filter(
            (i) => i.id !== action.payload.transactionId
          ),
        },
      };
    },
    updateClientPerformanceType: (
      state,
      action: PayloadAction<{ id: string; performanceType: PerformanceType }>
    ) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          performanceType: action.payload.performanceType,
        },
      };
    },
    getClientMovementsCount: (state, action: PayloadAction<TransactionCountPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          transactionsCount: action.payload.transactionsCount,
        },
      };
    },
    getClientConnections: (state, action: PayloadAction<ConnectionType[]>) => {
      state.connections = action.payload;
    },
    getClientAccounts: (state, action: PayloadAction<GetAccountsPayload>) => {
      const index = state.connections.findIndex((i) => i.id === action.payload.id);
      if (index !== -1) {
        let newConnections = [...state.connections];
        newConnections[index] = { ...newConnections[index], accounts: action.payload.accounts };
        state.connections = [...newConnections];
      }
    },
    getClientCredentialsNetworth: (state, action: PayloadAction<ConnectionType[]>) => {
      state.connections = action.payload;
    },
    updateClientNetWorth: (state, action: PayloadAction<NetWorthType>) => {
      state.netWorth = action.payload;
    },
    updateClientAllInfo: (state, action: PayloadAction<UpdateAllPayload>) => {
      state.portfolios = action.payload.portfolios;
      state.agregatedSummary = action.payload.agregatedSummary;
    },
    updateNetWorthPeriod: (state, action: PayloadAction<NetworthPeriodPayload>) => {
      state.netWorth = { ...state.netWorth, ...action.payload };
    },
    deleteClientPortfolio: (state, action: PayloadAction<{ id: string }>) => {
      delete state.portfolios[action.payload.id];
    },
    updatePerformanceClientTabData: (state, action: PayloadAction<UpdatePerformanceTabPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          stateDataTab: {
            isLoaded: true,
            performance: {
              ...action.payload,
            },
            tabRefresh: { ...state.portfolios[action.payload.id].stateDataTab.tabRefresh },
          },
        },
      };
    },
    updateClientTabRefresh: (state, action: PayloadAction<UpdateTabRefreshPayload>) => {
      state.portfolios = {
        ...state.portfolios,
        [action.payload.id]: {
          ...state.portfolios[action.payload.id],
          stateDataTab: {
            isLoaded: true,
            performance: {
              ...state.portfolios[action.payload.id].stateDataTab.performance,
            },
            tabRefresh: { ...action.payload.tabRefresh },
          },
        },
      };
    },
    changeClientCurrentPortfolio: (state, { payload }: PayloadAction<string>) => {
      state.currentPortfolio = payload;
      state.portfolios = {
        ...state.portfolios,
        [payload]: {
          ...state.portfolios[payload],
          stateDataTab: {
            ...state.portfolios[payload].stateDataTab,
            performance: defaultPerformance,
          },
        },
      };
    },
    clientReset: () => initialState,
    updateClientPortfolioEntity: (state, { payload }: PayloadAction<UpdatePortfolioEntity>) => {
      const { id, entity } = payload;
      state.portfolios = {
        ...state.portfolios,
        [id]: {
          ...state.portfolios[id],
          entity,
        },
      };
    },
  },
});

const persistDataConfig = {
  key: 'client',
  storage,
  whitelist: [...Object.keys(initialState)],
  blacklist: ['isReady'],
};

const persistClientReducer = persistReducer<ClientState>(persistDataConfig, clientSlice.reducer);

export const {
  addClient,
  addClientPortfolio,
  addClientPortfolios,
  getClientConnections,
  getClientAccounts,
  getClientCredentialsNetworth,
  updateClientPerformanceType,
  addClientPositions,
  addClientDividends,
  getClientOperations,
  getClientMovementsPortfolio,
  getClientMovementsCount,
  updateClientInfo,
  updateClientNetWorth,
  updateClientAllInfo,
  updateNetWorthPeriod,
  deleteClientPortfolio,
  updateClientTabRefresh,
  updatePerformanceClientTabData,
  changeClientCurrentPortfolio,
  deleteClientMovement,
  clientReset,
  updateConnection,
  updateClientPortfolioRisk,
  updateClientLogin,
  updateClientPortfolioEntity,
} = clientSlice.actions;

export default persistClientReducer;

const handleUpdatePorfolio = (portfolio: any): Portfolio => ({
  ...portfolio,
  performance: { '7D': portfolio.performance['7D'] },
  operations: {
    vol: {},
    count: {},
  },
});
