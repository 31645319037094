import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

export interface ColorPalette {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
  contrastText?: string;
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: string[];
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: string[];
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#FFEAD6',
  light: '#FDC590',
  main: '#FF9634',
  dark: '#EF7E14',
  darker: '#D46701',
  contrastText: '#fff',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};
const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
};
const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: '#fff',
};
const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
};

// ADDITIONAL COLORS
const TURQUOISE = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#fff',
};

const RED_FIRE = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
};

const PINK = {
  lighter: '#F980BA',
  light: '#E9549B',
  main: '#DC2E81',
  dark: '#AF1E64',
  darker: '#8D134E',
  contrastText: '#fff',
};

const SPRING_GREEN = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#fff',
};

const BLUE_PURPLE = {
  lighter: '#F7D2FF',
  light: '#D0AEFF',
  main: '#9E86FF',
  dark: '#826AF9',
  darker: '#5A42D2',
  contrastText: '#fff',
};

const GREY_DARK = {
  lighter: '#C4CDD5',
  light: '#919EAB',
  main: '#637381',
  dark: '#454F5B',
  darker: '#212B36',
  contrastText: '#fff',
};

const SEA = {
  lighter: '#a3c1ad',
  light: '#a0d6b4',
  main: '#5f9ea0',
  dark: '#317873',
  darker: '#49796b',
  contrastText: '#fff',
};

const BLACK = {
  lighter: '#161C24',
  light: '#fff',
  main: '#111111',
  dark: '#111111',
  darker: '#161C24',
  contrastText: '#fff',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

export const CHART_REVERSE_COLOR = {
  first: ['#FF6C40', '#2CD9C5', '#826AF9', '#FFC107', '#2D99FF'],
  second: ['#FF9634', '#FFE16A', '#60F1C8', '#83CFFF', '#9E86FF'],
  third: ['#FFBD98', '#54D62C', '#A4F7CC', '#A5F3FF', '#D0AEFF'],
  fourth: ['#FF8F6D', '#229A16', '#C0F2DC', '#CCFAFF', '#F7D2FF'],
};

export const BARS_CHART_COLORS = ['#FF9634', '#2CD9C5', '#826AF9', '#FFC107'];

export const LONG_CHART_COLORS = [
  '#FF6C40',
  '#2CD9C5',
  '#826AF9',
  '#FFC107',
  '#2D99FF',
  '#FF9634',
  '#FFE16A',
  '#60F1C8',
  '#83CFFF',
  '#9E86FF',
  '#FFBD98',
  '#54D62C',
  '#A4F7CC',
  '#A5F3FF',
  '#D0AEFF',
  '#FF8F6D',
  '#229A16',
  '#C0F2DC',
  '#CCFAFF',
  '#F7D2FF',
];

export const ASSETS_COLORS: { [color: string]: ColorPalette } = {
  springGreen: SPRING_GREEN,
  turquoise: TURQUOISE,
  redFire: RED_FIRE,
  bluePurple: BLUE_PURPLE,
  greyDark: GREY_DARK,
  warning: WARNING,
  info: INFO,
  primary: PRIMARY,
  success: SUCCESS,
  error: ERROR,
  black: BLACK,
  pink: PINK,
  blue: SECONDARY,
  sea: SEA,
};

const COMMON = {
  common: { black: '#000000', white: '#ffffff' },
  primary: { ...PRIMARY, contrastText: '#ffffff' },
  secondary: { ...SECONDARY, contrastText: '#ffffff' },
  info: { ...INFO, contrastText: '#ffffff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#ffffff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: LONG_CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#ffffff', default: GREY[100], neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#ffffff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;

export default palette;
