export const GET_CONNECTIONS = 'GET_CONNECTIONS';
export const GET_CONNECTIONS_STARTED = 'GET_CONNECTIONS_STARTED';
export const GET_CONNECTIONS_FAILURE = 'GET_CONNECTIONS_FAILURE';

export const GET_CONNECTIONS_NETWORTH = 'GET_CONNECTIONS_NETWORTH';
export const GET_CONNECTIONS_NETWORTH_STARTED = 'GET_CONNECTIONS_NETWORTH_STARTED';
export const GET_CONNECTIONS_NETWORTH_FAILURE = 'GET_CONNECTIONS_NETWORTH_FAILURE';

export const CREATE_CONNECTION = 'CREATE_CONNECTION';
export const CREATE_CONNECTION_STARTED = 'CREATE_CONNECTION_STARTED';
export const CREATE_CONNECTION_FAILURE = 'CREATE_CONNECTION_FAILURE';

export const GET_CONNECTION_DETAIL = 'GET_CONNECTION_DETAIL';
export const GET_CONNECTION_DETAIL_STARTED = 'GET_CONNECTION_DETAIL_STARTED';
export const GET_CONNECTION_DETAIL_FAILURE = 'GET_CONNECTION_DETAIL_FAILURE';

export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const UPDATE_CONNECTION_STARTED = 'UPDATE_CONNECTION_STARTED';
export const UPDATE_CONNECTION_FAILURE = 'UPDATE_CONNECTION_FAILURE';

export const DELETE_CONNECTION = 'DELETE_CONNECTION';
export const DELETE_CONNECTION_STARTED = 'DELETE_CONNECTION_STARTED';
export const DELETE_CONNECTION_FAILURE = 'DELETE_CONNECTION_FAILURE';

export const UPDATE_MANUAL_CONNECTION = 'UPDATE_MANUAL_CONNECTION';

export const UPDATE_AVAILABILITY_CONNECTION = 'UPDATE_AVAILABILITY_CONNECTION';
export const UPDATE_AVAILABILITY_CONNECTION_STARTED = 'UPDATE_AVAILABILITY_CONNECTION_STARTED';
export const UPDATE_AVAILABILITY_CONNECTION_FAILURE = 'UPDATE_AVAILABILITY_CONNECTION_FAILURE';

export const GET_VUMI_CREDENTIALS = 'GET_VUMI_CREDENTIALS';

export const REIMPORT_CONNECTION = 'REIMPORT_CONNECTION';
