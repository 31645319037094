// @mui
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, Stack, Typography } from '@mui/material';

// components

// ----------------------------------------------------------------------

type Props = {
  onCancel: VoidFunction;
  title?: string;
  description?: string;
  cancelTitle?: string;
  acceptTitle?: string;
  loading?: boolean;
  isError?: boolean;
  onAccept?: (event: any) => void;
};

export default function InfoActionModal({
  onCancel,
  onAccept,
  cancelTitle,
  acceptTitle,
  title,
  description,
  loading,
  isError,
}: Props) {
  return (
    <>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography alignSelf="center" textAlign={'center'} variant="h6">
          {title}
        </Typography>
        <Typography alignSelf="center" textAlign={'center'} variant="subtitle2">
          {description}
        </Typography>
      </Stack>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button color="inherit" onClick={onCancel} sx={{ width: '42%' }} variant="outlined">
          {cancelTitle}
        </Button>

        <LoadingButton
          color={isError ? 'error' : 'primary'}
          loading={loading}
          onClick={onAccept}
          sx={{ width: '42%' }}
          type="submit"
          variant="contained"
        >
          {acceptTitle}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
