import { LoadingButton } from '@mui/lab';
import { DialogActions, Stack, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  onAccept: () => void;
};

export default function NewRegisterModal({ onAccept }: Props) {
  const { translate } = useLocales();

  return (
    <>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography alignSelf="center" textAlign={'center'} variant="h3">
          {translate('auth.new_register_title')}
        </Typography>
        <Typography
          alignSelf="center"
          color="text.secondary"
          sx={{ px: 3 }}
          textAlign={'center'}
          variant="subtitle2"
        >
          {translate('auth.new_register_des')}
        </Typography>
        <Typography
          alignSelf="center"
          color="text.secondary"
          sx={{ px: 3 }}
          textAlign={'center'}
          variant="subtitle2"
        >
          {translate('auth.new_register_ad')}
        </Typography>
      </Stack>

      <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
        <LoadingButton onClick={onAccept} size="medium" type="submit" variant="contained">
          {translate('auth.new_register_button')}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
