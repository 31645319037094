import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CompanyType,
  OfficeType,
  defaultOffice,
  defaultCompany,
  CompanySettingsPayload,
} from './businessSlice.d';
import { RootState } from 'src/redux/store';
import { ThemeColorsApp } from '../SettingsSlice/settingsSlice.d';

type BusinessState = {
  company: CompanyType;
  office: OfficeType;
};

export const initialState: BusinessState = {
  company: defaultCompany,
  office: defaultOffice,
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    getCompany: (state, action: PayloadAction<CompanyType>) =>
      (state = {
        ...state,
        company: action.payload,
      }),
    getOffice: (state, action: PayloadAction<OfficeType>) =>
      (state = {
        ...state,
        office: action.payload,
      }),
    updateCompany: (state, action: PayloadAction<CompanyType>) =>
      (state = {
        ...state,
        company: action.payload,
      }),
    getCompanySettingsCompany: (state, action: PayloadAction<CompanySettingsPayload>) =>
      (state = {
        ...state,
        company: { ...state.company, ...action.payload },
      }),
    updateCompanyColor: (state, action: PayloadAction<ThemeColorsApp>) =>
      (state = {
        ...state,
        company: { ...state.company, themeCompany: action.payload },
      }),
    updateOffice: (state, action: PayloadAction<OfficeType>) =>
      (state = {
        ...state,
        office: action.payload,
      }),
  },
});

const persistBusinessConfig = {
  key: 'business',
  storage,
  whitelist: ['company'],
};

const persistBusinessReducer = persistReducer<BusinessState>(
  persistBusinessConfig,
  businessSlice.reducer
);

export const {
  getCompany,
  getOffice,
  updateCompany,
  updateOffice,
  getCompanySettingsCompany,
  updateCompanyColor,
} = businessSlice.actions;

export const isBusinessOnBoardingSelector = createSelector(
  [(state: RootState) => state],
  (state: RootState) => {
    const { user } = state.auth;
    if (!!!user) return true;
    if (!!user.privileges && user.privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1) {
      if (user.onboardingFinished === undefined) return true;
      if (!user.onboardingFinished) return false;

      const { company } = state.business;

      if (!!!company.id) return true;

      // Is not Admin
      if (!!user.privileges && user.privileges.findIndex((i) => i === 'MANAGE_BUSINESS') === -1)
        return true;

      if (
        !!company &&
        !!company?.address &&
        Object.values(company?.address).every((item) => !!item)
      )
        return true;
      if (!!company && Object.values(company).every((item) => !!item)) return true;
      return false;
    }
    return true;
  }
);

export default persistBusinessReducer;
