export type User = {
  id: string;
  name: string;
  firstSurname: string;
  secondSurname?: string;
  email: string;
  imageUrl: string;
  country: string;
  isVerified?: boolean;
  stripeCustomerId: string;
  flanksCustomerId?: string;
  preferLanguage?: string;
  baseCurrency: string;
  privileges: string[];
  hideAccount: boolean;
  shareLink: string;
  latestPriceUpdate: string;
  latestAutomaticPriceUpdate?: string;
  mfaEnabled?: boolean;
  lastLogin?: string;
  onboardingFinished?: boolean;
  needsTour?: boolean;
  reportsGenerated: number;
  reportsLimit: number;
  access: string;
};

export const defaultUser: User = {};

export const testUser: User = {
  id: 'string',
  name: 'string',
  email: 'string',
  imageUrl: 'string',
  country: '631',
  stripeCustomerId: '',
  flanksCustomerId: '',
  baseCurrency: '',
  preferLanguage: '',
  isVerified: false,
  hideAccount: false,
  privileges: [],
  shareLink: '',
  latestPriceUpdate: '',
  latestAutomaticPriceUpdate: '',
};

export type InitialPayload = {
  isAuthenticated: boolean;
  user: User;
  token: string | null;
};

export type LoginPayload = {
  user: User;
  token: string;
  refreshToken: string;
  mfaToken: string;
};

export type RegisterPayload = {
  user: User;
};

export type RefreshTokenPayload = {
  token: string;
  refreshToken: string;
};

export type GetUserSettingsPayload = {
  preferLanguage: string;
  baseCurrency: string;
  name: string;
  imageUrl: string;
  country: string;
  latestAutomaticPriceUpdate: string;
  mfaEnabled: boolean;
  onboardingFinished?: boolean;
};
