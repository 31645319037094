import { createContext } from 'react';

export interface ContextType {
  isCollapse?: boolean;
  isOpenSidebar: boolean;
  actions: {
    onOpenSidebar: VoidFunction;
    onCloseSidebar: VoidFunction;
  };
}

const defaultContextValues: ContextType = {
  isCollapse: false,
  isOpenSidebar: false,
  actions: {
    onOpenSidebar: () => { },
    onCloseSidebar: () => { },
  },
};

export default createContext<ContextType>(defaultContextValues);
