export const ADD_PORTFOLIO = 'ADD_PORTFOLIO';
export const ADD_PORTFOLIO_STARTED = 'ADD_PORTFOLIO_STARTED';
export const ADD_PORTFOLIO_FAILURE = 'ADD_PORTFOLIO_FAILURE';

export const GET_PORTFOLIO_PERFORMANCE = 'GET_PORTFOLIO_PERFORMANCE';
export const GET_PORTFOLIO_PERFORMANCE_STARTED = 'GET_PORTFOLIO_PERFORMANCE_STARTED';
export const GET_PORTFOLIO_PERFORMANCE_FAILURE = 'GET_PORTFOLIO_PERFORMANCE_FAILURE';

export const GET_PORTFOLIO_RISK = 'GET_PORTFOLIO_RISK';
export const GET_PORTFOLIO_RISK_STARTED = 'GET_PORTFOLIO_RISK_STARTED';
export const GET_PORTFOLIO_RISK_FAILURE = 'GET_PORTFOLIO_RISK_FAILURE';

export const GET_PORTFOLIO_SUMMARY_DASH = 'GET_PORTFOLIO_SUMMARY_DASH';
export const GET_PORTFOLIO_SUMMARY_DASH_STARTED = 'GET_PORTFOLIO_SUMMARY_DASH_STARTED';
export const GET_PORTFOLIO_SUMMARY_DASH_FAILURE = 'GET_PORTFOLIO_SUMMARY_DASH_FAILURE';

export const GET_PORTFOLIOS = 'GET_PORTFOLIOS';
export const GET_PORTFOLIOS_STARTED = 'GET_PORTFOLIOS_STARTED';
export const GET_PORTFOLIOS_FAILURE = 'GET_PORTFOLIOS_FAILURE';

export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO';
export const UPDATE_PORTFOLIO_STARTED = 'UPDATE_PORTFOLIO_STARTED';
export const UPDATE_PORTFOLIO_FAILURE = 'UPDATE_PORTFOLIO_FAILURE';

export const GET_SETTINGS_PORTFOLIO = 'GET_SETTINGS_PORTFOLIO';
export const GET_SETTINGS_PORTFOLIO_STARTED = 'GET_SETTINGS_PORTFOLIO_STARTED';
export const GET_SETTINGS_PORTFOLIO_FAILURE = 'GET_SETTINGS_PORTFOLIO_FAILURE';

export const DELETE_PORTFOLIO = 'DELETE_PORTFOLIO';
export const DELETE_PORTFOLIO_STARTED = 'DELETE_PORTFOLIO_STARTED';
export const DELETE_PORTFOLIO_FAILURE = 'DELETE_PORTFOLIO_FAILURE';

export const GET_MOVEMENTS = 'GET_MOVEMENTS';
export const GET_MOVEMENTS_STARTED = 'GET_MOVEMENTS_STARTED';
export const GET_MOVEMENTS_FAILURE = 'GET_MOVEMENTS_FAILURE';

export const GET_SKIPPED_MOVEMENTS = 'GET_SKIPPED_MOVEMENTS';

export const DELETE_MOVEMENT = 'DELETE_MOVEMENT';
export const DELETE_MOVEMENT_STARTED = 'DELETE_MOVEMENT_STARTED';
export const DELETE_MOVEMENT_FAILURE = 'DELETE_MOVEMENT_FAILURE';

export const GET_POSITIONS = 'GET_POSITIONS';
export const GET_POSITIONS_STARTED = 'GET_POSITIONS_STARTED';
export const GET_POSITIONS_FAILURE = 'GET_POSITIONS_FAILURE';

export const GET_DIVIDENDS = 'GET_DIVIDENDS';
export const GET_DIVIDENDS_STARTED = 'GET_DIVIDENDS_STARTED';
export const GET_DIVIDENDS_FAILURE = 'GET_DIVIDENDS_FAILURE';

export const GET_MISSING_SECURITIES = 'GET_MISSING_SECURITIES';

export const GET_PORTFOLIO_IMPORT_ERRORS = 'GET_PORTFOLIO_IMPORT_ERRORS';

export const SEND_MISSING_SECURITIES = 'SEND_MISSING_SECURITIES';

export const MANUAL_UPDATE_PORTFOLIO = 'MANUAL_UPDATE_PORTFOLIO';

export const GET_PORTFOLIO_SUMMARY = 'GET_PORTFOLIO_SUMMARY';
export const GET_PORTFOLIO_SUMMARY_STARTED = 'GET_PORTFOLIO_SUMMARY_STARTED';
export const GET_PORTFOLIO_SUMMARY_FAILED = 'GET_PORTFOLIO_SUMMARY_FAILED';

export const GET_NET_WORTH = 'GET_NET_WORTH';
export const GET_NET_WORTH_STARTED = 'GET_NET_WORTH_STARTED';
export const GET_NET_WORTH_FAILED = 'GET_NET_WORTH_FAILED';

export const GET_NET_WORTH_PERIOD = 'GET_NET_WORTH_PERIOD';
export const GET_NET_WORTH_PERIOD_STARTED = 'GET_NET_WORTH_PERIOD_STARTED';
export const GET_NET_WORTH_PERIOD_FAILED = 'GET_NET_WORTH_PERIOD_FAILED';

export const GET_ALL_DIVIDENDS = 'GET_ALL_DIVIDENDS';

export const GET_ALL_INFO = 'GET_ALL_INFO';
export const GET_ALL_INFO_STARTED = 'GET_ALL_INFO_STARTED';
export const GET_ALL_INFO_FAILED = 'GET_ALL_INFO_FAILED';
