import {
  ChartPerformance,
  ChartPerformanceMap,
  ConnectionDetails,
  PortfolioEntity,
  PerformanceStatistics,
  Portfolio,
  PortfolioRole,
  PortfolioSettings,
  SecurityInfo,
  SharedPortfolio,
  TransactionsCount,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import moment from 'moment';

const _emptyTransactionsCount: TransactionsCount = {
  total: 0,
  dividends: 0,
  deposits: 0,
  buys: 0,
  sells: 0,
  fees: 0,
  removals: 0,
  custom: 0,
  splits: 0,
  notConfirmed: 0,
  interestCharge: 0,
  transfersInCount: 0,
  transfersOutCount: 0,
  taxes: 0,
  taxRefund: 0,
  interest: 0,
};

const _emptyPerformance = (period: string): ChartPerformanceMap => ({
  '7D': {
    amount: 0,
    percent: 0,
    timeSeries: [],
    investedAmount: [],
    period,
    positionsPerformance: [],
  },
});

export const _portfolioWallet = (
  id: string,
  name: string,
  firstTransactionDate: string,
  baseCurrency: string,
  status: string,
  liquidityConsidered: boolean,
  role: PortfolioRole = 'OWNER',
  owners: string[] = [],
  connectedEntity?: PortfolioEntity,
  connectedCredentials?: ConnectionDetails,
  activesNotFoundShowed?: boolean,
  type?: string
): Portfolio => ({
  type,
  id: id ?? 'not_valid',
  name,
  baseCurrency,
  performance: _emptyPerformance('X'),
  absoluteValue: _emptyPerformance('X'),
  lastUpdatedAt: 0,
  transactions: [],
  transactionsCount: _emptyTransactionsCount,
  performanceMeta: {
    day: { value: 0, performance: 0 },
    year: { value: 0, performance: 0 },
    total: { value: 0, performance: 0 },
    currentValue: { value: 0, performance: 0 },
    lastFullYear: { value: 0, performance: 0 },
  },
  positions: [],
  historicalPositions: [],
  firstTransactionDate,
  operations: {
    count: {},
    vol: {},
  },
  gainsPerPeriod: {},
  dividends: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  bonds: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  settings: {
    share: 'PRIVATE',
    showReturn: false,
    showMoney: false,
    showTransactions: false,
    showHoldings: false,
    showHistoricalPositions: false,
    showDividends: false,
    shareLink: '',
    activesNotFoundShowed,
  },
  connectionDetails: connectedCredentials,
  entity: connectedEntity,
  status,
  performanceStatistics: {
    ALL: {
      deposits: 0,
      removals: 0,
      dividends: 0,
      retentions: 0,
      transactionFees: 0,
      otherFees: 0,
      interests: 0,
      otherTaxes: 0,
    },
  },
  performanceType: 'MWRR',
  referenceIndices: [],
  riskFreeAssetBenchmark: {
    id: '2976add7-1470-40ea-9037-bf6e48b37273',
    name: 'United States Government Bond 10Y',
  },
  todaysPositionPerformances: [],
  latestOperation: '',
  monthlyPerformance: {
    value: {},
    performance: {},
  },
  annualizedPerformances: {},
  owners,
  todaysGainers: [],
  todaysLosers: [],
  currencyPairPerformances: [],
  upcomingEvents: [],
  stateDataTab: {
    performance: {
      startDate: undefined,
      endDate: undefined,
      performancePeriod: '7D',
      isPerformanceType: true,
      selectedIndices: ['portfolio'],
    },
    isLoaded: false,
    tabRefresh: {
      refreshMovements: false,
      refreshPositions: false,
      refreshDividends: false,
      refreshPerformance: false,
    },
  },
  empty: true,
  role,
  liquidityConsidered,
  yearlyPerformance: {
    value: {},
    performance: {},
  },
  accumulatedPerformances: {
    accumulatedPerformanceByPeriod: {},
    accumulatedValueByPeriod: {},
  },
  riskMetrics: {},
});

export const _portfolioWalletMock = (id: string): Portfolio => ({
  id: id ?? 'not_valid',
  name: '',
  baseCurrency: 'EUR',
  performance: _emptyPerformance('7D'),
  absoluteValue: _emptyPerformance('7D'),
  lastUpdatedAt: moment().unix(),
  transactions: [],
  transactionsCount: _emptyTransactionsCount,
  performanceMeta: {
    day: { value: 0, performance: 0 },
    year: { value: 0, performance: 0 },
    total: { value: 0, performance: 0 },
    currentValue: { value: 0, performance: 0 },
    lastFullYear: { value: 0, performance: 0 },
  },
  positions: [],
  historicalPositions: [],
  firstTransactionDate: '',
  operations: { count: {}, vol: {} },
  gainsPerPeriod: {},
  dividends: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  bonds: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  status: 'READY',
  settings: {
    share: 'PRIVATE',
    showReturn: false,
    showMoney: false,
    showTransactions: false,
    showHoldings: false,
    showHistoricalPositions: false,
    showDividends: false,
    shareLink: '',
    activesNotFoundShowed: false,
  },
  performanceStatistics: {
    ALL: {
      deposits: 0,
      removals: 0,
      dividends: 0,
      retentions: 0,
      transactionFees: 0,
      otherFees: 0,
      interests: 0,
      otherTaxes: 0,
    },
  },
  performanceType: 'MWRR',
  referenceIndices: [],
  riskFreeAssetBenchmark: {
    id: '2976add7-1470-40ea-9037-bf6e48b37273',
    name: 'United States Government Bond 10Y',
  },
  todaysPositionPerformances: [],
  latestOperation: '',
  monthlyPerformance: {
    value: {},
    performance: {},
  },
  annualizedPerformances: {},
  owners: [],
  todaysGainers: [],
  todaysLosers: [],
  currencyPairPerformances: [],
  upcomingEvents: [],
  stateDataTab: {
    performance: {
      startDate: undefined,
      endDate: undefined,
      performancePeriod: '7D',
      isPerformanceType: true,
      selectedIndices: ['portfolio'],
    },
    isLoaded: false,
    tabRefresh: {
      refreshMovements: false,
      refreshPositions: false,
      refreshDividends: false,
      refreshPerformance: false,
    },
  },
  empty: true,
  role: 'ADVISOR',
  yearlyPerformance: {
    value: {},
    performance: {},
  },
  accumulatedPerformances: {
    accumulatedPerformanceByPeriod: {},
    accumulatedValueByPeriod: {},
  },
  riskMetrics: {},
});

export const _chartEmptyPerformance = (): ChartPerformance => ({
  amount: 0,
  percent: 0,
  timeSeries: [],
  period: '7D',
  investedAmount: [],
  positionsPerformance: [],
});

export const _emptyPerformanceStatistics = (): PerformanceStatistics => ({
  deposits: 0,
  removals: 0,
  dividends: 0,
  retentions: 0,
  transactionFees: 0,
  otherFees: 0,
  interests: 0,
  otherTaxes: 0,
});

export const _emptyConnectedCredentials = (): ConnectionDetails => ({
  cif: '',
  entityType: 'BROKER',
  lastUpdate: '',
});

export const _emptyConnectedEntity = (id: string, name: string): PortfolioEntity => ({
  id,
  name,
});

export const _sharedPortfolio = (
  id: string,
  name: string,
  isConnected: boolean,
  settings: PortfolioSettings
): SharedPortfolio => ({
  id,
  name,
  isConnected,
  settings,
  performance: _emptyPerformance('X'),
  absoluteValue: _emptyPerformance('X'),
  performanceMeta: {
    day: { value: 0, performance: 0 },
    year: { value: 0, performance: 0 },
    total: { value: 0, performance: 0 },
    currentValue: { value: 0, performance: 0 },
    lastFullYear: { value: 0, performance: 0 },
  },
  firstTransactionDate: '',
  gainsPerPeriod: {},
  positions: [],
  historicalPositions: [],
  dividends: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  bonds: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  transactions: [],
  transactionsCount: _emptyTransactionsCount,
  performanceType: 'MWRR',
  performanceStatistics: {
    ALL: {
      deposits: 0,
      removals: 0,
      dividends: 0,
      retentions: 0,
      transactionFees: 0,
      otherFees: 0,
      interests: 0,
      otherTaxes: 0,
    },
  },
  todaysPositionPerformances: [],
  monthlyPerformance: {
    value: {},
    performance: {},
  },
  todaysGainers: [],
  todaysLosers: [],
  currencyPairPerformances: [],
  upcomingEvents: [],
});

export const _sharedPortfolioEmpty = (id?: string): SharedPortfolio => ({
  id: id ?? 'empty',
  name: 'empty',
  isConnected: false,
  settings: {
    share: 'PRIVATE',
    showReturn: false,
    showMoney: false,
    showTransactions: false,
    showHoldings: false,
    showHistoricalPositions: false,
    showDividends: false,
    shareLink: '',
  },
  performance: _emptyPerformance('X'),
  absoluteValue: _emptyPerformance('X'),
  performanceMeta: {
    day: { value: 0, performance: 0 },
    year: { value: 0, performance: 0 },
    total: { value: 0, performance: 0 },
    currentValue: { value: 0, performance: 0 },
    lastFullYear: { value: 0, performance: 0 },
  },
  firstTransactionDate: '',
  gainsPerPeriod: {},
  positions: [],
  historicalPositions: [],
  dividends: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  bonds: {
    incomeByMonthAndYear: [],
    incomeMap: {},
    total: 0,
    ytd: 0,
  },
  transactions: [],
  transactionsCount: _emptyTransactionsCount,
  performanceType: 'MWRR',
  performanceStatistics: {
    ALL: {
      deposits: 0,
      removals: 0,
      dividends: 0,
      retentions: 0,
      transactionFees: 0,
      otherFees: 0,
      interests: 0,
      otherTaxes: 0,
    },
  },
  todaysPositionPerformances: [],
  monthlyPerformance: {
    value: {},
    performance: {},
  },
  todaysGainers: [],
  todaysLosers: [],
  currencyPairPerformances: [],
  upcomingEvents: [],
});

export const defaultSecurity: SecurityInfo = {
  id: 'none',
  name: '',
  symbol: '',
  numberActiveShares: 0,
  currentPrice: 0,
  totalValue: 0,
  gainLossRealized: 0,
  gainLossUnrealized: 0,
  profitRateTotal: 0,
  dividendAmounts: 0,
  isin: '',
  dividendTaxes: 0,
  ownerNumbers: 0,
  exchangeRate: 1,
  prices: {
    '7D': {
      priceList: [],
      performanceList: [],
      lastEvents: [],
    },
  },
  country: '',
  market: '',
  sector: '',
  industry: '',
  currency: '',
  bep: 0,
  delisted: false,
  lastDate: '',
  historicalPositions: [],
  futureEvents: [],
  activePositions: [],
  earliestDate: '',
};
