export const GET_REPORTS_TYPES = 'GET_REPORTS_TYPES';
export const GET_HISTORIC_REPORTS = 'GET_HISTORIC_REPORTS';
export const GET_REPORT_S3 = 'GET_REPORT_S3';

export const GENERATE_REPORT = 'GENERATE_REPORT';

export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';

export const UPDATE_FAVORITE_REPORT = 'UPDATE_FAVORITE_REPORT';

export const DELETE_REPORT = 'DELETE_REPORT';

export const GET_REPORT_COOLDOWN = 'GET__REPORT_COOLDOWN';

export const GET_CLIENT_REPORT_S3 = 'GET_CLIENT_REPORT_S3';

export const UPDATE_CHECK_REPORT = 'UPDATE_CHECK_REPORT';
