import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { persistStore } from 'redux-persist';
import { rootReducer } from './rootReducer';
import { getNetworkMiddleware } from 'src/services/config';
import { APP_ENV } from 'src/config';

// ----------------------------------------------------------------------

const logger = createLogger();

const networkMiddleware = getNetworkMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = [
      ...getDefaultMiddleware({ serializableCheck: false }),
      networkMiddleware,
      thunk,
    ];
    if (APP_ENV !== 'prod' && APP_ENV !== 'business') {
      middlewares.push(logger);
    }

    return middlewares;
  },
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch };
