import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GuestGuardProps } from './GuestGuard.Types';
import useGetCompanyThemeSettings from 'src/thunks/useGetCompanyThemeSettings';

// ----------------------------------------------------------------------

export default function GuestGuard({ children, isAuthenticated }: GuestGuardProps) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  useGetCompanyThemeSettings({ company: searchParams.get('company') ?? '' });

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
