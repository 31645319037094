import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { FormProvider, RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';
import { CompanyType, OfficeType } from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { CountryType } from 'src/redux/slices/DataSlice/dataSlice.d';

// ----------------------------------------------------------------------

export type OfficeFormProps = {
  name: string;
  city: string;
  street: string;
  town: string;
  zipCode: number;
  country: string;
  contactNumber: string;
  contact_email: string;
  point_of_contact: string;
  phone_code: string;
  useCompany: boolean;
};

type OnboardingOfficeFormProps = {
  onSubmitForm: (data: OfficeFormProps) => void;
  countries: CountryType[];
  office: OfficeType;
  company: CompanyType;
};

export default function OnboardingOfficeForm(props: OnboardingOfficeFormProps) {
  const { translate } = useLocales();

  const Schema = Yup.object().shape({
    name: Yup.string().required('Por favor introduzca el nombre'),
    city: Yup.string().required('Por favor introduzca la ciudad'),
    street: Yup.string().required('Por favor introduzca la calle'),
    town: Yup.string().required('Por favor introduzca la provincia'),
    contact_email: Yup.string().required('Por favor introduzca un email de contacto'),
    point_of_contact: Yup.string().required('Por favor introduzca una persona de contacto'),
    country: Yup.string().required('Por favor introduzca el país'),
    zipCode: Yup.number().required('Por favor introduzca el código postal'),
    number: Yup.string().required('Por favor introduzca el teléfono de contacto'),
    phone_code: Yup.string().required('Por favor introduzca el prefijo'),
    useCompany: Yup.boolean(),
  });

  const defaultValues = {
    name: props.office.name ?? '',
    city: props.company.address?.city ?? '',
    street: props.company.address?.street ?? '',
    town: props.company.address?.town ?? '',
    country: props.company.address?.country ?? '631',
    zipCode: props.company.address?.zipCode ?? undefined,
    number: props.company?.phoneNumber?.telNumber ?? undefined,
    point_of_contact: props.company.pointOfContact ?? '',
    contact_email: props.company.contactEmail ?? '',
    phone_code: props.company.phoneNumber?.country ?? '631',
    useCompany: true,
  };

  const methods = useForm<OfficeFormProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const { reset, handleSubmit, watch } = methods;
  const watchValues = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [props.office]);

  const onSubmit = async (data: OfficeFormProps) => {
    try {
      props.onSubmitForm(data);
      reset();
    } catch (error) {
      console.error(error);
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={12} sx={{ mt: -3 }} xs={12}>
          <Stack alignItems="center" flexDirection="row" sx={{ ml: 0.4 }}>
            <RHFCheckbox label={''} name="useCompany" value={watchValues.useCompany} />
            <Typography color="text.secondary" sx={{ ml: -1.5 }} variant="body2">
              {'¿Deseas repetir la información anterior?'}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField label={'Nombre'} name="name" type={'text'} />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            disabled={watchValues.useCompany}
            label={'Persona de contacto'}
            name="point_of_contact"
            type={'text'}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            disabled={watchValues.useCompany}
            label={'Email de contacto'}
            name="contact_email"
            type={'email'}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <RHFTextField
            disabled={watchValues.useCompany}
            label={'Calle'}
            name="street"
            type={'text'}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            disabled={watchValues.useCompany}
            label={'Ciudad'}
            name="city"
            type={'text'}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            disabled={watchValues.useCompany}
            label={'Provincia'}
            name="town"
            type={'text'}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            disabled={watchValues.useCompany}
            label={'Código postal'}
            name="zipCode"
            type={'number'}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFSelect
            disabled={watchValues.useCompany}
            label="País"
            name="country"
            value={watchValues.country ?? ''}
          >
            {props.countries.map((val, index) => (
              <option key={index} value={val.id}>
                {val.nameEs}
              </option>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item md={12} xs={12}>
          <Stack alignItems="center" flexDirection="row">
            <RHFSelect
              disabled={watchValues.useCompany}
              label="País"
              name="phone_code"
              sx={{ mr: 3 }}
              value={watchValues.phone_code ?? ''}
            >
              {_.orderBy(props.countries, 'nameEs').map((val, index) => (
                <option key={index} value={val.id}>
                  {val.dialCode} {val.nameEs}
                </option>
              ))}
            </RHFSelect>
            <RHFTextField
              disabled={watchValues.useCompany}
              label={'Teléfono de contacto'}
              name="number"
              sx={{ minWidth: 200 }}
              type={'number'}
            />
          </Stack>
        </Grid>
      </Grid>

      <LoadingButton fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
        {'Siguiente'}
      </LoadingButton>
    </FormProvider>
  );
}
