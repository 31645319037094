import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

export interface Props {
  isDragReject?: boolean;
  error?: boolean;
  hasFile?: boolean;
}

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function PlaceholderSelector({ isDragReject, error, hasFile }: Props) {
  const { translate } = useLocales();

  return (
    <PlaceholderStyle
      className="placeholder"
      sx={{
        ...(hasFile && {
          opacity: 0,
          color: 'common.white',
          bgcolor: 'grey.900',
          '&:hover': { opacity: 0.72 },
        }),
        ...((isDragReject || error) && {
          bgcolor: 'error.lighter',
        }),
      }}
    >
      <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
      <Typography variant="caption">{translate('account.upload_photo')}</Typography>
    </PlaceholderStyle>
  );
}
