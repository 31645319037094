import { Divider } from '@mui/material';
// components
import MenuPopover from 'src/components/MenuPopover';
import Scrollbar from 'src/components/Scrollbar';
import MenuList from './MenuList';
import MenuTitle from './MenuTitle';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useGetPortfoliosInfo from 'src/layouts/dashboard/services/useGetPortfoliosInfo';

// ----------------------------------------------------------------------

type Props = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
};

export default function ConnectionsMenu({ anchorEl, onClose, open }: Props) {
  const { connections } = useGetPortfoliosInfo();

  const isMobile = useResponsive('down', 'sm');

  const unReadyConnections = connections.filter((i) => i.pending && !i.errored);
  const readyConnections = connections.filter((i) => !i.pending && !i.displayed);
  const total = unReadyConnections.length + readyConnections.length;

  return (
    <MenuPopover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      sx={{ minWidth: isMobile ? 100 : 500, p: 0, mt: 1.5, ml: 0.75 }}
    >
      <MenuTitle unReadyConnections={unReadyConnections.length} />

      {total > 0 && <Divider sx={{ borderStyle: 'dashed' }} />}

      <Scrollbar>
        {unReadyConnections.length !== 0 && (
          <MenuList connections={unReadyConnections} onClose={onClose} title={'Importando'} />
        )}
        {readyConnections.length !== 0 && (
          <MenuList connections={readyConnections} onClose={onClose} title={'Cuentas listas'} />
        )}
      </Scrollbar>
    </MenuPopover>
  );
}
