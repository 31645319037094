// @mui
import { Box, BoxProps, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export default function EmptyChartIllustration({ ...other }: BoxProps) {
  const theme = useTheme();
  return (
    <Box {...other}>
      <svg fill="none" height="100%" viewBox="0 0 733 504" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
          <path
            clipRule="evenodd"
            d="M0 278.293C0 336.333 22.8228 389.222 60.3794 429.193C103.465 475.063 165.944 503.903 235.587 504C266.024 504.041 295.127 498.558 321.86 488.549C335.682 483.372 351.152 483.966 364.54 490.1C380.243 497.285 397.827 501.314 416.395 501.314C424.065 501.314 431.576 500.622 438.842 499.308C459.582 495.569 478.455 486.722 493.954 474.191C503.695 466.313 516.17 462.353 528.92 462.367H529.137C571.268 462.367 610.402 450.142 642.893 429.193C671.94 410.489 695.66 384.818 711.464 354.678C725.243 328.413 733 298.743 733 267.341C733 159.638 641.72 72.3147 529.137 72.3147C517.66 72.3147 506.415 73.2421 495.445 74.9725C466.398 29.9881 414.382 0 355.044 0C329.992 0 306.242 5.34476 284.982 14.9257C264.764 24.0077 246.788 36.9128 232.084 52.64C182.153 53.4016 135.984 69.0059 98.196 95.048C38.7138 136.017 0 202.835 0 278.293Z"
            fill="url(#paint0_linear_1268_106001)"
            fillRule="evenodd"
          />
        </g>
        <path
          d="M167.414 130.384C167.414 130.384 42.7401 181.159 38.4492 251.859C34.1582 322.557 266.901 309.809 317.795 340.297C368.69 370.783 589.274 417.115 585.023 364.009C580.771 310.902 528.366 292.581 528.366 292.581"
          opacity="0.24"
          stroke={theme.palette.primary.main}
          strokeDasharray="3 3"
          strokeWidth="2"
        />
        <path
          d="M493.88 358.691L417.796 172.562C416.552 169.534 414.33 166.924 411.427 165.082C408.524 163.241 405.077 162.254 401.546 162.254H81.1594C76.5615 162.264 72.1555 163.945 68.9081 166.93C65.6606 169.913 63.8369 173.957 63.8369 178.172C63.8423 179.79 64.1101 181.398 64.6315 182.943L138.252 394.203C139.453 397.621 141.883 400.563 145.146 402.549C148.408 404.534 152.31 405.444 156.21 405.132L479.141 379.999C483.73 379.654 487.981 377.655 490.962 374.438C493.941 371.221 495.406 367.05 495.033 362.843C494.847 361.418 494.459 360.022 493.88 358.691Z"
          fill={theme.palette.primary.main}
          opacity="0.12"
        />
        <path
          d="M73.2133 164.331L146.953 375.701C148.174 379.113 150.614 382.049 153.881 384.033C157.147 386.015 161.048 386.929 164.951 386.628L261.655 379.124L487.683 361.423C489.955 361.262 492.17 360.691 494.201 359.741C496.23 358.794 498.037 357.486 499.513 355.894C500.99 354.304 502.109 352.46 502.807 350.471C503.504 348.481 503.765 346.385 503.576 344.304C503.414 342.808 503.04 341.338 502.462 339.933L484.385 296.223L451.687 216.09L426.339 154.168C425.094 151.14 422.872 148.53 419.969 146.689C417.066 144.847 413.62 143.861 410.089 143.861H89.7014C87.4266 143.861 85.174 144.272 83.0723 145.07C80.9707 145.868 79.0611 147.038 77.4525 148.512C75.844 149.986 74.568 151.738 73.6975 153.664C72.827 155.591 72.3789 157.656 72.3789 159.742C72.4026 161.301 72.6839 162.848 73.2133 164.331Z"
          fill="white"
        />
        <path
          d="M260.482 379.215L164.951 386.628C161.047 386.929 157.147 386.015 153.881 384.033C150.614 382.049 148.174 379.113 146.953 375.701L73.2133 164.331C72.6839 162.848 72.4026 161.301 72.3789 159.742C72.3789 157.656 72.827 155.591 73.6975 153.664C74.568 151.738 75.844 149.986 77.4526 148.512C79.0611 147.038 80.9707 145.868 83.0723 145.07C85.174 144.272 87.4266 143.861 89.7014 143.861H177.436L260.482 379.215Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M347.99 260.44C351.981 260.213 354.661 256.592 353.416 253.108L340.462 216.855C339.605 214.461 337.11 212.885 334.364 213.005L240.264 217.151C236.193 217.33 233.451 221.046 234.785 224.576L248.907 261.98C249.804 264.356 252.32 265.893 255.058 265.736L347.99 260.44Z"
          fill={theme.palette.primary.main}
          opacity="0.18"
        />
        <path
          d="M373.95 327.64C377.941 327.414 380.621 323.792 379.376 320.309L366.422 284.056C365.565 281.662 363.07 280.085 360.324 280.206L266.224 284.351C262.153 284.53 259.411 288.246 260.745 291.777L274.867 329.18C275.763 331.556 278.28 333.093 281.018 332.937L373.95 327.64Z"
          fill={theme.palette.primary.main}
          opacity="0.18"
        />
        <path
          d="M386.37 181.086L410.724 255.136C411.083 256.329 411.863 257.379 412.941 258.123C414.018 258.868 415.334 259.264 416.684 259.253L477.094 257.653C479.189 257.598 480.602 255.671 479.867 253.873L449.324 179.113C448.901 178.076 447.849 177.366 446.644 177.3L391.813 174.347C390.912 174.312 390.014 174.48 389.2 174.837C388.384 175.194 387.679 175.728 387.141 176.393C386.604 177.058 386.252 177.833 386.118 178.651C385.984 179.47 386.071 180.306 386.37 181.086Z"
          fill={theme.palette.primary.main}
          opacity="0.18"
        />
        <path
          d="M534.605 236.925L509.415 171.544C509.029 170.494 508.314 169.571 507.362 168.892C506.407 168.212 505.257 167.804 504.052 167.719L397.893 161.963C396.989 161.938 396.091 162.113 395.277 162.474C394.463 162.836 393.757 163.372 393.218 164.037C392.679 164.703 392.325 165.477 392.184 166.296C392.042 167.115 392.12 167.953 392.409 168.738L416.764 242.826C417.128 244.019 417.907 245.07 418.983 245.819C420.058 246.569 421.371 246.976 422.723 246.978L529.758 244.027C530.651 243.979 531.518 243.737 532.288 243.319C533.059 242.9 533.708 242.319 534.183 241.625C534.658 240.929 534.944 240.139 535.017 239.322C535.09 238.504 534.948 237.682 534.605 236.925Z"
          fill="white"
        />
        <path
          d="M498.333 226.488C499.063 228.225 499.428 229.093 500.208 229.584C500.99 230.076 502.004 230.076 504.034 230.076H504.513C508.559 230.076 510.58 230.076 511.491 228.859C512.401 227.641 511.671 225.912 510.209 222.456L507.787 216.717C507.056 214.985 506.691 214.12 505.911 213.63C505.13 213.139 504.116 213.139 502.091 213.139H501.626C497.588 213.139 495.569 213.139 494.659 214.354C493.749 215.569 494.474 217.297 495.925 220.75L498.333 226.488Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M464.839 226.49C465.569 228.226 465.934 229.094 466.715 229.586C467.496 230.077 468.51 230.077 470.54 230.077H471.021C475.065 230.077 477.088 230.077 477.997 228.859C478.907 227.641 478.177 225.912 476.717 222.454L472.586 212.674C471.855 210.942 471.49 210.077 470.709 209.587C469.929 209.097 468.915 209.097 466.89 209.097H466.427C462.388 209.097 460.37 209.097 459.459 210.312C458.549 211.527 459.276 213.255 460.728 216.71L464.839 226.49Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M448.111 226.498C448.842 228.23 449.207 229.096 449.988 229.586C450.768 230.078 451.782 230.078 453.808 230.078H454.297C458.341 230.078 460.362 230.078 461.273 228.86C462.183 227.643 461.453 225.914 459.993 222.456L445.57 188.272C444.838 186.539 444.473 185.674 443.693 185.182C442.913 184.692 441.899 184.692 439.872 184.692H439.383C435.34 184.692 433.318 184.692 432.408 185.909C431.498 187.127 432.228 188.855 433.686 192.313L448.111 226.498Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M481.604 226.498C482.334 228.229 482.7 229.096 483.48 229.586C484.261 230.077 485.273 230.077 487.3 230.077H487.788C491.832 230.077 493.854 230.077 494.764 228.859C495.674 227.643 494.944 225.914 493.486 222.456L483.671 199.199C482.94 197.466 482.573 196.601 481.793 196.109C481.013 195.619 480 195.619 477.974 195.619H477.485C473.441 195.619 471.419 195.619 470.509 196.837C469.599 198.054 470.329 199.783 471.788 203.239L481.604 226.498Z"
          fill={theme.palette.primary.dark}
        />
        <path
          d="M431.344 226.495C432.076 228.229 432.441 229.096 433.221 229.586C434.001 230.077 435.015 230.077 437.042 230.077H437.534C441.576 230.077 443.596 230.077 444.506 228.861C445.416 227.644 444.688 225.915 443.231 222.459L423.478 175.598C422.747 173.865 422.382 172.998 421.602 172.508C420.821 172.017 419.807 172.017 417.781 172.017H417.289C413.247 172.017 411.227 172.017 410.316 173.233C409.406 174.45 410.135 176.179 411.591 179.635L431.344 226.495Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M119.142 209.97C117.554 204.576 118.34 198.826 121.331 193.958C124.323 189.091 129.28 185.495 135.135 183.946C140.99 182.396 147.274 183.016 152.635 185.673C157.996 188.33 162.003 192.812 163.796 198.151C165.588 203.491 165.021 209.263 162.217 214.223C159.412 219.184 154.596 222.936 148.803 224.672C143.011 226.408 136.706 225.988 131.248 223.504C125.789 221.02 121.613 216.671 119.619 211.391L119.38 210.698L119.142 209.97Z"
          fill={theme.palette.primary.light}
        />
        <path
          d="M169.958 345.213C168.763 339.926 169.789 334.418 172.834 329.795C175.879 325.172 180.717 321.775 186.378 320.282C192.04 318.79 198.107 319.313 203.365 321.748C208.623 324.183 212.685 328.349 214.734 333.413V334.104C214.734 334.323 214.734 334.578 215.012 334.796C216.585 339.983 215.966 345.524 213.279 350.311C210.591 355.097 206.031 358.778 200.512 360.618C194.993 362.457 188.922 362.321 183.507 360.235C178.093 358.149 173.736 354.267 171.308 349.366C171.07 348.674 170.872 347.981 170.633 347.29C170.395 346.598 170.236 345.906 169.958 345.213Z"
          fill={theme.palette.primary.light}
        />
        <path
          d="M146.198 270.362C146.198 264.691 148.655 259.253 153.029 255.244C157.402 251.234 163.335 248.981 169.52 248.981C175.706 248.981 181.637 251.234 186.011 255.244C190.384 259.253 192.841 264.691 192.841 270.362V271.855C192.841 277.526 190.384 282.964 186.011 286.973C181.637 290.984 175.706 293.237 169.52 293.237C163.335 293.237 157.402 290.984 153.029 286.973C148.655 282.964 146.198 277.526 146.198 271.855V270.362Z"
          fill={theme.palette.primary.light}
        />
        <path
          d="M142.345 204.106L136.862 187.971C138.41 187.533 140.012 187.277 141.63 187.205C145.676 187.067 149.66 188.149 152.97 190.288C156.279 192.427 158.73 195.503 159.945 199.044C160.422 200.463 160.703 201.93 160.779 203.414C160.829 204.894 160.683 206.374 160.342 207.822C159.641 210.714 158.104 213.381 155.892 215.544C153.713 217.742 150.933 219.367 147.838 220.251C144.744 221.136 141.448 221.245 138.292 220.57C136.737 220.237 135.23 219.736 133.803 219.076C132.368 218.382 131.033 217.525 129.83 216.527C127.484 214.536 125.733 212.029 124.744 209.243L142.345 204.106Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M169.203 271.054V254.153C172.428 254.176 175.59 254.967 178.38 256.448C179.839 257.168 181.176 258.076 182.354 259.143C184.652 261.235 186.306 263.847 187.149 266.714C187.99 269.581 187.989 272.603 187.146 275.47C186.303 278.336 184.647 280.948 182.346 283.038C180.046 285.128 177.185 286.624 174.049 287.373C170.931 288.173 167.633 288.173 164.514 287.373C162.96 287.004 161.465 286.453 160.064 285.733L169.203 271.054Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M192.762 340.696L187.042 324.633C190.124 323.733 193.407 323.569 196.577 324.16C200.551 324.934 204.133 326.888 206.77 329.72C209.406 332.553 210.95 336.104 211.158 339.823C211.245 341.289 211.112 342.76 210.761 344.194C210.435 345.636 209.901 347.033 209.172 348.346C208.1 350.316 206.615 352.073 204.8 353.518C202.988 354.961 200.882 356.063 198.604 356.76L192.883 340.66L192.762 340.696Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M230.825 99.1318L190.101 116.397L196.974 138.397L230.825 99.1318Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M230.824 99.1318H167.374L186.684 109.95L230.824 99.1318Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M179.333 126.122L190.1 116.397L230.825 99.1318L186.684 109.95L179.333 126.122Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M179.333 126.122L190.1 116.397L230.825 99.1318L179.333 126.122Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M179.333 126.122L190.1 116.397L230.825 99.1318L186.684 109.95L179.333 126.122Z"
          fill={theme.palette.primary.dark}
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_1268_106001"
            x1="502.12"
            x2="734.869"
            y1="593.645"
            y2="21.4544"
          >
            <stop stopColor={theme.palette.primary.main} />
            <stop offset="1" stopColor={theme.palette.primary.light} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
