import { RootState } from 'src/redux/store';
import { getAxiosEmptyRequest } from '../http/axios';
import { GET_FEATURES_ENABLED } from './RequestIds';
import { transformGetFeatures } from './ResponseUtils';

const getFeaturesUrl = '/api/v1/queries/feature-flags';

export function getFeaturesFlagsRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, { [key: string]: boolean }, any>();
  apiCall.setEndpointFromState = () => getFeaturesUrl;
  apiCall.method = 'GET';
  apiCall.reqId = GET_FEATURES_ENABLED;
  apiCall.transformResponseDataWithState = transformGetFeatures();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
