import { Outlet } from 'react-router-dom';
import Image from 'mui-image';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import useGetInfoCompany from 'src/hooks/useGetInfoCompany';
import { APP_ENV } from 'src/config';
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const isBusiness = APP_ENV === 'business' || APP_ENV === 'businesstest';
  const company = useGetInfoCompany();
  return (
    <>
      <HeaderStyle>
        {isBusiness && company.imageUrl ? (
          <Stack sx={{ position: 'absolute' }}>
            <Image
              bgColor={undefined}
              duration={200}
              errorIcon={<Logo />}
              fit={'contain'}
              height={50}
              src={company.imageUrl ?? ''}
              width={'100%'}
            />
          </Stack>
        ) : (
          <Logo />
        )}
      </HeaderStyle>
      <Outlet />
    </>
  );
}
