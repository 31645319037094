import { Dispatch } from '@reduxjs/toolkit';
import {
  OutActionFailure,
  OutActionStarted,
  OutActionSuccess,
} from 'src/services/http/redux/Types.d';
import { RequestActionFromObject } from 'src/services/http/redux/ReduxRequestAction';
import { ReduxActionInterface } from 'src/services/http/redux/ReduxRequestAction.d';
import { RequestInterface } from 'src/services/http/base/Request.d';

export default function adaptRequestFromReduxAction<
  StateType,
  ResponseType,
  ErrorType,
  DomainResponseType,
  DomainErrorType
>(
  _getState: () => StateType,
  next: Dispatch,
  action: ReduxActionInterface<
    StateType,
    ResponseType,
    ErrorType,
    DomainResponseType,
    DomainErrorType
  >
): RequestInterface<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType> {
  const call = RequestActionFromObject(action);
  call.onStart = () => {
    if (call.startedReqType) {
      const actionStart: OutActionStarted = { type: call.startedReqType };
      next(actionStart);
    }
  };
  const newOnSuccess =
    (type: string | undefined, method: (response: DomainResponseType) => void) =>
    (response: DomainResponseType) => {
      if (type) {
        const out: OutActionSuccess<DomainResponseType> = {
          type,
          payload: response,
        };
        next(out);
      }
      method(response);
    };
  call.onSuccess = newOnSuccess(call.successReqType, call.onSuccess);
  const newOnFailure =
    (type: string | undefined, method: (error: DomainErrorType) => void) =>
    (error: DomainErrorType) => {
      if (type) {
        const out: OutActionFailure<DomainErrorType> = {
          type,
          error,
        };
        next(out);
      }
      method(error);
    };
  call.onFailure = newOnFailure(call.failureReqType, call.onFailure);
  return call;
}
