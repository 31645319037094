import { AxiosResponse } from 'axios';

import { GetUserSettingsPayload } from 'src/redux/slices/AuthSlice/authSlice.d';
import { RootState } from 'src/redux/store';
import {
  GetUserSettingsMorePayload,
  GetUserSettingsResponseNetType,
  UpdateUserResponseNetType,
} from './NetTypes';

export function transformGetUserSettings(): (
  response: AxiosResponse<GetUserSettingsResponseNetType>,
  state: RootState
) => GetUserSettingsPayload {
  return (response: AxiosResponse<GetUserSettingsResponseNetType>, _state: RootState) => ({
    baseCurrency: response.data.baseCurrency,
    preferLanguage: response.data.preferLanguage,
    name: response.data.name,
    imageUrl: response.data.imageUrl,
    country: response.data.country,
    latestPriceUpdate: response.data.latestPriceUpdate,
    latestAutomaticPriceUpdate: response.data.latestAutomaticPriceUpdate,
    lastLogin: response.data.lastLogin,
    mfaEnabled: response.data.mfaEnabled,
    onboardingFinished: response.data.onboardingFinished,
  });
}

export function transformGetUserSettingsRequest(): (
  response: AxiosResponse<GetUserSettingsResponseNetType>,
  state: RootState
) => GetUserSettingsMorePayload {
  return ({ data }: AxiosResponse<GetUserSettingsResponseNetType>, _state: RootState) => {
    const org =
      !!data.office && !!data.organization
        ? { office: data.office, company: data.organization }
        : {};
    return {
      settings: {
        baseCurrency: data.baseCurrency ?? '',
        preferLanguage: data.preferLanguage,
        name: data.name,
        imageUrl: data.imageUrl,
        firstSurname: data.firstSurname,
        secondSurname: data.secondSurname,
        country: data.country,
        latestPriceUpdate: data.latestPriceUpdate,
        lastLogin: data.lastLogin,
        mfaEnabled: data.mfaEnabled,
        onboardingFinished: data.onboardingFinished,
        needsTour: data.needsTour,
        webSettings: data.webSettings,
        latestAutomaticPriceUpdate: data.latestAutomaticPriceUpdate,
        reportsGenerated: data.reportsGenerated,
        reportsLimit: data.reportsLimit,
        access: data.access,
      },
      ...org,
    };
  };
}

export function transformUpdateUserSettings(
  baseCurrency: string
): (
  response: AxiosResponse<GetUserSettingsResponseNetType>,
  state: RootState
) => GetUserSettingsPayload {
  return (_response: AxiosResponse<GetUserSettingsResponseNetType>, { auth }: RootState) => ({
    baseCurrency,
    preferLanguage: auth.user.preferLanguage ?? 'es',
    name: auth.user.name,
    imageUrl: auth.user.imageUrl,
    country: auth.user.country,
    mfaEnabled: auth.user.mfaEnabled ?? false,
    onboardingFinished: auth.user.onboardingFinished,
    latestAutomaticPriceUpdate: auth.user.latestAutomaticPriceUpdate ?? '',
  });
}

export function transformUpdateUserLanguage(
  preferLanguage: string
): (
  response: AxiosResponse<GetUserSettingsResponseNetType>,
  state: RootState
) => GetUserSettingsPayload {
  return (_response: AxiosResponse<GetUserSettingsResponseNetType>, { auth }: RootState) => ({
    baseCurrency: auth.user.baseCurrency,
    preferLanguage,
    name: auth.user.name,
    imageUrl: auth.user.imageUrl,
    country: auth.user.country,
    mfaEnabled: auth.user.mfaEnabled ?? false,
    onboardingFinished: auth.user.onboardingFinished ?? true,
    latestAutomaticPriceUpdate: auth.user.latestAutomaticPriceUpdate ?? '',
  });
}

export function transformUpdateUser(): (
  response: AxiosResponse<UpdateUserResponseNetType>,
  state: RootState
) => string {
  return (response: AxiosResponse<UpdateUserResponseNetType>, state: RootState) =>
    response.data.message;
}

export function transformSetupMfaCode(
  mfaEnabled: boolean
): (
  response: AxiosResponse<UpdateUserResponseNetType>,
  state: RootState
) => { mfaEnabled: boolean } {
  return (_response: AxiosResponse<UpdateUserResponseNetType>, state: RootState) => ({
    mfaEnabled,
  });
}

export function transformUserNeedsTourBody(
  needsTour: boolean
): (
  response: AxiosResponse<UpdateUserResponseNetType>,
  state: RootState
) => { needsTour: boolean } {
  return (_response: AxiosResponse<UpdateUserResponseNetType>, state: RootState) => ({
    needsTour,
  });
}
