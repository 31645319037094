import { Grid, Typography } from '@mui/material';
// components
import UploadCompanyLogo from 'src/components/upload/UploadCompanyLogo/UploadCompanyLogo';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  description: string;
  isIcon?: boolean;
  file: any | null;
  onSave: (newFile: any) => void;
};

export default function LogoSelector({ onSave, title, description, isIcon, file }: Props) {
  return (
    <Grid item md={6} xs={12}>
      <Typography color="text.primary" sx={{ mb: 2 }} variant="subtitle2">
        {title}
      </Typography>
      <Typography
        color="text.secondary"
        fontSize={12}
        sx={{ mb: 2, height: 54 }}
        textAlign={'left'}
        variant="body1"
      >
        {description}
      </Typography>
      <UploadCompanyLogo
        accept="image/*"
        file={file}
        hideRejectFiles
        isIcon={isIcon}
        maxSize={1000000}
        onSave={onSave}
      />
    </Grid>
  );
}
