import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './AuthGuard.View';
import { AuthGuardInputProps, AuthGuardOwnProps, AuthGuardEventProps } from './AuthGuard.Types';
import { RootState } from 'src/redux/store';

const mapStateToProps = (
  { auth }: RootState,
  ownProps: AuthGuardOwnProps
): AuthGuardInputProps => ({
  isAuthenticated: auth.isAuthenticated,
  isInitialized: auth.isInitialized,
  isReady: auth.isReady,
  hasBaseCurrency:
    auth.user.baseCurrency !== undefined &&
    !!auth.user &&
    auth.isInitialized &&
    auth.isReady &&
    auth.user.baseCurrency !== '',
  isBusiness:
    auth.user.privileges && auth.user.privileges.findIndex((i) => i === 'BUSINESS_FEATURES') !== -1,
});

const mapDispatchToProps: MapDispatchToPropsFunction<AuthGuardEventProps, AuthGuardOwnProps> = (
  _dispatch: ThunkDispatch<any, any, AnyAction>,
  _ownProps: AuthGuardOwnProps
) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(View);
