import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { CompanyType, InfoCompanyForm } from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { CountryType } from 'src/redux/slices/DataSlice/dataSlice.d';

// ----------------------------------------------------------------------

type OnboardingCompanyFormProps = {
  onSubmitForm: (data: InfoCompanyForm) => void;
  countries: CountryType[];
  company: CompanyType;
};

export default function OnboardingInfoForm({
  company,
  onSubmitForm,
  countries,
}: OnboardingCompanyFormProps) {
  const { address, phoneNumber, contactEmail, pointOfContact } = company;

  const Schema = Yup.object().shape({
    city: Yup.string().required('Por favor introduzca la ciudad'),
    street: Yup.string().required('Por favor introduzca la calle'),
    town: Yup.string().required('Por favor introduzca la provincia'),
    contact_email: Yup.string().required('Por favor introduzca un email de contacto'),
    point_of_contact: Yup.string().required('Por favor introduzca una persona de contacto'),
    country: Yup.string().required('Por favor introduzca el país'),
    zipCode: Yup.number().required('Por favor introduzca el código postal'),
    contactNumber: Yup.string()
      .required('Por favor introduzca el teléfono de contacto')
      .min(5, 'Debe de contener más de 5 dígitos')
      .max(10, 'No puede tener más de 10 digitos'),
    phone_code: Yup.string().required('Por favor seleccione el prefijo'),
  });

  const defaultValues = {
    city: address?.city ?? '',
    street: address?.street ?? '',
    town: address?.town ?? '',
    country: address?.country !== '' ? address?.country : '631' ?? '631',
    zipCode: address?.zipCode ?? undefined,
    contactNumber: phoneNumber?.telNumber ?? undefined,
    point_of_contact: pointOfContact ?? '',
    contact_email: contactEmail ?? '',
    phone_code: phoneNumber?.country ?? '631',
  };

  const methods = useForm<InfoCompanyForm>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const { reset, handleSubmit, watch } = methods;
  const watchValues = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [company]);

  const onSubmit = async (data: InfoCompanyForm) => {
    try {
      onSubmitForm(data);
      reset();
    } catch (error) {
      console.error(error);
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <RHFTextField label={'Persona de contacto'} name="point_of_contact" type={'text'} />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField label={'Email de contacto'} name="contact_email" type={'email'} />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField label={'Calle'} name="street" type={'text'} />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField label={'Ciudad'} name="city" type={'text'} />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField label={'Provincia'} name="town" type={'text'} />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField label={'Código postal'} name="zipCode" type={'number'} />
        </Grid>
        <Grid item md={12} xs={12}>
          <Stack alignItems="center" flexDirection="row">
            <RHFSelect
              label="País"
              name="phone_code"
              sx={{ mr: 3 }}
              value={watchValues.phone_code ?? ''}
            >
              {_.orderBy(countries, 'nameEs').map((val, index) => (
                <option key={index} value={val.id}>
                  {val.dialCode} {val.nameEs}
                </option>
              ))}
            </RHFSelect>
            <RHFTextField
              label={'Teléfono de contacto'}
              name="contactNumber"
              sx={{ minWidth: 200 }}
              type={'number'}
            />
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFSelect label="País" name="country" value={watchValues.country ?? '631'}>
            {_.orderBy(countries, 'nameEs').map((val, index) => (
              <option key={index} value={val.id}>
                {val.nameEs}
              </option>
            ))}
          </RHFSelect>
        </Grid>
      </Grid>
      <LoadingButton fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
        {'Siguiente'}
      </LoadingButton>
    </FormProvider>
  );
}
