import { ReactNode } from 'react';
import { Breakpoint } from '@mui/material';

export type ModalContent = {
  title: string;
  view: ReactNode | null;
  size: Breakpoint | false;
};

export const defaultContent: ModalContent = {
  title: '',
  view: null,
  size: false,
};
