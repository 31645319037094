/* eslint-disable max-statements */
/* eslint-disable complexity */
import {
  API_CALL,
  ReduxActionInterface,
  ReduxRequestActionInterface,
  ReduxCallObjectInterfaceLiteral,
} from 'src/services/http/redux/ReduxRequestAction.d';
import Request from 'src/services/http/base/Request';

export class ReduxRequest<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
  extends Request<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
  implements
    ReduxRequestActionInterface<
      StateType,
      ResponseType,
      ErrorType,
      DomainResponseType,
      DomainErrorType
    >
{
  startedReqType?: string;

  successReqType?: string;

  failureReqType?: string;

  constructor(
    req?: Request<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
  ) {
    super();
    this.reqId = req ? req.reqId : '';
    this.setEndpointFromState = req ? req.setEndpointFromState : undefined;
    this.method = req ? req.reqId : 'GET';
    this.setBodyFromState = req ? req.setBodyFromState : undefined;
    this.getHeadersFromState = req ? req.getHeadersFromState : () => ({});
    this.onStart = req ? req.onStart : () => undefined;
    this.onSuccess = req ? req.onSuccess : () => undefined;
    this.onFailure = req ? req.onFailure : () => undefined;
    this.onFinish = req ? req.onFinish : () => undefined;
    this.transformResponseDataWithState = req ? req.transformResponseDataWithState : undefined;
    this.transformErrorDataWithState = req ? req.transformErrorDataWithState : undefined;
    this.debugForcedResponse = req ? req.debugForcedResponse : { debugForced: 'disabled' };
  }

  getAction = (): ReduxActionInterface<
    StateType,
    ResponseType,
    ErrorType,
    DomainResponseType,
    DomainErrorType
  > => {
    if (this.setEndpointFromState !== undefined) {
      return {
        type: ReduxCallObjectInterfaceLiteral,
        [API_CALL]: {
          reqId: this.reqId,
          startedReqType: this.startedReqType,
          successReqType: this.successReqType,
          failureReqType: this.failureReqType,
          setEndpointFromState: this.setEndpointFromState,
          method: this.method,
          setBodyFromState: this.setBodyFromState,
          getHeadersFromState: this.getHeadersFromState,
          onStart: this.onStart,
          onSuccess: this.onSuccess,
          onFailure: this.onFailure,
          onFinish: this.onFinish,
          transformResponseDataWithState: this.transformResponseDataWithState,
          transformErrorDataWithState: this.transformErrorDataWithState,
          debugForcedResponse: this.debugForcedResponse,
        },
      };
    }
    throw new Error("You've to specify a function endpoint URL.");
  };
}

export function RequestActionFromObject<
  StateType,
  ResponseType,
  ErrorType,
  DomainResponseType,
  DomainErrorType
>(
  objectAction: ReduxActionInterface<
    StateType,
    ResponseType,
    ErrorType,
    DomainResponseType,
    DomainErrorType
  >
): ReduxRequest<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType> {
  const object = objectAction[API_CALL];
  if (object === undefined) {
    throw new Error(`The object action in incorrect: ${JSON.stringify(objectAction)}`);
  }
  const request = new ReduxRequest<
    StateType,
    ResponseType,
    ErrorType,
    DomainResponseType,
    DomainErrorType
  >();
  if ('reqId' in object) request.reqId = object.reqId;
  if ('setEndpointFromState' in object) request.setEndpointFromState = object.setEndpointFromState;
  if ('method' in object) request.method = object.method;
  if ('getHeadersFromState' in object) request.getHeadersFromState = object.getHeadersFromState;
  if ('startedReqType' in object) request.startedReqType = object.startedReqType;
  if ('successReqType' in object) request.successReqType = object.successReqType;
  if ('failureReqType' in object) request.failureReqType = object.failureReqType;
  if ('setBodyFromState' in object) request.setBodyFromState = object.setBodyFromState;
  if ('onStart' in object) request.onStart = object.onStart;
  if ('onSuccess' in object) request.onSuccess = object.onSuccess;
  if ('onFailure' in object) request.onFailure = object.onFailure;
  if ('onFinish' in object) request.onFinish = object.onFinish;
  if ('transformResponseDataWithState' in object)
    request.transformResponseDataWithState = object.transformResponseDataWithState;
  if ('transformErrorDataWithState' in object)
    request.transformErrorDataWithState = object.transformErrorDataWithState;
  if ('debugForcedResponse' in object) request.debugForcedResponse = object.debugForcedResponse;
  return request;
}
