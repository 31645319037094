import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
// hooks
import useLocales from 'src/hooks/useLocales';
import useGetRiskValues from './useGetRiskValues';

interface RiskValueSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

export default function RiskValueSelector({ value, onChange }: RiskValueSelectorProps) {
  const { translate } = useLocales();
  const { loading, data } = useGetRiskValues();

  if (loading) return <Skeleton variant="text" />;

  return (
    <TextField
      SelectProps={{ native: true }}
      fullWidth
      label="Selecciona activo libre"
      onChange={(event) => onChange(event.target.value)}
      select
      value={value}
    >
      {data &&
        data.map((val, index) => (
          <option key={val.id} value={val.id}>
            {translate(`riskFeeAsset.${val.code}`)}
          </option>
        ))}
    </TextField>
  );
}
