/* eslint-disable max-classes-per-file */
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, Middleware } from '@reduxjs/toolkit';
import NetClientAxios from 'src/services/http/axios/ServiceAxiosNetClient';
import ServiceClient from 'src/services/http/base/ServiceClient';
import { getServiceClientMiddleware } from 'src/services/http/redux/NetworkMiddleware';
import { ReduxRequest } from 'src/services/http/redux/ReduxRequestAction';
import Request from 'src/services/http/base/Request';
import {
  InterceptorRequestErrorInputFunction,
  InterceptorRequestSuccessInputFunction,
  RequestInterceptorListType,
} from 'src/services/http/base/RequestInterceptorUtils.d';
import {
  InterceptorResponseErrorInputFunction,
  InterceptorResponseSuccessInputFunction,
  ResponseInterceptorListType,
} from 'src/services/http/base/ResponseInterceptorUtils.d';
import RequestInterceptorList from 'src/services/http/base/RequestInterceptorUtils';
import ResponseInterceptorList from 'src/services/http/base/ResponseInterceptorUtils';
import * as Types from 'src/services/http/axios/ServiceAxiosNetClient.d';

export type AxiosNetClientConfig = Types.AxiosNetClientConfig;

export type AxiosInterceptorRequestSuccessInputFunctionType<StateType> =
  InterceptorRequestSuccessInputFunction<
    StateType,
    AxiosNetClientConfig,
    AxiosResponse,
    AxiosError
  >;

export type AxiosInterceptorRequestErrorInputFunctionType<StateType> =
  InterceptorRequestErrorInputFunction<StateType, AxiosNetClientConfig, AxiosResponse, AxiosError>;

export type AxiosInterceptorResponseSuccessInputFunctionType<StateType> =
  InterceptorResponseSuccessInputFunction<
    StateType,
    AxiosNetClientConfig,
    AxiosResponse,
    AxiosError
  >;

export type AxiosInterceptorResponseErrorInputFunctionType<StateType> =
  InterceptorResponseErrorInputFunction<StateType, AxiosNetClientConfig, AxiosResponse, AxiosError>;

export class AxiosRequestInterceptorList<State> extends RequestInterceptorList<
  State,
  AxiosNetClientConfig,
  AxiosResponse,
  AxiosError
> {}

export class AxiosResponseInterceptorList<State> extends ResponseInterceptorList<
  State,
  AxiosNetClientConfig,
  AxiosResponse,
  AxiosError
> {}

export function getAxiosEmptyRequest<State, DomainResponseType, DomainErrorType>(): Request<
  State,
  AxiosResponse,
  AxiosError,
  DomainResponseType,
  DomainErrorType
> {
  return new Request<State, AxiosResponse, AxiosError, DomainResponseType, DomainErrorType>();
}

export function getAxiosEmptyRequestAction<State, DomainResponseType, DomainErrorType>(
  req?: Request<State, AxiosResponse, AxiosError, DomainResponseType, DomainErrorType>
): ReduxRequest<State, AxiosResponse, AxiosError, DomainResponseType, DomainErrorType> {
  return new ReduxRequest<State, AxiosResponse, AxiosError, DomainResponseType, DomainErrorType>(
    req
  );
}

export function getAxiosNewClient<State>(
  baseUrl: string,
  state: () => State,
  baseHeaders: { [key: string]: string },
  requestInterceptorList: RequestInterceptorListType<
    State,
    AxiosNetClientConfig,
    AxiosResponse,
    AxiosError
  > = () => [],
  responseInterceptorList: ResponseInterceptorListType<
    State,
    AxiosNetClientConfig,
    AxiosResponse,
    AxiosError
  > = () => [],
  debugPrint = false,
  timeoutMillis = 10000
): ServiceClient<State, AxiosNetClientConfig, AxiosResponse, AxiosError> {
  return new ServiceClient<State, AxiosNetClientConfig, AxiosResponse, AxiosError>(
    NetClientAxios,
    baseUrl,
    state,
    baseHeaders,
    requestInterceptorList,
    responseInterceptorList,
    debugPrint,
    timeoutMillis
  );
}

export function getAxiosNewClientMiddleware<State>(
  baseUrl: string,
  baseHeaders: { [key: string]: string },
  requestInterceptorList: (
    getState: () => State,
    next: Dispatch
  ) => RequestInterceptorListType<State, AxiosNetClientConfig, AxiosResponse, AxiosError> = () =>
    () =>
      [],
  responseInterceptorList: (
    getState: () => State,
    next: Dispatch
  ) => ResponseInterceptorListType<State, AxiosNetClientConfig, AxiosResponse, AxiosError> = () =>
    () =>
      [],
  debugPrint = false,
  timeoutMillis = 10000
): Middleware {
  return getServiceClientMiddleware<State, AxiosNetClientConfig, AxiosResponse, AxiosError>(
    NetClientAxios,
    baseUrl,
    baseHeaders,
    debugPrint,
    timeoutMillis,
    requestInterceptorList,
    responseInterceptorList
  );
}
