import { Typography, Stack, StackProps } from '@mui/material';
import Image from 'mui-image';

// ----------------------------------------------------------------------

interface EmptyContentProps extends StackProps {
  title: string;
  img?: string;
  description?: string;
}

export default function EmptyContent({ title, description, img, sx, ...other }: EmptyContentProps) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        textAlign: 'center',
        p: (theme) => theme.spacing(8, 2),
        ...sx,
      }}
      {...other}
    >
      <Image
        duration={700}
        src={
          img ??
          'https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/public/assets/icons/empty/ic-content.svg'
        }
        width={180}
      />

      <Typography color="text.secondary" gutterBottom sx={{ mt: 3 }} variant="subtitle1">
        {title}
      </Typography>

      {description && (
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          {description}
        </Typography>
      )}
    </Stack>
  );
}
