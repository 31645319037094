import { AxiosResponse } from 'axios';

export function transformGetFeatures(): (response: AxiosResponse<{ [key: string]: boolean }>) => {
  [key: string]: boolean;
} {
  return (response: AxiosResponse<{ [key: string]: boolean }>) => ({
    ...response.data,
    isFetch: true,
  });
}
