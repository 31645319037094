import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// hooks
import useLocales from 'src/hooks/useLocales';
// services
import { getNetWorthRequestAction } from 'src/services/portfolio';
import { updateLatestPricesRequest } from 'src/services/securities';

export default function useUpdateLatestPrices() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const updatePrices = () => {
    setLoading(true);
    dispatch(
      updateLatestPricesRequest(
        () => {
          dispatch(getNetWorthRequestAction());
          setTimeout(() => window.location.reload(), 1000);
          setLoading(false);
        },
        () => {
          enqueueSnackbar(translate('navbar.error_prices'), {
            variant: 'error',
          });
          setLoading(false);
        }
      )
    );
  };

  return {
    updatePrices,
    loading,
  };
}
