import { lazy, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import Loadable from 'src/components/Loadable';
import Scrollbar from 'src/components/Scrollbar';
import { MAINTENANCE } from 'src/config';
import useLocales from 'src/hooks/useLocales';
import ModalManager from 'src/layouts/modal/ModalManager';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
import { RoutesProps } from './Routes.Types';
import { AuthRoute, DashboardRoute } from './modules';
import OAuth2RedirectHandler from './modules/OAuth2RedirectHandler';
import { PATH_DASHBOARD } from './paths';
import useCheckAppVersion from './useCheckAppVersion';
import useChangeFavIcon from 'src/hooks/useChangeFavIcon';

// ----------------------------------------------------------------------

export default function Router(props: RoutesProps) {
  const { currentLang, onChangeLang } = useLocales();
  const { boot, update } = useIntercom();

  useCheckAppVersion();
  useChangeFavIcon();

  useEffect(() => {
    props.onInitApp();
    boot();
  }, []);

  useEffect(() => {
    if (props.user.preferLanguage && currentLang.value !== props.user.preferLanguage)
      onChangeLang(props.user.preferLanguage);
  }, [props.user.preferLanguage]);

  useEffect(() => {
    let { name, email, firstSurname } = props.user;
    if (!email || email === '') {
      name = 'Usuario desconocido';
      email = '';
    }
    update({
      name: `${name}${firstSurname ? ' ' + firstSurname : ''}`,
      email: email,
    });
  }, [props.user]);

  const routes = [
    AuthRoute,
    DashboardRoute,
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: '500', element: <Page500 /> },
        { path: 'broker-connect', element: <BrokerConnect /> },
        { path: 'account-confirmation/:token', element: <AccountConfirmation /> },
        { path: 'recover-password/:token', element: <RecoverPassword /> },
        { path: '404', element: <NotFound /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: <Navigate replace to={PATH_DASHBOARD.general.summary} />,
          index: true,
        },
      ],
    },
    { path: '/oauth2/redirect', element: <OAuth2RedirectHandler /> },
    { path: '*', element: <Navigate replace to="/404" /> },
  ];

  const maintenanceRoute = { path: '*', element: <Maintenance /> };

  const renderRoutes = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRoutes(MAINTENANCE !== 'true' ? routes : [maintenanceRoute]);

  return (
    <>
      <Scrollbar>{renderRoutes()}</Scrollbar>
      <ModalManager />
    </>
  );
}

const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/dashboard/Pricing/Pricing')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const BrokerConnect = Loadable(lazy(() => import('../pages/BrokerConnect')));
const AccountConfirmation = Loadable(lazy(() => import('../pages/AccountConfirmation')));
const RecoverPassword = Loadable(lazy(() => import('../pages/RecoverPassword')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
