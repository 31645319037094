import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router';
// components
import SecurityAvatar from 'src/components/SecurityAvatar';
import ConnectionItemTitle from './ConnectionItemTitle';
import ConnectionItemDescription from './ConnectionItemDescription';
// types
import { ConnectionType } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { PATH_DASHBOARD } from 'src/routes/paths';
// utils
import { entitiesUrl } from 'src/utils/formatUrl';

// ----------------------------------------------------------------------

type Props = {
  connection: ConnectionType;
  onClose: () => void;
};

export default function ConnectionItem({ connection, onClose }: Props) {
  const navigate = useNavigate();

  const onPressConnection = () => {
    onClose();
    navigate(PATH_DASHBOARD.general.wallets.newConnection, { state: { connection } });
  };

  return (
    <ListItemButton
      onClick={onPressConnection}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
      }}
    >
      <ListItemAvatar>
        <SecurityAvatar
          backgroundColor={'white'}
          fit={'contain'}
          size={40}
          url={entitiesUrl(connection.entity)}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<ConnectionItemTitle connection={connection} />}
        secondary={<ConnectionItemDescription pending={connection.pending} />}
      />
    </ListItemButton>
  );
}
