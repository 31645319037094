import { useState } from 'react';
// services
import { updateCompanyImageRequest } from 'src/services/companies';
import { getNetworkClient } from 'src/services/config';

// ----------------------------------------------------------------------

type Props = {
  companyId: string;
  onFinish: () => void;
};

export default function useGetUpdateLogos({ companyId, onFinish }: Props) {
  const [logo, setLogo] = useState<any | null>(null);
  const [logoIcon, setLogoIcon] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSuccess = () => {
    setLoading(false);
    onFinish();
  };

  const onFailure = () => {
    setLoading(false);
  };

  const onSuccessFirst = () => {
    const formData = new FormData();
    if (logoIcon) {
      formData.append('file', logoIcon);
      getNetworkClient('multipart').executeRequest(
        updateCompanyImageRequest(companyId, formData, true, onSuccess, onFailure)
      );
    } else {
      onSuccess();
    }
  };

  const onUpdateLogos = () => {
    setLoading(true);
    const formData = new FormData();
    if (logo) {
      formData.append('file', logo);
      getNetworkClient('multipart').executeRequest(
        updateCompanyImageRequest(companyId, formData, false, onSuccessFirst, onFailure)
      );
    } else {
      onSuccessFirst();
    }
  };

  return {
    onUpdateLogos,
    logoIcon,
    logo,
    loading,
    onUpdateLogoIcon: setLogoIcon,
    onUpdateLogo: setLogo,
  };
}
