import { styled } from '@mui/material/styles';
import { DropzoneOptions, DropzoneState } from 'react-dropzone';

import PlaceholderSelector from './PlaceholderSelector';
import { isString } from 'lodash';

// ----------------------------------------------------------------------

export interface Props extends DropzoneOptions {
  dropZoneState: DropzoneState;
  error?: boolean;
  disabled?: boolean;
  hasFile?: boolean;
  file: any | null;
}

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: 10,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
});

// ----------------------------------------------------------------------

export default function DropZoneSelector({ dropZoneState, hasFile, error, disabled, file }: Props) {
  const { getRootProps, isDragActive, getInputProps, isDragReject } = dropZoneState;

  return (
    <DropZoneStyle
      {...getRootProps()}
      sx={{
        ...(isDragActive && { opacity: 0.72 }),
        backgroundColor: 'background.paper',
      }}
    >
      {!hasFile && <input {...getInputProps()} />}
      {file && (
        <img
          alt=""
          src={isString(file) ? file : file.preview}
          style={{
            objectFit: 'contain',
          }}
        />
      )}
      {!disabled && !hasFile && (
        <PlaceholderSelector error={error} hasFile={file} isDragReject={isDragReject} />
      )}
    </DropZoneStyle>
  );
}
