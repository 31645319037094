import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import { get } from 'lodash';
// hooks
import useSettings from 'src/hooks/useSettings';
// redux
import { RootState, useSelector } from 'src/redux/store';
// theme
import breakpoints from './breakpoints';
import customPalette from './customPalette';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import typography from './typography';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode } = useSettings();

  const themeColorApp = useSelector((state: RootState) =>
    get(state, ['settings', 'themeColorApp'])
  );

  const isLight = themeMode === 'light';

  const palette = customPalette(themeColorApp);

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: 'ltr',
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeColorApp]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
