/* eslint-disable no-console */
import { RequestInterface } from 'src/services/http/base/Request.d';
import {
  NetClientConstructor,
  NetClientInterface,
  ServiceClientInterface,
  NetClientConfigWithID,
} from 'src/services/http/base/CommonTypes.d';
import { RequestInterceptorListType } from 'src/services/http/base/RequestInterceptorUtils.d';
import { ResponseInterceptorListType } from 'src/services/http/base/ResponseInterceptorUtils.d';
import { log } from 'src/utils/logger';

class ServiceClient<
  StateType,
  ConfigType extends NetClientConfigWithID<ResponseType, ErrorType>,
  ResponseType,
  ErrorType
> implements ServiceClientInterface<StateType, ConfigType, ResponseType, ErrorType>
{
  getState: () => StateType;

  debugPrint: boolean;

  netClient: NetClientInterface<ResponseType, ErrorType>;

  constructor(
    NetClientCtor: NetClientConstructor<ConfigType, ResponseType, ErrorType>,
    baseUrl: string,
    getState: () => StateType,
    baseHeaders: { [key: string]: string },
    requestInterceptorList: RequestInterceptorListType<
      StateType,
      ConfigType,
      ResponseType,
      ErrorType
    > = () => [],
    responseInterceptorList: ResponseInterceptorListType<
      StateType,
      ConfigType,
      ResponseType,
      ErrorType
    > = () => [],
    debugPrint = false,
    timeoutMillis?: number
  ) {
    this.getState = getState;
    this.debugPrint = debugPrint;
    this.netClient = new NetClientCtor(
      baseUrl,
      requestInterceptorList(this),
      responseInterceptorList(this),
      baseHeaders,
      timeoutMillis,
      debugPrint
    );
  }

  onInnerSuccess =
    <DomainResponseType, DomainErrorType>(
      req: RequestInterface<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
    ) =>
    (response: ResponseType) => {
      if (this.debugPrint) {
        log('[NetJoyBase] Final response before transformation: ', response);
      }
      let transformedResponse: DomainResponseType;
      if (req.transformResponseDataWithState) {
        transformedResponse = req.transformResponseDataWithState(response, this.getState());
      } else {
        transformedResponse = <DomainResponseType>(<unknown>response);
      }
      if (this.debugPrint) {
        log('[NetJoyBase] Final response after transformation: ', transformedResponse);
      }
      if (req.onSuccess) {
        req.onSuccess(transformedResponse);
      }
    };

  onInnerFailure =
    <DomainResponseType, DomainErrorType>(
      req: RequestInterface<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
    ) =>
    (error: ErrorType): void => {
      if (this.debugPrint) {
        log('[NetJoyBase] Final error before transformation: ', error);
      }
      let transformedError: DomainErrorType;
      if (req.transformErrorDataWithState) {
        transformedError = req.transformErrorDataWithState(error, this.getState());
      } else {
        transformedError = <DomainErrorType>(<unknown>error);
      }
      if (this.debugPrint) {
        log('[NetJoyBase] Final error after transformation: ', transformedError);
      }
      if (req.onFailure) {
        req.onFailure(transformedError);
      }
    };

  onInnerFinish =
    <DomainResponseType, DomainErrorType>(
      req: RequestInterface<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
    ) =>
    (): void => {
      if (req.onFinish) {
        req.onFinish();
      }
    };

  executeDirectCallWithConfig<T extends NetClientConfigWithID<ResponseType, ErrorType>>(
    config: T
  ): Promise<ResponseType> {
    return this.netClient.executeDirectCallWithConfig<T>(config);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, complexity, max-statements
  executeRequest<DomainResponseType, DomainErrorType>(
    req: RequestInterface<StateType, ResponseType, ErrorType, DomainResponseType, DomainErrorType>
  ) {
    let body = '';
    if (req.setBodyFromState) {
      body = req.setBodyFromState(this.getState());
    }

    if (this.debugPrint) {
      log(`[NetJoyBase] Started req ${req.reqId} : ${JSON.stringify(req)}`);
    }

    // Select debug mode response
    let debugForcedResponse: Partial<ResponseType> | undefined;
    let debugForcedError: Partial<ErrorType> | undefined;
    if (req.debugForcedResponse?.debugForced) {
      if (req.debugForcedResponse?.debugForced === 'error') {
        debugForcedError = req.debugForcedResponse?.debugForcedError;
      } else if (req.debugForcedResponse?.debugForced === 'response') {
        debugForcedResponse = req.debugForcedResponse?.debugForcedResponse;
      }
    }

    // Execute the call
    return this.netClient.makeCallFromParams(
      req.reqId,
      req.setEndpointFromState!(this.getState()),
      req.method,
      body,
      req.getHeadersFromState(this.getState()),
      req.onStart,
      this.onInnerSuccess(req),
      this.onInnerFailure(req),
      this.onInnerFinish(req),
      debugForcedResponse,
      debugForcedError
    );
  }
}

export default ServiceClient;
