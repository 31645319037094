import { ThemeColorsApp } from '../SettingsSlice/settingsSlice.d';

export type CompanyType = {
  id: string;
  companyName: string;
  name: string;
  firstSurname: string;
  secondSurname?: string;
  cif: string;
  phoneNumber: CompanyPhoneNumber;
  contactEmail: string;
  employeesAmounts: number;
  clientsAmount: number;
  pointOfContact: string;
  address: CompanyAddress;
  onboardingLanguage: string;
  createdAt?: string;
  imageUrl?: string;
  logoUrl?: string;
  themeCompany: ThemeColorsApp;
};

export type OfficeType = {
  id: string;
  name: string;
  pointOfContact: string;
  contactEmail: string;
  phoneNumber: CompanyPhoneNumber;
  address: CompanyAddress;
};

export type ClientsType = {
  id: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  employeeId: string;
  email: string;
  imageUrl?: string;
  employeeName?: string;
  signDate?: string;
};

export type EmployeeType = {
  id: string;
  name: string;
  firstSurname: string;
  secondSurname?: string;
  email: string;
  imageUrl?: string;
  officeDto: OfficeDto;
  role: CompanyRole;
  clientsAmount: number;
};

export type CompanyAddress = {
  street: string;
  city: string;
  town: string;
  country: string;
  zipCode: number;
};

export type OfficeDto = {
  id: string;
  name: string;
  pointOfContact: string;
  contactEmail: string;
};

export type InfoCompanyForm = {
  city: string;
  cif: string;
  street: string;
  town: string;
  zipCode: number;
  country: string;
  contactNumber: string;
  contact_email: string;
  point_of_contact: string;
  phone_code: string;
  imageUrl: File | any;
  logoUrl: File | any;
};

export type CompanyPhoneNumber = {
  country?: string;
  telNumber?: string;
};

export type EmployeeCompanyForm = {
  name: string;
  email: string;
  firstSurname: string;
  secondSurname?: string;
  officeId: string;
  onboardingLanguage: string;
  officeAdmin: boolean;
};

export type EditEmployeeCompanyForm = {
  id: string;
  name: string;
  firstSurname: string;
  secondSurname?: string;
  email: string;
  officeId: string;
  officeAdmin: boolean;
};

export type OfficeCompanyForm = {
  name: string;
  city: string;
  street: string;
  town: string;
  zipCode: number;
  country: string;
  contactNumber: string;
  contact_email: string;
  point_of_contact: string;
  phone_code: string;
};

export type ClientCompanyForm = {
  clientName: string;
  clientFirstSurname: string;
  clientSecondSurname: string;
  clientEmail: string;
  birthDate?: Date | null;
  signDate?: Date | null;
  nationalID?: string;
  country?: string;
  baseCurrency: string;
  contactNumber?: number;
  phone_code?: string;
  personType?: string;
  administrator?: string;
  residenceType: string;
  leiCode?: string;
  clientKind: string;
  gender?: string;
  type?: string;
  cif?: string;
  employee?: string;
  access: ClientAccess;
};

type ClientAccess = 'NO_ACCESS' | 'CONNECT_ACCOUNTS' | 'FULL_ACCESS';

export type CompanySettingsPayload = {
  id: string;
  imageUrl?: string;
  logoUrl?: string;
  name: string;
  themeCompany: ThemeColorsApp;
};

export const CompanyRole =
  'ROLE_BUSINESS_ADMIN' | 'ROLE_BUSINESS_EMPLOYEE' | 'ROLE_BUSINESS_OFFICE_ADMIN';

export const defaultCompany: CompanyType = {
  id: undefined,
  name: undefined,
  cif: undefined,
  phoneNumber: undefined,
  onboardingLanguage: undefined,
  contactEmail: undefined,
  address: undefined,
  pointOfContact: undefined,
};

export const defaultOffice: OfficeType = {
  id: undefined,
  name: undefined,
  contactEmail: undefined,
  pointOfContact: undefined,
};

export const defaultClientForm: ClientCompanyForm = {
  clientName: '',
  clientFirstSurname: '',
  clientSecondSurname: '',
  clientEmail: '',
  birthDate: '',
  nationalID: '',
  country: '',
  baseCurrency: 'EUR',
  contactNumber: undefined,
  phone_code: '',
  personType: 'INDIVIDUAL',
  administrator: undefined,
  residenceType: '',
  leiCode: undefined,
  clientKind: '',
  gender: undefined,
  type: undefined,
  cif: undefined,
  employee: undefined,
  access: 'NO_ACCESS',
};
