import {
  Box,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// paths
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
// components
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import MyAvatar from 'src/components/MyAvatar';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// types
import BasicTooltip from 'src/components/BasicTooltip';
import useGetInfoCompany from 'src/hooks/useGetInfoCompany';
import useSettings from 'src/hooks/useSettings';
import { updateTheme } from 'src/redux/slices/SettingsSlice/settingsSlice';
import { dispatch } from 'src/redux/store';
import Context, { ContextType } from '../../context';
import useGetInfoUser from '../../services/useGetUserInfo';
import { AccountPopoverProps } from './AccountPopover.Types';
import { clientReset } from 'src/redux/slices/ClientSlice/clientSlice';
import useGetClientInfo from 'src/hooks/useGetClientInfo';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  cursor: 'pointer',
  padding: theme.spacing(2, 2.5),
  overflow: 'hidden',
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    to: PATH_DASHBOARD.user.account,
    label: 'account_pop.profile',
    component: RouterLink,
  },
  {
    href: 'https://www.vumi.io/blog',
    label: 'Blog',
    target: '_blank',
    component: Link,
  },
  {
    href: 'https://www.vumi.io/seguridad',
    label: 'account.security',
    target: '_blank',
    component: Link,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({
  logoutAction,
  user,
  updateUserWebSettings,
}: AccountPopoverProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { isCollapse }: ContextType = useContext<ContextType>(Context);
  const { translate } = useLocales();
  const client = useGetClientInfo();
  const isMountedRef = useIsMountedRef();
  const { isBusiness } = useGetInfoUser();
  const { id } = useGetInfoCompany();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const isLight = theme.palette.mode === 'light';

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (option?: string) => {
    if (client && client.id !== '' && option === 'account_pop.profile') {
      dispatch(clientReset());
    }
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      const loginUrl = isBusiness ? `${PATH_AUTH.login}?company=${id}` : PATH_AUTH.login;

      logoutAction(() => navigate(loginUrl, { replace: true }));

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleChangeMode = () => {
    dispatch(updateTheme({ themeMode: isLight ? 'dark' : 'light' }));
    updateUserWebSettings(isLight ? 'dark' : 'light', themeStretch);
  };

  return (
    <>
      <StyledRoot
        onClick={handleOpen}
        sx={{ justifyContent: isCollapse ? 'center' : 'space-between' }}
      >
        <MyAvatar user={user} />
        {!isCollapse && (
          <>
            <Stack justifyContent="center" sx={{ ml: { xs: 1, sm: 1.5 } }}>
              <Typography
                color={'text.secondary'}
                fontSize={12}
                maxWidth={110}
                noWrap
                variant="subtitle2"
              >
                {`${translate('auth.hello')} ${user.name}`}
              </Typography>
              <Typography color="text.primary" noWrap sx={{ lineHeight: 1 }} variant="subtitle2">
                {translate('navbar.account')}
              </Typography>
            </Stack>
            <Iconify icon="iconamoon:menu-kebab-vertical-bold" ml="auto" />
          </>
        )}
      </StyledRoot>
      <MenuPopover
        anchorEl={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        arrow="left-bottom"
        onClose={() => handleClose()}
        open={Boolean(open)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography noWrap variant="subtitle2">
            {user?.name}
          </Typography>
          <Typography noWrap sx={{ color: 'text.secondary' }} variant="body2">
            {user?.email}
          </Typography>
        </Box>
        {!isBusiness && (
          <>
            <Divider sx={{ borderStyle: 'dashed', my: 1 }} />
            <Stack alignItems={'center'} flexDirection={'row'} sx={{ my: 1.5, px: 2 }}>
              <Typography color="text.primary" noWrap sx={{ mr: 2 }} variant="subtitle2">
                {'Modo claro'}
              </Typography>
              <BasicTooltip
                title={
                  isLight ? 'Pulsa para pasar a modo osucuro' : 'Pulsa para pasar a modo claro'
                }
              >
                <IconButton onClick={handleChangeMode}>
                  <Iconify
                    color="text.primary"
                    icon={isLight ? 'iconamoon:mode-dark-light' : 'tdesign:mode-light'}
                  />
                </IconButton>
              </BasicTooltip>
            </Stack>
          </>
        )}
        <Divider sx={{ borderStyle: 'dashed', my: 1 }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem {...option} key={option.label} onClick={() => handleClose(option.label)}>
            <Typography variant="body2">{translate(option.label)}</Typography>
          </MenuItem>
        ))}
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleLogout} sx={{ color: 'error.main' }}>
          <Typography variant="subtitle2">{translate('account_pop.logout')}</Typography>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
