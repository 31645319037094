import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import View from './Login.View';
import { LoginInputProps, LoginOwnProps, LoginEventProps } from './Login.Types';
import { RootState } from 'src/redux/store';
import { loginRequestAction, addEmailToWaitingListRequest } from 'src/services/auth';
import { LoginPayload } from 'src/redux/slices/AuthSlice/authSlice.d';
import { showModal } from 'src/redux/slices/ModalSlice/modalSlice';
import { renderSendBetaEmailModal } from 'src/layouts/modal/ModalList';
import { getNetworkClient } from 'src/services/config';

const mapStateToProps = (state: RootState, ownProps: LoginOwnProps): LoginInputProps => ({});

const mapDispatchToProps: MapDispatchToPropsFunction<LoginEventProps, LoginOwnProps> = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  _ownProps: LoginOwnProps
) => ({
  loginService: (
    email: string,
    password: string,
    onSuccess: (data: LoginPayload) => void,
    onFailure: (errorMessage: string) => void
  ) => {
    dispatch(
      loginRequestAction(email, password, onSuccess, ({ response }) => {
        onFailure(response.data);
      })
    );
  },
  showBetaModal: (isLoading: boolean, onSave: (email: string, onSuccess: () => void) => void) => {
    dispatch(
      showModal({
        title: '',
        size: 'xs',
        view: renderSendBetaEmailModal(isLoading, onSave, dispatch),
      })
    );
  },
  sendEmailBeta: (email: string, onSuccess: () => void, onFailure: () => void) => {
    getNetworkClient().executeRequest(addEmailToWaitingListRequest(email, onSuccess, onFailure));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
