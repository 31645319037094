import {
  addClient,
  addClientDividends,
  addClientPortfolio,
  addClientPortfolios,
  addClientPositions,
  getClientAccounts,
  getClientConnections,
  getClientMovementsCount,
  getClientMovementsPortfolio,
  getClientOperations,
  updateClientAllInfo,
  updateClientNetWorth,
  updateClientPortfolioEntity,
  updateClientPortfolioRisk,
  updateConnection,
  updateNetWorthPeriod,
} from 'src/redux/slices/ClientSlice/clientSlice';
import { AddClientPayload } from 'src/redux/slices/ClientSlice/clientSlice.d';
import {
  ConnectionType,
  DividendsByPeriod,
  GetAccountsPayload,
  ImportError,
  OperationsPayload,
  PerformanceType,
  Portfolio,
  PortfolioEntity,
  PortfolioMap,
  Transaction,
  TransactionCountPayload,
  TransactionsPayload,
  UpdateAllPayload,
  UpdatePortfolioEntity,
  UpdatePortfolioRiskPayload,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState, store } from 'src/redux/store';
import { transformGetCurrentAccounts } from 'src/services/accounts/ResponseUtils';
import {
  transformClientGetAllInfo,
  transformGetClientsNotifications,
  transformGetConnectionsNetworth,
  transformGetImportErrors,
  transformGetInfoClient,
  transformGetMovements,
  transformGetMovementsCount,
  transformGetNetWorthPeriod,
  transformGetOperations,
  transformGetPorfolio,
  transformGetPorfolioPositions,
  transformGetPorfolios,
  transformGetPorfoliosAction,
  transformGetPorfoliosCompleteAction,
  transformGetPortfolioDividends,
  transformGetSkippedMovements,
  transformGetSummaryPorfolio,
  transformUpdateClientPortfolio,
  transformUpdatePortfolioSettings,
  transformRiskPortfolio,
  transformGetPortfolioBonds,
} from 'src/services/client/ResponseUtils';
import { getAxiosEmptyRequest, getAxiosEmptyRequestAction } from 'src/services/http/axios';
import { setUpdatePortfolioSettingsBody } from 'src/services/portfolio/RequestUtils';
import { setUpdateAvConnectionBody } from 'src/services/connections/RequestUtils';
import {
  transformGetNetWorth,
  transformGetAllDividends,
  transformGetAllMovements,
} from 'src/services/portfolio/ResponseUtils';
import { generalCommandsUrl, generalQueriesUrl } from '../EnpointConstants';
import { GetCurrentAccountsType } from '../accounts/NetTypes';
import {
  GET_PORTFOLIO_RISK,
  GET_PORTFOLIO_RISK_FAILURE,
  GET_PORTFOLIO_RISK_STARTED,
  GET_PORTFOLIO_SUMMARY_DASH,
  GET_PORTFOLIO_SUMMARY_DASH_FAILURE,
  GET_PORTFOLIO_SUMMARY_DASH_STARTED,
} from '../portfolio/RequestIds';
import { SecurityInfoNetType } from '../securities/NetTypes';
import { transformgetInfoSecurity } from '../securities/ResponseUtils';
import {
  GET_MOVEMENTS_COUNT,
  GET_MOVEMENTS_COUNT_FAILURE,
  GET_MOVEMENTS_COUNT_STARTED,
} from '../transactions/RequestIds';
import { GetClientsNotificationPayload, GetInfoClientResponseNetType } from './NetTypes';
import {
  GET_CLIENT_ALL_DIVIDENDS,
  GET_CLIENT_ALL_INFO,
  GET_CLIENT_ALL_INFO_FAILED,
  GET_CLIENT_ALL_INFO_STARTED,
  GET_CLIENT_CONNECTIONS,
  GET_CLIENT_CONNECTIONS_FAILURE,
  GET_CLIENT_CONNECTIONS_STARTED,
  GET_CLIENT_CONNECTION_DETAIL,
  GET_CLIENT_CONNECTION_DETAIL_FAILURE,
  GET_CLIENT_CONNECTION_DETAIL_STARTED,
  GET_CLIENT_CREDENTIALS_NETWORTH,
  GET_CLIENT_CREDENTIALS_NETWORTH_FAILURE,
  GET_CLIENT_CREDENTIALS_NETWORTH_STARTED,
  GET_CLIENT_NET_WORTH,
  GET_CLIENT_NET_WORTH_FAILED,
  GET_CLIENT_NET_WORTH_PERIOD,
  GET_CLIENT_NET_WORTH_PERIOD_FAILED,
  GET_CLIENT_NET_WORTH_PERIOD_STARTED,
  GET_CLIENT_NET_WORTH_STARTED,
  GET_CLIENT_NOTIFICATIONS,
  GET_CURRENT_ACCOUNTS_CLIENT,
  GET_DIVIDENDS_CLIENT,
  GET_DIVIDENDS_CLIENT_FAILURE,
  GET_DIVIDENDS_CLIENT_STARTED,
  GET_INFO_CLIENT,
  GET_MOVEMENTS_CLIENT,
  GET_MOVEMENTS_CLIENT_FAILURE,
  GET_MOVEMENTS_CLIENT_STARTED,
  GET_OPERATIONS_CLIENT,
  GET_OPERATIONS_CLIENT_FAILURE,
  GET_OPERATIONS_CLIENT_STARTED,
  GET_PORTFOLIOS_CLIENT,
  GET_PORTFOLIOS_FAILURE_CLIENT,
  GET_PORTFOLIOS_STARTED_CLIENT,
  GET_PORTFOLIO_CLIENT,
  GET_PORTFOLIO_CLIENT_IMPORT_ERRORS,
  GET_POSITIONS_CLIENT,
  GET_POSITIONS_CLIENT_FAILURE,
  GET_POSITIONS_CLIENT_STARTED,
  GET_SECURITY_INFO,
  GET_SKIPPED_CLIENT_MOVEMENTS,
  MARK_READ_CLIENT_NOTIFICATIONS,
  UPDATE_CLIENT_PORTFOLIO,
  UPDATE_CLIENT_PORTFOLIO_FAILED,
  UPDATE_CLIENT_PORTFOLIO_SETTINGS,
  UPDATE_CLIENT_PORTFOLIO_STARTED,
} from './RequestIds';
import axios from 'axios';
import { BASE_URL } from 'src/config';
import {
  transformGetConnectionDetails,
  transformGetConnections,
  transformUpdateAvConnection,
} from '../connections/ResponseUtils';
import { UpdateAvConnectionBody } from '../connections/NetTypes';
import {
  UPDATE_AVAILABILITY_CONNECTION,
  UPDATE_AVAILABILITY_CONNECTION_STARTED,
  UPDATE_AVAILABILITY_CONNECTION_FAILURE,
} from '../connections/RequestIds';
import { GetMovementsResponseNetType } from '../portfolio/NetTypes';

// Commands
const clientsCommand = generalCommandsUrl + `/clients`;
const updateClientPortfolioSettingsUrl = (id: string, clientId: string) =>
  clientsCommand + `/${clientId}/portfolios/${id}/settings`;
const updateClientPortfolioUrl = (id: string) => generalCommandsUrl + `/portfolios/${id}`;
const updateAvailabilityConnectionUrl = (id: string) =>
  `/api/v1/commands/credentials/${id}/availability`;
const readClientsNotificationsUrl = (notificationIds: string[]) =>
  clientsCommand + `/notifications/read?notificationIds=${notificationIds}`;
const updatePortfolioEntityUrl = (id: string, entityId: string) =>
  generalCommandsUrl + `/portfolios/${id}/entity?entity=${entityId}`;

// Queries
const clientsQuery = generalQueriesUrl + `/clients`;
const getPortfoliosUrl = (clientId: string) => clientsQuery + `/${clientId}/portfolios`;
const getSummaryDashUrl = (id: string, clientId: string) => getPortfoliosUrl(clientId) + `/${id}`;
const getPerformanceUrl = (
  clientId: string,
  id: string,
  fullLoad: boolean,
  period: string = '',
  type: PerformanceType = 'MWRR',
  startDate: string = '',
  endDate: string = ''
) =>
  clientsQuery +
  `/${clientId}/portfolios/${id}/performance?period=${period}&from=${startDate}&to=${endDate}&method=${type}&fullLoad=${fullLoad}`;
const getPortfolioPositionsIdUrl = (clientId: string, id: string) =>
  clientsQuery + `/${clientId}/portfolios/${id}/positions`;
const getPortfolioDividendsUrl = (
  clientId: string,
  id: string,
  period: string,
  type: string = 'DIVIDENDS'
) => clientsQuery + `/${clientId}/portfolios/${id}/income?period=${period}&type=${type}`;
const getRiskUrl = (
  clientId: string,
  id: string,
  period: string = '',
  startDate: string = '',
  endDate: string = ''
) =>
  clientsQuery +
  `/${clientId}/portfolios/${id}/risk?period=${period}&from=${startDate}&to=${endDate}`;
const getOperationsUrl = (clientId: string, portfolioId: string, year: number, method = 'count') =>
  clientsQuery +
  `/${clientId}/portfolios/${portfolioId}/transactions/aggregation/${year}?method=${method}`;
const getPortfolioMovementsUrl = (
  clientId: string,
  portfolioId: string,
  size: number,
  page: number,
  type: string,
  securityType: string,
  startDate?: string,
  endDate?: string,
  min?: string,
  max?: string,
  security?: string,
  unConfirmed?: boolean,
  download?: boolean,
  entity: string = '',
  portfolioName: string = ''
) =>
  clientsQuery +
  `/${clientId}/portfolios/${portfolioId}/transactions${download ? '/download' : ''}?types=${
    type === 'NOT_CONFIRMED' ? 'ALL' : type
  }&size=${size}&page=${page}
    &startDate=${startDate}&endDate=${endDate}&security=${security}&unConfirmed=${unConfirmed}&minAmount=${min}&maxAmount=${max}&securityType=${
    securityType === 'ALL' ? '' : securityType
  }&entity=${entity}&portfolioName=${portfolioName}`;

const getAllMovementsUrl = (
  clientId: string,
  size: number,
  page: number,
  type: string,
  securityType: string,
  startDate?: string,
  endDate?: string,
  min?: string,
  max?: string,
  security?: string,
  unConfirmed?: boolean,
  download?: boolean,
  entity: string = '',
  portfolioName: string = ''
) =>
  clientsQuery +
  `/${clientId}/portfolios/all/transactions${download ? '/download' : ''}?type=${
    type === 'NOT_CONFIRMED' ? 'ALL' : type
  }&size=${size}&page=${page}
      &startDate=${startDate}&endDate=${endDate}&security=${security}&unConfirmed=${unConfirmed}&minAmount=${min}&maxAmount=${max}&securityType=${
    securityType === 'ALL' ? '' : securityType
  }&entity=${entity}&portfolioName=${portfolioName}`;

const getSkippedPortfolioMovementsUrl = (
  clientId: string,
  portfolioId: string,
  page: number,
  name: string,
  size: number = 10,
  download?: boolean
) =>
  clientsQuery +
  `/${clientId}/portfolios/${portfolioId}/transactions${
    download ? '/download' : ''
  }?skipped=${true}&size=${size}&page=${page}&startDate=${''}&endDate=${''}&unConfirmed=${false}&security=${name}`;
const getPortfolioMovementsCountUrl = (clientId: string, portfolioId: string) =>
  clientsQuery + `/${clientId}/portfolios/${portfolioId}/transactions/count`;
const getCurrentAccountsUrl = (clientId: string) => clientsQuery + `/${clientId}/cash_accounts`;
const getClientInfoUrl = (clientId: string) => clientsQuery + `/${clientId}/information`;
const getImportErrorsUrl = (clientId: string, id: string) =>
  clientsQuery + `/${clientId}/portfolios/${id}/errors`;
const getClientNetWorthUrl = (clientId: string) => clientsQuery + `/${clientId}/net_worth`;
const getClientAllInfoUrl = (clientId: string, type: string, fullLoad: boolean, period: string) =>
  clientsQuery + `/${clientId}/portfolios/all?method=${type}&fullLoad=${fullLoad}&period=${period}`;
const getClientNetWorthPeriodUrl = (clientId: string, period: string, isFirstLoad: boolean) =>
  clientsQuery + `/${clientId}/home?period=${period}&isFirstLoad=${isFirstLoad}`;
const getInfoSecurityUrl = (
  id: string,
  clientId: string,
  period: string,
  startDate: string = '',
  endDate: string = ''
) =>
  generalQueriesUrl +
  `/securities/${id}/clients/${clientId}?period=${period}&from=${startDate}&to=${endDate}`;
const getClientsNotifications = (
  page: number,
  size: number,
  isRead?: boolean,
  notificationTypes?: string[],
  clientId?: string
) =>
  clientsQuery +
  `/notifications?page=${page}&size=${size}${
    notificationTypes ? '&notificationTypes=' + notificationTypes : ''
  }${clientId ? '&clientId=' + clientId : ''}&isRead=${isRead}`;
const getClientConnectionsUrl = (clientId: string) => clientsQuery + `/${clientId}/credentials`;
const getConnectionDetailsUrl = (clientId: string, id: string) =>
  clientsQuery + `/${clientId}/credentials/${id}`;
const getClientConnectionsNetworthUrl = (clientId: string) =>
  clientsQuery + `/${clientId}/credentials/values`;
const getAllDividends = (clientId: string, period: string = '1Y') =>
  clientsQuery + `/${clientId}/portfolios/all/dividends?period=${period}`;

export function getPortfoliosCompleteClientRequestAction(
  id: string,
  client: GetInfoClientResponseNetType,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, AddClientPayload, any>();
  apiCall.reqId = GET_PORTFOLIOS_CLIENT;
  apiCall.startedReqType = GET_PORTFOLIOS_STARTED_CLIENT;
  apiCall.successReqType = addClient.type;
  apiCall.failureReqType = GET_PORTFOLIOS_FAILURE_CLIENT;
  apiCall.setEndpointFromState = () => getPortfoliosUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPorfoliosCompleteAction(id, client);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfoliosClientRequestAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, PortfolioMap, any>();
  apiCall.reqId = GET_PORTFOLIOS_CLIENT;
  apiCall.startedReqType = GET_PORTFOLIOS_STARTED_CLIENT;
  apiCall.successReqType = addClientPortfolios.type;
  apiCall.failureReqType = GET_PORTFOLIOS_FAILURE_CLIENT;
  apiCall.setEndpointFromState = () => getPortfoliosUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPorfoliosAction();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfoliosClientRequest(
  id: string,
  onSuccess: (portfolios: Portfolio[]) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, Portfolio[], any>();
  apiCall.reqId = GET_PORTFOLIOS_CLIENT;
  apiCall.setEndpointFromState = () => getPortfoliosUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPorfolios();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getSummaryDashRequestAction(
  clientId: string,
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_PORTFOLIO_SUMMARY_DASH;
  apiCall.startedReqType = GET_PORTFOLIO_SUMMARY_DASH_STARTED;
  apiCall.successReqType = addClientPortfolio.type;
  apiCall.failureReqType = GET_PORTFOLIO_SUMMARY_DASH_FAILURE;
  apiCall.setEndpointFromState = () => getSummaryDashUrl(id, clientId);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetSummaryPorfolio(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPerformanceClientRequestAction(
  clientId: string,
  id: string,
  fullLoad: boolean,
  period: string = '',
  type: PerformanceType = 'MWRR',
  startDate?: string,
  endDate?: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_PORTFOLIO_CLIENT;
  apiCall.startedReqType = GET_PORTFOLIO_CLIENT;
  apiCall.successReqType = addClientPortfolio.type;
  apiCall.failureReqType = GET_PORTFOLIO_CLIENT;
  apiCall.setEndpointFromState = () =>
    getPerformanceUrl(clientId, id, fullLoad, period, type, startDate, endDate);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPorfolio(id, fullLoad, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfolioPositionsRequestAction(
  clientId: string,
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_POSITIONS_CLIENT;
  apiCall.startedReqType = GET_POSITIONS_CLIENT_STARTED;
  apiCall.successReqType = addClientPositions.type;
  apiCall.failureReqType = GET_POSITIONS_CLIENT_FAILURE;
  apiCall.setEndpointFromState = () => getPortfolioPositionsIdUrl(clientId, id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPorfolioPositions(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getClientRiskRequestAction(
  clientId: string,
  id: string,
  period: string = '',
  startDate?: string,
  endDate?: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdatePortfolioRiskPayload, any>();
  apiCall.reqId = GET_PORTFOLIO_RISK;
  apiCall.startedReqType = GET_PORTFOLIO_RISK_STARTED;
  apiCall.successReqType = updateClientPortfolioRisk.type;
  apiCall.failureReqType = GET_PORTFOLIO_RISK_FAILURE;
  apiCall.setEndpointFromState = () => getRiskUrl(clientId, id, period, startDate, endDate);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformRiskPortfolio(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getAllPositionsRequest(
  clientId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_POSITIONS_CLIENT;
  apiCall.setEndpointFromState = () => getPortfolioPositionsIdUrl(clientId, 'all');
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getPortfolioDividendsRequest(
  clientId: string,
  id: string,
  period: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_DIVIDENDS_CLIENT;
  apiCall.startedReqType = GET_DIVIDENDS_CLIENT_STARTED;
  apiCall.successReqType = addClientDividends.type;
  apiCall.failureReqType = GET_DIVIDENDS_CLIENT_FAILURE;
  apiCall.setEndpointFromState = () => getPortfolioDividendsUrl(clientId, id, period, 'DIVIDENDS');
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPortfolioDividends(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfolioBondsRequest(
  clientId: string,
  id: string,
  period: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_DIVIDENDS_CLIENT_STARTED;
  apiCall.startedReqType = GET_DIVIDENDS_CLIENT_STARTED;
  apiCall.successReqType = addClientDividends.type;
  apiCall.failureReqType = GET_DIVIDENDS_CLIENT_FAILURE;
  apiCall.setEndpointFromState = () => getPortfolioDividendsUrl(clientId, id, period, 'COUPONS');
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPortfolioBonds(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getClientOperationsRequestAction(
  clientId: string,
  year: number,
  walletId: string,
  method: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, OperationsPayload, any>();
  apiCall.reqId = GET_OPERATIONS_CLIENT;
  apiCall.startedReqType = GET_OPERATIONS_CLIENT_STARTED;
  apiCall.successReqType = getClientOperations.type;
  apiCall.failureReqType = GET_OPERATIONS_CLIENT_FAILURE;
  apiCall.setEndpointFromState = () => getOperationsUrl(clientId, walletId, year, method);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetOperations(walletId, year, method);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getMovementsRequest(
  clientId: string,
  portfolioId: string,
  payload: TransactionsPayload,
  isFirstTime: boolean = false,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_MOVEMENTS_CLIENT;
  apiCall.startedReqType = GET_MOVEMENTS_CLIENT_STARTED;
  apiCall.successReqType = getClientMovementsPortfolio.type;
  apiCall.failureReqType = GET_MOVEMENTS_CLIENT_FAILURE;
  apiCall.setEndpointFromState = () =>
    getPortfolioMovementsUrl(
      clientId,
      portfolioId,
      payload.size,
      payload.page,
      payload.type ?? 'ALL',
      payload.actionType ?? 'ALL',
      payload.startDate ?? '',
      payload.endDate ?? '',
      payload.min ?? '',
      payload.max ?? '',
      payload.name ?? '',
      payload.type === 'NOT_CONFIRMED'
    );
  const isCustom: boolean = !!payload.startDate || !!payload.endDate || payload.name !== '';
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetMovements(
    portfolioId,
    isFirstTime,
    isCustom
  );
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getAllClientMovementsRequest(
  clientId: string,
  payload: TransactionsPayload,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetMovementsResponseNetType, any>();
  apiCall.reqId = GET_MOVEMENTS_CLIENT;
  apiCall.setEndpointFromState = () =>
    getAllMovementsUrl(
      clientId,
      payload.size,
      payload.page,
      payload.type ?? 'ALL',
      payload.actionType ?? 'ALL',
      payload.startDate ?? '',
      payload.endDate ?? '',
      payload.min ?? '',
      payload.max ?? '',
      payload.name ?? '',
      payload.type === 'NOT_CONFIRMED'
    );
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetAllMovements();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getDownloadMovementsRequestX(
  clientId: string,
  portfolioId: string,
  entity: string,
  portfolioName: string,
  payload: TransactionsPayload,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const { token } = store.getState().auth;
  axios({
    method: 'get',
    url:
      BASE_URL +
      getPortfolioMovementsUrl(
        clientId,
        portfolioId,
        payload.size,
        payload.page,
        payload.type ?? 'ALL',
        payload.actionType ?? 'ALL',
        payload.startDate ?? '',
        payload.endDate ?? '',
        payload.min ?? '',
        payload.max ?? '',
        payload.name ?? '',
        payload.type === 'NOT_CONFIRMED',
        true,
        entity,
        portfolioName
      ),
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + token ?? '',
    },
  })
    .then(onSuccess)
    .catch(onFailure);
}

export function getClientMovementsCountRequest(
  clientId: string,
  portfolioId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, TransactionCountPayload, any>();
  apiCall.reqId = GET_MOVEMENTS_COUNT;
  apiCall.startedReqType = GET_MOVEMENTS_COUNT_STARTED;
  apiCall.successReqType = getClientMovementsCount.type;
  apiCall.failureReqType = GET_MOVEMENTS_COUNT_FAILURE;
  apiCall.setEndpointFromState = () => getPortfolioMovementsCountUrl(clientId, portfolioId);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetMovementsCount(portfolioId);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getClientsCurrentAccountsRequest(
  clientId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetCurrentAccountsType, any>();
  apiCall.reqId = GET_CURRENT_ACCOUNTS_CLIENT;
  apiCall.setEndpointFromState = () => getCurrentAccountsUrl(clientId);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetCurrentAccounts();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getClientInformationRequest(
  clientId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetInfoClientResponseNetType, any>();
  apiCall.reqId = GET_INFO_CLIENT;
  apiCall.setEndpointFromState = () => getClientInfoUrl(clientId);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetInfoClient();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updatePortfolioSettingsRequest(
  clientId: string,
  id: string,
  performanceType: PerformanceType,
  indicesIds: string[],
  riskFreeAsset: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, PortfolioMap, any>();
  apiCall.reqId = UPDATE_CLIENT_PORTFOLIO_SETTINGS;
  apiCall.setEndpointFromState = () => updateClientPortfolioSettingsUrl(id, clientId);
  apiCall.setBodyFromState = setUpdatePortfolioSettingsBody(
    performanceType,
    indicesIds,
    riskFreeAsset
  );
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdatePortfolioSettings(id, performanceType);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateClientPortfolioRequestAction(
  id: string,
  name: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = UPDATE_CLIENT_PORTFOLIO;
  apiCall.startedReqType = UPDATE_CLIENT_PORTFOLIO_STARTED;
  apiCall.successReqType = addClientPortfolio.type;
  apiCall.failureReqType = UPDATE_CLIENT_PORTFOLIO_FAILED;
  apiCall.setEndpointFromState = () => updateClientPortfolioUrl(id);
  apiCall.setBodyFromState = () => ({ name });
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdateClientPortfolio(id, name);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateAvailabilityConnectionAction(
  id: string,
  components: UpdateAvConnectionBody[],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType, any>();
  apiCall.reqId = UPDATE_AVAILABILITY_CONNECTION;
  apiCall.startedReqType = UPDATE_AVAILABILITY_CONNECTION_STARTED;
  apiCall.successReqType = updateConnection.type;
  apiCall.failureReqType = UPDATE_AVAILABILITY_CONNECTION_FAILURE;
  apiCall.setEndpointFromState = () => updateAvailabilityConnectionUrl(id);
  apiCall.setBodyFromState = setUpdateAvConnectionBody(components);
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdateAvConnection(id, components);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}
export function getSkippedMovementsRequest(
  clientId: string,
  portfolioId: string,
  page: number,
  name: string,
  size: number,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<
    RootState,
    { transactions: Transaction[]; totalItems: number },
    any
  >();
  apiCall.reqId = GET_SKIPPED_CLIENT_MOVEMENTS;
  apiCall.setEndpointFromState = () =>
    getSkippedPortfolioMovementsUrl(clientId, portfolioId, page, name, size);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetSkippedMovements();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getDownloadSkippedMovementsRequest(
  clientId: string,
  portfolioId: string,
  name: string,
  size: number,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const { token } = store.getState().auth;
  axios({
    method: 'get',
    url: BASE_URL + getSkippedPortfolioMovementsUrl(clientId, portfolioId, 0, name, size, true),
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + token ?? '',
    },
  })
    .then(onSuccess)
    .catch(onFailure);
}

export function getPortfolioImportErrorsRequest(
  clientId: string,
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, ImportError[], any>();
  apiCall.reqId = GET_PORTFOLIO_CLIENT_IMPORT_ERRORS;
  apiCall.setEndpointFromState = () => getImportErrorsUrl(clientId, id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetImportErrors();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getClientNetWorthRequestAction(
  clientId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = GET_CLIENT_NET_WORTH;
  apiCall.startedReqType = GET_CLIENT_NET_WORTH_STARTED;
  apiCall.successReqType = updateClientNetWorth.type;
  apiCall.failureReqType = GET_CLIENT_NET_WORTH_FAILED;
  apiCall.setEndpointFromState = () => getClientNetWorthUrl(clientId);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetNetWorth();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getAllInfoRequestAction(
  clientId: string,
  fullLoad: boolean,
  period: string,
  type: PerformanceType = 'TWRR',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdateAllPayload, any>();
  apiCall.reqId = GET_CLIENT_ALL_INFO;
  apiCall.startedReqType = GET_CLIENT_ALL_INFO_STARTED;
  apiCall.successReqType = updateClientAllInfo.type;
  apiCall.failureReqType = GET_CLIENT_ALL_INFO_FAILED;
  apiCall.setEndpointFromState = () => getClientAllInfoUrl(clientId, type, fullLoad, period);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformClientGetAllInfo(fullLoad, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getClientNetWorthPeriodAction(
  clientId: string,
  fullLoad: boolean,
  period: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = GET_CLIENT_NET_WORTH_PERIOD;
  apiCall.startedReqType = GET_CLIENT_NET_WORTH_PERIOD_STARTED;
  apiCall.successReqType = updateNetWorthPeriod.type;
  apiCall.failureReqType = GET_CLIENT_NET_WORTH_PERIOD_FAILED;
  apiCall.setEndpointFromState = () => getClientNetWorthPeriodUrl(clientId, period, fullLoad);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetNetWorthPeriod(fullLoad, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getInfoSecurityClientRequest(
  id: string,
  clientId: string,
  period: string,
  startDate: string = '',
  endDate: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, SecurityInfoNetType, any>();
  apiCall.setEndpointFromState = () => getInfoSecurityUrl(id, clientId, period, startDate, endDate);
  apiCall.method = 'GET';
  apiCall.reqId = GET_SECURITY_INFO;
  apiCall.transformResponseDataWithState = transformgetInfoSecurity(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getClientsNotificationsRequest(
  page: number,
  size: number,
  isRead?: boolean,
  notificationTypes?: string[],
  clientId?: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, GetClientsNotificationPayload, any>();
  apiCall.reqId = GET_CLIENT_NOTIFICATIONS;
  apiCall.setEndpointFromState = () =>
    getClientsNotifications(page, size, isRead, notificationTypes, clientId);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetClientsNotifications();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function readClientsNotificationsRequest(
  notificationIds: string[],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = MARK_READ_CLIENT_NOTIFICATIONS;
  apiCall.setEndpointFromState = () => readClientsNotificationsUrl(notificationIds);
  apiCall.method = 'PATCH';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getClientConnectionsAction(
  clientId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType[], any>();
  apiCall.method = 'GET';
  apiCall.reqId = GET_CLIENT_CONNECTIONS;
  apiCall.startedReqType = GET_CLIENT_CONNECTIONS_STARTED;
  apiCall.successReqType = getClientConnections.type;
  apiCall.failureReqType = GET_CLIENT_CONNECTIONS_FAILURE;
  apiCall.setEndpointFromState = () => getClientConnectionsUrl(clientId);
  apiCall.transformResponseDataWithState = transformGetConnections();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getConnectionDetailsAction(
  clientId: string,
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, GetAccountsPayload, any>();
  apiCall.reqId = GET_CLIENT_CONNECTION_DETAIL;
  apiCall.startedReqType = GET_CLIENT_CONNECTION_DETAIL_STARTED;
  apiCall.successReqType = getClientAccounts.type;
  apiCall.failureReqType = GET_CLIENT_CONNECTION_DETAIL_FAILURE;
  apiCall.setEndpointFromState = () => getConnectionDetailsUrl(clientId, id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetConnectionDetails(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getClientNetworthByCredentialsAction(
  clientId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType[], any>();
  apiCall.method = 'GET';
  apiCall.reqId = GET_CLIENT_CREDENTIALS_NETWORTH;
  apiCall.startedReqType = GET_CLIENT_CREDENTIALS_NETWORTH_STARTED;
  apiCall.successReqType = getClientConnections.type;
  apiCall.failureReqType = GET_CLIENT_CREDENTIALS_NETWORTH_FAILURE;
  apiCall.setEndpointFromState = () => getClientConnectionsNetworthUrl(clientId);
  apiCall.transformResponseDataWithState = transformGetConnectionsNetworth();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getClientAllDividendsRequest(
  id: string,
  period: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, DividendsByPeriod, any>();
  apiCall.reqId = GET_CLIENT_ALL_DIVIDENDS;
  apiCall.setEndpointFromState = () => getAllDividends(id, period);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetAllDividends();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateClientPortfolioEntityRequestAction(
  id: string,
  entity: PortfolioEntity,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdatePortfolioEntity, any>();
  apiCall.reqId = UPDATE_CLIENT_PORTFOLIO;
  apiCall.startedReqType = UPDATE_CLIENT_PORTFOLIO_STARTED;
  apiCall.successReqType = updateClientPortfolioEntity.type;
  apiCall.failureReqType = UPDATE_CLIENT_PORTFOLIO_FAILED;
  apiCall.setEndpointFromState = () => updatePortfolioEntityUrl(id, entity.id);
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = () => ({
    entity,
    id,
  });
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}
