import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICONS } from 'src/utils/iconUtils';
import { ConnectionPortfolioMap, getWalletsChildren } from './navConfigUtils';

export const summaryPathConfig = {
  title: 'navbar.summary',
  path: PATH_DASHBOARD.general.summary,
  icon: ICONS.home,
  isTranslate: true,
};

export const myWealthPathConfig = {
  title: 'navbar.my_wealth',
  path: PATH_DASHBOARD.general.my_wealth.root,
  icon: ICONS.analytics,
  isTranslate: true,
  children: [
    {
      title: 'navbar.global_wallet',
      path: PATH_DASHBOARD.general.my_wealth.general,
      isConnected: false,
      isTranslate: true,
    },
    {
      title: 'navbar.financial_investments',
      path: PATH_DASHBOARD.general.my_wealth.financial_investments,
      isConnected: false,
      isTranslate: true,
    },
    {
      title: 'navbar.alt_investments',
      path: PATH_DASHBOARD.general.my_wealth.alternative_investments,
      isConnected: false,
      isTranslate: true,
    },
  ],
};

export const myWealthLimitedPathConfig = {
  title: 'navbar.my_wealth',
  path: PATH_DASHBOARD.general.my_wealth.general,
  icon: ICONS.analytics,
  isTranslate: true,
};

export const calendarPathConfig = {
  title: 'navbar.calendar',
  path: PATH_DASHBOARD.calendar,
  icon: ICONS.calendar,
  isTranslate: true,
};

export const walletsPathConfig = (connections: ConnectionPortfolioMap) => ({
  title: 'navbar.managed_wallets',
  path: PATH_DASHBOARD.general.wallets_managment,
  icon: ICONS.wallets,
  isTranslate: true,
  children: [...getWalletsChildren(connections)],
});

export const reportingPathConfig = {
  title: 'navbar.reporting',
  path: PATH_DASHBOARD.general.reporting.all,
  icon: ICONS.reporting,
  isTranslate: true,
};

export const myConnectionsPathConfig = {
  title: 'navbar.my_connections',
  path: PATH_DASHBOARD.myConnections,
  icon: ICONS.connection,
  isTranslate: true,
};

export const companyPathConfig = [
  {
    title: 'navbar.company',
    path: PATH_DASHBOARD.company.info,
    icon: ICONS.business,
    isTranslate: true,
  },
  {
    title: 'navbar.clients',
    path: PATH_DASHBOARD.company.clients,
    icon: ICONS.people,
    isTranslate: true,
  },
  {
    title: 'navbar.notifications',
    path: PATH_DASHBOARD.company.notifications,
    icon: ICONS.notification,
    isTranslate: true,
    highlightAdd: true,
  },
];

export const adminPathConfig = [
  {
    title: 'navbar.admin',
    path: PATH_DASHBOARD.admin,
    icon: ICONS.kanban,
    isTranslate: true,
  },
];
