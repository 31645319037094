import { BoxProps, alpha, Box, SxProps, Stack } from '@mui/material';
import { m } from 'framer-motion';

import Logo from 'src/components/Logo';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  sx?: SxProps;
}

export default function LogoLoader({ sx }: Props) {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <m.div
        animate={{ rotateY: 360 }}
        initial={{ rotateY: 0 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo disabledLink sx={{ width: 54, height: 54 }} />
      </m.div>

      <Box
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        component={m.div}
        sx={{
          width: 100,
          height: 100,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
      />

      <Box
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        component={m.div}
        sx={{
          width: 120,
          height: 120,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
      />
    </Stack>
  );
}
