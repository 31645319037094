// theme
import palette, { LONG_CHART_COLORS } from '../theme/palette';
// @type
import { ThemeColorPresets } from '../components/settings/type';
import { ThemeColorsApp, ThemeColorsReport } from 'src/redux/slices/SettingsSlice/settingsSlice.d';

// ----------------------------------------------------------------------

export const defaultThemeColorApp: ThemeColorsApp = {
  primary: { ...palette.light.primary },
  text: {
    primary: '#212B36',
    secondary: '#637381',
    disabled: '#919EAB',
  },
  background: {
    paper: '#ffffff',
    default: '#F9FAFB',
    neutral: '#F4F6F8',
  },
  chartColors: LONG_CHART_COLORS,
};

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
];

export const defaultThemeColorBusinessReport: ThemeColorsReport = {
  primary: '#264A79',
  border: '#6291CF',
  success: ['#36B37E', '#1B806A', '#0A5554'],
  error: ['#FF5630', '#B71D18', '#7A0916'],
  chartColors: [
    '#264A79',
    '#00C19E',
    '#00739F',
    '#009BAC',
    '#8BE282',
    '#4E578F',
    '#7564A1',
    '#9C70AF',
    '#C47CBA',
    '#EA89C0',
    '#AC6575',
    '#763445',
  ],
};

export const defaultThemeColorReport: ThemeColorsReport = {
  primary: '#FF9634',
  border: '#F9DFC6',
  success: ['#36B37E', '#1B806A', '#0A5554'],
  error: ['#FF5630', '#B71D18', '#7A0916'],
  chartColors: [
    '#FF7E00',
    '#E44E1B',
    '#ffca00',
    '#CC6600',
    '#F4AD33',
    '#4C7CFF',
    '#0044ff',
    '#0030b3',
    '#001B66',
    '#008836',
    '#006633',
    '#D0AEFF',
    '#9E86FF',
  ],
};

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];

export default function getColorPresets(presetsKey: ThemeColorPresets) {
  return {
    purple: purplePreset,
    cyan: cyanPreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    default: defaultPreset,
  }[presetsKey];
}
