import { AxiosResponse } from 'axios';

import {
  GetIndicesResponseNetType,
  StockIndexDto,
  GetRiskValuesResponseNetType,
  RiskFreeAssetDto,
} from './NetTypes';

export function transformGetIndices(): (response: AxiosResponse<GetIndicesResponseNetType>) => {
  generalIndices: StockIndexDto[];
  customIndices: StockIndexDto[];
} {
  return (response: AxiosResponse<GetIndicesResponseNetType>) => ({ ...response.data });
}

export function transformGetRiskValues(): (
  response: AxiosResponse<GetRiskValuesResponseNetType>
) => RiskFreeAssetDto[] {
  return (response: AxiosResponse<GetRiskValuesResponseNetType>) =>
    response.data.riskFreeAssets.map((i) => ({ ...i, id: i.code === 'US10Y' ? 'default' : i.id }));
}
