import { AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
// config
import { HEADER, NAVBAR } from 'src/config';
// components
import MobileToolbar from './Toolbar/MobileToolbar';
import MainToolbar from './Toolbar/MainToolbar';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useGetInfoUser from '../services/useGetUserInfo';
// utils
import cssStyles from 'src/utils/cssStyles';
// context
import Context, { ContextType } from '../context';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isBusinessNotClient: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<RootStyleProps>(({ isCollapse, theme, isBusinessNotClient }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: 85,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - 90px)`,
    }),
  },
  [theme.breakpoints.down('sm')]: {
    height: isBusinessNotClient ? 95 : HEADER.MOBILE_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardHeader() {
  const { isBusiness, isClient } = useGetInfoUser();
  const { isCollapse }: ContextType = useContext<ContextType>(Context);

  const isBusinessNotClient = isBusiness && !isClient;
  const isSm = useResponsive('up', 'sm');

  return (
    <RootStyle isBusinessNotClient={isBusinessNotClient} isCollapse={isCollapse ?? false}>
      {!isSm ? <MobileToolbar /> : <MainToolbar />}
    </RootStyle>
  );
}
