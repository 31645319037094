import { Fragment, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// mui
import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import NavbarContent from './NavbarContent';
import NavbarHeader from './NavbarHeader';
import NavbarFooter from './NavbarFooter';
import NavbarDrawer from './NavbarDrawer';
import AccountPopover from '../DashboardHeader/AccountPopover/AccountPopover';
// config
import { NAVBAR } from '../../../config';
// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';
import useGetInfoUser from '../services/useGetUserInfo';
// context
import Context, { ContextType } from '../context';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function NavbarVertical() {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { actions, isOpenSidebar }: ContextType = useContext<ContextType>(Context);
  const { onCloseSidebar } = actions;

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse } = useCollapseDrawer();

  const { isBusiness, isClient } = useGetInfoUser();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = () => (
    <Fragment>
      <NavbarHeader collapseClick={collapseClick} onToggleCollapse={onToggleCollapse} />
      <NavbarContent />
      <AccountPopover />
      {(isBusiness || isClient) && <NavbarFooter />}
    </Fragment>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          PaperProps={{
            sx: { width: NAVBAR.DASHBOARD_WIDTH },
          }}
          onClose={onCloseSidebar}
          open={isOpenSidebar}
        >
          {renderContent()}
        </Drawer>
      )}

      {isDesktop && <NavbarDrawer collapseHover={collapseHover}>{renderContent()}</NavbarDrawer>}
    </RootStyle>
  );
}
