// hooks
import { useSelector } from 'react-redux';
// utils
import get from 'lodash/get';
// types
import { RootState } from 'src/redux/store';

export default function useGetPortfoliosInfo() {
  const { portfolios, connections } = useSelector((state: RootState) => get(state, ['portfolio']));

  return {
    portfolios,
    connections,
  };
}
