import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingScreen from 'src/components/LoadingScreen';
import Login from 'src/pages/auth/Login/Login';
import OnBoarding from 'src/pages/dashboard/OnBoarding/OnBoarding';
import { isBusinessOnBoardingSelector } from 'src/redux/slices/BusinessSlice/businessSlice';
import { useSelector } from 'src/redux/store';
import { AuthGuardProps } from './AuthGuard.Types';
import useGetCompanyThemeSettings from 'src/thunks/useGetCompanyThemeSettings';

// ----------------------------------------------------------------------

export default function AuthGuard({
  children,
  isInitialized,
  isAuthenticated,
  hasBaseCurrency,
  isBusiness,
  isReady,
}: AuthGuardProps) {
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  const isBusinessOnBoardingFinished = useSelector(isBusinessOnBoardingSelector);

  useGetCompanyThemeSettings({ company: '' });

  const showOnBoarding =
    isAuthenticated &&
    isInitialized &&
    isReady &&
    (isBusiness ? !isBusinessOnBoardingFinished || !hasBaseCurrency : !hasBaseCurrency);

  useEffect(() => {}, [isBusinessOnBoardingFinished, isInitialized, isReady]);

  if ((!isInitialized && !isAuthenticated) || (!isReady && isInitialized && isAuthenticated))
    return <LoadingScreen />;

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (showOnBoarding) return <OnBoarding />;

  if (requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
