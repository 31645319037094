import { AdminSecurity, SectorSecurity } from 'src/pages/dashboard/Admin/Admin.View';
import { RootState } from 'src/redux/store';
import { NewSecurityType, SplitType } from 'src/sections/@dashboard/admin/AddNewSecurityPanel';
import {
  AcceptSuggestedSectorBodyNetType,
  AcceptSuggestedSecurityBodyNetType,
  CreateNewSecurityBodyNetType,
  ModifyUnknownSecurityBodyNetType,
} from './NetTypes';

export function setAcceptSuggestedSecurityBody(
  securityId: string,
  securitySuggestionId: string
): (state: RootState) => AcceptSuggestedSecurityBodyNetType {
  return (state: RootState) => ({
    securityId,
    securitySuggestionId,
  });
}

export function setAcceptSuggestedSectorBody(
  security: SectorSecurity
): (state: RootState) => AcceptSuggestedSectorBodyNetType {
  return (_state: RootState) => ({
    sector: security.sector,
    gicGroup: security.gicGroup,
    industry: security.industry,
    subIndustry: security.subIndustry,
  });
}

export function setModifyUnknownSecurityBody(
  security: AdminSecurity
): (state: RootState) => ModifyUnknownSecurityBodyNetType {
  return (_state: RootState) => ({
    type: security.type,
    name: security.name,
    currencyCode: security.currency,
  });
}

export function setCreateNewSecurityBody(
  security: NewSecurityType,
  splits: SplitType[],
  prices: FormData
): (state: RootState) => CreateNewSecurityBodyNetType {
  return (_state: RootState) => ({
    ...security,
    splits,
    prices,
  });
}
