import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function IconProtect({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        fill="none"
        height="65"
        viewBox="0 0 66 65"
        width="66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.1579 0H32.8421C27.9587 0 24 3.95875 24 8.84211V33.9891C24 38.8724 27.9587 42.8312 32.8421 42.8312H57.1579C62.0413 42.8312 66 38.8724 66 33.9891V8.84211C66 3.95875 62.0413 0 57.1579 0Z"
          fill="#FF9634"
        />
        <path
          d="M35.0532 15.2718L45.2062 9.95215L54.948 15.243C54.948 15.243 53.1375 28.3128 45.446 32.0574C45.4272 32.0574 35.8866 28.399 35.0532 15.2718Z"
          fill="#D56701"
        />
        <path
          d="M27.8947 18.9277V29.203C27.8918 29.7591 27.5936 30.2728 27.1122 30.552C26.8704 30.6985 26.5926 30.7755 26.3098 30.7747C25.4393 30.7747 24.7315 30.0728 24.7241 29.203V18.9277C24.7249 15.5124 22.172 12.6356 18.7809 12.229C18.5141 12.1996 18.2459 12.1835 17.9778 12.1813C14.2518 12.1813 11.2314 15.2024 11.2314 18.9277V29.203C11.2255 29.7584 10.9288 30.2706 10.4488 30.552C10.2048 30.6985 9.9249 30.7755 9.63913 30.7747C8.7657 30.7711 8.05786 30.0632 8.0542 29.1891V18.9277C8.06153 13.4512 12.5012 9.01441 17.9778 9.01074C18.2459 9.01074 18.5141 9.02393 18.7809 9.05104C23.9269 9.46944 27.8911 13.7655 27.8947 18.9277Z"
          fill="white"
        />
        <path
          d="M27.8955 18.9256V29.2009C28.1189 29.5804 28.0361 30.0655 27.6998 30.3498C27.3627 30.6341 26.8703 30.6341 26.5333 30.3498C26.1969 30.0655 26.1141 29.5804 26.3376 29.2009V18.9256C26.3376 15.2003 23.3173 12.1792 19.5912 12.1792C19.3209 12.1814 19.0505 12.1975 18.7816 12.2268C18.5148 12.1975 18.2467 12.1814 17.9785 12.1792C14.2525 12.1792 11.2321 15.2003 11.2321 18.9256V29.2009C11.4519 29.5804 11.3669 30.0633 11.0313 30.3454C10.695 30.6275 10.2048 30.6275 9.86845 30.3454C9.53212 30.0633 9.44711 29.5804 9.66694 29.2009V18.9256C9.37091 15.4846 11.0386 12.1704 13.9792 10.3591C16.9197 8.54698 20.6303 8.54698 23.5701 10.3591C26.5106 12.1704 28.179 15.4846 27.8823 18.9256H27.8955Z"
          fill="#DFE3E8"
        />
        <path
          d="M29.6473 29.1641H5.35274C2.39651 29.1641 0 31.5606 0 34.5168V58.8113C0 61.7676 2.39651 64.1641 5.35274 64.1641H29.6473C32.6035 64.1641 35 61.7676 35 58.8113V34.5168C35 31.5606 32.6035 29.1641 29.6473 29.1641Z"
          fill="#D56701"
        />
        <path
          d="M29.6528 29.167H11.4181C8.46515 29.167 6.07129 31.5609 6.07129 34.5138V58.8134C6.07129 61.7664 8.46515 64.1603 11.4181 64.1603H29.6528C32.6058 64.1603 34.9996 61.7664 34.9996 58.8134V34.5138C34.9996 31.5609 32.6058 29.167 29.6528 29.167Z"
          fill="#EF7E14"
        />
        <path
          d="M19.8398 47.8482C20.2083 46.5519 19.2199 45.1172 18.9839 43.7895C18.9414 43.5535 18.937 43.3066 18.9759 43.0582C19.1444 41.973 20.0779 41.1729 21.1756 41.1729C22.2739 41.1729 23.2075 41.973 23.376 43.0582C23.4134 43.3 23.4104 43.5403 23.3709 43.7712C23.1459 45.0996 22.178 46.5615 22.5832 47.8467L22.8755 48.7765C23.2272 49.8911 22.3948 51.0261 21.2261 51.0261C20.0779 51.0261 19.2484 49.9277 19.5621 48.8234L19.8398 47.8482Z"
          fill="#D56701"
        />
      </svg>
    </Box>
  );
}
