import { dispatch } from 'src/redux/store';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
// components
import Image from 'src/components/Image';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// services
import { updateUserLanguajeRequestAction } from 'src/services/user';

export default function LanguagePopoverItems({ onClick }: { onClick: () => void }) {
  const isSm = useResponsive('down', 'sm');
  const { allLang, currentLang, onChangeLang, translate } = useLocales();

  const handleOnChangeLang = (newLang: string) => {
    if (newLang !== currentLang.value) {
      onChangeLang(newLang);
      dispatch(updateUserLanguajeRequestAction(newLang));
    }
    onClick();
  };

  const width = isSm ? 36 : 30;

  return (
    <>
      {allLang.map((option, index) => (
        <>
          <MenuItem
            key={option.value}
            onClick={() => handleOnChangeLang(option.value)}
            selected={option.value === currentLang.value}
          >
            <Image alt={option.label} disabledEffect src={option.icon} sx={{ width, mr: 2 }} />
            {translate(`languages.${option.label}`)}
          </MenuItem>
          {isSm && !(index / 2) && <Divider sx={{ borderStyle: 'dashed' }} />}
        </>
      ))}
    </>
  );
}
