import { DialogTitle } from '@mui/material';

import { DialogAnimate } from 'src/components/animate';
import { ModalManagerProps } from './ModalManager.Types';

// ----------------------------------------------------------------------

export default function ModalManager({
  hideModalAction,
  visible,
  title,
  children,
  size,
}: ModalManagerProps) {
  return (
    <DialogAnimate maxWidth={size} onClose={hideModalAction} open={visible}>
      {title !== '' && <DialogTitle>{title}</DialogTitle>}
      {children}
    </DialogAnimate>
  );
}
