import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
//
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, sx, ...other }: NavSectionProps) {
  return (
    <Stack direction="row" spacing={1} sx={sx} {...other}>
      {data.map((group) => (
        <Items items={group.items} key={group.subheader} />
      ))}
    </Stack>
  );
}

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

type ItemsProps = {
  items: NavListProps[];
};

function Items({ items }: ItemsProps) {
  return (
    <>
      {items.map((list) => (
        <NavList
          data={list}
          depth={1}
          hasChild={list.children?.length > 0}
          key={list.title + list.path}
        />
      ))}
    </>
  );
}
