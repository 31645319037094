import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, TextField, Typography, Grid, Stack, IconButton } from '@mui/material';
import { LoadingButton, LocalizationProvider, DatePicker } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import MomentAdapter from '@date-io/moment';
import moment from 'moment';
import useLocales from 'src/hooks/useLocales';
import { useState } from 'react';
import { fDecimalNumber } from 'src/utils/formatNumber';
import { getCurrencyFromBase } from 'src/utils/currency';
import Iconify from '../Iconify';

type FormValuesProps = {
  wallet: string;
  quantity: number;
  price: number;
  feeCurrency: string;
  fee: number;
  notes: string;
  date: Date;
  type: string;
};

const getMaxDate = () => new Date();

const resolver = yupResolver(
  Yup.object().shape({
    wallet: Yup.string(),
    date: Yup.lazy(() =>
      Yup.date()
        .nullable()
        .typeError('La fecha debe tener el formato dd/mm/yyyy')
        .required('Introduce la fecha del movimiento')
        .max(getMaxDate(), 'La fecha no puede ser posterior al día actual')
    ),
    price: Yup.number()
      .required('Añade el precio')
      .positive('El valor no pueder ser negativo')
      .typeError('Añade el precio'),
    fee: Yup.number()
      .required('Añade la comisión')
      .moreThan(-1, 'El valor no pueder ser negativo')
      .typeError('Añade la comisión'),
    notes: Yup.string().max(100),
    type: Yup.string(),
  })
);

// ----------------------------------------------------------------------

type Props = {
  walletName?: string;
  baseCurrency: string;
  onSubmitModal: (data: FormValuesProps, onFinish: () => void) => void;
  onCancel: () => void;
};

export default function FirstDepositModal({
  onSubmitModal,
  walletName,
  baseCurrency,
  onCancel,
}: Props) {
  const { translate } = useLocales();
  const [selectableCurrencies, setSelectableCurrencies] = useState([baseCurrency]);
  const [loading, setLoading] = useState(false);

  const methods = useForm<FormValuesProps>({
    resolver,
    defaultValues: {
      type: 'DEPOSIT',
      feeCurrency: baseCurrency,
      date: new Date(),
      notes: '',
    },
  });

  const { reset, watch, control, handleSubmit } = methods;

  const watchValues = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      setLoading(true);
      onSubmitModal(data, () => setLoading(false));
      reset();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const renderResultRow = (title: string, value: string | number) => (
    <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 1 }}>
      <Typography
        sx={{
          color: 'text.secondary',
        }}
        variant="body2"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: 'text.main',
        }}
        variant="body2"
      >
        {value ?? 0}
      </Typography>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Stack alignItems="center" flexDirection={'row'}>
            <IconButton>
              <Iconify
                color={'primary.light'}
                height={20}
                icon={'bi:info-circle-fill'}
                width={20}
              />
            </IconButton>
            <Typography
              sx={{
                color: 'primary.light',
                ml: 0.8,
                fontSize: 13,
              }}
              variant="subtitle2"
            >
              {
                'Es necesario que el primer movimiento de tu cartera sea un depósito para que Vumi pueda hacer los cálculos de rentabilidad correctamente'
              }
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <RHFSelect disabled label="Cartera" name="wallet">
            {[walletName].map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="date"
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={MomentAdapter}>
                <DatePicker
                  PaperProps={{
                    sx: {
                      '& .PrivatePickersYear-yearButton': {
                        textAlign: 'center',
                      },
                    },
                  }}
                  allowSameDateSelection
                  disableFuture
                  label="Fecha"
                  maxDate={moment()}
                  minDate={moment('January 1st, 2000', 'MMM-DD-YYYY')}
                  onChange={(newValue: any) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} error={!!error} fullWidth helperText={error?.message} />
                  )}
                  value={field.value}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            label={'Cantidad'}
            name="price"
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}
            type="number"
            value={watchValues.price ?? ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack flexDirection={'row'}>
            <RHFTextField
              label="Comisión"
              name="fee"
              sx={{ mr: 2 }}
              type="number"
              value={watchValues.fee ?? ''}
            />
            <RHFSelect disabled label="Divisa de la comisión" name="feeCurrency">
              {selectableCurrencies.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </RHFSelect>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField label="Notas" multiline name="notes" rows={5} />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography
            sx={{
              color: 'text.main',
            }}
            variant="subtitle2"
          >
            {'Resultado del movimiento'}
          </Typography>
          {renderResultRow('Subtotal', watchValues.price ?? 0 + getCurrencyFromBase(baseCurrency))}
          {renderResultRow(
            'Comisión',
            `(-) ${watchValues.fee ?? 0}` + getCurrencyFromBase(baseCurrency)
          )}
          <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 1 }}>
            <Typography
              sx={{
                color: 'text.secondary',
              }}
              variant="body2"
            >
              {'Total'}
            </Typography>
            <Typography
              sx={{
                color: 'text.main',
              }}
              variant="h4"
            >
              {fDecimalNumber((watchValues.price ?? 0 * 1.0) - (watchValues.fee ?? 0 * 1.0)) +
                getCurrencyFromBase(baseCurrency)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <DialogActions sx={{ mb: 1 }}>
        <LoadingButton
          color="inherit"
          disabled={loading}
          onClick={onCancel}
          size="medium"
          type="submit"
          variant="outlined"
        >
          {'Cancelar'}
        </LoadingButton>
        <LoadingButton loading={loading} size="medium" type="submit" variant="contained">
          {'Guardar'}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
