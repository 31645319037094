import { Button, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export interface Props {
  onCancel?: VoidFunction;
  onSelect: VoidFunction;
  isSelected?: boolean;
}

// ----------------------------------------------------------------------

export default function SelectorFooter({ onCancel, onSelect, isSelected }: Props) {
  return (
    <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'center'} sx={{ mt: 2 }}>
      <Button color="inherit" onClick={onCancel} size="small" variant="outlined">
        {isSelected ? 'Atras' : 'Cancelar'}
      </Button>
      <Button color="primary" onClick={onSelect} size="small" sx={{ ml: 2 }} variant="contained">
        {isSelected ? 'Guardar' : 'Seleccionar'}
      </Button>
    </Stack>
  );
}
