import { RootState } from 'src/redux/store';
import { getAxiosEmptyRequestAction } from 'src/services/http/axios';
import { transformGetCountries } from 'src/services/data/ResponseUtils';
import { GET_COUNTRIES, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE } from './RequestIds';
import { getCountries } from 'src/redux/slices/DataSlice/dataSlice';
import { CountryType } from 'src/redux/slices/DataSlice/dataSlice.d';

const getCountriesURL = () => `/api/v1/queries/data/countries`;

export function getCountriesRequestAction(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, CountryType[], any>();
  apiCall.reqId = GET_COUNTRIES;
  apiCall.startedReqType = GET_COUNTRIES_SUCCESS;
  apiCall.successReqType = getCountries.type;
  apiCall.failureReqType = GET_COUNTRIES_FAILURE;
  apiCall.setEndpointFromState = () => getCountriesURL();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetCountries();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}
