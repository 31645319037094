import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import SvgIconStyle from '../../SvgIconStyle';
import useLocales from 'src/hooks/useLocales';

export default function PerformanceTitle() {
  const { translate } = useLocales();
  const tooltipPerformance = translate('wallets.performance_tooltip');

  return (
    <Stack alignItems="center" flexDirection="row">
      <Typography color="text.secondary" variant="subtitle2">
        Rentabilidad por defecto en toda tu cartera
      </Typography>
      <Link
        href="https://www.vumi.io/blog/modified-dietz-return-mdr"
        target="_blank"
        underline="always"
      >
        <Tooltip sx={{ mt: 0.2, ml: 1 }} title={tooltipPerformance}>
          <IconButton>
            <SvgIconStyle
              src="/icons/ic_info_outline.svg"
              sx={{ width: 16, height: 16, color: 'text.primary' }}
            />
          </IconButton>
        </Tooltip>
      </Link>
    </Stack>
  );
}
