import {
  getNotifications,
  readNotifications,
} from 'src/redux/slices/NotificationsSlice/notificationsSlice';
import { RootState } from 'src/redux/store';
import { getAxiosEmptyRequestAction } from '../http/axios';
import {
  GET_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_FAILURE,
  READ_USER_NOTIFICATIONS,
  READ_USER_NOTIFICATIONS_SUCCESS,
  READ_USER_NOTIFICATIONS_FAILURE,
} from './RequestIds';
import { readNotificationsBody } from './RequestUtils';
import { transformGetUserNotifications, transformReadNotifications } from './ResponseUtils';

const getUserNotificationsUrl = (size: number, page: number) =>
  `/api/v1/notifications/user?size=${size}&page=${page}`;
const readNotificationsUrl = () => `/api/v1/notifications/user`;

export function getUserNotificationsRequestAction(
  size: number = 20,
  page: number = 0,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, void, any>();
  apiCall.setEndpointFromState = () => getUserNotificationsUrl(size, page);
  apiCall.method = 'GET';
  apiCall.reqId = GET_USER_NOTIFICATIONS;
  apiCall.startedReqType = GET_USER_NOTIFICATIONS_SUCCESS;
  apiCall.successReqType = getNotifications.type;
  apiCall.failureReqType = GET_USER_NOTIFICATIONS_FAILURE;
  apiCall.transformResponseDataWithState = transformGetUserNotifications();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function readNotificationsRequestAction(
  ids: string[] = [],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, void, any>();
  apiCall.setEndpointFromState = () => readNotificationsUrl();
  apiCall.method = 'PUT';
  apiCall.reqId = READ_USER_NOTIFICATIONS;
  apiCall.startedReqType = READ_USER_NOTIFICATIONS_SUCCESS;
  apiCall.successReqType = readNotifications.type;
  apiCall.failureReqType = READ_USER_NOTIFICATIONS_FAILURE;
  apiCall.setBodyFromState = readNotificationsBody(ids);
  apiCall.transformResponseDataWithState = transformReadNotifications(ids);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}
