import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Collapse, Box } from '@mui/material';
// type
import { NavListProps } from '../type';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';
import { StyledPopover } from '../horizontal/styles';

// ----------------------------------------------------------------------

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
};

export function NavListRoot({ list, isCollapse }: NavListRootProps) {
  const navRef = useRef(null);

  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  useEffect(() => {
    if (isCollapse && open) setOpen(false);
  }, [isCollapse]);

  if (hasChildren) {
    const Item = () => (
      <NavItemRoot
        active={active}
        isCollapse={isCollapse}
        item={list}
        onOpen={() => setOpen(!open)}
        open={open}
      />
    );

    const Menu = () => (
      <List component="div" disablePadding>
        {list.children
          ?.filter((item) => item)
          .map((item) => (
            <NavListSub key={item.title} list={item} />
          ))}
      </List>
    );

    return (
      <>
        {isCollapse ? (
          <Box onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} ref={navRef}>
            <Item />
          </Box>
        ) : (
          <Item />
        )}

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Menu />
          </Collapse>
        )}

        {isCollapse && (
          <StyledPopover
            PaperProps={{
              onMouseEnter: () => setOpen(true),
              onMouseLeave: () => setOpen(false),
            }}
            anchorEl={navRef.current}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            open={open}
            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          >
            <Menu />
          </StyledPopover>
        )}
      </>
    );
  }

  return <NavItemRoot active={active} isCollapse={isCollapse} item={list} />;
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  list: NavListProps;
};

function NavListSub({ list }: NavListSubProps) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname, list.children);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub active={active} item={list} onOpen={() => setOpen(!open)} open={open} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub active={getActive(item.path, pathname)} item={item} key={item.title} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub active={active} item={list} />;
}
