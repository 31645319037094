export type CardType = {
  id: string;
  lastFour: string;
  brand: string;
};

export type SubPrice = {
  id: string;
  period: string;
  price: string;
};

export type Subscription = {
  id: string;
  status: string;
  trialEnd?: number;
  currentPeriodEnd?: number;
  priceId: string;
  hasCancelAtPeriodEnd: boolean;
};

export type SubInfoPayload = {
  paymentCards: CardType[];
  subPricesList: SubPrice[];
  subscription: Subscription;
};

export const subscriptionDefault: Subscription = {
  id: '',
  status: 'not_created',
};
