import { RootState } from 'src/redux/store';
import { getAxiosEmptyRequest } from 'src/services/http/axios';
import { transformGetIndices, transformGetRiskValues } from 'src/services/indices/ResponseUtils';
import { setCreateIndiceBody } from 'src/services/indices/RequestUtils';
import { CustomStockIndexComponentDto, RiskFreeAssetDto, StockIndexDto } from './NetTypes';
import { GET_INDICES, CREATE_INDICE, UPDATE_INDICE, DELETE_INDICE } from './RequestIds';

const getIndicesUrl = () => `/api/v1/queries/benchmark/indices`;
const getRiskValuesUrl = () => `/api/v1/queries/benchmark/risk_free_assets`;
const createNewIndiceUrl = () => `/api/v1/commands/indices`;
const editIndiceUrl = (id: string) => `/api/v1/commands/indices/${id}`;

export function getIndicesRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<
    RootState,
    {
      generalIndices: StockIndexDto[];
      customIndices: StockIndexDto[];
    },
    any
  >();
  apiCall.reqId = GET_INDICES;
  apiCall.setEndpointFromState = () => getIndicesUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetIndices();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getRiskValueRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, RiskFreeAssetDto[], any>();
  apiCall.reqId = GET_INDICES;
  apiCall.setEndpointFromState = () => getRiskValuesUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetRiskValues();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function createNewIndiceRequest(
  customIndices: CustomStockIndexComponentDto[],
  name: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = CREATE_INDICE;
  apiCall.setEndpointFromState = () => createNewIndiceUrl();
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setCreateIndiceBody(customIndices, name);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function editIndiceRequest(
  id: string,
  customIndices: CustomStockIndexComponentDto[],
  name: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = UPDATE_INDICE;
  apiCall.setEndpointFromState = () => editIndiceUrl(id);
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setCreateIndiceBody(customIndices, name);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function deleteIndiceRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = DELETE_INDICE;
  apiCall.setEndpointFromState = () => editIndiceUrl(id);
  apiCall.method = 'DELETE';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
