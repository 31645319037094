import { Dispatch } from 'redux';
import { AxiosInterceptorRequestSuccessInputFunctionType } from 'src/services/http/axios';

import { RootState } from 'src/redux/store';
import { getTokenAuthHeader } from 'src/services/config/Auth.Utils';
import { AuthRequestIds } from 'src/services/RequestIds';

export const manageLanguageRequest: (
  getState: () => RootState,
  next: Dispatch
) => AxiosInterceptorRequestSuccessInputFunctionType<RootState> =
  (getState, _next) => async (config, _serviceClient) => {
    try {
      return {
        ...config,
        headers: {
          ...config.headers,
          'Accept-Language': 'en',
        },
      };
    } catch (e) {
      throw e;
    }
  };
