import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const APP_ENV = process.env.REACT_APP_ENV || '';

export const MAINTENANCE = process.env.REACT_APP_MAINTENANCE || 'false';

export const VERSION = process.env.REACT_APP_VERSION || '';

export const BASE_URL = process.env.REACT_APP_VUMI_API_URL || '';
export const APP_URL = process.env.REACT_APP_VUMI_URL || '';
export const OAUTH2_GOOGLE_URL = process.env.REACT_APP_OAUTH2_GOOGLE_URL || '';

export const PUBLIC_STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY || '';
export const SECRET_STRIPE_KEY = process.env.REACT_APP_SECRET_STRIPE_KEY || '';

export const S3_URL = process.env.REACT_APP_S3_URL || '';
export const ENTITIES_URL = process.env.REACT_APP_ENTITIES_URL || '';
export const LOGOS_URL = process.env.REACT_APP_LOGOS_URL || '';
export const PROFILES_URL = process.env.REACT_APP_PROFILES_URL || '';
export const CSV_TEMPLATE_EN_URL = process.env.REACT_APP_CSV_TEMPLATE_EN_URL || '';
export const CSV_TEMPLATE_ES_URL = process.env.REACT_APP_CSV_TEMPLATE_ES_URL || '';
export const CSV_TEMPLATE_TUTORIAL_URL = process.env.REACT_APP_CSV_TEMPLATE_TUTORIAL_URL || '';

export const FINLINK_URL = process.env.REACT_APP_FINLINK_URL || '';
export const FINLINK_ID = process.env.REACT_APP_FINLINK_ID || '';

export const FLANKS_URL = process.env.REACT_APP_FLANKS_URL || '';
export const FLANKS_TOKEN = process.env.REACT_APP_FLANKS_TOKEN || '';

export const MAP_BOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN || '';

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.markets; // as '/dashboard/markets'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 60,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 74,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 40,
};

export const ICON = {
  NAVBAR_ITEM: 24,
  NAVBAR_ITEM_HORIZONTAL: 22,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: true,
};
