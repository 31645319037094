import { combineReducers } from 'redux';

import calendarReducer from './slices/calendar';
import persistPortfoliosReducer from './slices/PortfolioSlice/portfolioSlice';
import persistSharePortfoliosReducer from './slices/SharePortfolioSlice/sharePortfolioSlice';
import persistAuthReducer from './slices/AuthSlice/authSlice';
import notificationsReducer from './slices/NotificationsSlice/notificationsSlice';
import subAuthReducer from './slices/SubscriptionSlice/subscriptionSlice';
import dataReducer from './slices/DataSlice/dataSlice';
import businessReducer from './slices/BusinessSlice/businessSlice';
import persistClientReducer from './slices/ClientSlice/clientSlice';
import persistSettingsReducer from './slices/SettingsSlice/settingsSlice';
import modalReducer from './slices/ModalSlice/modalSlice';

// ----------------------------------------------------------------------

const appReducer = combineReducers({
  auth: persistAuthReducer,
  subscription: subAuthReducer,
  portfolio: persistPortfoliosReducer,
  sharePortfolio: persistSharePortfoliosReducer,
  notifications: notificationsReducer,
  calendar: calendarReducer,
  data: dataReducer,
  business: businessReducer,
  client: persistClientReducer,
  settings: persistSettingsReducer,
  modal: modalReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') state = { settings: state.settings, business: state.business };
  return appReducer(state, action);
};

export { rootReducer };
