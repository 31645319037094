import { Box, Card, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'mui-image';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import LogoIso from 'src/components/LogoIso';
import Page from 'src/components/Page';
import { DialogAnimate } from 'src/components/animate';
import NewRegisterModal from 'src/components/modal/NewRegisterModal';
import { APP_ENV } from 'src/config';
import useFeatureToggle from 'src/hooks/useFeatureToggle';
import useGetInfoCompany from 'src/hooks/useGetInfoCompany';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import LanguagePopover from 'src/layouts/dashboard/DashboardHeader/LanguagePopover/LanguagePopover';
import { LoginPayload } from 'src/redux/slices/AuthSlice/authSlice.d';
import { dispatch } from 'src/redux/store';
import { PATH_AUTH } from 'src/routes/paths';
import { LoginForm } from 'src/sections/auth/login';
import { onInitAppFlow } from 'src/thunks/onInitAppFlow';
import { LoginProps } from './Login.Types';
import ImageComponent from 'src/components/Image';
import LogoIsoBusiness from 'src/components/LogoIsoBusiness';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 540,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  position: 'relative',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const Login = ({ loginService, showBetaModal, sendEmailBeta }: LoginProps) => {
  const [loading, setLoading] = useState(false);
  const [showNewRegisterModal, setShowNewRegisterModal] = useState(false);

  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const { translate } = useLocales();
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const isUserRegistratioEnabled = useFeatureToggle('userRegistrationEnabled');
  const { enqueueSnackbar } = useSnackbar();
  const isBusiness = APP_ENV === 'business' || APP_ENV === 'businesstest';
  const company = useGetInfoCompany();

  useEffect(() => {
    if (state && state.isNewRegister) setShowNewRegisterModal(true);
  }, []);

  const loginServiceRequest = (
    email: string,
    password: string,
    onFailure: (errorMessage: string) => void
  ) => {
    setLoading(true);
    loginService(
      email,
      password,
      ({ mfaToken }: LoginPayload) => {
        setLoading(false);
        if (mfaToken && mfaToken !== '') {
          navigate(PATH_AUTH.verifyMFA, { state: { token: mfaToken } });
        } else dispatch(onInitAppFlow);
      },
      (errorMessage: string) => {
        setLoading(false);
        if (typeof errorMessage === 'string') onFailure(translate(`tags.${errorMessage}`));
        else onFailure(translate('tags.AUTH_01'));
      }
    );
  };

  const handleSignBeta = () => {
    showBetaModal(loading, (email: string, onSuccess: () => void) => {
      setLoading(true);
      sendEmailBeta(
        email,
        () => {
          onSuccess();
          enqueueSnackbar('Email añadido a la lista de espera.');
          setLoading(false);
        },
        () => {
          enqueueSnackbar('Se ha producido un error, por favor vuelva a intentarlo.', {
            variant: 'error',
          });
          setLoading(false);
        }
      );
    });
  };

  const renderWelcomeModal = () => (
    <DialogAnimate maxWidth={'sm'} open={showNewRegisterModal}>
      <NewRegisterModal onAccept={() => setShowNewRegisterModal(false)} />
    </DialogAnimate>
  );

  const renderLogo = () => {
    if (isBusiness) {
      if (company.imageUrl) {
        return (
          <Stack>
            <Image
              bgColor={undefined}
              duration={200}
              errorIcon={<LogoIso />}
              fit={'contain'}
              height={50}
              src={company.imageUrl ?? ''}
              width={'100%'}
            />
          </Stack>
        );
      } else return <LogoIsoBusiness />;
    }
    return <LogoIso />;
  };

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {renderLogo()}
          <Stack
            alignItems="center"
            direction="row"
            sx={{ alignSelf: !smUp ? 'center' : 'flex-start' }}
          >
            <LanguagePopover />
            {smUp && isUserRegistratioEnabled && (
              <Typography sx={{ ml: 2 }} variant="body2">
                {translate('auth.dont_have_account')}{' '}
                <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
                  {translate('auth.start_now')}
                </Link>
              </Typography>
            )}
          </Stack>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Stack sx={{ zIndex: 100 }}>
              <Typography color="text.primary" sx={{ px: 5, mt: 2 }} variant="h4">
                {translate('auth.welcome')}
              </Typography>
              <Typography color={'text.secondary'} sx={{ mt: 2, mb: 0, px: 5 }} variant="body2">
                {isBusiness
                  ? `Descubre cómo Vumi puede multiplicar la productividad de tu negocio. Toma mejores decisiones con datos y análisis al instante.`
                  : 'Agrega todas tus inversiones en un mismo sitio y eleva tu toma de decisiones con el mejor análisis financiero del mercado.'}
              </Typography>

              <Stack sx={{ alignItems: 'center', mr: 6, mt: 6 }}>
                <ImageComponent
                  src={
                    isBusiness
                      ? '/ilustrations/office_amico.svg'
                      : '/ilustrations/programmer_amico.png'
                  }
                  sx={{ width: { xs: 200, sm: 300 } }}
                />
              </Stack>
            </Stack>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
              }}
            >
              {isBusiness ? (
                <Stack alignItems={'center'} flexDirection={'row'} sx={{ px: 5, mb: 2 }}>
                  <Typography color="text.secondary" fontSize={13} sx={{ mr: 1.3 }} variant="body1">
                    {'Powered by'}
                  </Typography>
                  <LogoIso height={30} sx={{ mt: 0.6 }} width={60} />
                </Stack>
              ) : (
                <Typography color="text.secondary" sx={{ px: 5, mb: 4 }} variant="subtitle2">
                  {'© 2024 Vumi Portfolio S.L.'}
                </Typography>
              )}
            </div>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack alignItems="center" direction="row" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h4">
                  {translate('auth.sign_in_vumi', {
                    company: 'Vumi',
                  })}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {translate('auth.add_your_credentials')}
                </Typography>
              </Box>
            </Stack>

            <LoginForm
              defaultEmail={(state && state.email) ?? ''}
              handleOnSubmit={loginServiceRequest}
              isLoading={loading}
              onPressBeta={handleSignBeta}
            />

            <Typography align="center" sx={{ color: 'text.secondary', mt: 3 }} variant="body2">
              <Link
                color="text.secondary"
                href="https://www.vumi.io/terminos-y-condiciones"
                target={'_blank'}
              >
                {translate('auth.terms_services')}
              </Link>
              <Link
                color="text.secondary"
                href="https://www.vumi.io/politica-de-privacidad/"
                sx={{ ml: 2 }}
                target={'_blank'}
              >
                {translate('auth.privacy_policy')}
              </Link>
              .
            </Typography>

            {!smUp && isUserRegistratioEnabled && (
              <Typography align="center" sx={{ mt: 3 }} variant="body2">
                {translate('auth.dont_have_account')}{' '}
                <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
                  {translate('auth.start_now')}
                </Link>
              </Typography>
            )}
            {!smUp && isBusiness && (
              <Stack
                alignItems={'center'}
                flexDirection={'row'}
                sx={{ position: 'absolute', mb: 1, bottom: 0 }}
              >
                <Typography color="text.secondary" fontSize={13} sx={{ mr: 1.3 }} variant="body1">
                  {'Powered by'}
                </Typography>
                <LogoIso height={30} sx={{ mt: 0.6 }} width={60} />
              </Stack>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
      {renderWelcomeModal()}
    </Page>
  );
};

export default Login;
