import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// ----------------------------------------------------------------------

type Props = {
  accept: string | string[] | undefined;
  maxSize?: number;
  disabled?: boolean;
};

export default function useGetDropzone({ accept, maxSize, disabled }: Props) {
  const [filePreview, setFilePreview] = useState<any | null>();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        setFilePreview(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
      }
    },
    [setFilePreview]
  );

  const dropZoneState = useDropzone({
    multiple: false,
    accept,
    maxSize,
    disabled: disabled || filePreview,
    onDrop: handleDrop,
  });

  const onChangeFile = (newFile: any | null) => {
    setFilePreview(newFile);
  };

  return {
    dropZoneState,
    filePreview,
    onChangeFile,
  };
}
