import { Typography } from '@mui/material';
// types
import { ConnectionType } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';

// ----------------------------------------------------------------------

type Props = {
  connection: ConnectionType;
};

export default function ConnectionItemTitle({ connection }: Props) {
  return (
    <Typography variant="subtitle2">
      {!connection.pending
        ? 'Ya tienes importada tu cuenta de'
        : 'Estamos importando tu conexión con'}
      <Typography
        component="span"
        sx={{ color: 'primary.main', textTransform: 'capitalize' }}
        variant="body2"
      >
        &nbsp; {connection.entity}
      </Typography>
    </Typography>
  );
}
