import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
// @mui
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
// redux
import { RootState } from 'src/redux/store';
// theme
import customPalette from 'src/theme/customPalette';
import componentsOverride from '../theme/overrides';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeColorPresets({ children }: Props) {
  const defaultTheme = useTheme();

  const themeColorApp = useSelector((state: RootState) =>
    get(state, ['settings', 'themeColorApp'])
  );

  const palette = customPalette(themeColorApp).light;

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: palette.primary,
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(palette.primary.main, 0.24)}`,
      },
    }),
    [defaultTheme, themeColorApp]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
