import jwtDecode from 'jwt-decode';
import { User } from 'src/redux/slices/AuthSlice/authSlice.d';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const getUserFromToken = (accessToken: string, baseCurrency?: string): User => {
  const decoded = jwtDecode<{
    name: string;
    sub: string;
    isVerified: boolean;
    email: string;
    imageUrl: string;
    stripeCustomerId: string;
    country: number;
    privileges: string;
    shareLink: string;
    latestPriceUpdate: string;
    mfaEnabled: boolean;
  }>(accessToken);
  return {
    id: decoded.sub,
    name: decoded.name,
    firstSurname: '',
    isVerified: decoded.isVerified,
    email: decoded.email,
    imageUrl: decoded.imageUrl,
    stripeCustomerId: decoded.stripeCustomerId,
    country: decoded?.country?.toString(),
    baseCurrency: baseCurrency ?? '',
    privileges: JSON.parse(decoded.privileges).map((i: any) => i.name),
    hideAccount: false,
    shareLink: decoded.shareLink,
    latestPriceUpdate: decoded.latestPriceUpdate,
    mfaEnabled: decoded.mfaEnabled,
    reportsGenerated: 0,
    reportsLimit: 0,
    access: 'FULL_ACCESS',
  };
};

export const getRefreshToken = (accessToken: string): string => {
  const decoded = jwtDecode<{
    refreshToken: string;
  }>(accessToken);
  return decoded.refreshToken;
};

export { isValidToken };
