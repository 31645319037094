import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  ListItemButton,
  Stack,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { PerformanceType, ReferenceIndice } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { StockIndexDto } from 'src/services/indices/NetTypes';
import IndicesSearch from '../../IndicesSearch';
import Label from '../../Label';
import RiskValueSelector from './RiskValueSelector';
import RiskValueFreeLabel from './RiskValueFreeLabel';
import IndicesTitle from './IndicesTitle';
import PerformanceTitle from './PerformanceTitle';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

type Props = {
  onSave: (
    newPerformanceType: PerformanceType,
    riskFreeAsset: string,
    indicesIds: string[],
    onSuccess: () => void,
    onFailure: () => void
  ) => void;
  onCancel: () => void;
  performanceType: PerformanceType;
  referenceIndices: ReferenceIndice[];
  riskFreeAssetBenchmark: string;
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
    },
    '& + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default function WalletSettingsModal({
  onSave,
  onCancel,
  performanceType,
  referenceIndices,
  riskFreeAssetBenchmark,
}: Props) {
  const [type, setType] = useState<PerformanceType>(performanceType);
  const [selectedIndices, setSelectedIndices] = useState<ReferenceIndice[]>(referenceIndices ?? []);
  const [riskValueSelected, setRiskValueSelected] = useState<string>(riskFreeAssetBenchmark ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const checkDisableSave = () => {
    if (
      performanceType !== type ||
      !_.isEqual(referenceIndices, selectedIndices) ||
      riskValueSelected !== riskFreeAssetBenchmark
    )
      return false;
    return true;
  };

  const handleRemoveSelected = (id: string) => {
    setSelectedIndices(selectedIndices.filter((i) => i.meta.id !== id));
  };

  const handleNavigateToNewIndice = () => {
    navigate(PATH_DASHBOARD.user.account, { state: { toSettings: true } });
  };

  const handleOnPress = (item: StockIndexDto) => {
    if (selectedIndices.length < 3) {
      const index = selectedIndices.findIndex((i) => i.meta.id === item.id);
      if (index === -1 && item.id !== '')
        setSelectedIndices([
          ...selectedIndices,
          {
            meta: {
              id: item.id,
              name: item.name,
              currencyCode: item.currency,
              symbol: item.symbol,
            },
            performanceTimeSeries: {},
            absoluteValueTimeSeries: {},
            annualizedPerformances: {},
            components: [],
            annualPerformances: { performance: {}, value: {} },
            accumulatedPerformances: {
              accumulatedPerformanceByPeriod: {},
              accumulatedValueByPeriod: {},
            },
            riskMetrics: {},
          },
        ]);
    }
  };

  const handleOnSave = () => {
    setIsLoading(true);
    onSave(
      type,
      riskValueSelected ?? '',
      selectedIndices.map((i) => i.meta.id),
      () => {
        enqueueSnackbar('Se han guardado con éxito tus cambios.');
        setIsLoading(false);
        onCancel();
      },
      () => {
        enqueueSnackbar(
          'Se ha producido un error al intentar guardar tus cambios, por favor vuelva a intentarlo.',
          { variant: 'error' }
        );
        setIsLoading(false);
        onCancel();
      }
    );
  };

  const renderPerformanceType = () => (
    <Stack alignItems="center" flexDirection="row" sx={{ mt: 2 }}>
      <PerformanceTitle />
      <Stack alignItems="center" flexDirection="row" sx={{ mr: 2, ml: 3 }}>
        <Typography
          color={type === 'MWRR' ? 'primary.main' : 'text.secondary'}
          fontSize={13}
          sx={{ mr: 1, mt: 0.2 }}
          variant="subtitle2"
        >
          {'MWRR'}
        </Typography>
        <MaterialUISwitch
          checked={type !== 'MWRR'}
          color="primary"
          onChange={() => setType(type === 'MWRR' ? 'TWRR' : 'MWRR')}
        />
        <Typography
          color={type === 'TWRR' ? 'primary.main' : 'text.secondary'}
          fontSize={13}
          sx={{ ml: 1, mt: 0.2 }}
          variant="subtitle2"
        >
          {'TWRR'}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderIndicesSection = () => (
    <Stack sx={{ mt: 2 }}>
      <IndicesTitle />
      {selectedIndices.length < 3 && (
        <IndicesSearch onSelectIndice={handleOnPress} sx={{ mt: 2, mb: 1 }} />
      )}
      {selectedIndices.length === 0 ? (
        <Typography color="text.primary" sx={{ mt: 2 }} variant="subtitle2">
          {'Todavía no tienes ningun indice seleccionado'}
        </Typography>
      ) : (
        renderList(selectedIndices)
      )}
      <Button
        color="primary"
        onClick={handleNavigateToNewIndice}
        size={'small'}
        sx={{ width: 220, mt: 2 }}
      >
        {'Ir a añadir indice personalizado'}
      </Button>
    </Stack>
  );

  const renderList = (items: ReferenceIndice[]) => (
    <Box
      sx={{
        mt: 2,
        display: 'grid',
        rowGap: 2,
        columnGap: 2,
        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
      }}
    >
      {items.map((i) => renderItemIndice(i))}
    </Box>
  );

  const renderItemIndice = (item: ReferenceIndice) => (
    <ListItemButton
      key={item.meta.id}
      onClick={() => handleRemoveSelected(item.meta.id)}
      sx={{ backgroundColor: 'background.neutral', borderRadius: 1 }}
    >
      <Stack>
        <Typography color="text.primary" fontSize={12} noWrap variant="subtitle2">
          {item.meta.name}
        </Typography>

        {!!!item.meta.currencyCode ? (
          <Label color={'primary'} sx={{ mt: 0.4 }}>
            {'Personalizado'}
          </Label>
        ) : (
          <Stack alignItems="center" flexDirection="row" sx={{ mt: 0.5 }}>
            <Typography color={'primary.main'} fontSize={10} noWrap variant="subtitle2">
              {'.' + item.meta.symbol}
            </Typography>
            <Stack
              sx={{
                height: 4,
                width: 4,
                backgroundColor: 'text.primary',
                borderRadius: 2,
                mx: 1,
              }}
            />
            <Typography color={'primary.main'} fontSize={10} noWrap variant="subtitle2">
              {item.meta.currencyCode}
            </Typography>
          </Stack>
        )}
      </Stack>
    </ListItemButton>
  );

  return (
    <>
      <Stack sx={{ p: 3 }}>
        <Typography variant="h5">{'Ajustes de cartera'}</Typography>
        {renderPerformanceType()}
        {renderIndicesSection()}
        <Stack spacing={2} sx={{ mt: 2 }}>
          <RiskValueFreeLabel />
          <RiskValueSelector onChange={setRiskValueSelected} value={riskValueSelected} />
        </Stack>
      </Stack>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <LoadingButton color="inherit" onClick={onCancel} size="medium" variant="outlined">
          {'Volver'}
        </LoadingButton>
        <LoadingButton
          disabled={checkDisableSave()}
          loading={isLoading}
          onClick={handleOnSave}
          size="medium"
          variant="contained"
        >
          {'Guardar'}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
