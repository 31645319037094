import {
  getCompany,
  updateCompany,
  updateOffice,
} from 'src/redux/slices/BusinessSlice/businessSlice';
import {
  ClientAccess,
  ClientCompanyForm,
  ClientsType,
  CompanyType,
  EditEmployeeCompanyForm,
  EmployeeCompanyForm,
  EmployeeType,
  InfoCompanyForm,
  OfficeCompanyForm,
  OfficeType,
} from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { updateClientInfo } from 'src/redux/slices/ClientSlice/clientSlice';
import { UpdateClientPayload } from 'src/redux/slices/ClientSlice/clientSlice.d';
import {
  getReportSettings,
  updateReportSettings,
  updateThemeColorApp,
} from 'src/redux/slices/SettingsSlice/settingsSlice';
import { ThemeColorsApp, ThemeColorsReport } from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import { RootState } from 'src/redux/store';
import { CompanyFormProps } from 'src/sections/@dashboard/admin/AdminCompanyDrawer';
import { getAxiosEmptyRequest, getAxiosEmptyRequestAction } from 'src/services/http/axios';
import { generalCommandsUrl, generalQueriesUrl } from '../EnpointConstants';
import { GetPortfoliosManagedResponse } from './NetTypes';
import {
  CREATE_CLIENT,
  CREATE_COMPANY,
  CREATE_CSV_CLIENTS,
  CREATE_EMPLOYEE,
  CREATE_OFFICE,
  DELETE_CLIENT,
  DELETE_CLIENTS,
  DELETE_COMPANY,
  DELETE_EMPLOYEE,
  GET_ALL_PORTFOLIOS_MANAGED,
  GET_CLIENTS,
  GET_COMPANIES,
  GET_COMPANY,
  GET_COMPANY_DETAIL,
  GET_COMPANY_FAILURE,
  GET_COMPANY_STARTED,
  GET_EMPLOYEES,
  GET_OFFICE,
  GET_OFFICES,
  GET_REPORT_SETTINGS,
  GET_REPORT_SETTINGS_FAILURE,
  GET_REPORT_SETTINGS_STARTED,
  GRANT_ACCESS_CLIENTS,
  REASING_CLIENTS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_STARTED,
  UPDATE_COMPANY,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_STARTED,
  UPDATE_EMPLOYEE,
  UPDATE_OFFICE,
  UPDATE_OFFICE_FAILURE,
  UPDATE_OFFICE_STARTED,
} from './RequestIds';
import {
  setCreateClientBody,
  setCreateCompanyBody,
  setCreateEmployeeBody,
  setCreateOfficeBody,
  setEditEmployeeBody,
  setUpdateCompanyActionBody,
  setUpdateCompanyBody,
  setUpdateCompanySettingsBody,
  setUpdateOfficeActionBody,
  setUpdateReportSettingsBody,
} from './RequestUtils';
import {
  transformCreateCSVClients,
  transformGetClients,
  transformGetCompanies,
  transformGetCompany,
  transformGetCompanyAction,
  transformGetEmployees,
  transformGetOffice,
  transformGetOffices,
  transformGetPortfoliosManaged,
  transformGetReportSettings,
  transformUpdateClient,
  transformUpdateCompanySettings,
  transformUpdateOfficeAction,
  transformUpdateReportSettings,
} from './ResponseUtils';

const queryUrl = generalQueriesUrl + '/organizations';
const commandUrl = generalCommandsUrl + '/organizations';

// COMMANDS
const createCompanyUrl = () => commandUrl;
const createOfficeUrl = (id: string) => commandUrl + `/${id}/offices`;
const createEmployeeUrl = (companyId: string, officeId: string) =>
  commandUrl + `/${companyId}/offices/${officeId}/employees`;
const createClientUrl = (companyId: string) => commandUrl + `/${companyId}/clients`;
const updateClientUrl = (companyId: string, clientId: string) =>
  commandUrl + `/${companyId}/clients/${clientId}`;
const updateEmployeeUrl = (companyId: string, officeId: string, employee: string) =>
  commandUrl + `/${companyId}/offices/${officeId}/employees/${employee}`;
const createCSVClientsUrl = (companyId: string) => commandUrl + `/${companyId}/clients/file`;
const updateCompanyUrl = (id: string) => commandUrl + `/${id}`;
const updateCompanyImageUrl = (id: string, secondary: boolean = false) =>
  commandUrl + `/${id}/image?secondary=${secondary}`;
const updateOfficeUrl = (companyId: string, office: string) =>
  commandUrl + `/${companyId}/offices/${office}`;
const deleteClientUrl = (companyId: string, clientId: string) =>
  commandUrl + `/${companyId}/clients/${clientId}`;
const deleteClientsUrl = (clientIds: string[]) => commandUrl + `/clients?clientIds=${clientIds}`;
const grantAccessClientsUrl = (clientIds: string[], access: ClientAccess) =>
  commandUrl + `/clients/grant-access?clientIds=${clientIds}&access=${access}`;
const deleteEmployeeUrl = (companyId: string, employeeId: string) =>
  commandUrl + `/${companyId}/employees/${employeeId}`;
const reasignClientsUrl = (clientIds: string[], employeeId: string) =>
  commandUrl + `/clients/reassign?clientIds=${clientIds}&newEmployeeId=${employeeId}`;
const updateSettingsReportsUrl = (id: string) => commandUrl + `/${id}/settings/report`;
const updateCompanySettingsUrl = (id: string) => commandUrl + `/${id}/settings/web`;

// QUERIES
const getCompaniesUrl = (page: number) => queryUrl + `?page=${page}&size=${7}&name=${''}`;
const getCompanyUrl = (id: string) => queryUrl + `/${id}`;
const getOfficesUrl = (id: string) => queryUrl + `/${id}/offices?page=${0}&size=${20}&name=${''}`;
const getOfficeUrl = (id: string, officeId: string) => queryUrl + `/${id}/offices/${officeId}`;
const getClientsUrl = (
  companyId: string,
  name: string = '',
  office: string = '',
  employee: string = '',
  page: number = 0,
  size: number = 10
) =>
  queryUrl +
  `/${companyId}/clients?page=${page}&size=${size}${name !== '' ? `&name=${name}` : ''}${
    office !== '' ? `&office=${office}` : ''
  }${employee !== '' ? `&employee=${employee}` : ''}`;
const getEmployeesUrl = (
  id: string,
  page: number,
  size: number,
  officeId: string = '',
  name: string = ''
) =>
  queryUrl +
  `/${id}/employees?page=${page}&size=${size}${name !== '' ? `&name=${name}` : ''}${
    officeId !== '' ? `&office=${officeId}` : ''
  }`;

const getManagedPortfolios = (clientId: string, page: number, size: number) =>
  generalQueriesUrl +
  `/portfolios/managed?page=${page}&size=${size}${
    clientId && clientId !== '' ? '&clientId=' + clientId : ''
  }`;

const getSettingsReportsUrl = (id: string) => queryUrl + `/${id}/settings/report`;

export function createCompanyRequest(
  companyName: string,
  name: string,
  firstSurname: string,
  secondSurname: string,
  cif: string,
  email: string,
  onboardingLanguage: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = CREATE_COMPANY;
  apiCall.setEndpointFromState = () => createCompanyUrl();
  apiCall.setBodyFromState = setCreateCompanyBody(
    companyName,
    name,
    firstSurname,
    secondSurname,
    cif,
    email,
    onboardingLanguage
  );
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateCompanyRequest(
  id: string,
  company: CompanyFormProps,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = UPDATE_COMPANY;
  apiCall.setEndpointFromState = () => updateCompanyUrl(id);
  apiCall.setBodyFromState = setUpdateCompanyBody(id, company);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
export function updateCompanyImageRequest(
  id: string,
  formData: FormData,
  secondary: boolean,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = UPDATE_COMPANY;
  apiCall.setEndpointFromState = () => updateCompanyImageUrl(id, secondary);
  apiCall.setBodyFromState = (_) => formData;
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateCompanyAction(
  id: string,
  company: InfoCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = UPDATE_COMPANY;
  apiCall.startedReqType = UPDATE_COMPANY_STARTED;
  apiCall.successReqType = updateCompany.type;
  apiCall.failureReqType = UPDATE_COMPANY_FAILURE;
  apiCall.setEndpointFromState = () => updateCompanyUrl(id);
  apiCall.setBodyFromState = setUpdateCompanyActionBody(id, company);
  apiCall.transformResponseDataWithState = transformGetCompanyAction(company);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateOfficeAction(
  companyId: string,
  officeId: string,
  office: OfficeCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, OfficeType, any>();
  apiCall.reqId = UPDATE_OFFICE;
  apiCall.startedReqType = UPDATE_OFFICE_STARTED;
  apiCall.successReqType = updateOffice.type;
  apiCall.failureReqType = UPDATE_OFFICE_FAILURE;
  apiCall.setEndpointFromState = () => updateOfficeUrl(companyId, officeId);
  apiCall.setBodyFromState = setUpdateOfficeActionBody(companyId, officeId, office);
  apiCall.transformResponseDataWithState = transformUpdateOfficeAction(office);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function deleteCompanyRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = DELETE_COMPANY;
  apiCall.setEndpointFromState = () => updateCompanyUrl(id);
  apiCall.method = 'DELETE';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getCompaniesRequest(
  page: number = 0,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<
    RootState,
    { companies: CompanyType[]; totalItems: number },
    any
  >();
  apiCall.reqId = GET_COMPANIES;
  apiCall.setEndpointFromState = () => getCompaniesUrl(page);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetCompanies();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getCompanyDetailRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, CompanyType, any>();
  apiCall.reqId = GET_COMPANY_DETAIL;
  apiCall.setEndpointFromState = () => getCompanyUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetCompany();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getCompanyDetailAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, CompanyType, any>();
  apiCall.reqId = GET_COMPANY;
  apiCall.startedReqType = GET_COMPANY_STARTED;
  apiCall.successReqType = getCompany.type;
  apiCall.failureReqType = GET_COMPANY_FAILURE;
  apiCall.setEndpointFromState = () => getCompanyUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetCompany();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getEmployeesRequest(
  id: string,
  page: number = 0,
  size: number = 10,
  officeId: string = '',
  name: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<
    RootState,
    { employees: EmployeeType[]; totalItems: number },
    any
  >();
  apiCall.reqId = GET_EMPLOYEES;
  apiCall.setEndpointFromState = () => getEmployeesUrl(id, page, size, officeId, name);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetEmployees();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getOfficesRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<
    RootState,
    { offices: OfficeType[]; totalItems: number },
    any
  >();
  apiCall.reqId = GET_OFFICES;
  apiCall.setEndpointFromState = () => getOfficesUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetOffices();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function createEmployeeRequest(
  companyId: string,
  employee: EmployeeCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = CREATE_EMPLOYEE;
  apiCall.setEndpointFromState = () => createEmployeeUrl(companyId, employee.officeId);
  apiCall.setBodyFromState = setCreateEmployeeBody(employee);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function editEmployeeRequest(
  companyId: string,
  employee: EditEmployeeCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = UPDATE_EMPLOYEE;
  apiCall.setEndpointFromState = () => updateEmployeeUrl(companyId, employee.officeId, employee.id);
  apiCall.setBodyFromState = setEditEmployeeBody(employee);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function createOfficeRequest(
  companyId: string,
  office: OfficeCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = CREATE_OFFICE;
  apiCall.setEndpointFromState = () => createOfficeUrl(companyId);
  apiCall.setBodyFromState = setCreateOfficeBody(office);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getOfficeDetailRequest(
  companyId: string,
  officeId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, OfficeType, any>();
  apiCall.reqId = GET_OFFICE;
  apiCall.setEndpointFromState = () => getOfficeUrl(companyId, officeId);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetOffice();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateOfficeRequest(
  companyId: string,
  officeId: string,
  office: OfficeCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, OfficeType, any>();
  apiCall.reqId = UPDATE_OFFICE;
  apiCall.setEndpointFromState = () => updateOfficeUrl(companyId, officeId);
  apiCall.setBodyFromState = setUpdateOfficeActionBody(companyId, officeId, office);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getClientsRequest(
  companyId: string,
  name: string = '',
  office: string = '',
  employee: string = '',
  page: number = 0,
  size: number = 10,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<
    RootState,
    { clients: ClientsType[]; totalItems: number },
    any
  >();
  apiCall.reqId = GET_CLIENTS;
  apiCall.setEndpointFromState = () => getClientsUrl(companyId, name, office, employee, page, size);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetClients();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function createClientRequest(
  companyId: string,
  employeeId: string,
  client: ClientCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = CREATE_CLIENT;
  apiCall.setEndpointFromState = () => createClientUrl(companyId);
  apiCall.setBodyFromState = setCreateClientBody(employeeId, client);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateClientRequest(
  clientId: string,
  companyId: string,
  employeeId: string,
  client: ClientCompanyForm,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdateClientPayload, any>();
  apiCall.reqId = UPDATE_CLIENT;
  apiCall.startedReqType = UPDATE_CLIENT_STARTED;
  apiCall.successReqType = updateClientInfo.type;
  apiCall.failureReqType = UPDATE_CLIENT_FAILURE;
  apiCall.setEndpointFromState = () => updateClientUrl(companyId, clientId);
  apiCall.setBodyFromState = setCreateClientBody(employeeId, client);
  apiCall.transformResponseDataWithState = transformUpdateClient(client);
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function createCSVClientsRequest(
  companyId: string,
  formData: FormData,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = CREATE_CSV_CLIENTS;
  apiCall.setEndpointFromState = () => createCSVClientsUrl(companyId);
  apiCall.setBodyFromState = (_) => formData;
  apiCall.transformResponseDataWithState = transformCreateCSVClients();
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function deleteClientRequest(
  companyId: string,
  clientId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = DELETE_CLIENT;
  apiCall.setEndpointFromState = () => deleteClientUrl(companyId, clientId);
  apiCall.method = 'DELETE';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function deleteClientsRequest(
  companyId: string,
  clientIds: string[],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = DELETE_CLIENTS;
  apiCall.setEndpointFromState = () => deleteClientsUrl(clientIds);
  apiCall.method = 'DELETE';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function grantAccessClientsRequest(
  access: ClientAccess,
  clientIds: string[],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = GRANT_ACCESS_CLIENTS;
  apiCall.setEndpointFromState = () => grantAccessClientsUrl(clientIds, access);
  apiCall.method = 'PATCH';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function deleteEmployeeRequest(
  companyId: string,
  employeeId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = DELETE_EMPLOYEE;
  apiCall.setEndpointFromState = () => deleteEmployeeUrl(companyId, employeeId);
  apiCall.method = 'DELETE';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function reasignClientsRequest(
  clientIds: string[],
  employeeId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, any, any>();
  apiCall.reqId = REASING_CLIENTS;
  apiCall.setEndpointFromState = () => reasignClientsUrl(clientIds, employeeId);
  apiCall.method = 'PATCH';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getPortfoliosManagedRequest(
  clientId: string,
  page: number,
  size: number,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetPortfoliosManagedResponse, any>();
  apiCall.reqId = GET_ALL_PORTFOLIOS_MANAGED;
  apiCall.setEndpointFromState = () => getManagedPortfolios(clientId, page, size);
  apiCall.transformResponseDataWithState = transformGetPortfoliosManaged();
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getReportSettingsAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ThemeColorsReport, any>();
  apiCall.reqId = GET_REPORT_SETTINGS;
  apiCall.startedReqType = GET_REPORT_SETTINGS_STARTED;
  apiCall.successReqType = getReportSettings.type;
  apiCall.failureReqType = GET_REPORT_SETTINGS_FAILURE;
  apiCall.setEndpointFromState = () => getSettingsReportsUrl(id);
  apiCall.transformResponseDataWithState = transformGetReportSettings();
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateReportSettingsAction(
  id: string,
  reportSettings: ThemeColorsReport,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ThemeColorsReport, any>();
  apiCall.reqId = GET_REPORT_SETTINGS;
  apiCall.startedReqType = GET_REPORT_SETTINGS_STARTED;
  apiCall.successReqType = updateReportSettings.type;
  apiCall.failureReqType = GET_REPORT_SETTINGS_FAILURE;
  apiCall.setEndpointFromState = () => updateSettingsReportsUrl(id);
  apiCall.setBodyFromState = setUpdateReportSettingsBody(reportSettings);
  apiCall.transformResponseDataWithState = transformUpdateReportSettings(reportSettings);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateCompanySettingsAction(
  id: string,
  appSettings: ThemeColorsApp,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ThemeColorsApp, any>();
  apiCall.reqId = GET_REPORT_SETTINGS;
  apiCall.startedReqType = GET_REPORT_SETTINGS_STARTED;
  apiCall.successReqType = updateThemeColorApp.type;
  apiCall.failureReqType = GET_REPORT_SETTINGS_FAILURE;
  apiCall.setEndpointFromState = () => updateCompanySettingsUrl(id);
  apiCall.setBodyFromState = setUpdateCompanySettingsBody(appSettings);
  apiCall.transformResponseDataWithState = transformUpdateCompanySettings(appSettings);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}
