import { Slider, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export interface Props {
  onChange: (value: number) => void;
}

// ----------------------------------------------------------------------

export default function HorizontalSlider({ onChange }: Props) {
  return (
    <Stack sx={{ mt: 2, alignItems: 'center' }}>
      <Slider defaultValue={100} onChange={(_event: Event, value: any) => onChange(value * 0.01)} />
      <Typography color="text.secondary" fontSize={11} sx={{}} variant={'body1'}>
        {'Escala'}
      </Typography>
    </Stack>
  );
}
