import { RootState } from 'src/redux/store';
import { store } from 'src/redux/store';
import {
  AxiosRequestInterceptorList,
  AxiosResponseInterceptorList,
  getAxiosNewClientMiddleware,
  getAxiosNewClient,
} from '../http/axios';
import { manageError } from './ManageGeneralErrorsAxiosInterceptor';
import { retryWithRefreshToken } from './ManageRetryWithRefreshTokenAxiosInterceptor';
import { manageAuthenticationRequest } from 'src/services/config/ManageRequestAuthAxiosInteceptor';
import { manageLanguageRequest } from 'src/services/config/ManageRequestLanguageAxiosInterceptor';
import { BASE_URL } from 'src/config';
import uuidv4 from 'src/utils/uuidv4';

const reduxRequestInterceptorList = (getState: () => RootState, next: any) => {
  const list = new AxiosRequestInterceptorList<RootState>();
  list.addInterceptor(manageAuthenticationRequest(getState, next));
  list.addInterceptor(manageLanguageRequest(getState, next));
  return list.getList();
};

const reduxResponseInterceptorList = (getState: () => RootState, next: any) => {
  const list = new AxiosResponseInterceptorList<RootState>();
  list.addInterceptor(undefined, retryWithRefreshToken(getState, next));
  //list.addInterceptor(undefined, retryTimeoutRequest(getState, next));
  list.addInterceptor(undefined, manageError(getState, next));
  return list.getList();
};

export const getNetworkMiddleware = () => {
  return getAxiosNewClientMiddleware(
    BASE_URL,
    { requestId: uuidv4() },
    reduxRequestInterceptorList,
    reduxResponseInterceptorList,
    false,
    10000
  );
};

export const getNetworkClient = (contentType = 'json') => {
  return getAxiosNewClient(
    BASE_URL,
    store.getState,
    contentType === 'json'
      ? { 'Content-Type': 'application/json', requestId: uuidv4() }
      : { 'Content-Type': 'multipart/form-data', requestId: uuidv4() },
    reduxRequestInterceptorList(store.getState, store.dispatch),
    reduxResponseInterceptorList(store.getState, store.dispatch),
    false,
    10000
  );
};
