import { RootState } from 'src/redux/store';
import { getAxiosEmptyRequest, getAxiosEmptyRequestAction } from 'src/services/http/axios';
import {
  transformGetConnections,
  transformCreateConnection,
  transformGetConnectionDetails,
  transformUpdateNameConnection,
  transformUpdateDisplayConnection,
  transformDeleteConnection,
  transformUpdateAvConnection,
  transformGetVumiCredentials,
  transformGetConnectionsNetworth,
} from 'src/services/connections/ResponseUtils';
import {
  GET_CONNECTIONS,
  GET_CONNECTIONS_STARTED,
  GET_CONNECTIONS_FAILURE,
  CREATE_CONNECTION,
  CREATE_CONNECTION_STARTED,
  CREATE_CONNECTION_FAILURE,
  GET_CONNECTION_DETAIL,
  GET_CONNECTION_DETAIL_STARTED,
  GET_CONNECTION_DETAIL_FAILURE,
  UPDATE_CONNECTION,
  UPDATE_CONNECTION_STARTED,
  UPDATE_CONNECTION_FAILURE,
  DELETE_CONNECTION,
  DELETE_CONNECTION_STARTED,
  DELETE_CONNECTION_FAILURE,
  UPDATE_MANUAL_CONNECTION,
  UPDATE_AVAILABILITY_CONNECTION,
  UPDATE_AVAILABILITY_CONNECTION_STARTED,
  UPDATE_AVAILABILITY_CONNECTION_FAILURE,
  GET_VUMI_CREDENTIALS,
  REIMPORT_CONNECTION,
  GET_CONNECTIONS_NETWORTH,
  GET_CONNECTIONS_NETWORTH_STARTED,
  GET_CONNECTIONS_NETWORTH_FAILURE,
} from './RequestIds';
import {
  addConnection,
  getAccounts,
  getConnections,
  updateConnection,
  deleteConnection,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice';
import {
  ConnectionType,
  GetAccountsPayload,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import {
  setCreateConnectionBody,
  setCreateManualConnectionBody,
  setUpdateAvConnectionBody,
  setUpdateConnectionDisplayedBody,
  setUpdateConnectionNameBody,
} from './RequestUtils';
import { UpdateAvConnectionBody } from './NetTypes';

const getConnectionsUrl = () => `/api/v1/queries/credentials`;
const getConnectionsNetworthUrl = () => `/api/v1/queries/credentials/values`;
const getVumiCredentialsUrl = () => `/api/v1/queries/credentials/vumi`;
const getConnectionDetailsUrl = (id: string) => `/api/v1/queries/credentials/${id}`;
const createConnectionUrl = () => `/api/v1/commands/credentials`;
const createManualConnectionUrl = () => `/api/v1/commands/credentials/vumi`;
const updateConnectionNameUrl = (id: string) => `/api/v1/commands/credentials/${id}`;
const updateManualConnectionUrl = (id: string) =>
  `/api/v1/commands/credentials/${id}/manual-update`;
const updateAvailabilityConnectionUrl = (id: string) =>
  `/api/v1/commands/credentials/${id}/availability`;
const reimportConnectionUrl = (id: string) => `/api/v1/commands/credentials/${id}/reimport`;
const reimportAllConnectionUrl = () => `/api/v1/commands/credentials/reimport`;

export function getConnectionsAction(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType[], any>();
  apiCall.reqId = GET_CONNECTIONS;
  apiCall.startedReqType = GET_CONNECTIONS_STARTED;
  apiCall.successReqType = getConnections.type;
  apiCall.failureReqType = GET_CONNECTIONS_FAILURE;
  apiCall.setEndpointFromState = () => getConnectionsUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetConnections();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getConnectionsNetworthAction(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType[], any>();
  apiCall.reqId = GET_CONNECTIONS_NETWORTH;
  apiCall.startedReqType = GET_CONNECTIONS_NETWORTH_STARTED;
  apiCall.successReqType = getConnections.type;
  apiCall.failureReqType = GET_CONNECTIONS_NETWORTH_FAILURE;
  apiCall.setEndpointFromState = () => getConnectionsNetworthUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetConnectionsNetworth();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function createConnectionAction(
  name: string,
  token: string,
  entity: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType, any>();
  apiCall.reqId = CREATE_CONNECTION;
  apiCall.startedReqType = CREATE_CONNECTION_STARTED;
  apiCall.successReqType = addConnection.type;
  apiCall.failureReqType = CREATE_CONNECTION_FAILURE;
  apiCall.setEndpointFromState = () => createConnectionUrl();
  apiCall.setBodyFromState = setCreateConnectionBody(name, token, entity);
  apiCall.method = 'POST';
  apiCall.transformResponseDataWithState = transformCreateConnection(name, entity);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getConnectionDetailsAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, GetAccountsPayload, any>();
  apiCall.reqId = GET_CONNECTION_DETAIL;
  apiCall.startedReqType = GET_CONNECTION_DETAIL_STARTED;
  apiCall.successReqType = getAccounts.type;
  apiCall.failureReqType = GET_CONNECTION_DETAIL_FAILURE;
  apiCall.setEndpointFromState = () => getConnectionDetailsUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetConnectionDetails(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateConnectionNameAction(
  id: string,
  name: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType, any>();
  apiCall.reqId = UPDATE_CONNECTION;
  apiCall.startedReqType = UPDATE_CONNECTION_STARTED;
  apiCall.successReqType = updateConnection.type;
  apiCall.failureReqType = UPDATE_CONNECTION_FAILURE;
  apiCall.setEndpointFromState = () => updateConnectionNameUrl(id);
  apiCall.setBodyFromState = setUpdateConnectionNameBody(name, id);
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdateNameConnection(id, name);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateConnectionDisplayAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType, any>();
  apiCall.reqId = UPDATE_CONNECTION;
  apiCall.startedReqType = UPDATE_CONNECTION_STARTED;
  apiCall.successReqType = updateConnection.type;
  apiCall.failureReqType = UPDATE_CONNECTION_FAILURE;
  apiCall.setEndpointFromState = () => updateConnectionNameUrl(id);
  apiCall.setBodyFromState = setUpdateConnectionDisplayedBody(id);
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdateDisplayConnection(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function deleteConnectionAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, { id: string }, any>();
  apiCall.reqId = DELETE_CONNECTION;
  apiCall.startedReqType = DELETE_CONNECTION_STARTED;
  apiCall.successReqType = deleteConnection.type;
  apiCall.failureReqType = DELETE_CONNECTION_FAILURE;
  apiCall.setEndpointFromState = () => updateConnectionNameUrl(id);
  apiCall.method = 'DELETE';
  apiCall.transformResponseDataWithState = transformDeleteConnection(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updateManualConnectionRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, ConnectionType, any>();
  apiCall.reqId = UPDATE_MANUAL_CONNECTION;
  apiCall.setEndpointFromState = () => updateManualConnectionUrl(id);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updateAvailabilityConnectionAction(
  id: string,
  components: UpdateAvConnectionBody[],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType, any>();
  apiCall.reqId = UPDATE_AVAILABILITY_CONNECTION;
  apiCall.startedReqType = UPDATE_AVAILABILITY_CONNECTION_STARTED;
  apiCall.successReqType = updateConnection.type;
  apiCall.failureReqType = UPDATE_AVAILABILITY_CONNECTION_FAILURE;
  apiCall.setEndpointFromState = () => updateAvailabilityConnectionUrl(id);
  apiCall.setBodyFromState = setUpdateAvConnectionBody(components);
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdateAvConnection(id, components);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getVumiCredentials(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, ConnectionType[], any>();
  apiCall.reqId = GET_VUMI_CREDENTIALS;
  apiCall.setEndpointFromState = () => getVumiCredentialsUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetVumiCredentials();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function createManualConnectionAction(
  name: string,
  token: string,
  entity: string,
  provider: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, ConnectionType, any>();
  apiCall.reqId = CREATE_CONNECTION;
  apiCall.startedReqType = CREATE_CONNECTION_STARTED;
  apiCall.successReqType = addConnection.type;
  apiCall.failureReqType = CREATE_CONNECTION_FAILURE;
  apiCall.setEndpointFromState = () => createManualConnectionUrl();
  apiCall.setBodyFromState = setCreateManualConnectionBody(name, token, entity, provider);
  apiCall.method = 'POST';
  apiCall.transformResponseDataWithState = transformCreateConnection(name, entity);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function reimportConnectionRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, ConnectionType, any>();
  apiCall.reqId = REIMPORT_CONNECTION;
  apiCall.setEndpointFromState = () => reimportConnectionUrl(id);
  apiCall.method = 'PUT';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function reimportAllConnectionRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, ConnectionType, any>();
  apiCall.reqId = REIMPORT_CONNECTION;
  apiCall.setEndpointFromState = () => reimportAllConnectionUrl();
  apiCall.method = 'POST';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
