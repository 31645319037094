import _ from 'lodash';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path: string) {
  return path.includes('http');
}

export function getActive(path: string, pathname: string, children?: any) {
  const pathArray = path.split('/');
  const pathNameArray = pathname.split('/');
  if (path) {
    if (pathArray.length > 2 && pathArray.includes('root') && children && children.length > 0) {
      return children.map((i: any) => i.path).includes(pathname);
    }

    return pathArray.length === pathNameArray.length
      ? _.isEqual(pathArray, pathNameArray)
      : _.isEqual(
          pathArray,
          pathNameArray.filter((_, index) => index < pathNameArray.length - 1)
        );
  }
  return false;
}
