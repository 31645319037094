import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import useLocales from 'src/hooks/useLocales';
import { APP_ENV, OAUTH2_GOOGLE_URL } from 'src/config';
import useFeatureToggle from 'src/hooks/useFeatureToggle';
import useGetInfoCompany from 'src/hooks/useGetInfoCompany';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

type LoginFormProps = {
  handleOnSubmit: (
    email: string,
    password: string,
    onFailure: (errorMessage: string) => void
  ) => void;
  isLoading: boolean;
  defaultEmail: string;
  onPressBeta: () => void;
};

export default function LoginForm(props: LoginFormProps) {
  const isMountedRef = useIsMountedRef();
  const { translate } = useLocales();
  const isGoogleLoginEnabled = useFeatureToggle('googleLoginEnabled');

  const [showPassword, setShowPassword] = useState(false);

  const isBusiness = APP_ENV === 'business' || APP_ENV === 'businesstest';
  const theme = useTheme();
  const company = useGetInfoCompany();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('auth.email_has_valid'))
      .required(translate('auth.email_required')),
    password: Yup.string().required(translate('auth.password_required')),
  });

  const defaultValues = {
    email: props.defaultEmail ?? '',
    password: '',
    remember: true,
  };

  const rememberBeLabel: string = translate('auth.remember_me');

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      props.handleOnSubmit(data.email.toLocaleLowerCase(), data.password, (errorMessage: string) =>
        setError('afterSubmit', { message: errorMessage })
      );
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField label={translate('auth.email')} name="email" />

        <RHFTextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={translate('auth.password')}
          name="password"
          type={showPassword ? 'text' : 'password'}
        />
      </Stack>

      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox label={rememberBeLabel} name="remember" />
        <Link
          color={'text.primary'}
          component={RouterLink}
          to={PATH_AUTH.resetPassword}
          variant="subtitle2"
        >
          {translate('auth.forgot_password')}
        </Link>
      </Stack>

      <LoadingButton
        color={isBusiness ? 'inherit' : 'primary'}
        fullWidth
        loading={props.isLoading}
        size="large"
        sx={{
          backgroundColor: !isBusiness
            ? 'primary.main'
            : company.themeCompany
            ? company.themeCompany.primary.main
            : 'text.primary',
          color: 'white',
        }}
        type="submit"
        variant="contained"
      >
        {translate('auth.login')}
      </LoadingButton>

      {isGoogleLoginEnabled && (
        <Button
          autoCapitalize="none"
          disabled={isSubmitting}
          fullWidth
          href={OAUTH2_GOOGLE_URL}
          size="large"
          sx={{ mt: 2, borderColor: 'text.primary' }}
          type="submit"
          variant="outlined"
        >
          <Iconify height={20} icon="logos:google-icon" width={20} />
          <Typography
            align="center"
            autoCapitalize="none"
            sx={{ ml: 2, color: 'text.primary' }}
            variant="subtitle2"
          >
            {translate('auth.login_with_google')}
          </Typography>
        </Button>
      )}
    </FormProvider>
  );
}
