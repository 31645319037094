import { Investment } from './useGetInvestments';

const mockData: Investment[] = [
  {
    id: 'PRIVATE_EQUITY',
    icon: 'fluent:people-money-24-regular',
    color: 'primary',
    title: 'Private Equity',
    description:
      'Inversión en empresas no cotizadas, buscando rentabilidad a través de su compra, desarrollo y venta.',
  },
  {
    id: 'VENTURE_CAPITAL',
    icon: 'streamline:computer-chip-1',
    color: 'bluePurple',
    title: 'Venture Capital',
    description:
      'Subconjunto del Private Equity que financia startups con alto potencial de crecimiento e innovación.',
  },
  {
    id: 'INFRASTRUCTURE',
    icon: 'game-icons:tall-bridge',
    color: 'turquoise',
    title: 'Infrastructure',
    description:
      'Inversión en activos tangibles a largo plazo, como carreteras o puertos, con retornos estables y predecibles.',
  },
  {
    id: 'REIT',
    icon: 'fluent-mdl2:real-estate',
    color: 'info',
    title: 'REIT',

    description:
      'Fideicomisos de inversión en bienes raíces que ofrecen una forma de invertir en propiedades generadoras de ingresos sin poseerlas directamente.',
  },
  {
    id: 'PRIVATE_FUND',
    icon: 'bx:bx-line-chart',
    color: 'pink',
    title: 'Private Fund',
    description:
      'Fondos que invierten en una gama de activos privados, desde deuda hasta capital, buscando diversificar y optimizar rendimientos.',
  },
  {
    id: 'IMPACT',
    icon: 'ic:outline-energy-savings-leaf',
    color: 'success',
    title: 'Impact',
    description:
      ' Busca generar un impacto social o ambiental positivo junto a un retorno financiero.',
  },
  {
    id: 'PRIVATE_DEBT',
    icon: 'majesticons:money-hand-line',
    color: 'redFire',
    title: 'Private Debt',
    description:
      'Préstamos a empresas no cotizadas, con retornos más altos que la deuda pública, pero con mayor riesgo.',
  },
  {
    id: 'SICAV',
    icon: 'mdi:company',
    color: 'sea',
    title: 'SICAV',
    description:
      'Institución de inversión colectiva similar a un fondo de inversión, con diferentes estrategias y niveles de riesgo.',
  },
  {
    id: 'HEDGE_FUND',
    icon: 'lucide:file-line-chart',
    title: 'Hedge Fund',
    color: 'warning',
    description:
      'Fondo de inversión que utiliza estrategias sofisticadas para obtener retornos absolutos, aprovechando ineficiencias del mercado o cubriéndose contra riesgos.',
  },
  {
    id: 'OTHER_ASSETS',
    icon: 'mingcute:car-line',
    color: 'black',
    title: 'Other Assets',
    description:
      'Activos alternativos que no se encuentra en ninguna categoría: Coches, relojes, barcos, cuadros.',
  },
];

export default mockData;
