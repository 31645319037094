import { Stack, Tooltip, TooltipProps, Typography, tooltipClasses, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEqual } from 'lodash';
import { memo } from 'react';

import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const CTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.background.default, 0.9),
  },
}));

// ----------------------------------------------------------------------

interface Props extends TooltipProps {
  title: string;
  link?: string;
}

const BasicTooltip = ({ sx, children, title, link, ...props }: Props) => {
  const { translate } = useLocales();

  const renderTooltip = () => (
    <Stack sx={{ p: 1 }}>
      <Typography color="text.primary" sx={{ fontSize: 12 }} variant="body1">
        {title}
      </Typography>
      {link && (
        <Typography
          color="primary.main"
          fontSize={12}
          onClick={() => window.open(link, '_blank')}
          sx={{
            mt: 0.2,
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
          }}
          variant="subtitle2"
        >
          {translate('wallets.knowMore')}
        </Typography>
      )}
    </Stack>
  );

  return (
    <CTooltip sx={sx} title={title !== '' ? renderTooltip() : ''} {...props}>
      {children}
    </CTooltip>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any) => isEqual(prevProps, nextProps);

export default memo(BasicTooltip, arePropsEqual);
