import { ThemeMode, ThemeStretch } from 'src/redux/slices/SettingsSlice/settingsSlice.d';
import { RootState } from 'src/redux/store';
import { UpdateUserSettingsBodyNetType, UpdateUserWebSettingsBodyNetType } from './NetTypes';

export function setUpdateUserSettingsBody(
  baseCurrency: string
): (state: RootState) => UpdateUserSettingsBodyNetType {
  return (state: RootState) => ({
    baseCurrency,
    preferLanguage: state.auth.user.preferLanguage ?? 'es',
  });
}

export function setUpdateUserLanguageBody(
  language: string
): (state: RootState) => UpdateUserSettingsBodyNetType {
  return (state: RootState) => ({
    baseCurrency: state.auth.user.baseCurrency,
    preferLanguage: language,
  });
}

export function setUpdateUserNeedsTourBody(
  needsTour: boolean
): (state: RootState) => { needsTour: boolean } {
  return (state: RootState) => ({
    needsTour,
  });
}

export function setUpdateUserWebSettingsBody(
  themeMode: ThemeMode,
  themeStretch: ThemeStretch
): (state: RootState) => UpdateUserWebSettingsBodyNetType {
  return (_state: RootState) => ({
    themeMode,
    themeStretch,
  });
}
