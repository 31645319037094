import { orderBy, values } from 'lodash';
// hooks
import useFeatureToggle from 'src/hooks/useFeatureToggle';
import useGetPortfolios from 'src/hooks/useGetPortfolios';
import useGetInfoUser from '../services/useGetUserInfo';
// utils
import {
  myConnectionsPathConfig,
  myWealthLimitedPathConfig,
  reportingPathConfig,
  summaryPathConfig,
  walletsPathConfig,
} from './utils/navConfigPaths';
import { getConnectionDetails, getWalletsByType } from './utils/navConfigUtils';

export default function useGetLimited() {
  const { isBusiness, isClient } = useGetInfoUser();
  const { portfolios } = useGetPortfolios();
  const isReporingEnabled = useFeatureToggle('reportingEnabled');

  const connections = getConnectionDetails(
    getWalletsByType(portfolios ? orderBy(values(portfolios), 'name') : [], 'STOCKS')
  );

  if (isBusiness && !isClient) {
    return [walletsPathConfig(connections), ...(isReporingEnabled ? [reportingPathConfig] : [])];
  } else {
    return [
      summaryPathConfig,
      myWealthLimitedPathConfig,
      ...(isReporingEnabled ? [reportingPathConfig] : []),
      myConnectionsPathConfig,
    ];
  }
}
