export const GET_SECURITIES = 'GET_SECURITIES';
export const GET_SECURITIES_COUNT = 'GET_SECURITIES_COUNT';
export const GET_EARLIEST_DATE = 'GET_EARLIEST_DATE';
export const GET_SECURITY_EOD = 'GET_SECURITY_EOD';

export const UPDATE_LATEST_PRICES = 'UPDATE_LATEST_PRICES';
export const UPDATE_LATEST_PRICES_STARTED = 'UPDATE_LATEST_PRICES_STARTED';
export const UPDATE_LATEST_PRICES_FAILED = 'UPDATE_LATEST_PRICES_FAILED';

export const GET_SUGGESTED_SECURITIES = 'GET_SUGGESTED_SECURITIES';
export const ACCEPT_SUGGESTED_SECURITY = 'ACCEPT_SUGGESTED_SECURITY';
export const DECLINE_SUGGESTED_SECURITY = 'DECLINE_SUGGESTED_SECURITY';

export const GET_SECURITY_INFO = 'GET_SECURITY_INFO';
export const GET_SECURITY_MOVEMENTS = 'GET_SECURITY_MOVEMENTS';
export const ADD_SECURITIES_MORGING_STARS = 'ADD_SECURITIES_MORGING_STARS';
export const MODIFY_UNKNOWN_SECURITY = 'MODIFY_UNKNOWN_SECURITY';
