import { useSubscription } from 'react-stomp-hooks';
import { useSnackbar } from 'notistack';

import { Notification } from 'src/redux/slices/NotificationsSlice/notificationsSlice.d';
import { addNewNotification } from 'src/redux/slices/NotificationsSlice/notificationsSlice';
import { dispatch, store } from 'src/redux/store';
import { singleTransformerNot } from 'src/utils/notifications';
import { log } from 'src/utils/logger';

const TOPICS = [
  '/user/topic/test',
  '/user/topic/subscription',
  '/user/topic/portfolios',
  '/user/topic/connections',
  '/user/topic/reports',
];

const useSubNotifications = () => {
  useSubscription(TOPICS, (message: any) => handleMessages(message));
  const { enqueueSnackbar } = useSnackbar();

  const handleMessages = (message: any) => {
    try {
      const newNotification: Notification = singleTransformerNot(
        JSON.parse(message.body),
        dispatch,
        store.getState()
      );
      dispatch(addNewNotification(newNotification));
      enqueueSnackbar('¡Tienes una notificación nueva!', { variant: 'info' });
    } catch (error) {
      log('Notification parse error', error);
    }
  };
};

export default useSubNotifications;
