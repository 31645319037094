import axios from 'axios';
import { BASE_URL } from 'src/config';
import {
  addPortfolio,
  deleteMovement,
  deletePortfolio,
  editPortfolio,
  getMovementsPortfolio,
  getPortfolios,
  updateAllInfo,
  updateNetWorth,
  updateNetWorthPeriod,
  updatePortfolioEntity,
  updatePortfolioPerformance,
  updatePortfolioRisk,
  updatePortfolioSettings,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice';
import {
  DeleteMovementPayload,
  DividendsByPeriod,
  ImportError,
  PerformanceType,
  Portfolio,
  PortfolioEntity,
  PortfolioMap,
  PortfolioSettingsMap,
  Transaction,
  TransactionsPayload,
  UpdateAllPayload,
  UpdatePortfolioEntity,
  UpdatePortfolioPerformancePayload,
  UpdatePortfolioRiskPayload,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState, store } from 'src/redux/store';
import { getAxiosEmptyRequest, getAxiosEmptyRequestAction } from 'src/services/http/axios';
import {
  setAddManualPortfolioBody,
  setAddPortfolioBody,
  setSendMissingSecuritiesBody,
  setUpdatePortfolioSettingsBody,
  setUpdatePortfolioVisibilitySettingsBody,
} from 'src/services/portfolio/RequestUtils';
import {
  transformAddManualPortfolio,
  transformAddPortfolio,
  transformDeleteMovements,
  transformGetAllDividends,
  transformGetAllInfo,
  transformGetImportErrors,
  transformGetMissingSecurities,
  transformGetMovements,
  transformGetNetWorth,
  transformGetNetWorthPeriod,
  transformGetPorfolioPositions,
  transformGetPorfolios,
  transformGetPortfolioByCredentialSettings,
  transformGetPortfolioDividends,
  transformGetPortfolioSettings,
  transformGetPortfolioSummary,
  transformGetSkippedMovements,
  transformGetSummaryPorfolio,
  transformPerformancePorfolio,
  transformUpdatePortfolioSettings,
  transformUpdatePortfolioVisibility,
  transformRiskPortfolio,
  transformGetAllMovements,
  transformGetPortfolioBonds,
} from 'src/services/portfolio/ResponseUtils';
import { generalCommandsUrl, generalQueriesUrl } from '../EnpointConstants';
import { GetMovementsResponseNetType, UnknownSecurities } from './NetTypes';
import {
  ADD_PORTFOLIO,
  ADD_PORTFOLIO_FAILURE,
  ADD_PORTFOLIO_STARTED,
  DELETE_MOVEMENT,
  DELETE_MOVEMENT_FAILURE,
  DELETE_MOVEMENT_STARTED,
  DELETE_PORTFOLIO,
  DELETE_PORTFOLIO_FAILURE,
  DELETE_PORTFOLIO_STARTED,
  GET_ALL_DIVIDENDS,
  GET_ALL_INFO,
  GET_ALL_INFO_FAILED,
  GET_ALL_INFO_STARTED,
  GET_DIVIDENDS,
  GET_DIVIDENDS_FAILURE,
  GET_DIVIDENDS_STARTED,
  GET_MISSING_SECURITIES,
  GET_MOVEMENTS,
  GET_MOVEMENTS_FAILURE,
  GET_MOVEMENTS_STARTED,
  GET_NET_WORTH,
  GET_NET_WORTH_FAILED,
  GET_NET_WORTH_PERIOD,
  GET_NET_WORTH_PERIOD_FAILED,
  GET_NET_WORTH_PERIOD_STARTED,
  GET_NET_WORTH_STARTED,
  GET_PORTFOLIOS,
  GET_PORTFOLIOS_FAILURE,
  GET_PORTFOLIOS_STARTED,
  GET_PORTFOLIO_IMPORT_ERRORS,
  GET_PORTFOLIO_PERFORMANCE,
  GET_PORTFOLIO_PERFORMANCE_FAILURE,
  GET_PORTFOLIO_PERFORMANCE_STARTED,
  GET_PORTFOLIO_RISK,
  GET_PORTFOLIO_RISK_FAILURE,
  GET_PORTFOLIO_RISK_STARTED,
  GET_PORTFOLIO_SUMMARY,
  GET_PORTFOLIO_SUMMARY_DASH,
  GET_PORTFOLIO_SUMMARY_DASH_FAILURE,
  GET_PORTFOLIO_SUMMARY_DASH_STARTED,
  GET_PORTFOLIO_SUMMARY_FAILED,
  GET_PORTFOLIO_SUMMARY_STARTED,
  GET_POSITIONS,
  GET_POSITIONS_FAILURE,
  GET_POSITIONS_STARTED,
  GET_SETTINGS_PORTFOLIO,
  GET_SETTINGS_PORTFOLIO_FAILURE,
  GET_SETTINGS_PORTFOLIO_STARTED,
  GET_SKIPPED_MOVEMENTS,
  MANUAL_UPDATE_PORTFOLIO,
  SEND_MISSING_SECURITIES,
  UPDATE_PORTFOLIO,
  UPDATE_PORTFOLIO_FAILURE,
  UPDATE_PORTFOLIO_STARTED,
} from './RequestIds';
import { MissingSecurities } from 'src/pages/dashboard/NewConnection/RevisionStep/services/useGetMovementsToAdjust';

// Commands
const generalPortfolioCommandsUrl = generalCommandsUrl + '/portfolios';
const deletePortfolioUrl = (id: string) => generalPortfolioCommandsUrl + `/${id}`;
const updatePortfolioUrl = (id: string) => generalPortfolioCommandsUrl + `/${id}`;
const deleteMovementUrl = (portfolioId: string, id: string) =>
  generalPortfolioCommandsUrl + `/${portfolioId}/transactions/${id}`;
const sendMissingSecuritiesUrl = (id: string) => generalPortfolioCommandsUrl + `/${id}/suggestions`;
const manualUpdatePortfolioUrl = (id: string) =>
  generalPortfolioCommandsUrl + `/${id}/manual-update`;
const updatePortfolioVisibilitySettingsUrl = () =>
  generalPortfolioCommandsUrl + `/settings/visibility`;
const updatePortfolioSettingsUrl = (id: string) => generalPortfolioCommandsUrl + `/${id}/settings`;
const updatePortfolioEntityUrl = (id: string, entityId: string) =>
  generalPortfolioCommandsUrl + `/${id}/entity?entity=${entityId}`;

// Queries
const getPortfoliosUrl = () => generalQueriesUrl + `/portfolios`;
const getSummaryDashUrl = (id: string) => getPortfoliosUrl() + `/${id}`;
const getPerformanceUrl = (
  id: string,
  fullLoad: boolean,
  period: string = '',
  type: PerformanceType = 'MWRR',
  startDate: string = '',
  endDate: string = ''
) =>
  getPortfoliosUrl() +
  `/${id}/performance?period=${period}&from=${startDate}&to=${endDate}&method=${type}&fullLoad=${fullLoad}`;
const getRiskUrl = (
  id: string,
  period: string = '',
  startDate: string = '',
  endDate: string = ''
) => getPortfoliosUrl() + `/${id}/risk?period=${period}&from=${startDate}&to=${endDate}`;
const getPortfolioMovementsUrl = (
  portfolioId: string,
  size: number,
  page: number,
  type: string,
  securityType: string,
  startDate?: string,
  endDate?: string,
  min?: string,
  max?: string,
  security?: string,
  unConfirmed?: boolean,
  download?: boolean,
  entity: string = '',
  portfolioName: string = ''
) =>
  getPortfoliosUrl() +
  `/${portfolioId}/transactions${download ? '/download' : ''}?types=${
    type === 'NOT_CONFIRMED' ? 'ALL' : type
  }&size=${size}&page=${page}
  &startDate=${startDate}&endDate=${endDate}&security=${security}&unConfirmed=${unConfirmed}&minAmount=${min}&maxAmount=${max}&securityType=${
    securityType === 'ALL' ? '' : securityType
  }&entity=${entity}&portfolioName=${portfolioName}`;

const getAllMovementsUrl = (
  size: number,
  page: number,
  type: string,
  securityType: string,
  startDate?: string,
  endDate?: string,
  min?: string,
  max?: string,
  security?: string,
  unConfirmed?: boolean,
  download?: boolean,
  entity: string = '',
  portfolioName: string = ''
) =>
  getPortfoliosUrl() +
  `/all/transactions${download ? '/download' : ''}?types=${
    type === 'NOT_CONFIRMED' ? 'ALL' : type
  }&size=${size}&page=${page}
    &startDate=${startDate}&endDate=${endDate}&security=${security}&unConfirmed=${unConfirmed}&minAmount=${min}&maxAmount=${max}&securityType=${
    securityType === 'ALL' ? '' : securityType
  }&entity=${entity}&portfolioName=${portfolioName}`;

const getSkippedPortfolioMovementsUrl = (
  portfolioId: string,
  page: number,
  name: string,
  size: number = 10,
  download?: boolean,
  entity: string = '',
  portfolioName: string = ''
) =>
  getPortfoliosUrl() +
  `/${portfolioId}/transactions${
    download ? '/download' : ''
  }?skipped=${true}&size=${size}&page=${page}&startDate=${''}&endDate=${''}&unConfirmed=${false}&security=${name}&entity=${entity}&portfolioName=${portfolioName}`;
const gePortfolioDividendsUrl = (id: string, period: string, type: string = 'DIVIDENDS') =>
  getPortfoliosUrl() + `/${id}/income?period=${period}&type=${type}`;
const getPortfolioPositionsIdUrl = (id: string) => getPortfoliosUrl() + `/${id}/positions`;
const getMissingSecuritiesUrl = (id: string) => getPortfoliosUrl() + `/${id}/suggestions`;
const getImportErrorsUrl = (id: string) => getPortfoliosUrl() + `/${id}/errors`;
const getPortfolioSettingsUrl = (credentials: string = '') =>
  getPortfoliosUrl() + `/settings${credentials !== '' ? '?credentials=' + credentials : ''}`;
const getPortfolioSummary = (id: string, period: string = 'ALL') =>
  getPortfoliosUrl() + `/${id}/summary?period=${period}`;
const getNetWorthUrl = () => generalQueriesUrl + `/net_worth`;
const getAllInfoUrl = (type: string, fullLoad: boolean, period: string = '') =>
  getPortfoliosUrl() + `/all?method=${type}&period=${period}&fullLoad=${fullLoad}`;
const getAllDividends = (period: string = '1Y') =>
  getPortfoliosUrl() + `/all/dividends?period=${period}`;
const getNetWorthPeriodUrl = (period: string, isFirstLoad: boolean) =>
  generalQueriesUrl + `/home?period=${period}&isFirstLoad=${isFirstLoad}`;

export function addPortfolioRequestAction(
  name: string,
  delayed: boolean,
  credentialsToken: string,
  flanksEntity: string,
  entityName: string,
  baseCurrency: string,
  clientId?: string,
  liquidityConsidered?: boolean,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = ADD_PORTFOLIO;
  apiCall.startedReqType = ADD_PORTFOLIO_STARTED;
  apiCall.successReqType = addPortfolio.type;
  apiCall.failureReqType = ADD_PORTFOLIO_FAILURE;
  apiCall.setEndpointFromState = () => generalPortfolioCommandsUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setAddPortfolioBody(
    name,
    credentialsToken,
    delayed,
    flanksEntity,
    clientId,
    liquidityConsidered
  );
  apiCall.transformResponseDataWithState = transformAddPortfolio(
    name,
    flanksEntity,
    entityName,
    liquidityConsidered ?? false,
    baseCurrency
  );
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function addManualPortfolioRequestAction(
  name: string,
  baseCurrency: string,
  clientId?: string,
  liquidityConsidered?: boolean,
  referenceIndex?: string[],
  riskFreeAsset?: string,
  entity?: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = ADD_PORTFOLIO;
  apiCall.startedReqType = ADD_PORTFOLIO_STARTED;
  apiCall.successReqType = addPortfolio.type;
  apiCall.failureReqType = ADD_PORTFOLIO_FAILURE;
  apiCall.setEndpointFromState = () => generalPortfolioCommandsUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setAddManualPortfolioBody(
    name,
    clientId,
    liquidityConsidered,
    riskFreeAsset,
    referenceIndex,
    entity
  );
  apiCall.transformResponseDataWithState = transformAddManualPortfolio(
    name,
    baseCurrency,
    liquidityConsidered ?? false,
    entity
  );
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function addPortfolioRequest(
  name: string,
  delayed: boolean,
  credentialsToken: string,
  flanksEntity: string,
  entityName: string,
  baseCurrency: string,
  clientId?: string,
  liquidityConsidered?: boolean,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, Portfolio, any>();
  apiCall.reqId = ADD_PORTFOLIO;
  apiCall.setEndpointFromState = () => generalPortfolioCommandsUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setAddPortfolioBody(
    name,
    credentialsToken,
    delayed,
    flanksEntity,
    clientId
  );
  apiCall.transformResponseDataWithState = transformAddPortfolio(
    name,
    flanksEntity,
    entityName,
    liquidityConsidered ?? false,
    baseCurrency
  );
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function addManualPortfolioRequest(
  name: string,
  baseCurrency: string,
  clientId?: string,
  liquidityConsidered?: boolean,
  referenceIndex?: string[],
  riskFreeAsset?: string,
  entity?: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, Portfolio, any>();
  apiCall.reqId = ADD_PORTFOLIO;
  apiCall.setEndpointFromState = () => generalPortfolioCommandsUrl;
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setAddManualPortfolioBody(
    name,
    clientId,
    liquidityConsidered,
    riskFreeAsset,
    referenceIndex,
    entity
  );
  apiCall.transformResponseDataWithState = transformAddManualPortfolio(
    name,
    baseCurrency,
    liquidityConsidered ?? false,
    entity
  );
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getPortfoliosRequestAction(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, PortfolioMap, any>();
  apiCall.reqId = GET_PORTFOLIOS;
  apiCall.startedReqType = GET_PORTFOLIOS_STARTED;
  apiCall.successReqType = getPortfolios.type;
  apiCall.failureReqType = GET_PORTFOLIOS_FAILURE;
  apiCall.setEndpointFromState = () => getPortfoliosUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPorfolios();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}
export function getSummaryDashRequestAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_PORTFOLIO_SUMMARY_DASH;
  apiCall.startedReqType = GET_PORTFOLIO_SUMMARY_DASH_STARTED;
  apiCall.successReqType = addPortfolio.type;
  apiCall.failureReqType = GET_PORTFOLIO_SUMMARY_DASH_FAILURE;
  apiCall.setEndpointFromState = () => getSummaryDashUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetSummaryPorfolio(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPerformanceRequestAction(
  id: string,
  fullLoad: boolean,
  period: string = '',
  type: PerformanceType = 'MWRR',
  startDate?: string,
  endDate?: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdatePortfolioPerformancePayload, any>();
  apiCall.reqId = GET_PORTFOLIO_PERFORMANCE;
  apiCall.startedReqType = GET_PORTFOLIO_PERFORMANCE_STARTED;
  apiCall.successReqType = updatePortfolioPerformance.type;
  apiCall.failureReqType = GET_PORTFOLIO_PERFORMANCE_FAILURE;
  apiCall.setEndpointFromState = () =>
    getPerformanceUrl(id, fullLoad, period, type, startDate, endDate);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformPerformancePorfolio(id, fullLoad, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getRiskRequestAction(
  id: string,
  period: string = '',
  startDate?: string,
  endDate?: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdatePortfolioRiskPayload, any>();
  apiCall.reqId = GET_PORTFOLIO_RISK;
  apiCall.startedReqType = GET_PORTFOLIO_RISK_STARTED;
  apiCall.successReqType = updatePortfolioRisk.type;
  apiCall.failureReqType = GET_PORTFOLIO_RISK_FAILURE;
  apiCall.setEndpointFromState = () => getRiskUrl(id, period, startDate, endDate);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformRiskPortfolio(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updatePortfolioRequestAction(
  id: string,
  name: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, { name: string }, any>();
  apiCall.reqId = UPDATE_PORTFOLIO;
  apiCall.startedReqType = UPDATE_PORTFOLIO_STARTED;
  apiCall.successReqType = editPortfolio.type;
  apiCall.failureReqType = UPDATE_PORTFOLIO_FAILURE;
  apiCall.setEndpointFromState = () => updatePortfolioUrl(id);
  apiCall.setBodyFromState = () => ({ name });
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = () => ({
    name,
    id,
  });
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function deletePortfolioRequestAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, { id: string }, any>();
  apiCall.reqId = DELETE_PORTFOLIO;
  apiCall.startedReqType = DELETE_PORTFOLIO_STARTED;
  apiCall.successReqType = deletePortfolio.type;
  apiCall.failureReqType = DELETE_PORTFOLIO_FAILURE;
  apiCall.setEndpointFromState = () => deletePortfolioUrl(id);
  apiCall.method = 'DELETE';
  apiCall.transformResponseDataWithState = () => ({
    id,
  });
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function deletePortfolioRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, { id: string }, any>();
  apiCall.reqId = DELETE_PORTFOLIO;
  apiCall.setEndpointFromState = () => deletePortfolioUrl(id);
  apiCall.method = 'DELETE';
  apiCall.transformResponseDataWithState = () => ({
    id,
  });
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getMovementsRequest(
  portfolioId: string,
  payload: TransactionsPayload,
  isFirstTime: boolean = false,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_MOVEMENTS;
  apiCall.startedReqType = GET_MOVEMENTS_STARTED;
  apiCall.successReqType = getMovementsPortfolio.type;
  apiCall.failureReqType = GET_MOVEMENTS_FAILURE;
  apiCall.setEndpointFromState = () =>
    getPortfolioMovementsUrl(
      portfolioId,
      payload.size,
      payload.page,
      payload.type ?? 'ALL',
      payload.actionType ?? 'ALL',
      payload.startDate ?? '',
      payload.endDate ?? '',
      payload.min ?? '',
      payload.max ?? '',
      payload.name ?? '',
      payload.type === 'NOT_CONFIRMED'
    );
  const isCustom: boolean =
    !!payload.startDate ||
    !!payload.endDate ||
    payload.name !== '' ||
    payload.min !== '' ||
    payload.max !== '';

  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetMovements(
    portfolioId,
    isFirstTime,
    isCustom
  );
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getAllMovementsRequest(
  payload: TransactionsPayload,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, GetMovementsResponseNetType, any>();
  apiCall.reqId = GET_MOVEMENTS;
  apiCall.setEndpointFromState = () =>
    getAllMovementsUrl(
      payload.size,
      payload.page,
      payload.type ?? 'ALL',
      payload.actionType ?? 'ALL',
      payload.startDate ?? '',
      payload.endDate ?? '',
      payload.min ?? '',
      payload.max ?? '',
      payload.name ?? '',
      payload.type === 'NOT_CONFIRMED'
    );
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetAllMovements();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getDownloadMovementsRequestX(
  portfolioId: string,
  entity: string,
  portfolioName: string,
  payload: TransactionsPayload,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const { token } = store.getState().auth;
  axios({
    method: 'get',
    url:
      BASE_URL +
      getPortfolioMovementsUrl(
        portfolioId,
        payload.size,
        payload.page,
        payload.type ?? 'ALL',
        payload.actionType ?? 'ALL',
        payload.startDate ?? '',
        payload.endDate ?? '',
        payload.min ?? '',
        payload.max ?? '',
        payload.name ?? '',
        payload.type === 'NOT_CONFIRMED',
        true,
        entity,
        portfolioName
      ),
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + token ?? '',
    },
  })
    .then(onSuccess)
    .catch(onFailure);
}

export function deleteMovementRequestAction(
  portfolioId: string,
  transactionId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, DeleteMovementPayload, any>();
  apiCall.reqId = DELETE_MOVEMENT;
  apiCall.startedReqType = DELETE_MOVEMENT_STARTED;
  apiCall.successReqType = deleteMovement.type;
  apiCall.failureReqType = DELETE_MOVEMENT_FAILURE;
  apiCall.setEndpointFromState = () => deleteMovementUrl(portfolioId, transactionId);
  apiCall.method = 'DELETE';
  apiCall.transformResponseDataWithState = transformDeleteMovements(portfolioId, transactionId);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function deleteMovementRequest(
  portfolioId: string,
  transactionId: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, DeleteMovementPayload, any>();
  apiCall.reqId = DELETE_MOVEMENT;
  apiCall.setEndpointFromState = () => deleteMovementUrl(portfolioId, transactionId);
  apiCall.method = 'DELETE';
  apiCall.transformResponseDataWithState = transformDeleteMovements(portfolioId, transactionId);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getPortfolioDividendsRequest(
  id: string,
  period: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_DIVIDENDS;
  apiCall.startedReqType = GET_DIVIDENDS_STARTED;
  apiCall.successReqType = addPortfolio.type;
  apiCall.failureReqType = GET_DIVIDENDS_FAILURE;
  apiCall.setEndpointFromState = () => gePortfolioDividendsUrl(id, period, 'DIVIDENDS');
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPortfolioDividends(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfolioBondsRequest(
  id: string,
  period: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_DIVIDENDS;
  apiCall.startedReqType = GET_DIVIDENDS_STARTED;
  apiCall.successReqType = addPortfolio.type;
  apiCall.failureReqType = GET_DIVIDENDS_FAILURE;
  apiCall.setEndpointFromState = () => gePortfolioDividendsUrl(id, period, 'COUPONS');
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPortfolioBonds(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfolioPositionsRequestAction(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_POSITIONS;
  apiCall.startedReqType = GET_POSITIONS_STARTED;
  apiCall.successReqType = addPortfolio.type;
  apiCall.failureReqType = GET_POSITIONS_FAILURE;
  apiCall.setEndpointFromState = () => getPortfolioPositionsIdUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPorfolioPositions(id);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getAllPositionsRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_POSITIONS;
  apiCall.setEndpointFromState = () => getPortfolioPositionsIdUrl('all');
  apiCall.method = 'GET';
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getMissingSecuritiesRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UnknownSecurities[], any>();
  apiCall.reqId = GET_MISSING_SECURITIES;
  apiCall.setEndpointFromState = () => getMissingSecuritiesUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetMissingSecurities();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function sendMissingSecuritiesRequest(
  id: string,
  securities: MissingSecurities[],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = SEND_MISSING_SECURITIES;
  apiCall.setEndpointFromState = () => sendMissingSecuritiesUrl(id);
  apiCall.method = 'POST';
  apiCall.setBodyFromState = setSendMissingSecuritiesBody(securities);
  apiCall.transformResponseDataWithState = () => {};
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function manualUpdatePortfolioRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = MANUAL_UPDATE_PORTFOLIO;
  apiCall.setEndpointFromState = () => manualUpdatePortfolioUrl(id);
  apiCall.method = 'POST';
  apiCall.transformResponseDataWithState = () => {};
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updatePortfolioVisibilityRequestAction(
  portfolioSettings: PortfolioSettingsMap,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, PortfolioMap, any>();
  apiCall.reqId = UPDATE_PORTFOLIO;
  apiCall.startedReqType = UPDATE_PORTFOLIO_STARTED;
  apiCall.successReqType = updatePortfolioSettings.type;
  apiCall.failureReqType = UPDATE_PORTFOLIO_FAILURE;
  apiCall.setEndpointFromState = () => updatePortfolioVisibilitySettingsUrl();
  apiCall.setBodyFromState = setUpdatePortfolioVisibilitySettingsBody(portfolioSettings);
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdatePortfolioVisibility(portfolioSettings);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfolioSettingsRequest(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, PortfolioMap, any>();
  apiCall.reqId = GET_SETTINGS_PORTFOLIO;
  apiCall.startedReqType = GET_SETTINGS_PORTFOLIO_STARTED;
  apiCall.successReqType = updatePortfolioSettings.type;
  apiCall.failureReqType = GET_SETTINGS_PORTFOLIO_FAILURE;
  apiCall.setEndpointFromState = () => getPortfolioSettingsUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPortfolioSettings();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfolioSettingsByConnectionRequest(
  credentials: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, PortfolioSettingsMap, any>();
  apiCall.reqId = GET_SETTINGS_PORTFOLIO;
  apiCall.setEndpointFromState = () => getPortfolioSettingsUrl(credentials);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPortfolioByCredentialSettings();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getPortfolioSummaryRequestAction(
  id: string,
  period: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, Portfolio, any>();
  apiCall.reqId = GET_PORTFOLIO_SUMMARY;
  apiCall.startedReqType = GET_PORTFOLIO_SUMMARY_STARTED;
  apiCall.successReqType = addPortfolio.type;
  apiCall.failureReqType = GET_PORTFOLIO_SUMMARY_FAILED;
  apiCall.setEndpointFromState = () => getPortfolioSummary(id, period);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetPortfolioSummary(id, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function updatePortfolioSettingsRequest(
  id: string,
  performanceType: PerformanceType,
  riskFreeAsset: string,
  indicesIds: string[],
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, PortfolioMap, any>();
  apiCall.reqId = UPDATE_PORTFOLIO;
  apiCall.setEndpointFromState = () => updatePortfolioSettingsUrl(id);
  apiCall.setBodyFromState = setUpdatePortfolioSettingsBody(
    performanceType,
    indicesIds,
    riskFreeAsset
  );
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = transformUpdatePortfolioSettings(id, performanceType);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function updatePortfolioEntityRequestAction(
  id: string,
  entity: PortfolioEntity,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdatePortfolioEntity, any>();
  apiCall.reqId = UPDATE_PORTFOLIO;
  apiCall.startedReqType = UPDATE_PORTFOLIO_STARTED;
  apiCall.successReqType = updatePortfolioEntity.type;
  apiCall.failureReqType = UPDATE_PORTFOLIO_FAILURE;
  apiCall.setEndpointFromState = () => updatePortfolioEntityUrl(id, entity.id);
  apiCall.method = 'PUT';
  apiCall.transformResponseDataWithState = () => ({
    entity,
    id,
  });
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getPortfolioImportErrorsRequest(
  id: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<RootState, ImportError[], any>();
  apiCall.reqId = GET_PORTFOLIO_IMPORT_ERRORS;
  apiCall.setEndpointFromState = () => getImportErrorsUrl(id);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetImportErrors();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getSkippedMovementsRequest(
  portfolioId: string,
  page: number,
  name: string,
  size: number,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequest<
    RootState,
    { transactions: Transaction[]; totalItems: number },
    any
  >();
  apiCall.reqId = GET_SKIPPED_MOVEMENTS;
  apiCall.setEndpointFromState = () =>
    getSkippedPortfolioMovementsUrl(portfolioId, page, name, size);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetSkippedMovements();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}

export function getDownloadSkippedMovementsRequest(
  portfolioId: string,
  name: string,
  size: number,
  entity: string,
  portfolioName: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const { token } = store.getState().auth;
  axios({
    method: 'get',
    url:
      BASE_URL +
      getSkippedPortfolioMovementsUrl(portfolioId, 0, name, size, true, entity, portfolioName),
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + token ?? '',
    },
  })
    .then(onSuccess)
    .catch(onFailure);
}

export function getNetWorthRequestAction(
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = GET_NET_WORTH;
  apiCall.startedReqType = GET_NET_WORTH_STARTED;
  apiCall.successReqType = updateNetWorth.type;
  apiCall.failureReqType = GET_NET_WORTH_FAILED;
  apiCall.setEndpointFromState = () => getNetWorthUrl();
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetNetWorth();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getNetWorthPeriodRequestAction(
  fullLoad: boolean,
  period: string = '',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, any, any>();
  apiCall.reqId = GET_NET_WORTH_PERIOD;
  apiCall.startedReqType = GET_NET_WORTH_PERIOD_STARTED;
  apiCall.successReqType = updateNetWorthPeriod.type;
  apiCall.failureReqType = GET_NET_WORTH_PERIOD_FAILED;
  apiCall.setEndpointFromState = () => getNetWorthPeriodUrl(period, fullLoad);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetNetWorthPeriod(fullLoad, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getAllInfoRequestAction(
  fullLoad: boolean,
  period: string,
  type: PerformanceType = 'TWRR',
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, UpdateAllPayload, any>();
  apiCall.reqId = GET_ALL_INFO;
  apiCall.startedReqType = GET_ALL_INFO_STARTED;
  apiCall.successReqType = updateAllInfo.type;
  apiCall.failureReqType = GET_ALL_INFO_FAILED;
  apiCall.setEndpointFromState = () => getAllInfoUrl(type, fullLoad, period);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetAllInfo(fullLoad, period);
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall.getAction();
}

export function getAllDividendsRequest(
  period: string,
  onSuccess: (response: any) => void = () => undefined,
  onFailure: (error: any) => void = () => undefined
) {
  const apiCall = getAxiosEmptyRequestAction<RootState, DividendsByPeriod, any>();
  apiCall.reqId = GET_ALL_DIVIDENDS;
  apiCall.setEndpointFromState = () => getAllDividends(period);
  apiCall.method = 'GET';
  apiCall.transformResponseDataWithState = transformGetAllDividends();
  apiCall.onSuccess = onSuccess;
  apiCall.onFailure = onFailure;
  return apiCall;
}
