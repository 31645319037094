// hooks
import { useSelector } from 'react-redux';
// utils
import get from 'lodash/get';
// types
import { RootState } from 'src/redux/store';

export default function useGetPortfolios() {
  const portfolios = useSelector((state: RootState) => get(state, ['portfolio', 'portfolios']));

  return {
    portfolios,
  };
}
