import { IconButton, Stack, Typography } from '@mui/material';

// components
import Iconify from 'src/components/Iconify';
import MyAvatar from 'src/components/MyAvatar';

// ----------------------------------------------------------------------

type Props = {
  name: string;
  firstSurname: string;
  secondSurname: string;
  email: string;
  imageUrl?: string;
  onRemoveClient: VoidFunction;
};

export default function SelectedClientBar({
  name,
  email,
  imageUrl,
  onRemoveClient,
  firstSurname,
  secondSurname,
}: Props) {
  return (
    <Stack alignItems={'center'} flexDirection={'row'}>
      <MyAvatar sx={{ mr: 2 }} user={{ name, imageUrl }} />
      <Stack>
        <Typography color={'text.primary'} variant="subtitle2">
          {`${name} ${firstSurname ?? ''} ${secondSurname ?? ''}`}
        </Typography>
        <Typography color="text.secondary" fontSize={12} variant="subtitle2">
          {email}
        </Typography>
      </Stack>
      <IconButton onClick={onRemoveClient} sx={{ ml: 3 }}>
        <Iconify icon="eva:close-fill" />
      </IconButton>
    </Stack>
  );
}
