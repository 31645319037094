/* eslint-disable */
import { NetClientConfigWithID, ServiceClientInterface } from 'src/services/http/base/CommonTypes.d';
import {
  InterceptorResponseErrorFunction,
  InterceptorResponseErrorInputFunction,
  InterceptorResponseSuccessFunction,
  InterceptorResponseSuccessInputFunction,
  ResponseInterceptorFunctionType,
  ResponseInterceptorListType,
} from 'src/services/http/base/ResponseInterceptorUtils.d';

function createResponseInterceptorSuccessFunction<
  StateType,
  ConfigType extends NetClientConfigWithID<ResponseType, ErrorType>,
  ResponseType,
  ErrorType
>(
  method: InterceptorResponseSuccessInputFunction<StateType, ConfigType, ResponseType, ErrorType> | undefined,
  serviceClient: ServiceClientInterface<StateType, ConfigType, ResponseType, ErrorType>
): InterceptorResponseSuccessFunction<ResponseType> {
  return (response: ResponseType) => {
    if (method) {
      return method(response, serviceClient);
    }
    return response;
  };
}

function createResponseInterceptorErrorFunction<
  StateType,
  ConfigType extends NetClientConfigWithID<ResponseType, ErrorType>,
  ResponseType,
  ErrorType
>(
  method: InterceptorResponseErrorInputFunction<StateType, ConfigType, ResponseType, ErrorType> | undefined,
  serviceClient: ServiceClientInterface<StateType, ConfigType, ResponseType, ErrorType>
): InterceptorResponseErrorFunction<ResponseType, ErrorType> {
  return (error: ErrorType) => {
    if (method) {
      return method(error, serviceClient);
    }
    throw error;
  };
}

function createResponseInterceptor<
  StateType,
  ConfigType extends NetClientConfigWithID<ResponseType, ErrorType>,
  ResponseType,
  ErrorType
>(
  onSuccess?: InterceptorResponseSuccessInputFunction<StateType, ConfigType, ResponseType, ErrorType>,
  onFailure?: InterceptorResponseErrorInputFunction<StateType, ConfigType, ResponseType, ErrorType>
): ResponseInterceptorFunctionType<StateType, ConfigType, ResponseType, ErrorType> {
  return (serviceClient: ServiceClientInterface<StateType, ConfigType, ResponseType, ErrorType>) => ({
    success: createResponseInterceptorSuccessFunction(onSuccess, serviceClient),
    error: createResponseInterceptorErrorFunction(onFailure, serviceClient),
  });
}

export default class ResponseInterceptorList<
  StateType,
  ConfigType extends NetClientConfigWithID<ResponseType, ErrorType>,
  ResponseType,
  ErrorType
> {
  private list: ResponseInterceptorFunctionType<StateType, ConfigType, ResponseType, ErrorType>[] = [];

  // eslint-disable-next-line class-methods-use-this
  private createResponseInterceptorList(
    interceptors: ResponseInterceptorFunctionType<StateType, ConfigType, ResponseType, ErrorType>[]
  ): ResponseInterceptorListType<StateType, ConfigType, ResponseType, ErrorType> {
    return (serviceClient: ServiceClientInterface<StateType, ConfigType, ResponseType, ErrorType>) =>
      interceptors.map((item) => item(serviceClient));
  }

  addInterceptor(
    onSuccess?: InterceptorResponseSuccessInputFunction<StateType, ConfigType, ResponseType, ErrorType>,
    onFailure?: InterceptorResponseErrorInputFunction<StateType, ConfigType, ResponseType, ErrorType>
  ): void {
    this.list.push(createResponseInterceptor(onSuccess, onFailure));
  }

  getList(): ResponseInterceptorListType<StateType, ConfigType, ResponseType, ErrorType> {
    return this.createResponseInterceptorList(this.list);
  }
}
