// hooks
import { useSelector } from 'react-redux';
// utils
import get from 'lodash/get';
// types
import { RootState } from 'src/redux/store';

export default function useGetClientInfo() {
  const clientInfo: any = useSelector((state: RootState) => get(state, ['client'], {}));
  return {
    ...clientInfo,
  };
}
