// hooks
import { useSelector } from 'react-redux';
// utils
import get from 'lodash/get';
// redux types
import { RootState } from 'src/redux/store';

export default function useGetInfoCompany() {
  const company = useSelector((state: RootState) => get(state, ['business', 'company']));

  return {
    ...company,
  };
}
