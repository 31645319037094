import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  pending: boolean;
};

export default function ConnectionItemDescription({ pending }: Props) {
  return (
    <Typography
      alignItems={'center'}
      color={'text.disabled'}
      display={'flex'}
      mt={0.5}
      variant="caption"
    >
      {!pending
        ? 'Pulsa en ella para empezar a configurarla.'
        : 'Este proceso puede tardar unos minutos. Pincha aquí para saber más.'}
    </Typography>
  );
}
