import * as Yup from 'yup';

function getMaxDate() {
  return new Date();
}

export const FullSchema = (hasEx?: boolean, isSplit?: boolean) =>
  Yup.object().shape({
    wallet: Yup.string(),
    earliestAvailableData: Yup.date().nullable(),
    date: Yup.lazy(() =>
      Yup.date()
        .nullable()
        .typeError('La fecha debe tener el formato dd/mm/yyyy')
        .test('earliest_date_test', function (value) {
          const { earliestAvailableData } = this.parent;

          if (!earliestAvailableData || (!!value && value >= earliestAvailableData)) {
            return true;
          } else {
            return this.createError({
              message: `No existen datos para la acción seleccionada en esta fecha. Disponibles a partir del ${(
                earliestAvailableData as Date
              ).toLocaleDateString()}`,
            });
          }
        })
        .required('Introduce la fecha del movimiento')
        .max(getMaxDate(), 'La fecha no puede ser posterior al día actual')
    ),
    security: Yup.string().required('Debes seleccionar un activo'),
    localCurrency: Yup.string(),
    quantity: Yup.number()
      .required('Añade la cantidad')
      .positive('El valor no pueder ser negativo')
      .typeError(''),
    price: isSplit
      ? Yup.number()
          .required('Añade el precio')
          .moreThan(-1, 'El valor no pueder ser negativo')
          .typeError('')
      : Yup.number()
          .required('Añade el precio')
          .positive('El valor no pueder ser negativo')
          .typeError(''),
    exchangeRate: hasEx
      ? Yup.number()
          .positive('El valor no pueder ser negativo')
          .required('Introduce el tipo de cambio')
          .typeError('')
      : Yup.number().positive('El valor no pueder ser negativo').typeError(''),
    currencyPair: Yup.string(),
    feeCurrency: Yup.string(),
    fee: Yup.number()
      .required('Añade la comisión')
      .moreThan(-1, 'El valor no pueder ser negativo')
      .typeError(''),
    notes: Yup.string().max(100),
    type: Yup.string(),
  });

export const DepositSchema = Yup.object().shape({
  wallet: Yup.string(),
  date: Yup.lazy(() =>
    Yup.date()
      .nullable()
      .typeError('La fecha debe tener el formato dd/mm/yyyy')
      .required('Introduce la fecha del movimiento')
      .max(getMaxDate(), 'La fecha no puede ser posterior al día actual')
  ),
  price: Yup.number()
    .required('Añade el precio')
    .positive('El valor no pueder ser negativo')
    .typeError(''),
  fee: Yup.number()
    .required('Añade la comisión')
    .moreThan(-1, 'El valor no pueder ser negativo')
    .typeError(''),
  notes: Yup.string().max(100),
  type: Yup.string(),
});

export const FeesSchema = Yup.object().shape({
  wallet: Yup.string(),
  date: Yup.lazy(() =>
    Yup.date()
      .nullable()
      .typeError('La fecha debe tener el formato dd/mm/yyyy')
      .required('Introduce la fecha del movimiento')
      .max(getMaxDate(), 'La fecha no puede ser posterior al día actual')
  ),
  fee: Yup.number()
    .required('Añade la comisión')
    .moreThan(-1, 'El valor no pueder ser negativo')
    .typeError(''),
  notes: Yup.string().max(100),
  type: Yup.string(),
});
