/* eslint-disable no-console */
import type { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ServiceClient from 'src/services/http/base/ServiceClient';
import type {
  NetClientConstructor,
  NetClientConfigWithID,
} from 'src/services/http/base/CommonTypes';
import adaptRequestFromReduxAction from 'src/services/http/redux/Redux.Utils';
import {
  ReduxActionInterface,
  ReduxCallObjectInterfaceLiteral,
} from 'src/services/http/redux/ReduxRequestAction.d';
import { RequestInterceptorListType } from 'src/services/http/base/RequestInterceptorUtils.d';
import { ResponseInterceptorListType } from 'src/services/http/base/ResponseInterceptorUtils.d';
import { log } from 'src/utils/logger';

export function getServiceClientMiddleware<
  StateType,
  ConfigType extends NetClientConfigWithID<ResponseType, ErrorType>,
  ResponseType,
  ErrorType
>(
  netClientCtor: NetClientConstructor<ConfigType, ResponseType, ErrorType>,
  baseUrl: string,
  baseHeaders: { [key: string]: string },
  debugPrint: boolean,
  timeoutMillis: number,
  requestInterceptorList?: (
    getState: () => StateType,
    next: Dispatch
  ) => RequestInterceptorListType<StateType, ConfigType, ResponseType, ErrorType>,
  responseInterceptorList?: (
    getState: () => StateType,
    next: Dispatch
  ) => ResponseInterceptorListType<StateType, ConfigType, ResponseType, ErrorType>
  // eslint-disable-next-line @typescript-eslint/ban-types
): Middleware<{}, StateType> {
  return (api: MiddlewareAPI<Dispatch, StateType>) => (next: Dispatch) => (action: AnyAction) => {
    if (!action) return;

    if (action.type !== ReduxCallObjectInterfaceLiteral) {
      next(action);
      return;
    }

    const middleware = new ServiceClient(
      netClientCtor,
      baseUrl,
      api.getState,
      baseHeaders,
      requestInterceptorList ? requestInterceptorList(api.getState, next) : undefined,
      responseInterceptorList ? responseInterceptorList(api.getState, next) : undefined,
      debugPrint,
      timeoutMillis
    );
    middleware.executeRequest(
      adaptRequestFromReduxAction<StateType, ResponseType, ErrorType, any, any>(
        api.getState,
        next,
        <ReduxActionInterface<StateType, ResponseType, ErrorType, any, any>>action
      )
    );
  };
}

export const loggerMiddleware: Middleware =
  <S>(api: MiddlewareAPI<Dispatch, S>) =>
  (next: Dispatch) =>
  <A extends Action>(action: A): A => {
    log('Before');
    const result = next(action);
    log(api.getState()); // Can use: api.getState()
    log('After'); // Can use: api.getState()
    return result;
  };
