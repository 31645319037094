import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CountryType } from './dataSlice.d';

type DataState = {
  countries: CountryType[];
};

export const initialState: DataState = {
  countries: [],
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    getCountries: (state, action: PayloadAction<CountryType[]>) =>
      (state = {
        ...state,
        countries: action.payload,
      }),
  },
});

const persistDataConfig = {
  key: 'data',
  storage,
  whitelist: ['countries'],
};

const persistDataReducer = persistReducer<DataState>(persistDataConfig, dataSlice.reducer);

export const { getCountries } = dataSlice.actions;

export default persistDataReducer;
