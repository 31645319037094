import { AxiosResponse } from 'axios';
import moment from 'moment';

import {
  ConnectionType,
  GetAccountsPayload,
} from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState } from 'src/redux/store';
import {
  GetConnectionDetailType,
  GetConnectionsNetworthType,
  GetConnectionsType,
  GetConnectionsVumiType,
  UpdateAvConnectionBody,
} from './NetTypes';

export function transformGetConnections(): (
  response: AxiosResponse<GetConnectionsType>
) => ConnectionType[] {
  return (response: AxiosResponse<GetConnectionsType>) => response.data.credentials;
}

export function transformGetConnectionsNetworth(): (
  response: AxiosResponse<GetConnectionsNetworthType>,
  state: RootState
) => ConnectionType[] {
  return ({ data }: AxiosResponse<GetConnectionsNetworthType>, state: RootState) => {
    let newConnections = [...state.portfolio.connections];
    data.netWorths.forEach((i) => {
      const index = newConnections.findIndex((k) => k.id === i.connectionDetails.id);
      if (index !== -1) {
        newConnections[index] = {
          ...newConnections[index],
          cashAccounts: i.cashAccounts,
          investments: i.investments,
          investmentsCash: i.investmentsCash,
        };
      }
    });
    return newConnections;
  };
}

export function transformCreateConnection(
  name: string,
  entity: string
): (response: AxiosResponse<{ message: string }>) => ConnectionType {
  return (response: AxiosResponse<{ message: string }>) => ({
    id: response.data.message,
    name,
    entity,
    displayed: false,
    pending: true,
    updating: false,
    lastUpdate: '',
    resetToken: '',
    blocked: false,
    errored: false,
    accounts: [],
    owner: '',
  });
}

export function transformGetConnectionDetails(
  id: string
): (response: AxiosResponse<GetConnectionDetailType>) => GetAccountsPayload {
  return (response: AxiosResponse<GetConnectionDetailType>) => ({
    id,
    accounts: response.data.components,
  });
}

export function transformUpdateNameConnection(
  id: string,
  name: string
): (response: AxiosResponse<any>, state: RootState) => ConnectionType {
  return (_response: AxiosResponse<any>, state: RootState) => ({
    ...state.portfolio.connections[state.portfolio.connections.findIndex((i) => i.id === id)],
    name,
  });
}

export function transformUpdateDisplayConnection(
  id: string
): (response: AxiosResponse<any>, state: RootState) => ConnectionType {
  return (_response: AxiosResponse<any>, state: RootState) => ({
    ...state.portfolio.connections[state.portfolio.connections.findIndex((i) => i.id === id)],
    displayed: true,
  });
}

export function transformDeleteConnection(
  id: string
): (response: AxiosResponse<any>, state: RootState) => { id: string } {
  return (_response: AxiosResponse<any>, state: RootState) => ({
    id,
  });
}
export function transformUpdateAvConnection(
  id: string,
  components: UpdateAvConnectionBody[]
): (response: AxiosResponse<any>, state: RootState) => ConnectionType {
  return (_response: AxiosResponse<any>, state: RootState) => {
    const index = state.portfolio.connections.findIndex((i) => i.id === id);
    let newAccounts = [...state.portfolio.connections[index].accounts];
    components.forEach((c) => {
      const ind = newAccounts.findIndex((i) => i.id === c.id);
      newAccounts[ind] = { ...newAccounts[ind], active: !c.inactive };
    });
    return {
      ...state.portfolio.connections[index],
      accounts: newAccounts,
      latestAvailabilityChange: moment().toDate().toString(),
    };
  };
}

export function transformGetVumiCredentials(): (
  response: AxiosResponse<GetConnectionsVumiType>,
  state: RootState
) => ConnectionType[] {
  return (response: AxiosResponse<GetConnectionsVumiType>, state: RootState) =>
    response.data.credentials;
}
