import { useState, useMemo, useCallback, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { debounce } from 'lodash';
import {
  Link,
  Box,
  Typography,
  Autocomplete,
  InputAdornment,
  Stack,
  TextField,
  SxProps,
  useTheme,
  Button,
} from '@mui/material';

import SearchNotFound from 'src/components/SearchNotFound';
import Iconify from 'src/components/Iconify';
import { getIndicesRequest } from 'src/services/indices';
import { getNetworkClient } from 'src/services/config';
import useLocales from 'src/hooks/useLocales';
import { StockIndexDto } from 'src/services/indices/NetTypes';
import Label from './Label';

const debouncedOnSearchMs = 0;

// ----------------------------------------------------------------------

type Props = {
  onSelectIndice: (security: StockIndexDto) => void;
  sx?: SxProps;
};

const defaultIndice: StockIndexDto = {
  name: '',
  currency: '',
  id: '',
  components: [],
};

export default function IndicesSearch({ onSelectIndice, sx }: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [generalIndices, setGeneralIndices] = useState<StockIndexDto[]>([]);
  const [customIndices, setCustomIndices] = useState<StockIndexDto[]>([]);
  const [searchResults, setSearchResults] = useState<StockIndexDto[]>([]);
  const [indice, setIndice] = useState<StockIndexDto>(defaultIndice);
  const [isCustomFilter, setIsCustomFilter] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const { translate } = useLocales();
  const theme = useTheme();

  useEffect(() => {
    getNetworkClient().executeRequest(
      getIndicesRequest((response) => {
        setGeneralIndices(response.generalIndices);
        setCustomIndices(response.customIndices);
      })
    );
  }, []);

  const onSearch = useCallback(
    (term: string) => {
      setSearchQuery(term);
      if (term) {
        setSearchResults(
          isCustomFilter
            ? [...customIndices]
            : [...generalIndices, ...customIndices].filter((i) =>
                i.name.toLowerCase().includes(term.toLowerCase())
              )
        );
        setLoading(false);
      } else {
        setSearchResults(
          isCustomFilter ? [...customIndices] : [...generalIndices, ...customIndices]
        );
        setLoading(false);
      }
    },
    [generalIndices, customIndices, isCustomFilter]
  );

  const debouncedOnSearch = useMemo(
    () => debounce((term: string) => onSearch?.(term), debouncedOnSearchMs),
    [onSearch]
  );

  useEffect(() => {
    debouncedOnSearch(searchQuery);
  }, [debouncedOnSearch, searchQuery]);

  const renderHeaderList = (isEmpty?: boolean) => (
    <Stack
      alignItems="center"
      flexDirection="row"
      sx={{
        position: isEmpty ? 'relative' : 'absolute',
        backgroundColor: 'background.paper',
        width: '99%',
        pt: isEmpty ? 0 : 1,
        pb: isEmpty ? 2 : 0.5,
        borderTopRightRadius: 5,
      }}
    >
      <Button
        color="inherit"
        onClick={() => setIsCustomFilter(false)}
        size="small"
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, !isCustomFilter ? 0.16 : 0),
        }}
      >
        <Typography
          color={!isCustomFilter ? 'primary.light' : 'text.primary'}
          fontSize={12}
          variant="subtitle1"
        >
          {'Todos'}
        </Typography>
      </Button>
      <Button
        onClick={() => setIsCustomFilter(true)}
        size="small"
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, isCustomFilter ? 0.16 : 0),
          ml: 1,
        }}
      >
        <Typography
          color={isCustomFilter ? 'primary.light' : 'text.primary'}
          fontSize={12}
          variant="subtitle1"
        >
          {'Personalizados'}
        </Typography>
      </Button>
    </Stack>
  );

  return (
    <Autocomplete
      autoHighlight
      filterOptions={(options) => options}
      fullWidth
      getOptionLabel={(indice: StockIndexDto) => indice.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      noOptionsText={
        <>
          {renderHeaderList(true)}
          <SearchNotFound searchQuery={searchQuery} />
        </>
      }
      onChange={(_e, v) => {
        if (v === null) {
          onSelectIndice({
            name: '',
            currency: '',
            id: '',
            components: [],
          });
          return;
        }
        onSelectIndice({ ...v });
        setIndice(defaultIndice);
      }}
      onInputChange={(_, value) => {
        setLoading(true);
        setSearchResults([]);
        setSearchQuery(value);
      }}
      options={searchResults}
      popupIcon={null}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
          label={'Encuentra el índice'}
          placeholder={'Buscar índice de referencia'}
        />
      )}
      renderOption={(props, indice) => {
        const { id, name, currency, symbol } = indice;
        const isFirst = searchResults[0].id === id;
        return (
          <Stack key={id}>
            {isFirst && renderHeaderList()}
            <li {...props} key={id} style={{ minWidth: '100%', marginTop: isFirst ? 40 : 0 }}>
              <Link key={id} minWidth="100%" underline="none">
                <Box minWidth="100%" sx={{ flexGrow: 1 }}>
                  <Typography color={'text.primary'} component="span" variant="subtitle2">
                    {name}
                  </Typography>
                  <Stack
                    alignSelf="stretch"
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    minWidth="100%"
                    sx={{ mt: 1 }}
                  >
                    <Box>
                      {!!!currency && indice.components.length > 0 ? (
                        <Label color={'primary'}>{'Personalizado'}</Label>
                      ) : (
                        <Stack alignItems="center" flexDirection="row">
                          <Typography color="primary.main" component="span" variant="body2">
                            {symbol}
                          </Typography>
                          <Stack
                            sx={{
                              height: 3,
                              width: 3,
                              backgroundColor: 'text.primary',
                              borderRadius: 2,
                              mx: 1,
                            }}
                          />
                          <Typography color="text.secondary" component="span" variant="body2">
                            {currency}
                          </Typography>
                        </Stack>
                      )}
                    </Box>
                  </Stack>
                </Box>
              </Link>
            </li>
          </Stack>
        );
      }}
      size="medium"
      sx={sx}
    />
  );
}
