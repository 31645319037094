// ----------------------------------------------------------------------

import { LabelColor } from 'src/components/SimpleLabel';
import { LONG_CHART_COLORS } from 'src/theme/palette';

export default function getColorName(hex: string) {
  let color;

  switch (hex) {
    case '#00AB55':
      color = 'Green';
      break;
    case '#000000':
      color = 'Black';
      break;
    case '#FFFFFF':
      color = 'White';
      break;
    case '#FFC0CB':
      color = 'Pink';
      break;
    case '#FF4842':
      color = 'Red';
      break;
    case '#1890FF':
      color = 'Blue';
      break;
    case '#94D82D':
      color = 'Greenyellow';
      break;
    case '#FFC107':
      color = 'Orange';
      break;
    default:
      color = hex;
  }

  return color;
}

export const ActionTypeDict: { [id: string]: { color: LabelColor; icon: string } } = {
  STOCK: {
    color: 'primary',
    icon: 'mdi:chart-finance',
  },
  ETF: {
    color: 'bluePurple',
    icon: 'solar:chart-bold-duotone',
  },
  FUND: {
    color: 'turquoise',
    icon: 'icon-park-outline:funds',
  },
  REIT: {
    color: 'info',
    icon: 'pixelarticons:buildings',
  },
  CASH: {
    color: 'success',
    icon: 'fa6-solid:money-bill',
  },
  PP: {
    color: 'pink',
    icon: 'ph:piggy-bank-bold',
  },
  BOND: {
    color: 'redFire',
    icon: 'fa-solid:money-check',
  },
  UNKNOWN: {
    color: 'black',
    icon: 'carbon:unknown-filled',
  },
};

export function getPositionsGroupStyle(group: string) {
  switch (group) {
    case 'STOCKS':
      return {
        icon: 'ion:wallet',
        color: 'primary',
      };
    case 'ALTERNATIVE_INVESTMENTS':
      return {
        color: 'bluePurple',
        icon: 'fluent:building-retail-money-24-filled',
      };
    case 'CASH_ACCOUNTS':
      return {
        icon: 'fa:bank',
        color: 'success',
      };
    default:
      return ActionTypeDict.UNKNOWN;
  }
}

export function getNextLongChartColor(currentIndex: number): string {
  const currentColor = LONG_CHART_COLORS[currentIndex % LONG_CHART_COLORS.length];
  return currentColor;
}
