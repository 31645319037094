import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import isString from 'lodash/isString';
import { RefObject } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';

// ----------------------------------------------------------------------

export interface Props {
  filePreview: any | null;
  scale: number;
  crop?: Crop;
  isIcon?: boolean;
  onImageLoad: (e: React.SyntheticEvent<HTMLImageElement>) => void;
  onChangeCompleteCrop: (crop: PixelCrop) => void;
  onChangeCrop: (crop: Crop) => void;
  imgRef: RefObject<HTMLImageElement>;
}

const DropZoneStyle = styled(Stack)({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: 10,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

export default function ReactCropZone({
  onImageLoad,
  onChangeCompleteCrop,
  onChangeCrop,
  filePreview,
  crop,
  scale,
  imgRef,
  isIcon,
}: Props) {
  return (
    <DropZoneStyle>
      <ReactCrop
        aspect={undefined}
        crop={crop}
        maxHeight={isIcon ? 90 : 240}
        maxWidth={isIcon ? 90 : 500}
        minHeight={isIcon ? undefined : 20}
        minWidth={isIcon ? undefined : 250}
        onChange={(_, percentCrop) => onChangeCrop(percentCrop)}
        onComplete={(c) => onChangeCompleteCrop(c)}
      >
        <img
          alt="Crop me"
          onLoad={onImageLoad}
          ref={imgRef}
          src={isString(filePreview) ? filePreview : filePreview.preview}
          style={{
            transform: `scale(${scale})`,
          }}
        />
      </ReactCrop>
    </DropZoneStyle>
  );
}
