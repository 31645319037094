import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NavSectionProps } from '../type';
import useLocales from 'src/hooks/useLocales';
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableGutters disableSticky {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List disablePadding key={group.subheader} sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.isTranslate ? translate(group.subheader) : group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavListRoot isCollapse={isCollapse} key={list.title} list={list} />
          ))}
        </List>
      ))}
    </Box>
  );
}
