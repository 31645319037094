import { StompSessionProvider } from 'react-stomp-hooks';

import { SocketWrapperProps } from './SocketWrapper.Types';
import { BASE_URL } from 'src/config';

// ----------------------------------------------------------------------

export default function SocketWrapper({
  children,
  token,
  refreshToken,
  user,
  refreshTokenSocket,
}: SocketWrapperProps) {
  return (
    <StompSessionProvider
      beforeConnect={() => refreshTokenSocket(token, refreshToken, user)}
      //debug={(str: any) => log(str)}
      url={`${BASE_URL}/ws-message?access_token=${token}`}
    >
      <>{children}</>
    </StompSessionProvider>
  );
}
