export const GET_ALTERNATIVE_INVESTMENTS = 'GET_ALTERNATIVE_INVESTMENTS';

export const GET_ALL_ALTERNATIVE_INVESTMENTS_TRANSACTIONS =
  'GET_ALL_ALTERNATIVE_INVESTMENTS_TRANSACTIONS';

export const GET_ALTERNATIVE_INVESTMENTS_PRICES = 'GET_ALTERNATIVE_INVESTMENTS_PRICES';

export const GET_USER_ALTERNATIVE_INVESTMENTS = 'GET_USER_ALTERNATIVE_INVESTMENTS';

export const GET_ALTERNATIVE_INVESTMENTS_POSITIONS = 'GET_ALTERNATIVE_INVESTMENTS_POSITIONS';
export const GET_ALL_ALTERNATIVE_INVESTMENTS_POSITIONS =
  'GET_ALL_ALTERNATIVE_INVESTMENTS_POSITIONS';

export const DELETE_PRICE_ALTERNATIVE_INVESTMENTS = 'DELETE_PRICE_ALTERNATIVE_INVESTMENTS';

export const ADD_PRICE_ALTERNATIVE_INVESTMENTS = 'ADD_PRICE_ALTERNATIVE_INVESTMENTS';
export const EDIT_PRICE_ALTERNATIVE_INVESTMENTS = 'EDIT_PRICE_ALTERNATIVE_INVESTMENTS';

export const ADD_ALTERNATIVE_INVESTMENTS = 'ADD_ALTERNATIVE_INVESTMENTS';
export const ADD_ALTERNATIVE_INVESTMENTS_STARTED = 'ADD_ALTERNATIVE_INVESTMENTS_STARTED';
export const ADD_ALTERNATIVE_INVESTMENTS_FAILURE = 'ADD_ALTERNATIVE_INVESTMENTS_FAILURE';

export const ADD_CLIENT_ALTERNATIVE_INVESTMENTS = 'ADD_CLIENT_ALTERNATIVE_INVESTMENTS';
export const ADD_CLIENT_ALTERNATIVE_INVESTMENTS_STARTED =
  'ADD_CLIENT_ALTERNATIVE_INVESTMENTS_STARTED';
export const ADD_CLIENT_ALTERNATIVE_INVESTMENTS_FAILURE =
  'ADD_CLIENT_ALTERNATIVE_INVESTMENTS_FAILURE';
