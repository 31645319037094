import { orderBy, values } from 'lodash';
// utils
import {
  alternativeClientPathConfig,
  calendarClientPathConfig,
  connectionsClientPathConfig,
  currentAccountsClientPathConfig,
  financialClientPathConfig,
  myWealthClientPathConfig,
  overflowClientPathConfig,
  reportingClientPathConfig,
  summaryClientPathConfig,
  userInfoClientPathConfig,
} from './utils/navClientPaths';
import { getConnectionDetails, getWalletsByType } from './utils/navConfigUtils';
// hooks
import { useMediaQuery } from '@mui/material';
import useGetClientInfo from '../services/useGetClientInfo';

export default function useGetClientNavConfig() {
  const { portfolios } = useGetClientInfo();
  const isXl_1 = useMediaQuery('(max-width:1888px)');
  const isXl_2 = useMediaQuery('(max-width:1748px)');
  const isLg_1 = useMediaQuery('(max-width:1512px)');
  const isLg_2 = useMediaQuery('(max-width:1393px)');
  const isDownMd = useMediaQuery('(max-width:1272px)');
  const isDownSm = useMediaQuery('(max-width:800px)');
  const isMobile = useMediaQuery('(max-width:587px)');
  const showDots = isXl_1 || isXl_2 || isLg_1 || isLg_2 || isDownMd || isDownSm || isMobile;

  const portfoliosArray = portfolios ? orderBy(values(portfolios), 'name') : [];
  const connections = getConnectionDetails(getWalletsByType(portfoliosArray, 'STOCKS'));
  const altInvestWallets = getConnectionDetails(
    getWalletsByType(portfoliosArray, 'ALTERNATIVE_INVESTMENTS')
  );

  const children: any[] = [];
  let mainPaths: any[] = [
    financialClientPathConfig(connections),
    alternativeClientPathConfig(altInvestWallets),
    currentAccountsClientPathConfig,
    calendarClientPathConfig,
    reportingClientPathConfig,
    userInfoClientPathConfig,
    connectionsClientPathConfig,
  ];

  if (isXl_1) {
    children.push(userInfoClientPathConfig);
    mainPaths = mainPaths.slice(0, 6);
  }

  if (isXl_2) {
    children.push(connectionsClientPathConfig);
    mainPaths = mainPaths.slice(0, 5);
  }

  if (isLg_1) {
    children.push(calendarClientPathConfig);
    mainPaths = mainPaths.slice(0, 4);
  }

  if (isLg_2) {
    children.push(reportingClientPathConfig);
    mainPaths = mainPaths.slice(0, 3);
  }

  if (isDownMd) {
    children.push(currentAccountsClientPathConfig);
    mainPaths = mainPaths.slice(0, 2);
  }

  if (isDownSm) {
    children.push(alternativeClientPathConfig(altInvestWallets));
    mainPaths = mainPaths.slice(0, 1);
  }

  if (isMobile) {
    children.push(financialClientPathConfig(connections));
    mainPaths = mainPaths.slice(0, 0);
  }

  const overflowPath = overflowClientPathConfig(children.reverse());

  const items = [summaryClientPathConfig, myWealthClientPathConfig, ...mainPaths];

  if (showDots) {
    items.push(overflowPath);
  }

  const navConfig = [
    {
      subheader: 'navbar.general',
      items,
      isTranslate: true,
    },
  ];

  return {
    navConfig,
  };
}
