// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function PageNotFoundIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        fill="none"
        height="260"
        viewBox="0 0 355 260"
        width="355"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <path
            clipRule="evenodd"
            d="M0 143.564C0 173.505 11.0412 200.789 29.2104 221.409C50.0544 245.072 80.2804 259.95 113.973 260C128.697 260.021 142.777 257.193 155.71 252.029C162.396 249.358 169.881 249.665 176.357 252.829C183.954 256.536 192.461 258.615 201.444 258.615C205.154 258.615 208.788 258.257 212.303 257.579C222.337 255.651 231.467 251.087 238.965 244.623C243.678 240.558 249.713 238.516 255.881 238.523H255.986C276.368 238.523 295.301 232.216 311.019 221.409C325.072 211.76 336.547 198.517 344.193 182.969C350.858 169.42 354.611 154.114 354.611 137.914C354.611 82.353 310.452 37.3052 255.986 37.3052C250.434 37.3052 244.994 37.7836 239.687 38.6763C225.634 15.47 200.47 0 171.764 0C159.644 0 148.154 2.75722 137.869 7.69978C128.088 12.3849 119.391 19.0423 112.278 27.1555C88.1219 27.5484 65.7863 35.5983 47.5053 49.0327C18.729 70.1677 0 104.637 0 143.564Z"
            fill="url(#paint0_linear_1832_107224)"
            fillRule="evenodd"
          />
        </g>
        <path
          d="M82.0837 101.289C100.291 101.289 115.05 86.8599 115.05 69.0609C115.05 51.2619 100.291 36.833 82.0837 36.833C63.8768 36.833 49.1172 51.2619 49.1172 69.0609C49.1172 86.8599 63.8768 101.289 82.0837 101.289Z"
          fill="#FFC107"
          opacity="0.15"
        />
        <path
          d="M82.0848 85.9651C91.6348 85.9651 99.3766 78.3967 99.3766 69.0607C99.3766 59.7246 91.6348 52.1562 82.0848 52.1562C72.5348 52.1562 64.793 59.7246 64.793 69.0607C64.793 78.3967 72.5348 85.9651 82.0848 85.9651Z"
          fill="url(#paint1_linear_1832_107224)"
        />
        <path
          d="M180.909 131.054C206.44 131.054 227.138 151.287 227.138 176.247C227.138 201.207 206.44 221.441 180.909 221.441C155.377 221.441 134.68 201.207 134.68 176.247C134.68 151.287 155.377 131.054 180.909 131.054ZM180.909 147.799C164.838 147.799 151.81 160.536 151.81 176.247C151.81 191.959 164.838 204.695 180.909 204.695C196.98 204.695 210.009 191.959 210.009 176.247C210.009 160.536 196.98 147.799 180.909 147.799Z"
          fill="#D56701"
        />
        <path
          d="M129.26 191.094C129.26 188.109 128.158 187.032 125.096 187.032H116.694V138.924C116.694 135.691 115.591 134.853 112.528 134.853H105.752C103.44 134.845 101.261 135.91 99.8806 137.723L59.2256 187.032C58.2059 188.466 57.6979 190.189 57.7801 191.934V196.244C57.7801 199.237 58.8823 200.315 61.9359 200.315H102.329V215.992C102.329 218.977 103.431 220.055 106.485 220.055H112.483C115.546 220.055 116.649 218.977 116.649 215.992V200.315H125.096C128.357 200.315 129.26 199.237 129.26 196.244V191.094ZM75.7582 187.032L102.329 154.954V187.032H75.7582Z"
          fill="#FF9634"
        />
        <path
          d="M304.084 191.094C304.084 188.109 302.982 187.032 299.919 187.032H291.482V138.924C291.482 135.691 290.379 134.853 287.316 134.853H280.586C278.274 134.845 276.094 135.91 274.714 137.723L234.059 187.032C233.033 188.464 232.518 190.186 232.596 191.934V196.244C232.596 199.237 233.697 200.315 236.751 200.315H277.153V215.992C277.153 218.977 278.255 220.055 281.309 220.055H287.308C290.37 220.055 291.472 218.977 291.472 215.992V200.315H299.919C303.226 200.315 304.084 199.237 304.084 196.244V191.094ZM250.582 187.032L277.153 154.954V187.032H250.582Z"
          fill="#FF9634"
        />
        <path
          d="M314.437 84.6596C314.437 81.3619 311.703 78.689 308.33 78.689C308 78.689 307.671 78.715 307.345 78.7684C305.917 76.1634 303.141 74.5355 300.117 74.529H299.82C300.801 70.6712 299.237 66.6221 295.892 64.3615C292.547 62.1009 288.12 62.1009 284.774 64.3615C281.429 66.6221 279.865 70.6712 280.847 74.529H280.549C277.542 74.4315 274.72 75.9446 273.187 78.4752C271.654 81.0059 271.654 84.1526 273.187 86.6833C274.72 89.2147 277.542 90.727 280.549 90.6295H309.125V90.5768C312.162 90.1832 314.432 87.6539 314.437 84.6596Z"
          fill="#FF9634"
          opacity="0.24"
        />
        <path
          d="M77.0233 143.754C77.0233 142.395 76.4707 141.093 75.4881 140.132C74.5056 139.171 73.1728 138.632 71.7831 138.632C71.5017 138.637 71.221 138.661 70.9428 138.702C69.7108 136.463 67.3188 135.068 64.7182 135.072H64.4652C65.3167 131.761 63.9789 128.28 61.1075 126.336C58.2359 124.392 54.4325 124.392 51.5611 126.336C48.6896 128.28 47.3518 131.761 48.2033 135.072H47.9503C45.3676 134.982 42.9406 136.277 41.6219 138.45C40.3031 140.623 40.3031 143.326 41.6219 145.499C42.9406 147.672 45.3676 148.967 47.9503 148.877H72.4607V148.833C75.0678 148.496 77.0181 146.325 77.0233 143.754Z"
          fill="#FF9634"
          opacity="0.24"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_1832_107224"
            x1="242.916"
            x2="368.506"
            y1="306.245"
            y2="16.7024"
          >
            <stop stopColor="#FF9634" />
            <stop offset="1" stopColor="#FF9634" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_1832_107224"
            x1="47.5013"
            x2="81.3013"
            y1="69.0607"
            y2="103.636"
          >
            <stop stopColor="#FFE16A" />
            <stop offset="1" stopColor="#B78103" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
