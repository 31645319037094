import { useContext } from 'react';
// mui
import { Stack, Typography, alpha, styled } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// context
import Context, { ContextType } from '../context';
// config
import { NAVBAR } from 'src/config';
// utils
import cssStyles from 'src/utils/cssStyles';

const FooterStyle = styled(Stack)<{ isDesktop?: boolean }>(({ theme, isDesktop }) => ({
  ...cssStyles(theme).bgGradient({
    direction: 'top',
    startColor: `${alpha(
      isDesktop ? theme.palette.background.default : theme.palette.background.paper,
      1
    )}`,
    endColor: `${alpha(
      isDesktop ? theme.palette.background.default : theme.palette.background.paper,
      0.4
    )}`,
  }),
  bottom: 0,
  zIndex: 100,
  width: NAVBAR.DASHBOARD_WIDTH - 1,
  ...cssStyles(theme).bgBlur({
    opacity: 0.2,
    blur: 0.8,
    color: isDesktop ? theme.palette.background.default : theme.palette.background.paper,
  }),
  boxShadow: 'none',
}));

export default function NavbarFooter() {
  const { isCollapse }: ContextType = useContext<ContextType>(Context);

  const isDesktop = useResponsive('up', 'lg');

  return (
    <FooterStyle
      isDesktop={isDesktop}
      sx={{
        alignItems: 'center',
        py: 2,
        ...(isCollapse && { width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH - 1 }),
      }}
    >
      {!isCollapse && (
        <Stack alignItems="center" flexDirection="row">
          <Typography color="text.disabled" fontSize={12} variant="subtitle2">
            {'Powered by'}
          </Typography>
          <Typography color="#FF9634" fontSize={12} ml={0.5} variant="subtitle2">
            {'VUMI'}
          </Typography>
        </Stack>
      )}
    </FooterStyle>
  );
}
