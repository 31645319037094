// hooks
import useGetInfoUser from '../services/useGetUserInfo';
import { adminPathConfig, companyPathConfig } from './utils/navConfigPaths';
import useGetActives from './useGetActives';
import useGetGeneral from './useGetGeneral';
import useGetLimited from './useGetLimited';

export default function useGetNavConfig() {
  const { isBusiness, isAdmin, isClient, access } = useGetInfoUser();
  const generalConfig = useGetGeneral();
  const activesConfig = useGetActives();
  const limtedConfig = useGetLimited();

  const isEmployee = isBusiness && !isClient;

  const isFullAccess = access === 'FULL_ACCESS';

  const general = {
    subheader: 'navbar.general',
    items: isFullAccess ? generalConfig : limtedConfig,

    isTranslate: true,
  };

  const navConfig = [general];

  if (!isEmployee && isFullAccess) {
    const actives = {
      subheader: 'navbar.actives',
      items: activesConfig,
      isTranslate: true,
    };
    navConfig.push(actives);
  }

  if (isEmployee || isAdmin) {
    const managementItems = [];
    if (isBusiness) managementItems.push(...companyPathConfig);
    if (isAdmin) managementItems.push(...adminPathConfig);

    const management = {
      subheader: 'navbar.management',
      isTranslate: true,
      items: managementItems,
    };
    navConfig.push(management);
  }

  return {
    navConfig,
  };
}
