import { useState } from 'react';
import { useDispatch } from 'react-redux';
// hooks
import { getNetworkClient } from 'src/services/config';
// services
import {
  getClientInformationRequest,
  getClientNetWorthRequestAction,
  getPortfoliosCompleteClientRequestAction,
} from 'src/services/client';
// types
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GetInfoClientResponseNetType } from 'src/services/client/NetTypes';
// redux action
import { clientReset } from 'src/redux/slices/ClientSlice/clientSlice';

export default function useNavigateClient() {
  const [idLoading, setIdLoading] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeClient = () => {
    dispatch(clientReset());
  };

  const onSuccess = () => {
    navigate(PATH_DASHBOARD.client.summary, { replace: true });
    setIdLoading('');
  };

  const navigateClient = (id: string, onFinish: () => void) => {
    setIdLoading(id);
    getNetworkClient().executeRequest(
      getClientInformationRequest(id, (data: GetInfoClientResponseNetType) => {
        dispatch(getClientNetWorthRequestAction(id));
        dispatch(
          getPortfoliosCompleteClientRequestAction(id, data, () => {
            onSuccess();
            onFinish();
          })
        );
      })
    );
  };

  return {
    navigateClient,
    removeClient,
    idLoading,
  };
}
